import React, { useRef, useEffect, useState } from 'react';
import { View, Text, Image } from 'react-native';

const Thumbnail = ({ url, width = 30, height = 30, style={} }) => {
	if (!url) return <div style={{width, height}}></div>;
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [thumbnailDataUrl, setThumbnailDataUrl] = useState(null);

  useEffect(() => {
	const isVideo = (url.startsWith('data:video/') || url.endsWith('.mp4') || url.endsWith('.webm') || url.endsWith('.ogg') || url.endsWith('.avi'));
	if (!isVideo) {
		setThumbnailDataUrl(url);
		return;
	}

    const videoElement = videoRef.current;
    const canvasElement = canvasRef.current;

    const captureThumbnail = () => {
      const context = canvasElement.getContext('2d');
	  var hRatio = width / videoElement.videoWidth;
	  var vRatio = height / videoElement.videoHeight;
	  var ratio  = Math.min (hRatio, vRatio);
	  canvasElement.height = videoElement.videoHeight*ratio;
	  canvasElement.width = videoElement.videoWidth*ratio;
      context.drawImage(videoElement, 0, 0, videoElement.videoWidth, videoElement.videoHeight, 0, 0, canvasElement.width, canvasElement.height);
	  
      const dataUrl = canvasElement.toDataURL('image/png');
      setThumbnailDataUrl(dataUrl);
    };

    videoElement.addEventListener('loadeddata', captureThumbnail);

    return () => {
      videoElement.removeEventListener('loadeddata', captureThumbnail);
    };
  }, [url, width, height]);

  return (
	<>
    	<View style={[{alignItems: 'center', justifyContent: 'center', width, height}, style]}>
      		{thumbnailDataUrl && <Image source={thumbnailDataUrl} alt="Video Thumbnail" style={{resizeMode: 'contain', width: width+'px', height: height+'px'}} />}
    	</View>
		<video ref={videoRef} src={url} preload={'auto'} style={{ display: 'none'}} crossOrigin="anonymous" />
    	<canvas ref={canvasRef} style={{ display: 'none' }} />
	</>
  );
};

export default Thumbnail;
