import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../main.styles';

import PageView from '../../components/views/page.view.component';
import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';
import LinkButton from '../../components/linkButton.component';

import { ModelComponent, connect } from '../ModelComponent';

import { Kiosk } from '../kiosks/kiosk.component';
import { Device } from '../devices/device.component';
import AddDeviceModal from '../devices/modals/addDevice.modal';
import { Site } from '../sites/site.component';

class DashboardView extends ModelComponent {
	constructor(props) {
		super(props);
		const deviceID = localStorage.getItem('addDevice');
		this.state = {
			addDeviceOpen: (deviceID != null),
			deviceID,
		}
		localStorage.removeItem('addDevice');
	}
	renderContent() {
		const kiosksBySiteID = {}; // { siteID: [kisok, ...], ... }
		this.getModelList('Kiosk').forEach((data) => {
			const siteID = data.siteID.value;
			if (!(siteID in kiosksBySiteID)) kiosksBySiteID[siteID] = [];
			kiosksBySiteID[siteID].push(data.id.value);
		});

		const borderStyle = { borderLeftWidth: 2, borderColor: '#8a745a' };
		const kioskContainerStyle = { marginLeft: 29, marginBottom: 40, marginTop: 5};

		return (
			<PageView>
				<AddDeviceModal
					deviceID={this.state.deviceID}
					open={this.state.addDeviceOpen}
					closeModal={() => this.setState({ addDeviceOpen: false })}
				/>
				<View>
					{this.makeList('Site', (data) => (<View key={data.id.value}>
						<Site id={data.id.value} showBox={false} />
						{(!(data.id.value in kiosksBySiteID)) ? null : <>
							<View style={[borderStyle, kioskContainerStyle]}>
								<View style={{ marginLeft: 29, flexDirection: 'column', gap: 40 }}>
									{/* <Text style={styles.subTitle}>Kiosks at {data.name.value}:</Text> */}
									{kiosksBySiteID[data.id.value].map((kioskID) => (
										<Kiosk id={kioskID} key={kioskID} />
									))}
									{/*
						<View style={{flexDirection: 'row'}}>
							<LinkButton title="Previous Page" onPress={() => this.updateState({pagination: {...this.state.pagination, currentPage: this.state.pagination.currentPage-1}})} />
							<Text> Page {this.state.pagination.currentPage} {this.state.pagination.listCount} </Text>
							<LinkButton title="Next Page" onPress={() => {
								this.updateState({pagination: {...this.state.pagination, currentPage: this.state.pagination.currentPage+1}});
								this.loadModels(true);
							}} />
						</View>
						*/}
								</View>
							</View>
						</>}
					</View>), () => true, 30)}
				</View>

				<View style={{ marginTop: 50 }}>
					<Text style={styles.title}>Devices</Text>
					{this.makeList('Device', (data) => (
						<Device id={data.id.value} key={data.id.value} />
					))}
				</View>
			</PageView>
		);
	}
}

export default DashboardView = connect({
	loadingName: 'Dashboard',
	models: [{ model: 'Site', loadChildren: true, list: true }, { model: 'Device', list: true }],
	component: DashboardView,
});
