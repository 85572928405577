import React from 'react';
import { View, Text } from 'react-native';
import { styles, siteRed } from '../../../main.styles';

import { faSortUp, faSortDown, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';
import IconButton from '../../../components/iconButton.component';

import { ModelModal, connect } from '../../ModelComponent';

import AddPlaylistItemModal from './addPlaylistItem.modal';
import PlaylistItemSettingsModal from './playlistItemSettings.modal';
import Thumbnail from '../../../components/thumbnail.component';

class PlaylistSettingsModal extends ModelModal {
	constructor(props) {
		super(props, "Playlist Settings");
	}
	renderModal() {
		const form = this.getFormHelper('SignagePlaylist', this.props.id);
		let nextIndex = 0;
		const itemList = this.getModelList('SignagePlaylistItem', (modelData) => {
			const data = modelData.model.data;
			if (!(this.props.id == data.playlistID.value)) return false;
			if (data.playlistIndex.value >= nextIndex) nextIndex = data.playlistIndex.value + 1;
			return true;
		});
		itemList.sort((a, b) => a.playlistIndex.value - b.playlistIndex.value);
		
		return (<>
			<AddPlaylistItemModal
				open={this.state.addPlaylistItemModalOpen}
				playlistID={this.props.id}
				playlistIndex={nextIndex}
				closeModal={() => this.setState({ addPlaylistItemModalOpen: false })}
			/>
			{form.textInput('name')}
			{form.textInput('description', true)}
			{form.colorPicker('backgroundColor', true)}
			{form.checkBox('mutePlaylist', {onChange: (result) => {
				if (result == false) {
					this.showConfirmDialogue('Are You Sure?', 'Due to web browser policies, unmuted videos will not play until a user interacts with (clicks/taps) the screen.').catch(() => {
						form.setModelField('mutePlaylist', true);
					});
				}
			}})}
			<Text style={[styles.label, { marginVertical: 10 }]}>Playlist Items:</Text>
			<View style={{ flexDirection: 'column', gap: 5 }}>
				{itemList.map(data => (
					<View key={data.id.value}>
						{this.state.playlistItemSettingsOpen?.[data.id.value] && <PlaylistItemSettingsModal
							id={data.id.value}
							open={this.state.playlistItemSettingsOpen?.[data.id.value]}
							closeModal={() => this.updateState({ playlistItemSettingsOpen: { ...this.state.playlistItemSettingsOpen, [data.id.value]: false } })}
						/>}
						<View style={{ flexDirection: 'row', gap: 10, alignItems: 'center', paddingBottom: 5, borderBottomWidth: 1, borderBottomColor: "#8a745a" }}>
							<View style={{ flexDirection: 'column' }}>
								<IconButton size={20} margin={0} style={{ marginBottom: -8 }} icon={faSortUp} onPress={() => {
									if (data.playlistIndex.value <= 0) return;
									const form = this.getFormHelper('SignagePlaylistItem', data.id.value);
									form.setModelField('playlistIndex', data.playlistIndex.value - 1).then(() => {
										if (data.playlistIndex.value - 1 in itemList) {
											const formForSwap = this.getFormHelper('SignagePlaylistItem', itemList[data.playlistIndex.value - 1].id.value);
											formForSwap.setModelField('playlistIndex', data.playlistIndex.value).then(() => {
												form.save();
												formForSwap.save()
											});
										} else {
											return form.save();
										}
									});
								}} />
								<Text style={{ fontWeight: 500, width: 20, textAlign: 'center' }}>{data.playlistIndex.value + 1}</Text>
								<IconButton size={20} margin={0} style={{ marginTop: -8 }} icon={faSortDown} onPress={() => {
									if (data.playlistIndex.value + 1 >= nextIndex) return;
									const form = this.getFormHelper('SignagePlaylistItem', data.id.value);
									form.setModelField('playlistIndex', data.playlistIndex.value + 1).then(() => {
										if (data.playlistIndex.value + 1 in itemList) {
											const formForSwap = this.getFormHelper('SignagePlaylistItem', itemList[data.playlistIndex.value + 1].id.value);
											formForSwap.setModelField('playlistIndex', data.playlistIndex.value).then(() => {
												form.save();
												formForSwap.save()
											});
										} else {
											return form.save();
										}
									});
								}} />
							</View>

							<Thumbnail url={this.getFileUri(data.filePath.value)} />

							<Text style={{ flex: 1, fontWeight: 500 }}>{data.name.value}</Text>

							<IconButton color={siteRed} size={20} onPress={() => {
								this.showConfirmDialogue("Delete "+data.name.value+"?", "Are you sure you want to delete this Playlist Item?")
								.then(() => this.deleteModel({
									model: 'SignagePlaylistItem',
									id: data.id.value,
									children: []
								}))
								.then(() => {
									//update indexes
									signagePlaylistItems.slice(data.playlistIndex.value + 1).forEach((item, index) => {
										const form = this.getFormHelper('SignagePlaylistItem', item.id.value);
										form.setModelField('playlistIndex', data.playlistIndex.value + index).then(() => form.save());
									});
								}).catch(console.error)
							}} icon={faTrashAlt} />
							<IconButton size={20} onPress={() => this.updateState({ playlistItemSettingsOpen: { ...this.state.playlistItemSettingsOpen, [data.id.value]: true } })} />
						</View>
					</View>
				))}
			</View>
			<View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 10 }}>
				<LinkButton title="Add Item" onPress={() => this.setState({ addPlaylistItemModalOpen: true })} />
			</View>
		</>);
	}
	renderModalBottom() {
		const form = this.getFormHelper('SignagePlaylist', this.props.id);
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Close" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Done" style={{ width: 100 }} onPress={() => form.save().then(() => this.props.closeModal()).catch(console.error)} />
				</View>
			</View>
		</>);
	}
}
export default PlaylistSettingsModal = connect({
	component: PlaylistSettingsModal,
	models: [{
		model: 'SignagePlaylist',
		loadChildren: true,
	}, {
		model: 'SignagePlaylistItem',
		list: true,
		keyName: 'playlistID',
		key: (r, props) => props.id,
	}]
});

