import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import PageView from '../../components/views/page.view.component'
import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';
import LinkButton from '../../components/linkButton.component';

import { ModelComponent, connect } from '../ModelComponent';

import { Kiosk } from './kiosk.component';
import AddKioskModal from './modals/addKiosk.modal';

import ButtonStyle from '../library/buttonStyle.component';
import AddButtonStyleModal from '../library/modals/addButtonStyle.modal';

import TextStyle from '../library/textStyle.component';
import AddTextStyleModal from '../library/modals/addTextStyle.modal';

class KiosksView extends ModelComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      addKioskModalOpen: false,
	  addButtonStyleModalOpen: false,
	  addTextStyleModalOpen: false,
	  pageWidth: 0,
    }
  }
  renderContent() { 
    return (
      <PageView>
		{this.accountAccess === 'admin' && <>
			<AddKioskModal
				open={this.state.addKioskModalOpen} 
				closeModal={() => this.setState({addKioskModalOpen: false})} 
			/>
		</>}
        {this.makeList('Kiosk', (data) => (
          <Kiosk id={data.id.value} key={data.id.value} />
        ), () => true, 30)}
		{this.accountAccess === 'admin' && <>
			<View style={{flexDirection: 'row', justifyContent: 'center', marginTop: 10}}>
				<LinkButton title="Add Kiosk" onPress={() => this.setState({addKioskModalOpen: true})} />
			</View>
		</>}
      </PageView>
    );
  }
}

export default KiosksView = connect({
  loadingName: 'Kiosks',
  models: [
	  (root, props) => {
		  if (root.userData.accountType === 'organisation') return {model: 'Kiosk', list: true, keyField: 'organisationID', useKey: true, key: (root) => root.userData.organisationID};
		  return {model: 'Kiosk', list: true, keyField: 'siteID', useKey: true, key: (root) => root.userData.siteID};
	  }, 
	  {model: 'ButtonStyle', list: true, loadChildren: true}, 
	  {model: 'TextStyle', list: true}],
  component: KiosksView,
});
