
export const openMobileUI = actionData => ({
    type: 'OPEN_MOBILE_UI',
    payload: actionData,
});

export const closeMobileUI = () => ({
    type: 'CLOSE_MOBILE_UI',
    payload: null,
});

export const UICallback = (callbackData) => ({
    type: 'CALLBACK_MOBILE_UI',
    payload: callbackData,
});
