import {
    StyleSheet
} from 'react-native';

export const siteBlue = '#8a745a';// 'rgb(20, 126, 251)';
export const siteRed = '#F33621';

export const styles = StyleSheet.create({
    container: {
        height: '100%',
    },
    normalView: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
    },
    centerView: {
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    fullCenteredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        width: '100%',
        height: '100%',
    },
    inlineView: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    inlineCenterView: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: "center",
    },
    smallShadowBox: {
        borderRadius: 10,
        padding: 5,
        shadowColor: '#8a745a',
		backgroundColor: '#FCFCFC',
        shadowOffset: {
            width: 2,
            height: 2
        },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    shadowBox: {
		padding: 20,
        borderRadius: 10,
        shadowColor: '#8a745a',
		backgroundColor: '#FCFCFC',
        shadowOffset: {
            width: 2,
            height: 2
        },
        shadowOpacity: 0.2,
        shadowRadius: 5,
    },
    shadowBoxNoPadding: {
		borderBottomWidth: 1,
		borderColor: "#8a745a",
		backgroundColor: '#FCFCFC',
        /*
		borderRadius: 10,
        shadowColor: '#000',
        shadowOffset: {
            width: 2,
            height: 2
        },
        shadowOpacity: 0.2,
        shadowRadius: 2,
		*/
    },
    formGroup: {
        marginTop: 2,
        marginBottom: 5,
        width: '100%',
        //maxWidth: 700,
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    formGroupStacked: {
        marginTop: 2,
        marginBottom: 10,
        width: '100%',
        //maxWidth: 700,
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    label: {
        marginRight: 5,
        fontWeight: '400',
		color: '#444',
    },
	labelTitle: {
        marginRight: 5,
        fontWeight: '500',
		color: '#444',
    },
    biggerLabel: {
        marginTop: 20,
        marginRight: 5,
        fontSize: 18,
        fontWeight: '500',
    },
    heavy: {
        fontWeight: '500',
    },
    input: {
        padding: 10,
		paddingHorizontal: 10,
        height: 35,
        borderColor: "#AAA",
        borderWidth: 1, 
        borderRadius: 5,
        width: '100%',
        backgroundColor: '#FFF',
    },
    dropdown: {
		padding: 5,
		paddingHorizontal: 10,
        height: 30,
        borderRadius: 5,
		borderColor: "#AAA",
		borderWidth: 1, 
        width: '100%',
        maxWidth: 250,
    },
    iosDropdownButton: {
        width: '100%',
        maxWidth: 250,
        height: 35,
        padding: 8,
        color: '#35F',
        backgroundColor: '#FFF',
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#AAA',
    },
    error: {
        color: '#A00',
        width: '100%',
        textAlign: 'center',
        padding: 20,
        backgroundColor: 'rgba(255,0,0,0.1)',
        borderRadius: 10,
        marginVertical: 10,
    },
    success: {
        color: '#0A0',
        width: '100%',
        textAlign: 'center',
        padding: 20,
        backgroundColor: 'rgba(0,255,0,0.1)',
        borderRadius: 10,
        marginVertical: 10,
    },
    greyText: {
        color: '#888',
    },
	greyItalicsText: {
        color: '#888',
		fontStyle: 'italic'
    },
    smallText: {
        fontSize: 12,
    },
    centerText: {
        textAlign: 'center',
    },
    submitButton: {
        padding: 10,
        height: 40,
        color: "white",
    },
    button: {
        borderRadius: 10,
        padding: 10,
        elevation: 2
    },
    uniformButton: {
        maxWidth: 250,
        borderRadius: 10,
        padding: 10,
        elevation: 2
    },
    buttonOpen: {
        backgroundColor: '#8a745a', //"#2136F3",
    },
    buttonClose: {
        backgroundColor: "#F33621",
    },
    buttonTextStyle: {
        color: '#FFF'
    },
    title: {
        marginBottom: 15,
        color: '#222',
        fontSize: 28,
        fontWeight: '500'
    },
    subTitle: {
        color: '#222',
        fontSize: 18,
        fontWeight: '500'
    },
	stringText: {
        color: '#222',
        fontSize: 15,
        fontWeight: '500'
    },
});
