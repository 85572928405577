import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { ModelModal, connect } from '../../ModelComponent';

import TextStylePicker from '../../../components/textStylePicker.component';

class TextStyleSettingsModal extends ModelModal {
	constructor(props) {
		super(props, "Text Style Settings");
		this.state = {
			...this.state,
		}
	}
	renderModal() {
		const data = this.getModelValues(['fontFamily', 'fontSize', 'fontColor', 'centerText'], 'TextStyle', this.props.id);
		return (<>
			{this.renderModelTextInput({ name: 'name', model: 'TextStyle', id: this.props.id })}

			{this.renderModelTextInput({ name: 'description', model: 'TextStyle', id: this.props.id }, true)}

			<TextStylePicker
				fontFamily={data.fontFamily} 
				fontSize={data.fontSize} 
				fontColor={data.fontColor} 
				centerText={data.centerText} 
				text="Sample Text" 
				onChange={(results) => {
					console.log(results);
					this.setModelFields(results, 'TextStyle', this.props.id)
				}}
				palette={this.makeSimpleList('PaleteColor', data => data.color.value)}
				onPaletteAdd={(color) => {
					this.resetDefaultModel('PaleteColor')
					.then(() => this.setModelFields({
						name: color,
						color
					}, 'PaleteColor', 'default'))
					.then(() => {
						this.saveModel(true, null, 'PaleteColor', 'default').catch(console.error);
					});
				}}
			/>
		</>);
	}
	renderModalBottom() {
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Close" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Save" style={{ width: 100, marginRight: 20 }} onPress={() => this.saveModel(false, null, 'TextStyle', this.props.id).then(() => this.props.closeModal(true)).catch(console.error)} />
					<OutlineButton title="Save As New" style={{ width: 200, marginRight: 20 }} onPress={() => this.saveModel(true, null, 'TextStyle', this.props.id, ['id'], false).then((response) => {
						const id = response.data.data.data.id.value;
						this.props.closeModal(true, id);
					}).catch(console.error)} />
					<OutlineButton title="Delete" color='#A00' style={{ width: 100, marginRight: 20 }} onPress={() => {
						const textStyle = this.getModelData('TextStyle', this.props.id)?.data;
						this.showConfirmDialogue("Delete "+textStyle.name+"?", "Are you sure you want to delete this Text Style?")
						.then(() => this.deleteModel({
							model: 'TextStyle',
							id: this.props.id,
							children: []
						}))
						.then(() => this.props.closeModal(true))
						.catch(console.error)
					}} />
				</View>
			</View>
		</>);
	}
}
export default TextStyleSettingsModal = connect({
	models: [{ model: 'TextStyle' }, {model: 'PaleteColor', list: true}],
	component: TextStyleSettingsModal
});

