import React from 'react';
import { WebView as WebViewNative } from 'react-native-webview';
import { View, Platform } from 'react-native';

export default class WebView extends React.Component {
	loadIFrame = () => {
		if ('iframe' in this.refs) {
			if (this.props.url) {
				console.log('loading '+this.props.url);
				this.refs.iframe.src = this.props.url;
			}
			else this.refs.iframe.srcdoc = this.props.html;
		}
    }
	render() {
		const bodyStyle = this.props.disableTouch ? 'pointer-events: none;' : '';
		if (Platform.OS === 'web') setTimeout(this.loadIFrame);
		return (
			<View style={this.props.style}>
				{this.props.disableTouch && <View style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 1002 }}></View>}
				{Platform.OS === 'web' 
				? (this.props.url && (this.props.url.substring(0, 29) == 'https://www.youtube.com/embed'))
					? <iframe ref="iframe" style={{ height: '100%', width: '100%', border: 0}} title="YouTube video player" autoPlay={true} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
					: <iframe ref="iframe" frameBorder="0" style={{ height: '100%', width: '100%', border: 0}} />
				: this.props.url
					? <WebViewNative
						source={{ uri: this.props.url }}
						automaticallyAdjustContentInsets={false}
					/>
					: <WebViewNative
						source={{ html: '<!DOCTYPE html><html style="background-color: rgba(0, 0, 0, 0);width:100%;height:100%;"><body style="background-color: rgba(0, 0, 0, 0); transform-origin: left top; transform:scale(' + (this.props.scale || 1) + ');width:100%;height:100%;padding:0;margin:0;border:0;overflow:hidden;' + bodyStyle + '">' + (this.props.html || '') + '</body></html>' }}
						automaticallyAdjustContentInsets={false}
					/>
				}
			</View>
		);
	}
}
