import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { ModelModal, APIModal, connect } from '../../ModelComponent';

class FolderSettingsModal extends ModelModal {
	constructor(props) {
		super(props, "Folder Settings");
		this.state = {
			...this.state,
		}
	}
	renderModal() {
		console.log(this.props.models);
		const form = this.getFormHelper('LibraryFolder', this.props.id);
		return (<>
			{form.textInput('name')}
		</>);
	}
	renderModalBottom() {
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Close" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Save" style={{ width: 100, marginRight: 20 }} onPress={() => this.saveModel(false, null, 'LibraryFolder', this.props.id).then(() => this.props.closeModal()).catch(console.error)} />
					<OutlineButton title="Delete" color='#A00' style={{ width: 100, marginRight: 20 }} onPress={() => {
						const buttonStyle = this.getModelData('LibraryFolder', this.props.id)?.data;
						this.showConfirmDialogue("Delete "+buttonStyle.name+"?", "Are you sure you want to delete this Library Folder? This action will also delete all Images, Button Styles and Text Styles within it. This action cannot be undone.")
						.then(() => {
							const modelDeletions = [{
								model: 'LibraryFolder',
								id: this.props.id,
								children: []
							}];

							const context = this;

							function searchFolderForDeletions(folderID) {

								const filter = (data) => {
									const itemParentID = data.model.data.parentID?.value || 0;
									if (itemParentID && itemParentID === folderID) return true;
								}
								
								['LibraryFolder', 'LibraryItem', 'TextStyle', 'ButtonStyle'].forEach(itemName => {
									context.getModelList(itemName, filter).forEach(item => {
										modelDeletions.push({
											model: itemName,
											id: item.id.value,
											children: []
										});
										if (itemName == 'LibraryFolder') {
											searchFolderForDeletions(item.id.value);
										}
									});
								});
							}
							searchFolderForDeletions(this.props.id);

							console.log(modelDeletions);
							const promises = [];
							modelDeletions.forEach(deletion => { promises.push(context.deleteModel(deletion)) });
							return Promise.all(promises);
						})
						.then(() => this.props.closeModal(true))
						.catch(console.error)
					}} />
				</View>
			</View>
		</>);
	}
}
export default FolderSettingsModal = connect({
	models: [
		/* { model: 'LibraryFolder', key: (root, props) => props.id }, */		
		{ model: 'LibraryItem', list: true },
		{ model: 'LibraryFolder', list: true, loadChildren: true },
		{ model: 'ButtonStyle', list: true, loadChildren: true },
		{ model: 'TextStyle', list: true },
	],
	component: FolderSettingsModal
});

