import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faTimesCircle, faCheckCircle, faWifi } from '@fortawesome/free-solid-svg-icons'
import { faUsb, faBluetooth } from '@fortawesome/free-brands-svg-icons'

import { ModelComponent, connect } from '../ModelComponent';

import PageView from '../../components/views/page.view.component'
import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';
import IconButton from '../../components/iconButton.component';
import Thumbnail from '../../components/thumbnail.component';

import PlaylistSettingsModal from './modals/playlistSettings.modal';
import LinkButton from '../../components/linkButton.component';
import PlaylistPreviewModal from './modals/playlistPreview.modal';

class PlaylistComponent extends ModelComponent {
	constructor(props) {
		super(props);
		this.state = {
			settingsOpen: false,
			previewOpen: false,
		}
	}
	renderContent() {
		const getValue = this.modelValueGetter('SignagePlaylist', this.props.id);
		
		const itemList = this.getModelList('SignagePlaylistItem', (modelData) => this.props.id == modelData.model.data.playlistID.value);
		itemList.sort((a, b) => a.playlistIndex.value - b.playlistIndex.value);

		return (<>
			{this.accountAccess === 'admin' && <PlaylistSettingsModal
				id={this.props.id}
				open={this.state.settingsOpen}
				closeModal={() => this.setState({ settingsOpen: false })}
			/>}
			<PlaylistPreviewModal 
				id={this.props.id} 
				open={this.state.previewOpen}
				closeModal={() => this.setState({ previewOpen: false })}
			/>
			<View style={[styles.shadowBoxNoPadding, { flexDirection: 'column', background: '#333', minWidth: 270, borderRadius: 5, borderBottomWidth: 0, gap: 10, padding: 10, flexWrap: 'wrap' }]}>
				<View style={{ flexDirection: 'column'}}>
					<View style={{flexDirection: 'row', gap: 5, marginBottom: 5}}>
						{this.accountAccess === 'admin' && <IconButton size={30} stretchContainer={false} title="Open Playlist Settings" onPress={() => this.setState({ settingsOpen: true })} />}
						<Text style={[styles.title, { color: '#FFF', marginBottom: 0 }]}>{getValue('name')}</Text>
					</View>
					<Text style={{color: '#FFF', paddingHorizontal: 5}}>{getValue('description')}</Text>
				</View>
				<View style={{ flex: 1, flexDirection: 'column', gap: 5}}>
					{itemList.map(data => { console.log(data); return (
						<View key={data.id.value} style={{flexDirection: 'row', alignItems: 'center', gap: 10}}>
							<Text style={{ fontWeight: 500, width: 20, textAlign: 'center', color: '#FFF' }}>{data.playlistIndex.value + 1}</Text>
							<Thumbnail url={this.getFileUri(data.filePath.value)} style={{marginTop:2}} />
							<Text style={{ flex: 1, fontWeight: 500, color: '#FFF' }}>{data.name.value}</Text>
						</View>
					)})}
				</View>
				{itemList.length > 0 && <View style={{ flexDirection: 'row', justifyContent: 'center'}}>
					<LinkButton title="Preview Playlist" onPress={() => this.setState({ previewOpen: true })} />
				</View>}
				{this.renderError()}
			</View>
		</>);
	}
}
export const Playlist = connect({
	component: PlaylistComponent,
	models: [{
		model: 'SignagePlaylist',
		loadChildren: true,
	}, {
		model: 'SignagePlaylistItem',
		list: true,
		keyName: 'playlistID',
		key: (r, props) => props.id,
	}]
});
