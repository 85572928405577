import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faTimesCircle, faCheckCircle, faWifi } from '@fortawesome/free-solid-svg-icons'
import { faUsb, faBluetooth } from '@fortawesome/free-brands-svg-icons'

import { ModelComponent, connect } from '../ModelComponent';

import PageView from '../../components/views/page.view.component'
import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';
import LinkButton from '../../components/linkButton.component';
import IconButton from '../../components/iconButton.component';

import { navigate } from '../../root.navigation';

import TemplateSettingsModal from './modals/templateSettings.modal';

class TemplateComponent extends ModelComponent {
  constructor (props) {
    super(props);
    this.state = {
      settingsOpen: false,
    }
  }
  renderContent() {
	const getValue = this.modelValueGetter('KioskTemplate', this.props.id);
    return (<>
      <TemplateSettingsModal
        id={this.props.id} 
        open={this.state.settingsOpen} 
        closeModal={() => this.setState({settingsOpen: false})} 
      />
      <View style={[styles.shadowBoxNoPadding, {flexDirection: 'row', gap: 10, flexWrap: 'wrap'}]}>
	  	<IconButton size={30} stretchContainer={false} style={{paddingTop: 10, paddingLeft: 10}} title="Settings" onPress={() => this.setState({ settingsOpen: true })} />

		<View style={{flex: 1, flexDirection: 'column', paddingTop: 10, minWidth: 270}}>
          <Text style={styles.title}>{getValue('name')}</Text>
          <Text>{getValue('description')}</Text>
        </View>

        <View style={{flexDirection: 'column', alignItems: "center"}}>
			<View style={{height: 140, width: 140, borderWidth: 1, borderRadius: 5, borderColor: '#AAA'}}>
				<View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
					<Text style={{textAlign: 'center'}}>No Preview Available</Text>
				</View>
			</View>
			<LinkButton title="Edit Template" style={{padding: 5}} onPress={() => { 
				navigate('TemplateEditor', {id: this.props.id});
			}} />
		</View>

        {this.renderError()}
      </View>
    </>);
  }
}
export const Template = connect({
	component: TemplateComponent,
	models: [{
  		model: 'KioskTemplate', 
	}]
});
