import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import { ModelComponent, connect } from '../ModelComponent';

import IconButton from '../../components/iconButton.component';

import CustomerSettingsModal from './modals/customerSettings.modal';

class CustomerComponent extends ModelComponent {
  constructor (props) {
    super(props);
    this.state = {
      settingsOpen: false,
    }
  }
  renderContent() {
	const getValue = this.modelValueGetter('DataSet', this.props.id);
    return (<>
      <CustomerSettingsModal
        id={this.props.id} 
        open={this.state.settingsOpen} 
        closeModal={() => this.setState({settingsOpen: false})} 
      />
      <View style={{ flexDirection: 'row', gap: 10, flexWrap: 'wrap', borderBottomWidth: 1, borderBottomColor: '#EEE'}}>
	  	<View style={{ flexDirection: 'column', justifyContent: 'space-around', width: 40, padding: 10 }}>
			<IconButton size={30} stretchContainer={false} title="Settings" onPress={() => this.setState({ settingsOpen: true })} />
		</View>
        <View style={{flex: 1, flexDirection: 'column', padding: 10, minWidth: 270}}>
          <Text style={styles.strongText}>{getValue('customerName')}</Text>
          <Text>{getValue('customerMessage')}</Text>
        </View>
        {this.renderError()}
      </View>
    </>);
  }
}
export const Customer = connect({
	component: CustomerComponent,
	models: [{
  		model: 'DataSet', 
	}]
});

