import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { ModelModal, connect } from '../../ModelComponent';

import { navigate } from '../../../root.navigation';

class CountdownSettingsModal extends ModelModal {
	constructor(props) {
		super(props, "Edit "+props.name);
		this.state = {
			...this.state,
		}
	}
	renderModalLeft() {
		const componentID = this.getModelValue('componentID', 'TemplateComponent', this.props.id);
		const chosenAction = this.getModelValue('action', 'TemplateComponentCountdown', componentID);
		return (<>
			{this.renderModelTextInput({ name: 'name', model: 'TemplateComponent', id: this.props.id })}
			{this.renderModelTextInput({ name: 'description', model: 'TemplateComponent', id: this.props.id }, true)}
			
			{this.renderModelDropdown({
				displayText: 'Action', 
				name: 'action',
				model: 'TemplateComponentCountdown',
				id: componentID,
				options: [{label: 'Reveal', value: 'reveal'}, {label: 'Goto Page', value: 'gotoPage'}, {label: 'Open Modal', value: 'openModal'}],
				onChange: (val) => { console.log(val) }
			})}
			{chosenAction === 'gotoPage' && this.renderModelDropdown({
				displayText: 'Page', 
				name: 'actionData',
				model: 'TemplateComponentCountdown',
				id: componentID,
				options: this.makeDropdownOptions('TemplatePage', 'name', 'id', (model) => {
					console.log(model);
					return model.data.type?.value === 'page' && model.data.kioskTemplateID?.value === this.props.templateID
				}),
				onChange: (val) => { console.log(val) }
			})}

			{this.renderModelSlider({ name: 'duration', model: 'TemplateComponentCountdown', id: componentID, valueSuffix: 's' })}
			{this.renderModelCheckBox({ name: 'hidden', model: 'TemplateComponentCountdown', id: componentID })}
		</>);
	}
	renderModalRight() {
		return (
			<View>
				<Text style={styles.label}>Preview:</Text>
				<View style={{ flex: 1, borderWidth: 1, borderColor: '#AAA', borderRadius: 5, minHeight: 200, justifyContent: "center", alignItems: "center" }}>
					<Text style={{ textAlign: 'center' }}>No Preview Available</Text>
				</View>
			</View>
		);
	}
	renderModalBottom() {
		const componentID = this.getModelValue('componentID', 'TemplateComponent', this.props.id);
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Close" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Save" style={{ width: 200 }} onPress={() => {
						const children = [this.getModelData('TemplateComponentCountdown', componentID)];
						this.saveModel(false, children, 'TemplateComponent', this.props.id).then(
						() => this.props.closeModal()).catch(console.error);
					}} />
				</View>
			</View>
		</>);
	}
}
export default CountdownSettingsModal = connect({
	loadingName: 'Countdown',
	loadingSize: 'xsmall',
	models: [{ model: 'TemplateComponent' }, { model: 'TemplateComponentCountdown', key: (root, props) => props.componentID }],
	component: CountdownSettingsModal
});

