import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { ModelModal, APIModal, connect } from '../../ModelComponent';

class AddLibraryItemModal extends ModelModal {
	constructor(props) {
		super(props, "Add Library Image");
		this.state = {
			...this.state,
		}
	}
	renderModal() {
		const form = this.getFormHelper('LibraryItem', 'default');
		return (<>
			{form.textInput('name')}
			{form.filePicker('imagePath', {label: 'Image'})}
		</>);
	}
	renderModalBottom() {
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Close" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Add" style={{width: 100}} onPress={() => {
						this.setModelFields({'organisationID': this.userData.organisationID, 'siteID': this.userData.siteID, 'parentID': this.props.parentID }, 'LibraryItem').then(() => {
							console.log(this.getModelValue('organisationID', 'LibraryItem'));
							this.saveModel({create: true, model: 'LibraryItem', id: 'default', children: [], parent: {model: 'Site', id: this.getModelValue('siteID', 'LibraryItem')}}).then(() => this.props.closeModal()).catch(console.error)
						});
					}} />
				</View>
			</View>
		</>);
	}
}
export default AddLibraryItemModal = connect({
	models: [{ model: 'LibraryItem' }],
	component: AddLibraryItemModal
});

