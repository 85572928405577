import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';
import StyledText from '../../../components/styledText.component';

import { ModelModal, ModelEditorComponent, ModelComponent, connect } from '../../ModelComponent';

import { navigate } from '../../../root.navigation';

import LibraryComponent from '../../library/library.component';
import TextStyleSettingsModal from '../../library/modals/textStyleSettings.modal';
import { clientBrowserRevealSolutionSet } from '../../../actions/clientBrowsers.actions';

class TextSettingsModal extends ModelModal {
	constructor(props) {
		super(props, "Edit " + props.name);
		this.state = {
			...this.state,
			styleSettingsOpen: false,
			isLinked: false,
			linkedDataField: null,
			demoText: null,
		}
	}
	renderModalLeft() {
		const componentID = this.getModelValue('componentID', 'TemplateComponent', this.props.id);

		const isLinked = this.getModelValue('isLinked', 'TemplateComponentText', componentID);

		console.log(this.makeDropdownOptions('DataGroup'), this.state, this.props)

		return (<>
			{this.renderModelTextInput({ name: 'name', model: 'TemplateComponent', id: this.props.id })}
			{this.renderModelTextInput({ name: 'description', model: 'TemplateComponent', id: this.props.id }, true)}
			{this.renderCheckBox({
				label: 'Link with customer data', value: isLinked, onChange: (newValue) => {
					this.setModelField('isLinked', newValue, 'TemplateComponentText', componentID);
				}
			})}
			{isLinked
				? <>
					{this.renderModelDropdown({
						displayText: 'Preview Data Group',
						name: 'previewDataGroupID',
						model: 'KioskTemplate',
						id: this.props.templateID,
						options: this.makeDropdownOptions('DataGroup'),
						onChange: (val) => {
							this.setModelField('previewDataGroupID', val, 'KioskTemplate', this.props.templateID)
							.then(() => this.saveModel({model: 'KioskTemplate', id: this.props.templateID, fields: ['previewDataGroupID'], reloadModels: false}))
						}
					})}

					{this.renderModelRadioButtons({
						label: 'Data Field',
						name: 'linkedDataSetFieldName',
						model: 'TemplateComponentText',
						id: componentID,
						options: [
							{ label: 'Customer Name', value: 'customerName' },
							{ label: 'Customer Message', value: 'customerMessage' }
						],
						onChange: (newValue) => {
							this.updateState({ linkedDataField: newValue });
						}
					})}
				</>
				: this.renderModelTextInput({ name: 'text', model: 'TemplateComponentText', id: componentID }, true)}
		</>);
	}
	renderModalRight() {
		const componentID = this.getModelValue('componentID', 'TemplateComponent', this.props.id);
		const getTextBoxValue = this.modelValueGetter('TemplateComponentText', componentID);
		const textStyleID = getTextBoxValue('textStyleID');
		const data = this.getModelValues(['fontFamily', 'fontSize', 'fontColor', 'centerText'], 'TextStyle', textStyleID);

		const isLinked = this.getModelValue('isLinked', 'TemplateComponentText', componentID);

		const previewDataGroupID = isLinked && this.getModelValue('previewDataGroupID', 'KioskTemplate', this.props.templateID);
		const linkedDataField = isLinked && (this.state.linkedDataField || getTextBoxValue('linkedDataSetFieldName'));
		return (
			<View>
				<Text style={styles.label}>Preview:</Text>
				<View style={{ flex: 1, borderWidth: 1, borderColor: '#AAA', borderRadius: 5, minHeight: 200, overflow: 'hidden', justifyContent: "center", alignItems: "center" }}>
					<StyledText
						fontFamily={data.fontFamily}
						fontSize={data.fontSize}
						fontColor={data.fontColor}
						centerText={data.centerText}
						text={isLinked ? (previewDataGroupID ? this.state.demoText || linkedDataField : linkedDataField) : getTextBoxValue('text')}
						scale={0.4}
					/>
				</View>
				{isLinked && previewDataGroupID && <CustomerSelector id={previewDataGroupID} templateID={this.props.templateID} dataField={linkedDataField} onChange={(value, dataSetID) => {
					//this.setModelField('previewDataSetID', dataSetID, 'TemplateComponentText', componentID).then(()=> this.updateState({demoText: value}));
					this.updateState({ demoText: value });
				}} />}

				<View>
					{textStyleID && <>
						<TextStyleSettingsModal
							id={textStyleID}
							open={this.state.styleSettingsOpen}
							closeModal={(saved, styleID) => {
								if (saved && styleID) this.setModelFields({ textStyleID: styleID }, 'TemplateComponentText', componentID);
								this.setState({ styleSettingsOpen: false });
							}}
						/>
						<LinkButton title="Edit text style" onPress={() => {
							this.updateState({ styleSettingsOpen: true });
						}} />
					</>}

					<LibraryComponent isSelector={true} filterBy={'text'} style={{marginBottom: 10, marginTop: 5, width: 530}} selected={this.getModelValue('textStyleID', 'TemplateComponentText', componentID)} onSelected={(itemID) => {
						if (itemID) {
							this.setModelField('textStyleID', itemID, 'TemplateComponentText', componentID);
						}
					}} />
				</View>
			</View>
		);
	}
	renderModalBottom() {
		const componentID = this.getModelValue('componentID', 'TemplateComponent', this.props.id);
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Close" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Save" style={{ width: 200 }} onPress={() => {
						const children = [this.getModelData('TemplateComponentText', componentID)];
						this.saveModel(false, children, 'TemplateComponent', this.props.id).then(() => this.props.closeModal()).catch(console.error);
					}} />
				</View>
			</View>
		</>);
	}
}
export default TextSettingsModal = connect({
	loadingName: 'Text',
	loadingSize: 'xsmall',
	models: [{ model: 'KioskTemplate', key: (root, props) => props.templateID }, { model: 'TemplateComponent' }, { model: 'TemplateComponentText', key: (root, props) => props.componentID }, { model: 'TextStyle', list: true }, { model: 'DataGroup', list: true }],
	component: TextSettingsModal
});


class CustomerSelectorBase extends ModelEditorComponent {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			currentDataSetIndex: -1,
			dataSets: [],
		}
	}
	componentDidMount() {
		super.componentDidMount();
		this.checkForChange(null, null, null);
	}
	componentDidUpdate(prevProps, prevState) {
		super.componentDidUpdate(prevProps, prevState);
		this.checkForChange(prevProps.id, prevProps.dataField);
		if (prevProps.id != this.props.id || prevState.currentDataSetIndex != this.state.currentDataSetIndex && this.state.dataSets.length > 0 && this.state.currentDataSetIndex != -1) {
			const dataSet = this.state.dataSets[this.state.currentDataSetIndex];
			console.log(dataSet);
			if (dataSet) this.setModelField('previewDataSetID', dataSet.id.value, 'KioskTemplate', this.props.templateID)
				.then(() => this.saveModel({model: 'KioskTemplate', id: this.props.templateID, fields: ['previewDataSetID']}))
				.then(() => {
					const dataValue = dataSet[this.props.dataField]?.value;
					if (this.props.onChange) this.props.onChange(dataValue, dataSet.id.value);
				});
		}
	}
	checkForChange(prevID, prevDataField) {
		let dataSets = this.state.dataSets;
		let currentDataSetIndex = this.state.currentDataSetIndex;

		const modelDataSets = this.getModelList('DataSet');
		const modelDataSetsList = [];
		for (const dataSetID in modelDataSets) {
			modelDataSetsList.push(modelDataSets[dataSetID]);
		}

		if (prevID != this.props.id || dataSets.length != modelDataSetsList.length) {
			dataSets = modelDataSetsList;
			dataSets.sort((a, b) => a.id.value > b.id.value);
			currentDataSetIndex = 0;
			const previewDataSetID = this.getModelPropValue('previewDataSetID', 'KioskTemplate', this.props.templateID)
			if (previewDataSetID) {
				for (; currentDataSetIndex < dataSets.length; currentDataSetIndex++) {
					if (previewDataSetID == dataSets[currentDataSetIndex].id.value) break;
				}
			}
			this.updateState({ dataSets, currentDataSetIndex });

			if (dataSets.length > 0) {
				const dataSet = dataSets[currentDataSetIndex];
				const dataValue = dataSet[this.props.dataField]?.value;
				if (this.props.onChange) this.props.onChange(dataValue, dataSet.id.value);
			}
		}
		if (currentDataSetIndex != -1 && prevDataField != this.props.dataField && dataSets.length > 0) {
			const dataSet = dataSets[currentDataSetIndex];
			const dataValue = dataSet[this.props.dataField]?.value;
			if (this.props.onChange) this.props.onChange(dataValue, dataSet.id.value);
		}
	}
	renderContent() {
		const dataSets = this.state.dataSets;
		if (dataSets.length === 0 || currentDataSetIndex == -1) return null;

		const currentDataSetIndex = this.state.currentDataSetIndex;
		const dataSet = dataSets[currentDataSetIndex];
		const dataValue = dataSet[this.props.dataField]?.value;

		return (<View style={{ flexDirection: 'row', borderWidth: 1, borderTopWidth: 0, borderColor: '#AAA', borderRadius: 5, }}>
			<OutlineButton title="<" 
				buttonStyle={{width: 40}}
				onPress={() => {
					let newDataSetIndex = currentDataSetIndex - 1;
					if (newDataSetIndex < 0) newDataSetIndex = dataSets.length - 1;
					this.updateState({ currentDataSetIndex: newDataSetIndex });
					const newDataSet = dataSets[newDataSetIndex];
					const newDataValue = newDataSet[this.props.dataField]?.value;
					if (this.props.onChange) this.props.onChange(newDataValue, newDataSet.id.value);
				}
			} />
			<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}><Text>{dataValue}</Text></View>
			<OutlineButton title=">" 
				buttonStyle={{width: 40}}
				onPress={() => {
					let newDataSetIndex = currentDataSetIndex + 1;
					if (newDataSetIndex === dataSets.length) newDataSetIndex = 0;
					this.updateState({ currentDataSetIndex: newDataSetIndex });
					const newDataSet = dataSets[newDataSetIndex];
					const newDataValue = newDataSet[this.props.dataField]?.value;
					if (this.props.onChange) this.props.onChange(newDataValue, newDataSet.id.value);
				}
			} />
		</View>);
	}
}
const CustomerSelector = connect({
	loadingName: 'Customer Selector',
	loadingSize: 'xsmall',
	models: [{ model: 'KioskTemplate', key: (r, props) => props.templateID }, { model: 'DataSet', list: true, keyField: 'dataGroupID', useKey: true, key: (r, props) => props.id }],
	component: CustomerSelectorBase
});

