import React, { useState, useRef, useEffect } from 'react';
import { Image, View, Platform, Text, TouchableOpacity } from 'react-native';
import * as ExpoImagePicker from 'expo-image-picker';
import { Button } from 'react-native-paper';

import { siteBlue, siteRed } from '../main.styles';

import { faTrashAlt, faUpload } from '@fortawesome/free-solid-svg-icons';

import IconButton from './iconButton.component';
import Thumbnail from './thumbnail.component';


export default function ImagePicker(props) {
	const [prevUri, setPrevUri] = useState(props.uri);
	const [image, setImage] = useState(props.uri);
	const [result, setResult] = useState(false);
	if (props.uri !== prevUri) {
		setPrevUri(props.uri);
		setImage(props.uri);
		setResult(false);
	}

	useEffect(() => {
		(async () => {
			if (Platform.OS !== 'web') {
				const { status } = await ExpoImagePicker.requestMediaLibraryPermissionsAsync();
				if (status !== 'granted') {
					alert('This app needs permissions to access your images to upload them.');
				}
			}
		})();
	}, []);

	useEffect(() => {
		if (result !== false && props.onChange) {
			props.onChange(result);
		}
	}, [result]);

	const pickImage = async () => {
		let result = await ExpoImagePicker.launchImageLibraryAsync({
			mediaTypes: ExpoImagePicker.MediaTypeOptions.All,
			allowsEditing: true,
			/*aspect: [4, 3],*/
			quality: 1,
		});

		if (!result.cancelled) {
			setImage(result.uri);
			setResult(result);
		}
	};

	const removeImage = async () => {
		//console.log(props.context, props.context.showConfirmDialogue);
		if (props.context) {
			props.context.showConfirmDialogue("Remove Image File", "Are you sure you want to remove this image file?").then(() => {
				setImage(null);
				setResult(null);
			}).catch(err => console.error(err));
		} else {
			setImage(null);
			setResult(null);
		}
		//if (props.onChange) props.onChange(null);
	};

	//<View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
	return (<>
		<View style={[{ flexDirection: 'row', height: 35, borderRadius: 5, backgroundColor: '#FFF', borderColor: '#CCC', borderWidth: 1 }, props.style]}>
			<View style={{ flex: 1, flexDirection: 'column', justifyContent: 'center' }}>
				<Text style={{ padding: 5, paddingLeft: 10 }}>{props.label}</Text>
			</View>
			{props.extensions && props.extensions.map((extension, idx) => {
				return <IconButton key={idx} icon={extension.icon} color={extension.iconColor} style={{ justifyContent: 'center', marginRight: 20 }} onPress={extension.onPress} />;
			})}
			<IconButton icon={faUpload} color={siteBlue} style={{ justifyContent: 'center', marginRight: 10 }} onPress={pickImage} />
			{image && <IconButton icon={faTrashAlt} color={siteRed} style={{ justifyContent: 'center', marginRight: 10 }} onPress={removeImage} />}
			<TouchableOpacity style={{ width: 90, borderLeftWidth: 1, borderColor: '#CCC', backgroundColor: props.color, borderTopRightRadius: 5, borderBottomRightRadius: 5, flexDirection: 'column', justifyContent: 'center' }} onPress={pickImage}>
				{image
					? <Thumbnail url={image} width="90" height="33" /> /*<Image source={{ uri: image }} resizeMode="contain" style={{ width: '100%', height: '100%' }} />*/
					: <Text style={{ color: '#111111', width: '100%', textAlign: 'center', fontSize: 10 }}>(Not set)</Text>
				}
			</TouchableOpacity>
		</View>
	</>);
}