import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import { ModelComponent, connect } from '../ModelComponent';

import IconButton from '../../components/iconButton.component';
import LinkButton from '../../components/linkButton.component';

class CustomerGroupListComponent extends ModelComponent {
  constructor (props) {
    super(props);
    this.state = {
      settingsOpen: false,
    }
	if (props.setCustomerGroupCall) props.setCustomerGroupCall.fn = () => {}
  }
  renderContent() {
    return (<>
      <View style={[styles.shadowBoxNoPadding, {flexDirection: 'row', gap: 10, flexWrap: 'wrap'}]}>
        <View style={{flex: 1, flexDirection: 'column', padding: 10}}>
          <Text style={{fontWeight: 500}}>{this.getModelValue('name', 'DataGroup', this.props.id)}</Text>
        </View>

        <View style={{ flexDirection: 'column', justifyContent: 'space-around', width: 140, padding: 10 }}>
			<LinkButton title="View Customers" stretchContainer={false} style={{ flexDirection: 'row-reverse' }} onPress={() => this.props.setCustomerGroup(this.props.id)} />
		</View>
        {this.renderError()}
      </View>
    </>);
  }
}
export const CustomerGroupListItem = connect({
	component: CustomerGroupListComponent,
	models: [{
		model: 'DataGroup', 
	}]
});

