import React from "react";
import { View, StyleSheet, Text, TextInput } from 'react-native';
import { styles } from '../../main.styles';

import sha256 from '../../core/sha256.api';
import rootStore from '../../root.store.js';
import { navigate } from '../../root.navigation.js';
import socket from '../../core/socket.api';

import PageView from '../../components/views/page.view.component';
import Button from '../../components/button.component';
import LinkButton from '../../components/linkButton.component';

export default class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.resetKey = props.route?.params?.resetKey;

        this.state = {
            sentEmailAddress: null,
            email: props.route?.params?.email || null,
            password: null,
            passwordConfirmation: null,
            error: null,
            emailSuccess: false,
            resetSuccess: false,
        }
    }
    render() {
        return (
            <PageView>
                <View style={styles.centerView}>
                    <View style={[loginStyles.container, styles.shadowBox, {borderWidth: 1, borderColor: '#ccc'}]}>
                        <Text style={loginStyles.title}>Reset Password</Text>
                        {this.resetKey
                            ? <Text style={{ marginBottom: 5 }}>Enter and confirm your new password to change it:</Text>
                            : <Text style={{ marginBottom: 5 }}>Enter your email address to receive an email with a link to reset your password:</Text>
                        }
                        {this.resetKey
                            ? <>
                                <View style={{ marginBottom: 5 }}>
                                    <TextInput
                                        style={[styles.input, { maxWidth: '100%', caretColor: 'rgba(0,0,0,0.5)' }]}
                                        placeholder="Password"
                                        onChangeText={password => this.setState({ password: password })}
                                        onChange={event => this.setState({ password: event.nativeEvent.text })}
                                        underlineColorAndroid="transparent"
                                        placeholderTextColor="grey"
                                        autoCapitalize="none"
                                        secureTextEntry={true}
                                    />
                                </View>
                                <View style={{ marginBottom: 5 }}>
                                    <TextInput
                                        style={[styles.input, { maxWidth: '100%', caretColor: 'rgba(0,0,0,0.5)' }]}
                                        placeholder="Confirm Password"
                                        onChangeText={password => this.setState({ passwordConfirmation: password })}
                                        onChange={event => this.setState({ passwordConfirmation: event.nativeEvent.text })}
                                        underlineColorAndroid="transparent"
                                        placeholderTextColor="grey"
                                        autoCapitalize="none"
                                        secureTextEntry={true}
                                    />
                                </View>
                            </>
                            : <View style={{ marginBottom: 5 }}>
                                <TextInput
                                    style={[styles.input, { maxWidth: '100%', caretColor: 'rgba(0,0,0,0.5)' }]}
                                    placeholder="Email"
                                    onChangeText={email => this.setState({ email: email })}
                                    onChange={event => this.setState({ email: event.nativeEvent.text })}
                                    defaultValue={this.state.email}
                                    underlineColorAndroid="transparent"
                                    textContentType="username"
                                    placeholderTextColor="grey"
                                    autoCapitalize="none"
                                />
                            </View>
                        }

                        <View style={{ alignItems: "flex-end" }}>
                            {this.resetKey
                                ? <Button
                                    onPress={() => {
                                        if (!this.state.password || !this.state.passwordConfirmation) return this.setState({ error: 'You must fill in both password fields.', success: false });
                                        if (this.state.password != this.state.passwordConfirmation) return this.setState({ error: 'Your password does not match the confirmation.', success: false });
                                        sha256(this.state.password).then(hashedPassword => {
                                            socket.emit('resetPasswordViaEmail', { resetKey: this.resetKey, newPasswordHash: hashedPassword }, (response) => {
                                                if (!response.success) return this.setState({ error: response.reason, success: false });
                                                this.setState({ resetSuccess: true, emailSuccess: false, error: null, password: null, passwordConfirmation: null });
                                            });
                                        }).catch(error => this.setState({ error }));
                                    }}
                                    title="Update Password"
                                />
                                : <Button
                                    onPress={() => {
                                        if (!this.state.email) return this.setState({ error: 'You must enter your email address to reset your password.', success: false });
                                        socket.emit('sendResetPasswordEmail', { email: this.state.email }, (response) => {
                                            if (!response.success) return this.setState({ error: response.reason, success: false });
                                            this.setState({ emailSuccess: true, resetSuccess: false, error: null, sentEmailAddress: this.state.email, email: null });
                                        });
                                    }}
                                    title="Send Reset Email"
                                />
                            }
                        </View>
                        {this.state.error && <Text style={styles.error}>{this.state.error}</Text>}
                        {this.state.emailSuccess && <Text style={styles.success}>An email has been sent to "{this.state.sentEmailAddress}" with a link to reset your password!</Text>}
                        {this.state.resetSuccess && <Text style={styles.success}>Your password has been reset! You can go back and login with your new password now</Text>}
                        <View style={{ position: 'absolute', bottom: 20 }}>
                            <LinkButton title="Back to Login" onPress={() => navigate('Login', {})} />
                        </View>
                    </View>
                </View>
            </PageView>
        );
    }
}

const loginStyles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 50,
        width: '100%',
        height: '100%',
        maxWidth: 400,
        minHeight: 400,
        backgroundColor: '#FFF', //'rgba(0,0,0,0.5)',
    },
    title: {
        marginBottom: 30,
        color: '#222',
        fontSize: 40
    },
});
