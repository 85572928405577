import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { ModelModal, connect } from '../../ModelComponent';

class StaffSettingsModal extends ModelModal {
  constructor(props) {
    super(props, "Staff Settings");
  }
  renderModal() {
	const form = this.getFormHelper('User', this.props.id);
    return (<>
      {form.textInput('email')}
      {form.dropdown({
        name: 'Site', 
        fieldName: 'siteID',
        options: this.makeDropdownOptions('Site'),
        onChange: (val) => { console.log(val) }
      })}
      {form.dropdown({
        name: 'Account Type',
		fieldName: 'accountType',
        options: [
          {label: "Organisation", value: 'organisation'},
          {label: "Site", value: 'site'},
          {label: "Staff", value: 'staff'}
        ],
        onChange: (val) => { console.log(val) }
      })}
      {form.dropdown({
        name: 'Account Access', 
        fieldName: 'accountAccess',
        options: [
          {label: "Standard", value: 'standard'},
          {label: "Admin", value: 'admin'}
        ],
        onChange: (val) => { console.log(val) }
      })}
    </>);
  }
  renderModalBottom() {
	const form = this.getFormHelper('User', this.props.id);
    return (<>
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 1, justifyContent: "center"}}>
          <LinkButton title="Close" color="#A00" style={{width: 200}} onPress={() => this.props.closeModal()} />
        </View>
        <View style={{flex: 1, flexDirection: 'row-reverse'}}>
          <Button title="Done" style={{width: 100}} onPress={() => form.save().then(() => this.props.closeModal()).catch(console.error)} />
        </View>
      </View>
    </>);
  }
}
export default StaffSettingsModal = connect({
	component: StaffSettingsModal,
	models: [{
  		model: 'User', 
	}]
});

