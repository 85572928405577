import React from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';
import { styles } from '../../main.styles';

import PageView from '../../components/views/page.view.component'
import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';
import LinkButton from '../../components/linkButton.component';

import { navigate, initNavigation, clearNavigation } from '../../root.navigation';

import { ModelComponent, ModelEditorComponent, connect } from '../ModelComponent';

import TemplateTabsView from './templateTabs.component';
import ComponentToolBox from './componentToolBox.component';
import EditorWindow from './editorWindow.component';

import EditorSettings from './editorSettings.component';
import PageSettings from './pageSettings.component';
import ComponentSettings from './componentSettings.component';
import KioskSettings from './kioskSettings.component';

const borderColor = '#CCC';
const subBorderColor = '#CCC';

export function makeArrow(size, color, down = true) {
	const style = { width: 0, height: 0, 
		borderLeftWidth: size, borderLeftColor: 'transparent', 
		borderRightWidth: size, borderRightColor: 'transparent' }
	if (down) {
		style.borderTopWidth = size;
		style.borderTopColor = color;
	} else {
		style.borderBottomWidth = size;
		style.borderBottomColor = color;
	}
	return <View style={style}></View>;
}

function Panel(props) {
	const [open, setOpen] = React.useState(props.startOpen !== false);
	return (
		<View style={{ borderTopWidth: props.isFirst ? 0 : 2, borderColor: subBorderColor, flexDirection: 'column' }}>
			<TouchableOpacity style={{ padding: 10, paddingVertical: 5, flexDirection: 'row' }} onPress={() => setOpen(!open)}>
				<Text style={[{flex:1}, styles.labelTitle]}>{props.name}</Text>
				<View style={{flexDirection: 'column', justifyContent: 'center'}}>{makeArrow(10, open?'#444':'#AAA', open)}</View>
			</TouchableOpacity>
			{open && <View style={{ flex: 1, minHeight: 100, borderTopWidth: 1, borderColor: subBorderColor, padding: 20, paddingTop: 12 }}>
				{props.children}
			</View>}
		</View>
	);
}
function PanelDock(props) {
	// <ScrollView scrollEnabled={true} style={{flex: 1, height: '100%'}} contentContainerStyle={{flex:1}} scrollIndicatorInsets={{ right: 1 }}></ScrollView>
	return (
		<View style={{ flexDirection: 'column' }}>
			{React.Children.map(props.children, (child, i) => (
				child ? React.cloneElement(child, { ...child.props, isFirst: i === 0 }) : null
			))}
		</View>
	);
}

class TemplateEditorView extends ModelComponent {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			//templateID: null,
			currentPageID: null,
			editingModal: false,
			hiddenComponents: [],
		}
		this.refreshEditorWindow = this.createChildCall();
		this.openEditor = this.createChildCall();
	}
	componentDidMount() {
		super.componentDidMount();
		this.checkAndLoadPages(null);
		
		this.navCallbackID = initNavigation({onNavigate: (name, route)=> {
			if (name == 'TemplateEditor' && route && route.params.id == this.props.route.params.id) {
				this.refreshEditorWindow.call();
			}
		}});
	}
	componentWillUnmount() {
		super.componentWillUnmount();
		clearNavigation(this.navCallbackID);
	}
	componentDidUpdate(prevProps, prevState) {
		super.componentDidUpdate(prevProps, prevState);
		this.checkAndLoadPages(prevProps.route.params.id);
	}
	checkAndLoadPages(prevTemplateID) {
		const templateID = this.props.route.params.id;

		if (templateID != prevTemplateID) { //this.state.templateID) {
			this.setState({ currentPageID: null }, ()=> this.loadModels(true).then(() => {
				const pageModels = this.getModelList('TemplatePage', (model) => model.model?.data.kioskTemplateID?.value == templateID);
				
				const hiddenComponents = [];

				pageModels.forEach(page => {
					if (page.type.value === 'page') {
						const widgets = this.getModelList('TemplateComponent', (model) => model.model?.data.type?.value === 'countdown' && model.model?.data.templatePageID?.value == page.id.value);
						hiddenComponents.push(...widgets.map(widget => widget.id.value));
					} else if (page.type.value === 'modal') {
						hiddenComponents.push(page.id.value);
					}
				});

				console.log(hiddenComponents);
				
				this.updateState({hiddenComponents});
			}));
		}
		const updatePage = () => {
			if (this.state.currentPageID === null) {
				this.resetCurrentPage();
			}
		}
		if (this.modelListLoaded('TemplatePage')) {
			updatePage();
		} else {
			this.loadModelList('TemplatePage', {kioskTemplateID: templateID}).then(() => updatePage())
		}
	}
	resetCurrentPage() {
		const templateID = this.props.route.params.id;
		const currentPages = this.getModelList('TemplatePage', (model) => {
			return model.model?.data.type?.value === 'page' && model.model?.data.kioskTemplateID?.value == templateID
		});
		if (currentPages.length > 0) {
			const currentPage = currentPages[0];
			
			this.setState({ currentPageID: currentPage.id.value });
		}
	}
	renderContent() {
		const kioskID = this.props.route.params.kioskID;
		const templateID = this.props.route.params.id;
		const currentPageID = this.state.currentPageID;
		
		const currentPageName = this.getModelValue('name', 'TemplatePage', currentPageID) || 'Loading';
		//console.log(this.props, this.state);

		return (
			<PageView padding={0}>
				<View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
					<View style={{ flex: 1, flexDirection: 'row' }}>
						<View style={{ flexDirection: 'column', width: 50, borderRightWidth: 1, borderColor }}>
							<ComponentToolBox
								kioskID={kioskID}
								templateID={templateID}
								currentPageID={currentPageID}
								editingModal={this.state.editingModal}
								onAdd={() => { this.loadModels(true); this.refreshEditorWindow.call() }}
								parentPage={this.props.route?.params?.parentPage}
								exitEditor={() => {
									console.log("exit", this.props.route.params);
									if (this.props.route?.params?.parentPage) navigate(this.props.route.params.parentPage, {});
								}}
							/>
						</View>
						<View style={{ flex: 1, flexDirection: 'column', minWidth: 300 }}>
							<TemplateTabsView
								id={templateID}
								kioskID={kioskID}
								editingModal={this.state.editingModal}
								currentPageName={currentPageName}
								currentPageID={(this.state.editingModal) ? null : currentPageID}
								onChange={newPageID => {
									this.updateState({ currentPageID: newPageID, editingModal: false })
								}}
								renderStyle={{flex: 'initial'}}
							/>
							<View style={{ flex: 1 }}>
								<EditorWindow
									id={templateID}
									kioskID={kioskID}
									kioskDataSet={this.state.kioskDataSet}
									currentPageID={currentPageID}
									refreshCall={this.refreshEditorWindow}
									editingModal={this.state.editingModal}
									hiddenComponents={this.state.hiddenComponents}
									onOpenEditor={(componentType, componentID) => this.openEditor.call(componentType, componentID)}
									onEditModalContents={modalID => this.updateState({ currentPageID: modalID, editingModal: true })}
								/>
							</View>
						</View>
					</View>
					<View style={{ width: 300, flexDirection: 'column', borderLeftWidth: 1, borderColor }}>
						<PanelDock>
							{kioskID && <Panel name="Kiosk Settings" startOpen={false}>
								<KioskSettings id={kioskID} templateID={templateID} editingModal={this.state.editingModal} onSetKioskDataSet={(kioskDataSet) => this.updateState({kioskDataSet})} onChange={() => this.refreshEditorWindow.call()} />
							</Panel>}
							<Panel name="Editor Settings" startOpen={false}>
								<EditorSettings kioskID={kioskID} id={templateID} editingModal={this.state.editingModal} onChange={() => this.refreshEditorWindow.call()} />
							</Panel>
							<Panel name={currentPageName + " Settings"}>
								<PageSettings kioskID={kioskID} id={currentPageID} editingModal={this.state.editingModal} onDelete={() => this.resetCurrentPage()} onChange={() => this.refreshEditorWindow.call()} />
							</Panel>
							<Panel name={currentPageName + " Components"}>
								<ComponentSettings templateID={templateID} id={currentPageID} 
									onChange={() => this.refreshEditorWindow.call()} 
									kioskID={kioskID}
									hiddenComponents={this.state.hiddenComponents}
									openEditorCall={this.openEditor}
									editingModal={this.state.editingModal}
									onVisibilityChange={(componentID, hidden) => {
										const hiddenComponents = [...this.state.hiddenComponents];
										if (hidden) hiddenComponents.push(componentID);
										else hiddenComponents.splice(hiddenComponents.indexOf(componentID), 1);
										this.setState({hiddenComponents});
									}}
								/>
							</Panel>
						</PanelDock>
					</View>
				</View>
			</PageView>
		);
	}
}
export default TemplateEditorView = connect({
	component: TemplateEditorView,
	loadingName: 'Editor',
	models: [
		{ model: 'KioskTemplate', key: (root, props) => props.route.params.id, loadChildren: true },
		//{ model: 'TemplatePage', keyField: 'kioskTemplateID', list: true, useKey: true, key: (root, props) => props.route.params.id },
		//{ model: 'TemplatePage', list: true },
	],
});
