import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { ModelModal, connect } from '../../ModelComponent';

class AddKioskModal extends ModelModal {
  constructor(props) {
    super(props, "Add Kiosk");
  }
  renderModal() {
	const form = this.getFormHelper('Kiosk', 'default');
    return (<>
      {form.dropdown({name: 'siteID', displayText: 'Site', options: this.makeDropdownOptions('Site')})}
      {form.textInput('name')}
      {form.textInput('description', true)}
    </>);
  }
  renderModalBottom() {
	const form = this.getFormHelper('Kiosk', 'default');
    return (<>
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 1, justifyContent: "center"}}>
          <LinkButton title="Cancel" color="#A00" style={{width: 200}} onPress={() => this.props.closeModal()} />
        </View>
        <View style={{flex: 1, flexDirection: 'row-reverse'}}>
          <Button title="Add" style={{width: 100}} onPress={() => this.saveModel({create: true, model: 'Kiosk', id: 'default', children: [
            {
              name: 'KioskTemplate', 
              data: { name: 'Template for '+form.getModelValue('name') }, 
              children: [{
                name: 'TemplatePages', 
                data: { name: 'Main Page' }
              }]
            },
            {
              name: 'RevealSettings', 
              data: {}, 
            }
          ], parent: {model: 'Site', id: form.getModelValue('siteID')}}).then(() => this.props.closeModal()).catch(console.error)} />
        </View>
      </View>
    </>);
  }
}
export default AddKioskModal = connect({
	component: AddKioskModal,
	models: [{
  		model: 'Kiosk', 
	}]
});