import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { ModelModal, connect } from '../../ModelComponent';
import LibraryComponent from '../library.component';

class FinderModal extends ModelModal { // todo: extend from APIModal + make APIModal
	constructor(props) {
		super(props, "Select Folder");
		this.state = {
			...this.state,
			selected: 0,
			path: 'Library /'
		}
	}
	renderModal() {
		return (<View style={{ width: 500 }}>
			<Text style={{marginBottom: 10}}>Select the folder to move {this.props.moveItemName} to:</Text>
			<LibraryComponent filterBy={this.props.filterBy} showAddFolderOption={true} removeFolderFromSearch={this.props.removeFolderFromSearch} selected={this.state.selected} isSelector={true} 
			onPathChange={(parentID, path) => {
				if (this.props.filterBy === 'folder') {
					console.log(parentID, path);
					this.updateState({selected: parentID, path: path});
				}
			}} onSelected={(item) => {
				console.log(item);
				this.updateState({selected: item});
			}} injectorItem={
				<Text style={{justifyContent: 'center', alignItems: 'center', textAlign:'center', padding: 5}} onPress={() => {
					this.props.onFound(this.state.selected);
					this.updateState({selected: 0, path: 'Library /'});
				}}>Move {this.props.moveItemName} Here</Text>
			} />
		</View>);
	}
	renderModalBottom() {
		return (<>
			<View style={{ flexDirection: 'row', alignItems: 'center' }}>
				<View style={{ justifyContent: "center", width: 100 }}>
					<LinkButton title="Cancel" color="#A00" style={{ width: 100 }} onPress={() => this.props.closeModal()} />
				</View>
				<Text style={{ flex: 1, justifyContent: "center", fontSize: 15, padding: 5, fontWeight: 500 }}>
					{this.state.path}{this.state.path == 'Library /' ? ' ' : ' / '}{this.props.moveItemName}
				</Text>
				<View style={{ flexDirection: 'row-reverse' }}>
					<Button title="Move" style={{ width: 100 }} onPress={() => {
						this.props.onFound(this.state.selected);
						this.updateState({selected: 0, path: 'Library /'});
					}} />
				</View>
			</View>
		</>);
	}
}
export default FinderModal = connect({
	component: FinderModal,
	models: []
});