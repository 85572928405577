import React from 'react';
import { View, Text, StyleSheet, TextInput, Platform, TouchableOpacity } from 'react-native';
import { styles } from '../main.styles';
import * as Font from 'expo-font';

import {availableFonts} from './fontPicker.component';

export default class StyledText extends React.Component {
    state = {
      fontLoaded: false,
	  fontFamily: null
    }
    componentDidMount() { 
        this._mounted = true;
		this.loadFont();
    }

	componentDidUpdate(prevProps, prevState) {
		if (this.state.fontFamily !== this.props.fontFamily) {
			this.loadFont(this.props.fontFamily);
		}
	}
	loadFont = (fontFamily) => {
		const needsFontLoad = (fontFamily && fontFamily in availableFonts && availableFonts[fontFamily] !== false);
		const fontLoaded = needsFontLoad ? Font.isLoaded(fontFamily) : false;
		this.setState({ fontFamily: fontFamily, fontLoaded });

		if (needsFontLoad && !fontLoaded) {
			const font = {[fontFamily]: require('../assets/fonts/'+availableFonts[fontFamily])}
			Font.loadAsync(font).then((result) => {
				//if (this._mounted) 
				this.setState({ fontLoaded: true });
			})
		}
	}
    componentWillUnmount() {
        this._mounted = false;
    }
    render() {
		const fontFamily = this.props.fontFamily;
		const needsFontLoad = (fontFamily && fontFamily in availableFonts && availableFonts[fontFamily] !== false);
		const fontLoaded = needsFontLoad ? Font.isLoaded(fontFamily) : false;
        return (
            <View style={this.props.style} pointerEvents="none">
            
                <Text 
				pointerEvents="none"
                selectable={false}
				focusable={false}
                style={{
                    fontFamily: this.props.fontFamily, 
                    fontSize: this.props.fontSize ? this.props.fontSize*(this.props.scale || 1) : 20, 
                    color: this.props.fontColor, 
                    textAlign: this.props.centerText ? 'center' : 'left',
                    width: '100%', 
                    height: '100%',
                }}>{this.props.text}</Text>
            
            </View>
        );
    }
}