import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import { ModelComponent, connect } from '../ModelComponent';

import IconButton from '../../components/iconButton.component';
import LinkButton from '../../components/linkButton.component';

import CustomerGroupSettingsModal from './modals/customerGroupSettings.modal';

import { Customer } from './customer.component';
import AddCustomerModal from './modals/addCustomer.modal'

class CustomerGroupComponent extends ModelComponent {
  constructor (props) {
    super(props);
    this.state = {
      settingsOpen: false,
	  addCustomerModalOpen: false,
    }
  }
  renderContent() {
    const id = this.props.id;

	console.log(this.props);
    return (<>
      <CustomerGroupSettingsModal
        id={id} 
        open={this.state.settingsOpen} 
        closeModal={() => this.setState({settingsOpen: false})} 
      />
	  <AddCustomerModal
        dataGroupID={id} 
        open={this.state.addCustomerModalOpen} 
        closeModal={() => this.setState({addCustomerModalOpen: false})} 
      />
      <View style={[styles.shadowBoxNoPadding, {padding: 10}]}>
	  	<View style={{flexDirection: 'row', gap: 10, flexWrap: 'wrap'}}>
			<IconButton size={30} stretchContainer={false} title="Settings" onPress={() => this.setState({ settingsOpen: true })} />
			<View style={{flex: 1, flexDirection: 'column'}}>
				<Text style={styles.title}>{this.getModelValue('name', 'DataGroup', id)}</Text>
				<Text>{this.getModelValue('description', 'DataGroup', id)}</Text>
			</View>
			{this.renderError()}
		</View>
		<View style={{flex: 1, flexDirection: 'column', justifyContent: 'flex-start', gap: 10, marginTop: 30}}>
			<Text style={styles.subTitle}>Customers</Text>
			{this.makeList('DataSet', (data) => {
			return (
				<Customer id={data.id.value} key={data.id.value} />
			)})}
			
			<View style={{flexDirection: 'row', justifyContent: 'center', marginTop: 10}}>
				<LinkButton title="Add Customer" onPress={() => this.setState({addCustomerModalOpen: true})} />
			</View>
		</View>
      </View>
    </>);
  }
}
export const CustomerGroup = connect({
  models: [{ model: 'DataGroup', loadChildren: true }],
  component: CustomerGroupComponent,
});

