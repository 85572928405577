import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { ModelModal, connect } from '../../ModelComponent';

import { navigate } from '../../../root.navigation';

class ModalSettingsModal extends ModelModal {
	constructor(props) {
		super(props, "Edit "+props.name);
		this.state = {
			...this.state,
		}
	}
	renderModal() {
		return (<>
			{this.renderModelTextInput({ name: 'name', model: 'TemplatePage', id: this.props.id })}
			{this.renderModelTextInput({ name: 'description', model: 'TemplatePage', id: this.props.id }, true)}
			{this.renderModelColorPicker({ name: 'backgroundColor', model: 'TemplatePage', id: this.props.id }, true)}
		</>);
	}
	renderModalBottom() {
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Close" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Save" style={{ width: 200 }} onPress={() => {
						const children = null; //[this.getModelData('TemplateComponentModal', componentID)];
						this.saveModel(false, children, 'TemplatePage', this.props.id).then(
						() => this.props.closeModal()).catch(console.error);
					}} />
				</View>
			</View>
		</>);
	}
}
export default ModalSettingsModal = connect({
	loadingName: 'Modal',
	loadingSize: 'xsmall',
	models: [{ model: 'TemplatePage' }],
	component: ModalSettingsModal
});

