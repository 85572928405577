import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { Button as PaperButton } from 'react-native-paper';

export default class OutlineButton extends React.Component {
  render() {
    return (
      <View style={this.props.style}>
        <PaperButton 
          mode="outlined" 
          onPress={this.props.onPress} 
          style={[this.props.buttonStyle, {borderColor: this.props.color || '#8a745a'}]}
          labelStyle={[buttonStyles.title, this.props.titleStyle, {color: this.props.textColor || this.props.color || '#8a745a'}]}
          {...this.props.buttonProps} 
        >
          {this.props.title}
        </PaperButton>
      </View>
    );
  }
}

const buttonStyles = StyleSheet.create({
  innerContainer: {
    width: '100%', 
    height: '100%',
    maxHeight: 35,
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: '#147EFB',
    borderRadius: 10,
    elevation: 2,
  },
  title: {
    color: '#147EFB',
    textAlign: 'center',
  },
});
