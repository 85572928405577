import React from 'react';
import { View, Text, TouchableOpacity, Image, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import { navigate } from '../../root.navigation';

import PageView from '../../components/views/page.view.component'
import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';
import IconButton from '../../components/iconButton.component';
import LinkButton from '../../components/linkButton.component';

import { ModelComponent, connect } from '../ModelComponent';

import ItemSettingsModal from './modals/itemSettings.modal';

class LibraryItem extends ModelComponent {
	constructor(props) {
		super(props);
		this.state = {
			settingsOpen: this.props.openSettings || false,
			imageSize: null,
		}
	}
	componentDidUpdate(prevProps, prevState) {
		super.componentDidUpdate(prevProps, prevState);
		const stateUpdate = {};
		if (this.props.openSettings !== prevProps.openSettings) stateUpdate.settingsOpen = this.props.openSettings;

		if (Object.keys(stateUpdate).length > 0) this.setState(stateUpdate);
	}
	renderContent() {
		const itemID = this.props.id;
		const isEditor = this.props.isEditor || false;
		const isSelected = this.props.isSelected || false;
		const onSelected = this.props.onSelected || false;
		
		const getValue = this.modelValueGetter('LibraryItem', itemID);

		const path = getValue('imagePath');
		const name = getValue('name');

		return (<>
			<ItemSettingsModal
				id={itemID}
				open={this.state.settingsOpen}
				closeModal={() => this.updateState({ settingsOpen: false }, () => {
					if (this.props.onSettingsClosed) this.props.onSettingsClosed();
				})}
			/>

			<TouchableOpacity style={{flexDirection: 'column', alignContent: 'center', height: isEditor ? 230 : 130, width: '100%', borderRadius: 5, backgroundColor: isSelected ? '#AAF' : '#DDD'}} onPress={() => {
				if (isEditor) this.updateState({settingsOpen: true})
				else if (onSelected) this.props.onSelected(itemID, path);
			}}>
				{path && <>
					{this.state.imageSize && <View style={{position: 'absolute', top: 5, right: 5, background: 'rgba(100,100,100,0.5)', zIndex: 100, borderRadius: 2, padding: 3}}>
						<Text style={{color: '#FFF', fontWeight: 400}}>{this.state.imageSize.width}x{this.state.imageSize.height}</Text>
					</View>}
					<Image 
						source={this.getImageUri(path)} 
						style={{width: '100%', height: isEditor ? 200 : 100, borderRadius: 5, resizeMode: 'contain'}}
						onLoad={(e) => {
							Image.getSize(
								this.getImageUri(path),
								(width, height) => {
									if (this.state.imageSize?.width != width && this.state.imageSize?.height != height) {
										this.setState({imageSize: {width, height}})
									}
								},
								(error) => {
								 	console.error(error);
								}
							);
						}}
					/>
				</>}
				{name && <>
					{!isEditor ? <Text style={{textAlign: "center", fontWeight:  isSelected ? 600 : 400, padding: 5}}>{name}</Text>
					:<View style={{ flexDirection: 'row', justifyContent: "center", alignItems: "center" }}>
						{/*<IconButton size={15} title="Settings" onPress={() => this.updateState({ settingsOpen: true })} />*/}
						<Text style={[{textAlign: "center", fontWeight: 400, padding: 5}, isEditor? {} : { paddingLeft: 35, paddingRight: 35}]}>{name}</Text>
					</View>}
				</>}
			</TouchableOpacity>
		</>);
	}
}
export default LibraryItem = connect({
	component: LibraryItem,
	models: [{
		model: 'LibraryItem',
		key: (root, props) => props.id,
	}]
});

