import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { ModelModal, connect } from '../../ModelComponent';

class AddStaffModal extends ModelModal {
	constructor(props) {
		super(props, "Add Staff");
		this.state = {
			...this.state,
			password: '',
			confirmPassword: '',
			passwordsMatch: false,
		}
	}
	confirmPassword() {
		const { password, confirmPassword } = this.state;
		const passwordsMatch = (password && password === confirmPassword);
		this.updateState({passwordsMatch});
		if (passwordsMatch) this.hashPassword(password).then((hashedPassword)=> this.setModelField('passwordHash', hashedPassword, 'User', 'default'));
	}
	renderModal() {
		const form = this.getFormHelper('User', 'default');
		return (<>
			{form.textInput('email')}

			{this.renderTextInput({ name: 'Password', value: this.state.password, onUpdate: (newValue) => this.updateState({ password: newValue }, () => this.confirmPassword()) })}
			{this.renderTextInput({ name: 'Confirm Password', value: this.state.confirmPassword, onUpdate: (newValue) => this.updateState({ confirmPassword: newValue }, () => this.confirmPassword()) })}
			{this.state.password && this.state.confirmPassword && !this.state.passwordsMatch && <Text style={styles.error}>Passwords do not match</Text>}
			{this.state.password && this.state.confirmPassword && this.state.passwordsMatch && <Text style={styles.success}>Passwords match</Text>}
			
			{form.dropdown({
				name: 'siteID', 
				displayText: 'Site',
				options: this.makeDropdownOptions('Site'),
				onChange: (val) => { console.log(val) }
			})}
			
			{form.dropdown({
				name: 'accountType', 
				displayText: 'Account Type',
				options: [
					{ label: "Organisation", value: 'organisation' },
					{ label: "Site", value: 'site' },
					{ label: "Staff", value: 'staff' }
				],
				onChange: (val) => { console.log(val) }
			})}
			
			{form.dropdown({
				name: 'accountAccess', 
				displayText: 'Account Access',
				options: [
					{ label: "Standard", value: 'standard' },
					{ label: "Admin", value: 'admin' }
				],
				onChange: (val) => { console.log(val) }
			})}
		</>);
	}
	renderModalBottom() {
		const form = this.getFormHelper('User', 'default');
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Cancel" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Add" style={{ width: 100 }} onPress={() => {
						form.setModelFields({
							organisationID: this.props.modelOptions.root.userData.organisationID, 
							accountID: 0,
						}).then(()=> form.save({create: true}).then(() => this.props.closeModal()).catch(console.error));
					}} />
				</View>
			</View>
		</>);
	}
}
export default AddStaffModal = connect({
	component: AddStaffModal,
	models: [{
		model: 'User',
	}]
});