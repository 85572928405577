import 'react-native-gesture-handler';
import React from 'react';
import { connect, Provider } from 'react-redux';
import { StatusBar } from 'expo-status-bar';
import { KeyboardAvoidingView, Platform, View, Text } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';

import rootStore from './root.store';
import { navigate, navigationRef, navigationRefReady } from './root.navigation';

import { connectSocket } from './core/socket.api';
import { APP_NAME } from './constants';

import { styles } from './main.styles';

import Navigation from './components/navigation.component';
import MobileUI from './components/mobileUI/mobileUI.component';
import Loader from './components/loader.component';

import Login from './screens/login/loginScreen';
import ResetPassword from './screens/login/resetPassword.screen';
import ClientRevealPage from './screens/clientReveal/clientReveal.screen';
import DeviceWifiSetupPage from './screens/devices/wifiSetup.screen';
import { SafeAreaProvider } from 'react-native-safe-area-context';

const Stack = createStackNavigator();

const theme = {
	...DefaultTheme,
	//roundness: 2,
	colors: {
		...DefaultTheme.colors,
		primary: '#147efb',
		accent: '#147efb', //'#53D769',
	},
};

const isLocal = (window?.location?.host?.split(':')[0] === 'localhost');

function hasGetParam(name) {
	const requestParams = new URLSearchParams(window.location.search);
  	return (requestParams.get(name) !== null);
}
function getGetParam(name) {
	const requestParams = new URLSearchParams(window.location.search);
  	const value = requestParams.get(name);
  	if (value) return value;
	return null;
}

const testResolution = hasGetParam('testResolution') || false;

const deviceWifiSetup = hasGetParam('deviceWifiSetup') || false;

const apiKey = (() => {
  if (!Platform.OS === "web") return null;

  const apiKey = getGetParam('apiKey');
  if (apiKey) return apiKey;

  const pathParts = window.location.pathname.split('/'); 
  if (pathParts.length == 3 && pathParts[1] == 'webReveal') {
	return pathParts[2];
  }

  return null;
})();

if (!isLocal && !apiKey && Platform.OS === "web") window.onbeforeunload = () => "Are you sure you want to navigate away from cxreveal.com";

connectSocket();

export default class Root extends React.Component {
	render() {
		if (testResolution) {
			const devicePixelRatio = window.devicePixelRatio;
			const screenWidth = window.screen.width;
			const screenHeight = window.screen.height;
			return (
				<View style={[styles.centerView, {padding: 20}]}>
					<Text style={styles.title}>Screen Resolution</Text>
					<Text>Device Width: {screenWidth}</Text>
					<Text>Device Height: {screenHeight}</Text>
					<Text>Device Pixel Ratio: {devicePixelRatio}</Text>
					<Text>Rendered Width: {screenWidth*devicePixelRatio}</Text>
					<Text>Rendered Height: {screenHeight*devicePixelRatio}</Text>
				</View>
			);
		} 
		else { 
			return (
				<Provider store={rootStore}>
					<PaperProvider theme={theme}>
						<SafeAreaProvider>
							<NavigationContainer ref={navigationRef} onReady={navigationRefReady} documentTitle={{ formatter: (options, route) => APP_NAME }}>
								<KeyboardAvoidingView style={{ flex: 1, caretColor: 'rgba(0,0,0,0)' }} behavior={Platform.OS === "ios" ? "padding" : "height"} >
									<RootScreen />
								</KeyboardAvoidingView>
							</NavigationContainer>
						</SafeAreaProvider>
					</PaperProvider>
				</Provider>
			);
		}
	}
}

class __RootScreen extends React.Component {
	render() {
		return (<>
			{deviceWifiSetup
				? <DeviceWifiSetupPage />
				: this.props.loggedIn
					? <Navigation />
					: <>
						{(this.props.connecting || this.props.loading || this.props.loggingIn)
							? <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
								<Loader size="large" text={this.props.loading ? 'Loading...' : this.props.loggingIn ? 'Logging In...' : 'Connecting...'} />
							</View>
							: apiKey
								? <ClientRevealPage isPreview={false} apiKey={apiKey} />
								: <Stack.Navigator options={{ title: APP_NAME }}>
									<Stack.Screen name="Login" component={Login} options={{ title: 'Login', headerTitle: APP_NAME, cardStyle: { backgroundColor: '#F2F2F6' } }} />
									<Stack.Screen name="ResetPassword" component={ResetPassword} options={{ title: 'Reset Password', headerTitle: APP_NAME, cardStyle: { backgroundColor: '#F2F2F6' } }} />
								</Stack.Navigator>
						}
					</>
			}
			{/*this.props.loggedIn
        ? this.props.viewingRevealAPIKey 
          ? <ClientRevealPage isPreview={true} apiKey={this.props.viewingRevealAPIKey} />
          : <Navigation />
        : <>
          {(this.props.loading || this.props.loggingIn) 
          ? <View style={{flex:1,justifyContent: "center",alignItems: "center"}}>
              <Loader size="large" text={this.props.loggingIn? 'Logging In...' : 'Loading...'} />
            </View>
          :  <Stack.Navigator options={{title: APP_NAME}}>
              <Stack.Screen name="Login" component={Login} options={{title: 'Login', headerTitle: APP_NAME, cardStyle: {backgroundColor: '#F2F2F6'}}} />
              <Stack.Screen name="ResetPassword" component={ResetPassword} options={{title: 'Reset Password', headerTitle: APP_NAME, cardStyle: {backgroundColor: '#F2F2F6'}}} />
            </Stack.Navigator>
          }
        </>
      */}
			<StatusBar style="auto" />
			<MobileUI />
		</>);
	}
}

const mapStateToProps = (state) => {
	return state.root;
};
const RootScreen = connect(mapStateToProps)(__RootScreen);
