import React, { useState, useRef } from 'react';
import { View, Text, StyleSheet, Animated } from 'react-native';
import { connect } from 'react-redux';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';
import { useNavigation, useRoute } from '@react-navigation/native';
import { initNavigation, clearNavigation, navigate } from '../root.navigation';

import { Logout } from '../screens/login/loginScreen';
import DashboardScreen from '../screens/dashboard/dashboardScreen';
import LibraryScreen from '../screens/library/libraryScreen';
import SignageScreen from '../screens/signage/signageScreen';
import KiosksScreen from '../screens/kiosks/kiosksScreen';
import TemplatesScreen from '../screens/templates/templatesScreen';
import TemplateEditorScreen from '../screens/templateEditor/templateEditorScreen';
import DevicesScreen from '../screens/devices/devicesScreen';
import AccountScreen from '../screens/account/accountScreen';
import StaffScreen from '../screens/staff/staffScreen';
import SitesScreen from '../screens/sites/sitesScreen';
import CustomersScreen from '../screens/customers/customerScreen';

const Drawer = createDrawerNavigator();

const hiddenHeaders = ['WebRevealEditor', 'TemplatePageEditor', 'Library', 'ButtonEditor', 'DataGroupEditor', 'DataSetEditor'];

const screenOptions = {
	'Dashboard': {
		component: DashboardScreen,
		options: {
			headerTitle: 'Dashboard',
			title: 'Dashboard',
		},
	},
	'Monitoring': {
		options: {
			title: 'Monitoring',
			children: {
				'Devices': {
					component: DevicesScreen,
					options: {
						headerTitle: 'Devices',
						title: 'Devices',
					},
				},
				'Kiosks': {
					component: KiosksScreen,
					options: {
						headerTitle: 'Kiosks',
						title: 'Kiosks',
					}
				},
			}
		}
	},
	'Media': {
		options: {
			title: 'Media',
			children: {
				'Templates': {
					component: TemplatesScreen,
					options: {
						headerTitle: 'Templates',
						title: 'Templates',
					}
				},
				'Library': {
					component: LibraryScreen,
					options: {
						headerTitle: 'Library',
						title: 'Component Library',
					},
				},
				'Signage': {
					component: SignageScreen,
					options: {
						headerTitle: 'Signage Playlists',
						title: 'Signage Playlists',
						//drawerItemStyle: { display: 'none' },
					},
				},
			}
		}
	},
	'TemplateEditor': {
		component: TemplateEditorScreen,
		options: {
			headerTitle: 'Template Editor',
			title: 'Template Editor',
			drawerItemStyle: { display: 'none' },
		},
	},
	'Admin': {
		component: AccountScreen,
		options: {
			headerTitle: 'Account',
			title: 'Admin',
			children: {
				'Sites': {
					component: SitesScreen,
					options: {
						headerTitle: 'Sites',
						title: 'Sites',
					},
				},
				'Customers': {
					component: CustomersScreen,
					options: {
						headerTitle: 'Customers',
						title: 'Customers',
					},
				},
				'Staff': {
					component: StaffScreen,
					options: {
						headerTitle: 'Users',
						title: 'Users',
					},
				},
			}
		}
	},
	'Logout': {
		component: Logout,
		options: {
			headerTitle: 'Logout',
			title: 'Logout',
		}
	},
};

function canShowNavOption(name, user) {
	if (user.accountAccess !== 'admin') switch (name) {
		case 'Templates':
		case 'TemplateEditor':
		case 'Staff':
		case 'Sites':
		case 'Library':
		case 'Customers':
		case 'Devices':
			return false;
	}
	return true;
}

const CustomDrawerContent = (props) => {
	const [expandedItems, setExpandedItems] = useState({});
	const animationHeights = useRef({}).current;

	const itemHeight = 50;

	const initAnimationHeight = (screenName, childrenCount, isOpen) => {
		if (!animationHeights[screenName]) {
		  animationHeights[screenName] = new Animated.Value(isOpen ? (itemHeight * childrenCount)+5 : 0);
		}
	  };

	const toggleExpand = (screenName, childrenCount) => {
		const isOpen = (!(expandedItems[screenName] === false));
		initAnimationHeight(screenName, childrenCount, isOpen);

		setExpandedItems((prevExpandedItems) => ({
			...prevExpandedItems,
			[screenName]: !isOpen,
		}));

		Animated.timing(animationHeights[screenName], {
			toValue: isOpen ? 0 : (childrenCount * itemHeight)+5,
			duration: 300,
			useNativeDriver: false
		}).start();
	};

	const renderMenuItem = (screenOption, isChild = false) => {
		const screenName = screenOption[0];
		const hasComponent = (screenOption[1].component != null);
		const item = screenOption[1].options;
		if (!canShowNavOption(screenName, props.user)) return null;
		if (item.drawerItemStyle && item.drawerItemStyle.display == 'none') return null;

		const isActive = props.currentPage === screenName;

		const itemContainerStyle = {};
		if (isChild) {
			itemContainerStyle.marginLeft = 30;
		}

		const isExpanded = expandedItems[screenName] !== false;

		const childCount = (() => {
			let count = 0;
			if (item.children) {
				for (const childScreenName in item.children) {
					const child = item.children[childScreenName];
					if (
						canShowNavOption(childScreenName, props.user) && 
						((!child.options?.drawerItemStyle) || child.options.drawerItemStyle.display != 'none')
					) {
						count++;
					}
				}
			}
			return count;
		})();

		if ((!hasComponent) && item.children) {
			initAnimationHeight(screenName, childCount, isExpanded);
		}

		return (
			<View key={screenName} style={itemContainerStyle}>
				<DrawerItem
					label={item.title}
					onPress={() => hasComponent ? navigate(screenName) : toggleExpand(screenName, childCount)}
					activeTintColor={props.drawerActiveTintColor}
					inactiveTintColor={props.drawerInactiveTintColor}
					labelStyle={isActive ? props.drawerActiveLabelStyle : props.drawerLabelStyle}
					style={isActive ? props.drawerActiveItemStyle : {}}
				/>
				{childCount > 0 && (hasComponent ? 
					Object.entries(item.children).map(child => renderMenuItem(child, true))
				:
					<Animated.View style={{ height: animationHeights[screenName], overflow: 'hidden' }}>
						{Object.entries(item.children).map(child => renderMenuItem(child, true))}
					</Animated.View>
				)}
			</View>
		);
	};

	//render menu list
	return (
		<DrawerContentScrollView {...props}>
			<View>
				{Object.entries(screenOptions).map((option) => renderMenuItem(option))}
			</View>
		</DrawerContentScrollView>
	);
};

class Navigation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			headerHidden: false,
			currentPage: 'Login',
		};
		this.navigationCallbackID = false;
	}
	navigationEvent = (name, route) => {
		console.log("Navigation Event:", name, "Params:", route.params);
		const headerHidden = hiddenHeaders.includes(name);
		this.setState({ headerHidden, currentPage: name });
	}
	componentDidMount = () => {
		this.navigationCallbackID = initNavigation({ onNavigate: this.navigationEvent });
	}
	componentWillUnmount = () => {
		if (this.navigationCallbackID) clearNavigation(this.navigationCallbackID);
		this.navigationCallbackID = false;
	}
	render() {
		const ctx = this;
		function renderDrawerItem(entry, isChild = false) {
			const name = entry[0];
			if (!canShowNavOption(name, ctx.props.user)) return null;
			const screen = entry[1];
			const options = { ...screen.options };
			if (ctx.state.headerHidden) options.headerShown = false;
			return (<React.Fragment key={name}>
				{screen.component && <Drawer.Screen
					name={name}
					component={screen.component}
					options={{
						...options,
						cardStyle: {
							backgroundColor: '#FCFCFC'
						}
					}}
				/>}
				{options.children && Object.entries(options.children).map(child => renderDrawerItem(child, true))}
			</React.Fragment>);
		}

		return (
			<Drawer.Navigator
				drawerContent={(props) => <CustomDrawerContent
					{...props}
					user={this.props.user}
					currentPage={this.state.currentPage}
					drawerActiveTintColor="#FCFCFC"
					drawerInactiveTintColor="#FCFCFC"
					drawerActiveItemStyle={{
						backgroundColor: '#8a745a',
						color: '#FCFCFC',
					}}
					drawerLabelStyle={{
						fontWeight: '400',
						color: '#FCFCFC'
					}}
					drawerActiveLabelStyle={{
						fontWeight: '400',
						color: '#FCFCFC',
					}}
				/>}
				screenOptions={{
					drawerStyle: {
						width: 240,
						backgroundColor: '#333333',
						borderRightWidth: 0
					},
					headerStyle: {
						backgroundColor: '#FCFCFC',
						paddingBottom: 5,
						paddingLeft: 10,
						borderBottom: '2px solid #8a745a',
						//height: 60,
					},
					header: ({ navigation, route, options }) => {
						return <View style={options.headerStyle}>
							<Text style={{ color: '#333333', fontSize: 20, fontWeight: 600, paddingLeft: 7, paddingVertical: 10 }}>{options.headerTitle.toUpperCase()}</Text>
						</View>;
					},
					drawerType: 'permanent',
				}}
			>
				{Object.entries(screenOptions).map(entry => renderDrawerItem(entry))}
			</Drawer.Navigator>
		);
	}
}

export default connect((state) => ({ user: state.root.userData }))(Navigation);
