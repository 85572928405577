import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { ModelModal, APIModal, connect } from '../../ModelComponent';

class ItemSettingsModal extends ModelModal {
	constructor(props) {
		super(props, "Image Settings");
		this.state = {
			...this.state,
		}
	}
	renderModal() {
		const form = this.getFormHelper('LibraryItem', this.props.id);
		return (<>
			{form.textInput('name')}
			{form.filePicker('imagePath', {label: 'Image'})}
		</>);
	}
	renderModalBottom() {
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Close" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Save" style={{ width: 100, marginRight: 20 }} onPress={() => this.saveModel(false, null, 'LibraryItem', this.props.id).then(() => this.props.closeModal(true)).catch(console.error)} />
					<OutlineButton title="Delete" color='#A00' style={{ width: 100, marginRight: 20 }} onPress={() => {
						const buttonStyle = this.getModelData('LibraryItem', this.props.id)?.data;
						this.showConfirmDialogue("Delete "+buttonStyle.name+"?", "Are you sure you want to delete this Library Item?")
						.then(() => this.deleteModel({
							model: 'LibraryItem',
							id: this.props.id,
							children: []
						}))
						.then(() => this.props.closeModal(true))
						.catch(console.error)
					}} />
				</View>
			</View>
		</>);
	}
}
export default ItemSettingsModal = connect({
	models: [{ model: 'LibraryItem', key: (root, props) => props.id }],
	component: ItemSettingsModal
});

