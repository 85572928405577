import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faTimesCircle, faCheckCircle, faWifi } from '@fortawesome/free-solid-svg-icons'
import { faUsb, faBluetooth } from '@fortawesome/free-brands-svg-icons'

import { ModelComponent, connect } from '../ModelComponent';

import PageView from '../../components/views/page.view.component'
import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';
import IconButton from '../../components/iconButton.component';

import StaffSettingsModal from './modals/staffSettings.modal';

class StaffComponent extends ModelComponent {
  constructor (props) {
    super(props);
    this.state = {
      settingsOpen: false,
    }
  }
  renderContent() {
	const getValue = this.modelValueGetter('User', this.props.id);
    return (<>
      <StaffSettingsModal
        id={this.props.id} 
        open={this.state.settingsOpen} 
        closeModal={() => this.setState({settingsOpen: false})} 
      />
      <View style={[styles.shadowBoxNoPadding, {flexDirection: 'row', gap: 10, padding: 10, flexWrap: 'wrap'}]}>
	  	<IconButton stretchContainer={false} size={30} title="Settings" onPress={() => this.setState({ settingsOpen: true })} />

        <View style={{flex: 1, flexDirection: 'column', minWidth: 270}}>
          <Text style={styles.title}>{getValue('email')}</Text>
          <Text>Site ID: {getValue('siteID')}</Text>
          <Text>{getValue('accountType')} {getValue('accountAccess')}</Text>
        </View>
        {this.renderError()}
      </View>
    </>);
  }
}
export const Staff = connect({
	component: StaffComponent,
	models: [{
  		model: 'User', 
	}]
});


export const deviceStyles = StyleSheet.create({
  deviceBoxContainer: {
    backgroundColor: '#F6F6F6',
    marginBottom: 20,
  },
  deviceLineContainer: {
    padding: 20, 
    backgroundColor: '#FFF',
    borderRadius: 10,
  },
  noBottomBorderRadius: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  commandContainer: {
    padding: 20, 
  },
  slotsContainer: {
    marginBottom: 10,
  }, 
  slotContainer: {
    backgroundColor: '#FFF',
    marginBottom: 5,
    borderRadius: 10,
    padding: 10,
  },  
  statusContainer: {
    flexDirection:'row', 
    flexWrap:'wrap',
  },
  statusIcon: {
    color: '#AAA',
    paddingTop: 2
  },
  status: {
    marginLeft: 5,
    marginRight: 10,
  },
  deviceName: {
    color: '#000',
    fontWeight: '500',
  },
  icon: {
    marginHorizontal: 5,
    transform: [
      { translateY: 2 },
    ],
  },
  iconConnected: {
    color: '#5AF'
  },
  iconDisconnected: {
    color: '#AAA'
  }
});