import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { SERVER_DOMAIN, CONNECTION_TYPE_NONE, CONNECTION_TYPE_WIFI, CONNECTION_TYPE_BLE, CONNECTION_TYPE_USB } from '../../../constants';

import { ModelModal, connect, SimpleModal } from '../../ModelComponent';

function DeviceSlot({ slot, codes, device, ctx }) {
	return (<View style={{ flexDirection: 'column', gap: 5, borderRadius: 5, padding: 5, borderWidth: 1, borderColor: '#AAA' }}>
		<View style={{ flexDirection: 'row' }}>
			<Text style={[styles.heavy, { flex: 1 }]}>Slot {slot}</Text>
		</View>
		<View style={{ flex: 2, flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
			<LinkButton title="Generate Reveal Code" onPress={()=> ctx.generateRevealCode(device, slot)} />
			{device.connected ? <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 10, flexWrap: 'wrap' }}>
				<LinkButton title="Reveal" onPress={()=> ctx.activateDeviceSlot(device, slot)} />
				<LinkButton title="Cover" onPress={()=> ctx.activateDeviceSlot(device, slot, 'cover')} />
				<LinkButton title="Re-Scan" onPress={()=> ctx.addDeviceSlot(device, slot)} />
				<LinkButton title="Delete" onPress={()=> ctx.removeDeviceSlot(device, slot)} />
			</View>:null}
		</View>
	</View>);
}

class DeviceSettingsModal extends ModelModal {
	constructor(props) {
		super(props, "Device Settings");
		this.state = {
			...this.state,
			generateRevealCodeModalURL: null
		}
	}
	deviceCommandCall(device, cmd) {
		return this.apiCall('Device', 'sendCommand', {id: device.shortID, connectionType: CONNECTION_TYPE_WIFI, cmd})
	}
	addDeviceSlot(device, slot) {
		return this.deviceCommandCall(device, 'scan:'+slot);
  	}
	removeDeviceSlot(device, slot) {
		return this.deviceCommandCall(device, 'removeSlot:'+slot);
	}
	activateDeviceSlot(device, slot, command = 'reveal', duration = 21) {
		return this.deviceCommandCall(device, command+':'+duration+':'+slot);
	}
	fullDeviceReset(device) {
		return this.deviceCommandCall(device, 'FULL_RESET:TRUE');
	}
	clearDeviceSlots(device) {
		return this.deviceCommandCall(device, 'CLEAR_SLOTS');
	}
	clearDeviceWifi(device) {
		return this.deviceCommandCall(device, 'CLEAR_SLOTS');
	}
	generateRevealCode(device, slot, duration = 21) {
		const triggerURL = SERVER_DOMAIN + '/api/reveal?slot=' + slot + '&device=' + device.shortID + '&key=' + device.apiKey + '&duration=' + duration;
		this.updateState({generateRevealCodeModalOpen: triggerURL});		
	}
	
	renderModal() {
		const form = this.getFormHelper('Device', this.props.id);
		const device = this.getModelData('Device', this.props.id)?.data;
		//if (device.status) device.status = JSON.parse(device.status);
		const { status, connected } = device;
		const deviceType = (status ? ((status.did && status.did == "AS1") ? 'AS1' : 'ACB') : 'UNKNOWN');
		return (<>
			{this.state.generateRevealCodeModalOpen != null ? <SimpleModal title="Reveal URL" open={this.state.generateRevealCodeModalOpen != null} closeModal={() => this.updateState({generateRevealCodeModalOpen: null})}>
				{this.renderSelectableTextField('URL', this.state.generateRevealCodeModalOpen, true)}
			</SimpleModal> : null}
			{form.textInput('deviceName')}
			{form.textInput('description', true)}
			{this.renderTextField('Wi-Fi Connection', status?.wifi || 'Unknown')}
			{/*this.renderTextField('BLE Connection', '(not connected)')*/}
			{deviceType == 'ACB' ? <>
				<Text style={styles.label}>Slots</Text>
				{status.slots && status.slots.map((slot, i) => {
					if (slot) return <DeviceSlot key={i} slot={i + 1} codes={slot} device={device} ctx={this} />
				})}
				{connected ? <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 10 }}>
					<LinkButton title="Add Slot" onPress={() => this.addDeviceSlot(device, 0)} />
				</View>:null}
			</>:null}
			{connected ? <View style={{flexDirection: 'row', flexWrap: 'wrap', gap: 10, marginTop: 10}}>
				{deviceType == 'ACB' ? <LinkButton title="Clear Slots" onPress={()=> ctx.clearDeviceSlots(device)} /> : null}
				<LinkButton title="Reset Wi-Fi" onPress={()=> ctx.clearDeviceWifi(device)} />
				<LinkButton title="Full Reset" onPress={()=> ctx.fullDeviceReset(device)} />
			</View>: null}
			
			{!connected ? <Text style={styles.error}>Device not connected. More actions will be available if you connect the device.</Text>:null}
			{(deviceType == 'AS1' && navigator && navigator.bluetooth) ? <LinkButton title="Open BLE Settings" style={{marginTop: 20}} onPress={()=> window.open('/?deviceWifiSetup', '_blank').focus()} />: null}
		</>);
	}
	renderModalBottom() {
		const form = this.getFormHelper('Device', this.props.id);
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Close" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Done" style={{ width: 100 }} onPress={() => form.save().then(() => this.props.closeModal()).catch(console.error)} />
				</View>
			</View>
		</>);
	}
}
export default DeviceSettingsModal = connect({
	component: DeviceSettingsModal,
	models: [{
		model: 'Device',
	}]
});

