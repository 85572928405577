import React from 'react';
import { View, Text, Image } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { Video } from 'expo-av'
import VideoPlayer from 'expo-video-player'

import { ModelModal, connect } from '../../ModelComponent';
import LibraryComponent from '../../library/library.component';

import { navigate } from '../../../root.navigation';

import { siteBlue } from '../../../main.styles';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';

function getImageSize(imagePath, callback) {
    const img = new window.Image();
    img.src = imagePath;

    img.onload = function() {
        const width = img.width;
        const height = img.height;
        callback({ width, height });
    };

    img.onerror = function() {
        console.error('Error loading image');
        callback({ width: 200, height: 200 });
    };
}

class PlaylistSettingsModal extends ModelModal {
	constructor(props) {
		super(props, "Edit " + props.name);
		//const componentID = this.getModelValue('componentID', 'TemplateComponent', this.props.id);
		this.state = {
			...this.state,
			openWebpage: null,
			customCommandNames: [],
		}
	}
	componentDidMount() {
		super.componentDidMount();
		const getKioskValue = this.modelValueGetter('Kiosk', this.props.kioskID);
		const getRevealValue = this.modelValueGetter('RevealSettings', getKioskValue('revealSettingsID'));
		const syncID = getRevealValue('signageSyncID');

		console.log(syncID);

		if (syncID) this.apiCall('getWebsocketCommandNames', { syncID }).then(response => {
			if (!response.success) return;
			if (Array.isArray(response.data)) this.updateState({ customCommandNames: response.data });
		})
	}
	renderModal() {
		const componentID = this.getModelValue('componentID', 'TemplateComponent', this.props.id);
		const chosenAction = this.getModelValue('action', 'TemplateComponentPlaylist', componentID);
		const chosenActionData = this.getModelValue('actionData', 'TemplateComponentPlaylist', componentID);

		const actionOptions = [{ label: 'None', value: 'None' }, { label: 'Toggle Play/Pause', value: 'playToggle' }, { label: 'Reveal', value: 'reveal' }, { label: 'Goto Page', value: 'gotoPage' }, { label: 'Open Webpage', value: 'openWebpage' }, { label: 'Signage Command', value: 'signageCommand' }, { label: 'Open Modal', value: 'openModal' }]

		actionOptions.push(...this.state.customCommandNames.map(trigger => ({
			label: trigger.name + ' (Signage)',
			value: trigger.command
		})));

		if (chosenAction && chosenAction.substring(0, 7) == 'custom:') {
			let actionFound = false;
			actionOptions.forEach(action => {
				if (action.value == chosenAction) actionFound = true;
			});
			if (!actionFound) actionOptions.push({ label: chosenAction.substring(7) + ' (Signage)', value: chosenAction });
		}

		if (this.props.editingModal) actionOptions.push({ label: 'Close Modal', value: 'closeModal' });

		/*
		const savedFile = (() => {
			//let fileName = null;
			//if (this.state.isGalleryImage) {
			//	const galleryImageID = this.getModelValue('galleryImageID', 'TemplateComponentImage', componentID);
			//	fileName = this.getModelValue('imagePath', 'LibraryItem', galleryImageID);
			//} else {
				const fileName = this.getModelValue('filePath', 'TemplateComponentPlaylist', componentID);
			//}

			if (!fileName) return null;
			return this.getFileUri(fileName);
		})();
		*/

		//const uploadData = this.state.models['TemplateComponentPlaylist'][componentID]?.uploads?.filePath;
		//const uploadURI = uploadData ? uploadData.uri : '';
		//const isVideo = uploadURI ? (uploadURI && uploadURI.startsWith('data:video')) : (savedFile && (savedFile.endsWith('.mp4') || savedFile.endsWith('.webp') || savedFile.endsWith('.avi')));

		//console.log({ uri: uploadURI || savedFile });

		
		const signagePlaylistID = this.getModelValue('signagePlaylistID', 'TemplateComponentPlaylist', componentID);
		const signagePlaylist = this.getModelData('SignagePlaylist', signagePlaylistID)?.data;

		return (<>
			{this.renderModelTextInput({ name: 'name', model: 'TemplateComponent', id: this.props.id })}
			{this.renderModelTextInput({ name: 'description', model: 'TemplateComponent', id: this.props.id }, true)}

			{this.renderModelDropdown({
				displayText: 'Signage Playlist',
				name: 'signagePlaylistID', 
				model: 'TemplateComponentPlaylist', 
				id: componentID,
				options: this.makeDropdownOptions('SignagePlaylist'),
				onChange: (val) => {
					console.log(val)
				}
			})}

			{/* !this.state.isGalleryImage && <View style={{ flex: 1, borderWidth: 1, borderColor: '#AAA', borderRadius: 5, minHeight: 200, overflow: 'hidden', justifyContent: "center", alignItems: "center" }}>
				{uploadURI || savedFile
					? isVideo
						? <VideoPlayer videoProps={{
							shouldPlay: true,
							resizeMode: "contain",
							source: { uri: uploadURI || savedFile },
							isLooping: true,
							//isMuted: true,
							onReadyForDisplay: (e) => {
								//console.log(e.target.videoWidth, e.target.videoHeight, e.target);
								if (uploadURI) {
									uploadData.width = e.target.videoWidth;
									uploadData.height = e.target.videoHeight;
								}
							}
						}}
							defaultControlsVisible={false}
							timeVisible={false}
							slider={{ visible: false }}
							errorCallback={(err) => console.error(err)}
							style={{ height: 200 }} /> //width: uploadData?.width || positionData.width, height: uploadData?.height || positionData.height}} />
						: <Image source={uploadURI || savedFile} style={{ width: '100%', height: '100%', resizeMode: 'contain' }} /> //width: uploadData?.width || positionData.width, height: uploadData?.height || positionData.height}} />
					: <Text style={{ textAlign: 'center' }}>No Preview Available</Text>
				}
			</View> */}

			{/*this.state.isGalleryImage && <>
				<LibraryComponent isSelector={true} style={{width: 490, marginVertical: 10}} filterBy={'image'} selected={this.getModelValue('galleryImageID', 'TemplateComponentImage', componentID)} onSelected={(itemID, itemPath) => {
					const ctx = this;
					this.setModelFields({
						'galleryImageID': itemID,
						'imagePath': itemPath,
					}, 'TemplateComponentImage', componentID).then(() => {
						ctx.updateState({ isGalleryImage: true, newGallerySelection: true });
					});
				}} />
			</>*/}

			{
				this.renderModelDropdown({
					displayText: 'Action',
					name: 'action',
					model: 'TemplateComponentPlaylist',
					id: componentID,
					options: actionOptions,
					onChange: (val) => {
						if (val && val != chosenAction) {
							this.setModelField('actionData', null, 'TemplateComponentPlaylist', componentID)
						}
						console.log(val)
					}
				})
			}
			{
				chosenAction === 'gotoPage' && this.renderModelDropdown({
					displayText: 'Page',
					name: 'actionData',
					model: 'TemplateComponentPlaylist',
					id: componentID,
					options: this.makeDropdownOptions('TemplatePage', 'name', 'id', (model) => {
						return (model?.data.id?.value !== this.props.currentPageID
							&& model?.data.kioskTemplateID?.value === this.props.templateID
							&& model?.data.type?.value === 'page');
					}),
					onChange: (val) => { console.log(val) }
				})
			}
			{
				chosenAction === 'openModal' && this.renderModelDropdown({
					displayText: 'Modal',
					name: 'actionData',
					model: 'TemplateComponentPlaylist',
					id: componentID,
					options: this.makeDropdownOptions('TemplatePage', 'name', 'id', (model) => {
						return (model?.data.id?.value !== this.props.currentPageID
							&& model?.data.templatePageID?.value === this.props.currentPageID
							&& model?.data.type?.value === 'modal');
					}),
					onChange: (val) => { console.log(val) }
				})
			}
			{
				chosenAction === 'signageCommand' && this.renderModelTextInput({
					label: 'Command',
					name: 'actionData',
					model: 'TemplateComponentPlaylist',
					id: componentID,
					onChange: (val) => { console.log(val) }
				})
			}
			<View style={{ flexDirection: 'row', alignItems: 'center', gap: 5 }}>
				<View style={{ flex: 1 }}>
					{chosenAction === 'openWebpage' && this.renderModelTextInput({
						label: 'Webpage URL',
						name: 'actionData',
						model: 'TemplateComponentPlaylist',
						id: componentID,
						onChange: (val) => { console.log(val) }
					})}
				</View>
				{(chosenAction === 'openWebpage' && chosenActionData) && <>
					{!this.state.openWebpage && <Button style={{ marginTop: 10 }} title="Preview" onPress={() => this.updateState({ openWebpage: chosenActionData })} />}
				</>}
			</View>
			{
				(chosenAction === 'openWebpage' && chosenActionData) && <>
					{this.state.openWebpage && <>
						<Text style={styles.label}>Preview:</Text>
						<View style={{ flex: 1, borderWidth: 1, borderColor: '#AAA', minHeight: 500, borderRadius: 5, position: 'relative', justifyContent: "center", alignItems: "center", overflow: 'hidden' }}>
							<View style={{ flex: 1, flexDirection: 'column', position: 'absolute', height: '100%', width: '100%', zIndex: 1001 }}>
								<View style={{ alignItems: 'center', padding: 10, backgroundColor: '#FFF', flexDirection: 'row' }}>
									<Button title="Close" onPress={() => this.updateState({ openWebpage: null })} />
									{/*<Text style={[{flex:1, textAlign: 'center'}, styles.subTitle]}>{this.state.openWebpage}</Text>*/}
								</View>
								<View style={{ flex: 1 }}>
									<WebView url={this.state.openWebpage} style={{ height: '100%', width: '100%', background: '#EEE' }} />
								</View>
							</View>
						</View>
					</>}
				</>
			}
		</>);
	}
	renderModalBottom() {
		const componentID = this.getModelValue('componentID', 'TemplateComponent', this.props.id);
		const positionDataID = this.getModelValue('templatePositionDataID', 'TemplateComponent', this.props.id);
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Close" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Save" style={{ width: 200 }} onPress={() => {
						const positionData = this.getModelData('TemplatePositionData', positionDataID);
						const playlist = this.getModelData('TemplateComponentPlaylist', componentID);
						
						//image.data.isGalleryImage = this.state.isGalleryImage;

						const ctx = this;
						function finish() {
							const children = [playlist, positionData];
							ctx.saveModel(false, children, 'TemplateComponent', ctx.props.id).then(() => {
								ctx.props.closeModal()
							}).catch(console.error);
						}

						/*
						if (this.state.newGallerySelection && image.data.isGalleryImage && image.data.galleryImageID) {
							getImageSize(this.getImageUri(image.data.imagePath), function(size) {
								positionData.data.width = size.width;
								positionData.data.height = size.height;
								finish();
							});
							return;
						} */

						finish();
					}} />
				</View>
			</View>
		</>);
	}
}
export default PlaylistSettingsModal = connect({
	loadingName: 'Playlist',
	loadingSize: 'xsmall',
	models: [
		{ model: 'Kiosk', key: (root, props) => props.kioskID, require: false, loadChildren: true }, 
		{ model: 'TemplateComponent' }, 
		{ model: 'TemplateComponentPlaylist', key: (root, props) => props.componentID }, 
		{ model: 'TemplatePositionData', key: (root, props) => props.positionDataID }, 
		//{ model: 'LibraryItem', list: true }
	],
	component: PlaylistSettingsModal
});

