import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import PageView from '../../components/views/page.view.component'
import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';
import LinkButton from '../../components/linkButton.component';

import { ModelComponent, connect } from '../ModelComponent';

import ButtonStyle from './buttonStyle.component';
import AddButtonStyleModal from './modals/addButtonStyle.modal';

import TextStyle from './textStyle.component';
import AddTextStyleModal from './modals/addTextStyle.modal';

import LibraryComponent from './library.component';

class LibraryView extends ModelComponent {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
		}
	}
	renderContent() {
		const siteID = this.state.siteID;

		return (
			<PageView padding={0}>
				<LibraryComponent isLibraryScreen={true} />
			</PageView>
		);
	}
}

export default LibraryView = connect({
	loadingName: 'Library',
	models: [],
	component: LibraryView,
});
