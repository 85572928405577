import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';
import LinkButton from '../../components/linkButton.component';

import RevealSettingsModal from '../kiosks/modals/revealSettings.modal';
import { KioskLiveSettings } from '../kiosks/kiosk.component';

import { ModelEditorComponent, connect } from '../ModelComponent';

import { navigate } from '../../root.navigation';

class KioskSettings extends ModelEditorComponent {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			revealSettingsOpen: false,
		}
		this.resetLiveSettings = this.createChildCall();
	}
	componentDidUpdate(prevProps, prevState) {
		super.componentDidUpdate(prevProps, prevState);
		if (prevProps.id !== this.props.id) {
			//this.loadModels(true);
			this.resetModel();
		}
	}
	render() {
		const getModelValue = this.modelValueGetter('Kiosk', this.props.id);
		const dataGroupID = getModelValue('dataGroupID');
		const revealSettingsID = getModelValue('revealSettingsID');
		const getRevealSetting = this.modelValueGetter('RevealSettings', revealSettingsID);
		const revealSolution = getRevealSetting('revealSolution');
		const revealDevice = getRevealSetting('revealDevice');
		const deviceID = getRevealSetting('deviceID');
		const deviceName = this.getModelStateValue('deviceName', 'Device', deviceID);
		const deviceShortID = this.getModelStateValue('shortID', 'Device', deviceID);
		return (<>
			<RevealSettingsModal
				id={revealSettingsID}
				kioskID={this.props.id}
				open={this.state.revealSettingsOpen}
				closeModal={() => this.setState({ revealSettingsOpen: false })}
			/>
			<Text style={[styles.subTitle, {marginBottom: 10}]}>Name: {getModelValue('name')}</Text>

			<Text style={[styles.label, {marginTop: 10}]}>Reveal Settings</Text>
			{revealSolution 
				? <Text>{revealSolution === 'acb' ? (revealDevice === 'AS1' ? 'Airshroud' : 'ACB') : this.ucFirst(revealSolution)} 
						{revealSolution === 'acb' 
							? ' (' + this.ucFirst(getRevealSetting('connectionMethod')) + '): ' + (deviceID ? ((deviceName || deviceShortID) + (revealDevice == 'acb' ? ' [Slot ' + getRevealSetting('deviceSlot') + ']' : '')) : 'Device Not Selected') 
							: ' (' + getRevealSetting('signageSyncID') + '): ' + this.ucFirst(getRevealSetting('signageCommand'))
						}</Text>
				: <Text>Missing</Text>
			}
			<LinkButton style={{marginBottom: 10}} title="Change settings" onPress={() => this.setState({ revealSettingsOpen: true })} />

			{this.renderModelCheckBox({label: 'Show Webpage after Reveal', name: 'showPostRevealWebpage', model: 'Kiosk', id: this.props.id, onChange: () => this.saveModel(false, null, 'Kiosk', this.props.id).catch(console.error)})}
			{getModelValue('showPostRevealWebpage') && this.renderModelTextInput({label: 'Webpage URL', name: 'postRevealWebpage', model: 'Kiosk', id: this.props.id, onChange: () => this.setState({postRevealWebPageChanged: true})})}
			{getModelValue('showPostRevealWebpage') && this.state.postRevealWebPageChanged && <View style={{flexDirection: 'row'}}><Button title="Save" onPress={() => this.saveModel(false, null, 'Kiosk', this.props.id).then(() => this.resetLiveSettings.call()).then(()=>this.setState({postRevealWebPageChanged: false})).catch(console.error)} /></View>}

			<KioskLiveSettings 
				style={{marginTop: 20}}
				id={this.props.id} 
				showPreview={false}
				resetCall={this.resetLiveSettings}
				templateID={getModelValue('templateID')}
				revealSettingsID={getModelValue('revealSettingsID')} 
				dataGroupID={getModelValue('dataGroupID')} 
				dataSetID={getModelValue('dataSetID')} 
				onSetKioskDataSet={(kioskDataSet) => this.props.onSetKioskDataSet(kioskDataSet)}
			/>

			{dataGroupID && <LinkButton style={{marginTop: 10}} title="Edit Customer List" onPress={() => { 
				navigate('Customers', {id: dataGroupID});
				//this.props.closeModal();
			}} />}

			<View style={{ flex: 1, alignItems: "center", marginTop: 30 }}>
				<Button title="Publish Template" onPress={() => {
					return this.apiCall('Kiosk', 'publishKiosk', { kioskID: this.props.id });
				}} />
			</View>
		</>);
	}
}

export default KioskSettings = connect({
	component: KioskSettings,
	models: [{
		model: 'Kiosk',
		key: (root, props) => props.id,
		loadChildren: true
	}]
});
