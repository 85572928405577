import React from 'react';
import { View, Image, Text, StyleSheet, TextInput, Platform, TouchableOpacity, TouchableWithoutFeedback } from 'react-native';
import { styles } from '../main.styles';
import StyledText from './styledText.component';
import { SERVER_DOMAIN } from '../constants';

const getImageUri = (fileName) => {
	if (!fileName) return null;
	return SERVER_DOMAIN + '/customerWebPages/' + fileName;
}

export default class ButtonPreview extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			pressed: false,
			imageLoading: true,
			pressedImageLoading: true,
			layout: null,
		}
		this._mounted = false;
	}
	componentDidMount() {
		this._mounted = true;
	}
	componentWillUnmount() {
		this._mounted = false;
	}
	render() {
		const button = this.props.button;
		if (!button) return null;
		const buttonStyle = button.component?.buttonStyle;

		const scale = this.props.scale || 1;

		const style = {};

		if (scale != 1) {
			const translateAmount = ((1 - scale) / 2) * 100;
			style.transform = [];

			if (!this.props.noTranslate) style.transform.push({ translateX: '-' + translateAmount + '%' });
			if (!this.props.noTranslate) style.transform.push({ translateY: '-' + translateAmount + '%' });
			style.transform.push({ scale: scale });
		}

		if (Platform.OS === 'web') {
			style.cursor = 'pointer';
		}

		if (!buttonStyle) return <Text style={[{ position: 'relative', textAlign: 'center', backgroundColor: '#CCC', width: button.positionData?.width, height: button.positionData?.height }, style, this.props.style]} focusable={false} selectable={false}>{button.component.text || '('+button.name+')'}</Text>
		
		const textStyle = buttonStyle.textStyle;

		const pressed = this.state.pressed;

		const imagePathURI = this.props.images?.backgroundImage || getImageUri(button.component?.buttonStyle?.backgroundImage);
		const pressedImagePathURI = this.props.images?.backgroundImagePressed || getImageUri(button.component?.buttonStyle?.backgroundImagePressed);

		const opacity = buttonStyle.opacity/100;
		const opacityHex = Math.floor(opacity*255).toString(16);
		const styleBackgroundColor = buttonStyle.backgroundColor + opacityHex;
		const styleBackgroundColorPressed = buttonStyle.backgroundColorPressed + opacityHex;

		const backgroundColor = pressed
		? styleBackgroundColorPressed
		: styleBackgroundColor;

		const inverseBackgroundColor = pressed
		? styleBackgroundColor
		: styleBackgroundColorPressed;

		const imageURI = pressed
			? pressedImagePathURI
			: imagePathURI

		style.width = button.positionData?.width || buttonStyle.width;
		style.height = button.positionData?.height || buttonStyle.height;
		style.borderRadius = buttonStyle.cornerRadius;
		style.position = 'relative';

		if (buttonStyle.bevelSize) {
			style.boxShadow = inverseBackgroundColor+' 0px 0px 1px '+(buttonStyle.bevelSize)+'px';
		}

		//inset 11px 11px 1px 0px rgb(158 60 79), inset -11px -11px 1px 0px rgb(79 60 158)

		

		if (!imageURI) style.backgroundColor = backgroundColor;

		const callOnLoadEndCallback = (stateSwitch) => {
			if (this._mounted && this.state[stateSwitch]) {
				const newState = { ...this.state };
				newState[stateSwitch] = false;
				this.setState(newState);
				if (this.props.onLoadEnd && newState.imageLoading === false && newState.pressedImageLoading === false) {
					this.props.onLoadEnd();
				}
			}
		}

		const view = () => {
			return (<View style={[style, this.props.style]}>
				{imageURI && <>
					<Image source={{ uri: imagePathURI }}
						resizeMode="cover"
						style={{ position: 'absolute', width: style.width, height: style.height, borderRadius: style.borderRadius, opacity: pressed ? 0 : opacity }}
						onLoadEnd={() => callOnLoadEndCallback('imageLoading')}
					/>
					<Image source={{ uri: pressedImagePathURI }}
						resizeMode="cover"
						style={{ position: 'absolute', width: style.width, height: style.height, borderRadius: style.borderRadius, opacity: pressed ? opacity : 0 }}
						onLoadEnd={() => callOnLoadEndCallback('pressedImageLoading')}
					/>
				</>}
				<View style={{ position: 'absolute', height: style.height, width: style.width, flexDirection: 'column', justifyContent: 'center' }}>
					{textStyle
						? <StyledText style={{ textAlign: 'center' }}
							text={button.component.text}
							fontFamily={textStyle.fontFamily}
							fontSize={textStyle.fontSize}
							fontColor={textStyle.fontColor}
							centerText={textStyle.centerText}
						/>
						: <Text style={{ textAlign: 'center' }} focusable={false} selectable={false}>{button.component.text}</Text>
					}
				</View>
			</View>)
		};

		return (<>
			{this.props.pressable === false ? view() :
				<TouchableWithoutFeedback
					onLayout={(layoutEvent) => {
						//console.log(layoutEvent, layoutEvent.layout, layoutEvent.nativeEvent.target)
						const { width, height, left, top } = layoutEvent.nativeEvent.layout;
						if (this._mounted) this.setState({ layout: { width, height, left, top, target: layoutEvent.nativeEvent.target } });
					}}
					onPressIn={() => {
						if (this._mounted) this.setState({ 'pressed': true });
						if (this.props.onPressIn) this.props.onPressIn();
					}}
					onPressOut={(pressEvent) => {
						const { pageX, pageY, type, target } = pressEvent.nativeEvent;
						const layout = this.state.layout;

						//console.log(layout.target, target, layout.target == target, type, pageX, pageY, layout);

						// touch is within bounds
						if (type != "mousemove" && type != "blur" && (layout.target == target || layout.target.contains(target) || (pageX > layout.left && pageX < layout.left + layout.width && pageY > layout.top && pageY < layout.top + layout.height))) {
							if (this.props.onPress && pressed) this.props.onPress();
						} else {
							if (this.props.onPressCancel) this.props.onPressCancel();
						}
						this._mounted && this.setState({ 'pressed': false });
					}}
				>
					{view()}
				</TouchableWithoutFeedback>
			}
		</>);
	}
}
