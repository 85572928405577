import React from 'react';
import { View, Text, StyleSheet, ScrollView, Modal as RNModal } from 'react-native';
import Button from '../button.component';

export default class Modal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: props.open,
		}
	}
	componentDidUpdate = () => {
		if (this.props.open != this.state.open) this.setState({open: this.props.open});
	}
	render() {
		//return null;
		const props = this.props;
		return (
			<RNModal
				animationType="fade"
				transparent={true}
				visible={this.state.open}
				onRequestClose={() => {
					this.setState({open: false});
				}}
			>
				<View style={[modalStyles.centeredView, {backgroundColor: 'rgba(0,0,0,0.5)'}]}>
					<View style={[modalStyles.centeredView, props.containerStyle]}>
						<View style={[modalStyles.modalView, props.style]}>
							{props.title && <Text style={modalStyles.title}>{props.title}</Text> }
							{props.text && <Text style={modalStyles.text} selectable>{props.text}</Text>}
							<>{props.children}</>
							{props.showButtons !== false &&
								<View style={{flexDirection: 'row', width: '100%', justifyContent: 'flex-end'}}>
								<Button
									onPress={() => { this.setState({open: false}); props.onCancel?.(); props.onClose?.(); }}
									title="Close"
								/>
								{props.onConfirm && 
									<Button
										onPress={() => { this.setState({open: false}); props.onConfirm?.(); props.onClose?.(); }}
										title="Confirm"
										style={{marginLeft: 10}}
									/>
								}
								</View>
							}
						</View>
					</View>
				</View>
			</RNModal>
		);
	};
}

export const modalStyles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
	modalView: {
		margin: 20,
		backgroundColor: "white",
		borderRadius: 2,
		borderBottomWidth: 10,
		borderTopWidth: 10,
		borderColor: "#8a745a",
		padding: 35,
		paddingVertical: 20,
		alignItems: "center",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5
	},
	container: {
	  borderWidth:0, 
	  position:'absolute', 
	  zIndex: 100, 
	  backgroundColor:'rgba(0,0,0,0.5)',
	  flex: 1,
	  flexWrap: 'wrap',
	},
	modal: {
	  margin: 20,
	  backgroundColor: "#FFF",
	  borderRadius: 20,
	  padding: 35,
	  alignItems: "center",
	  shadowColor: "#000",
	  shadowOffset: {
		  width: 0,
		  height: 2
	  },
	  shadowOpacity: 0.25,
	  shadowRadius: 4,
	  elevation: 5
	},
	contentContainer: {
	  //marginBottom: 20,
	  flex: 1,
	},
	title: {
	  fontWeight: 'bold',
	  fontSize: 20,
	  marginBottom: 10,
	},
	text: {
  
	}
  });

/*

export default class Modal extends React.Component {
  componentDidMount = () => {
	this.id = generateId();
  }
  componentDidUpdate = (prevProps) => {
	if (this.props.open && !prevProps.open) return rootStore.dispatch(
	  openMobileUI({
		purpose: 'modal', 
		data: {
		  id: this.id,
		  title: this.props.title, 
		  text: this.props.text,
		  showButtons: this.props.showButtons,
		  children: this.props.children,
		  onClose: () => { 
			rootStore.dispatch(closeMobileUI());
			this.props.onClose?.();
		  },
		  onConfirm: () => { 
			this.props.onConfirm?.();
		  },
		  onCancel: () => { 
			this.props.onCancel?.();
		  }
		}
	  })
	);
	if (!this.props.open && prevProps.open) return rootStore.dispatch(closeMobileUI());
  }
  render() {
	return null;
  }
}
*/
