import React, { useCallback, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import NativeColorPicker from 'react-native-wheel-color-picker';
import Button from './button.component';
import LinkButton from './linkButton.component';
import Modal from './mobileUI/modal.component';
import TextInput from './textInput.component';

export default class ColorPicker extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			colorText: props.color || '#000000',
			color: props.color || '#000000',
			open: false,
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.props.color != prevProps.color) {
			this.setState({
				colorText: this.props.color || '#000000',
				color: this.props.color || '#000000',
			});
		}
	}
	render() {
		const props = this.props;
		const state = this.state;

		const setColor = (c) => {
			this.setState({color: c, colorText: c});
		}
		const setColorText = (c) => {
			this.setState({colorText: c});
		}

		function wc_hex_is_light(color) {
			if (!color) return false;
			const hex = color.replace('#', '');
			const c_r = parseInt(hex.substr(0, 2), 16);
			const c_g = parseInt(hex.substr(2, 2), 16);
			const c_b = parseInt(hex.substr(4, 2), 16);
			const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
			return brightness > 155;
		}
		const colorIsLight = wc_hex_is_light(props.color);

		const palette = ['#FFFFFF', '#000000', ...(this.props.palette || [])]
		
		return(<>
		<Modal 
			open={state.open}
			showButtons={false}
			onClose={() => {
				this.setState({open: false});
			}}
		>
			<View style={{flex:1}}>
				<TextInput
					label="Hex Color"
					onChangeText={(newColor) => {
						if (newColor.substring(0,1) != '#') newColor = '#'+newColor.toUpperCase();
						if (!(/^#([0-9a-f]{6})$/i).test(newColor)) return setColorText(newColor);
						setColor(newColor); 
					}}
					value={state.colorText.toUpperCase()}
				/>
				{this.props.onPaletteAdd && <LinkButton title="Add To Palette" style={{marginTop: 10}} onPress={() => {
					this.props.onPaletteAdd(state.color)
				}} />}
				<View style={{flex:1}}>
					<NativeColorPicker 
						color={state.color}
						swatchesOnly={props.swatchesOnly}
						onColorChangeComplete={(newColor) => {
							setColor(newColor); 
						}}
						onColorChange={setColor}
						thumbSize={40}
						sliderSize={40}
						noSnap={true}
						row={false}
						swatchesLast={props.swatchesLast}
						swatches={props.swatchesEnabled}
						discrete={props.disc} 
						palette={palette}
					/>
				</View>

				<View style={{flexDirection: 'row'}}>
					<View style={{ flex: 1, justifyContent: "center" }}>
						<LinkButton title="Cancel" color="#A00" style={{marginTop: 10}} onPress={() => this.setState({open: false})} />
					</View>
					<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
						<Button title="Done" style={{marginTop: 10}} onPress={() => {
							if (props.onChange) props.onChange(state.color);
							this.setState({open: false});
						}} />
					</View>
				</View>
			</View>
		</Modal>
		<View style={[{flexDirection: 'row', height: 35, borderRadius: 5, backgroundColor: '#FFF', borderColor: '#CCC', borderWidth: 1}, props.style]}>
			<View style={{flex: 1, flexDirection: 'column', justifyContent: 'center'}}>
				<Text style={{padding: 5, paddingLeft: 10}}>{props.label}</Text>
			</View>
			<TouchableOpacity style={{ width: 90, padding: 2, borderLeftWidth: 1, borderColor: '#CCC', backgroundColor: props.color, borderTopRightRadius: 5, borderBottomRightRadius: 5, flexDirection: 'column', justifyContent: 'center'}} onPress={() => this.setState({open: true})}>
				{props.color && <Text style={{color: colorIsLight?'#111111':'#EEEEEE', width: '100%', textAlign:'center', fontWeight: '500'}}>{props.color.toUpperCase()}</Text>}
				<Text style={{color: colorIsLight?'#111111':'#EEEEEE', width: '100%', textAlign:'center', fontSize: 10}}>(Click To Change)</Text>
			</TouchableOpacity>
		</View>
		</>);
	}
}
