import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import PageView from '../../components/views/page.view.component'
import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';
import LinkButton from '../../components/linkButton.component';

import { ModelEditorComponent, connect } from '../ModelComponent';

class AccountView extends ModelEditorComponent {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			changingEmail: false,
			email: '',
			changingPassword: false,
			password: '',
			confirmPassword: '',
			passwordHash: null,
			passwordsMatch: false,
		}
	}
	confirmPassword() {
		const { password, confirmPassword } = this.state;
		const passwordsMatch = (password && password === confirmPassword);
		this.updateState({ passwordsMatch });
		if (passwordsMatch) this.hashPassword(password).then((hashedPassword) => this.updateState({passwordHash: hashedPassword}));
	}
	renderContent() {
		const userID = this.props.modelOptions.root.userData.userID;
		const userName = this.ucFirst(this.getModelValue('email', 'User', userID)?.split('@')[0]);
		return (
			<PageView>
				<Text style={styles.title}>Hello {userName}</Text>

				<View style={{ flexDirection: 'row', gap: 20, marginBottom: 10 }}>
					<LinkButton title="Change Email Address" onPress={() => this.updateState({ changingEmail: true })} />
					<LinkButton title="Change Password" onPress={() => this.updateState({ changingPassword: true })} />
				</View>

				{this.state.changingEmail ? <View style={{ padding: 10, backgroundColor: '#DDDDDD', borderRadius: 5, marginBottom: 10, maxWidth: 500 }}>
					{this.renderTextInput({ name: 'New Email', value: this.state.email, onUpdate: (newValue) => this.updateState({ email: newValue }) })}
					{this.state.email ? <>
						<Button title="Save" style={{ width: 100 }} onPress={() => {
							const userID = this.props.modelOptions.root.userData.userID;
							console.log(this.state.email);
							this.setModelFields({
								email: this.state.email, 
							}, 'User', userID).then(()=> this.saveModel(false, null, 'User', userID, ['passwordHash']).then(() => this.updateState({ email: '', changingEmail: false })).catch(console.error));
						}} />
					</> : null}
				</View> : null}

				{this.state.changingPassword ? <View style={{ padding: 10, backgroundColor: '#DDDDDD', borderRadius: 5, marginBottom: 10, maxWidth: 500 }}>
					{this.renderTextInput({ name: 'New Password', value: this.state.password, onUpdate: (newValue) => this.updateState({ password: newValue }, () => this.confirmPassword()) })}
					{this.renderTextInput({ name: 'Confirm Password', value: this.state.confirmPassword, onUpdate: (newValue) => this.updateState({ confirmPassword: newValue }, () => this.confirmPassword()) })}
					{this.state.password && this.state.confirmPassword && !this.state.passwordsMatch && <Text style={styles.error}>Passwords do not match</Text>}
					{this.state.passwordHash && this.state.passwordsMatch ? <>
						<Text style={styles.success}>Passwords match</Text>
						<Button title="Save" style={{ width: 100 }} onPress={() => {
							const userID = this.props.modelOptions.root.userData.userID;
							this.setModelFields({
								passwordHash: this.state.passwordHash, 
							}, 'User', userID).then(()=> this.saveModel(false, null, 'User', userID).then(() => this.updateState({ password: '', confirmPassword: '', passwordHash: null, passwordsMatch: false, changingPassword: false })).catch(console.error));
						}} />
					</> : null}
				</View> : null}

				{this.renderModelField({ name: 'email', model: 'User', id: userID,  })}

				{this.renderModelField({ name: 'accountType', model: 'User', id: userID, label: 'Account Type' })}
				{this.renderModelField({ name: 'accountAccess', model: 'User', id: userID, label: 'Account Access' })}

				{this.renderModelField({ name: 'name', model: 'Organisation', id: this.getModelValue('organisationID', 'User', userID), label: 'Organisation' })}
				{this.renderModelField({ name: 'name', model: 'Site', id: this.getModelValue('siteID', 'User', userID), label: 'Site' })}

				{this.renderError()}
			</PageView>
		);
	}
}
export default AccountView = connect({
	component: AccountView,
	loadingName: 'Account',
	models: [
		{
			model: 'User',
			key: (root) => root.userData.userID
		},
		{
			model: 'Organisation',
			key: (root) => root.userData.organisationID
		},
		{
			model: 'Site',
			key: (root) => root.userData.siteID
		},
	]
});


/*

import React from 'react';
import { connect } from 'react-redux';
import { SafeAreaView, View, Text, TextInput } from 'react-native';
import { styles } from '../../main.styles';
import sha256 from '../../core/sha256.api';

import rootStore from '../../root.store.js';
import {beginDetailUpdate, cancelDetailUpdate, clearDetailUpdates, requestDetailUpdate} from '../../actions/userData.actions';

import PageView from '../../components/views/page.view.component';
import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

class Account extends React.Component {
  constructor(props) {
	super(props);

	this.state = {
	  accountUpdateError: null,
	  newEmail: null,
	  newEmailConfirmation: null,
	  newPassword: null,
	  newPasswordConfirmation: null,
	};
  }
  render() {
	return (
	  <PageView>
		<SafeAreaView>
		  <View style={[styles.shadowBox, {backgroundColor: '#FFF'}]}>
			<Text style={styles.title}>Hello {capitalizeFirstLetter(this.props.userData.email.split('@')[0])}</Text>
			<Text>Email Address: {this.props.userData.email}</Text>
			<Text>Account Type: {capitalizeFirstLetter(this.props.userData.userType)}</Text>
			<View style={[styles.inlineView, {marginTop: 10}]}>
			  <View>
				<OutlineButton
				  title="Change Email"
				  onPress={() => {
					rootStore.dispatch(clearDetailUpdates());
					this.props.changedDetails?.email 
					? rootStore.dispatch(cancelDetailUpdate('email'))
					: rootStore.dispatch(beginDetailUpdate('email'));
				  }}
				/>
			  </View>
			  <View style={{marginLeft: 10}}>
				<OutlineButton
				  title="Change Password"
				  onPress={() => {
					rootStore.dispatch(clearDetailUpdates());
					this.props.changedDetails?.password 
					? rootStore.dispatch(cancelDetailUpdate('password'))
					: rootStore.dispatch(beginDetailUpdate('password'));
				  }}
				/>
			  </View>
			</View>
			  <View style={{marginTop: 30}}>
			  {this.props.changedDetails?.email && 
				<>
				  <Text>Please enter and confirm your new email address then click 'Save' to change it:</Text>
				  <View style={styles.inlineView}>
					<TextInput
					  style={styles.input}
					  placeholder="New Email"
					  onChangeText={email => this.setState({newEmail: email})}
					  underlineColorAndroid="transparent"
					  placeholderTextColor="grey"
					  autoCapitalize="none"
					/>
					<TextInput
					  style={styles.input}
					  placeholder="Confirm New Email"
					  onChangeText={email => this.setState({newEmailConfirmation: email})}
					  underlineColorAndroid="transparent"
					  placeholderTextColor="grey"
					  autoCapitalize="none"
					/>
					<Button
					  title="Save"
					  onPress={() => {
						if (this.state.newEmail == '' || this.state.newEmail != this.state.newEmailConfirmation) return this.setState({accountUpdateError: "Please fill out both boxes and make sure they match to change your email."});
						rootStore.dispatch(requestDetailUpdate('email', this.state.newEmail));
					  }}
					/>
				  </View>
				</>
			  }
			  {this.props.changedDetails?.password && 
				<>
				  <Text>Please enter and confirm your new password then click 'Save' to change it:</Text>
				  <View style={styles.inlineView}>
					<TextInput
					  style={styles.input}
					  placeholder="New Password"
					  onChangeText={password => this.setState({newPassword: password})}
					  underlineColorAndroid="transparent"
					  placeholderTextColor="grey"
					  autoCapitalize="none"
					  secureTextEntry={true}
					/>
					<TextInput
					  style={styles.input}
					  placeholder="Confirm New Password"
					  onChangeText={password => this.setState({newPasswordConfirmation: password})}
					  underlineColorAndroid="transparent"
					  placeholderTextColor="grey"
					  autoCapitalize="none"
					  secureTextEntry={true}
					/>
					<Button
					  title="Save"
					  onPress={() => {
						if (this.state.newPassword == '' || this.state.newPassword != this.state.newPasswordConfirmation) return this.setState({accountUpdateError: "Please fill out both boxes and make sure they match to change your password."});
						this.setState({accountUpdateError: null});
						sha256(this.state.newPassword).then(hashedPassword => {
						  rootStore.dispatch(requestDetailUpdate('password', hashedPassword));
						});
					  }}
					/>
				  </View>
				</>
			  }
			</View>
			{(this.state.accountUpdateError || this.props.detailsUpdateError) && <Text style={styles.error}>{this.state.accountUpdateError || this.props.detailsUpdateError}</Text>}
		  </View>
		</SafeAreaView>
	  </PageView>
	);
  }
}

const mapStateToProps = (state) => {
  return state.userData.userData;
};
export default connect(mapStateToProps)(Account);
*/