import { createStore } from 'redux';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { models as coreModels } from './core/socket.api';
import { clientBrowserRevealSolutionSet } from './actions/clientBrowsers.actions';

let currentState = {};
export const getState = () => currentState;

const INITIAL_STATE = {
    models: {}, // {modelName: {default: {data: {fieldName: {value, error}, ...}, children: [{model, id}, ...], parents: [{model, relationship, childField, parentField, childConditions, parentConditions}, ...] }, id: {}, id2: {}, lists?: {listID: [modelID, ...]}...}, ...}
    root: {
        loggedIn: false,
        loginError: null,
        loading: false,
        connecting: true, // will be connecting at start up
        loggingIn: false,
        viewingRevealAPIKey: null,
        loginToken: null,
        scrollEnabled: true,
        mobileUI: {
            open: false,
            purpose: null,
            data: null,
        },
        userData: {}
    }
}

function makeDefaultModelRecord(model) {
    const defaultRecord = { data: {}, children: {}, parents: [], childrenLoaded: false};
    for (const fieldName in model.fields) {
        const field = model.fields[fieldName];
        defaultRecord.data[fieldName] = { 
            value: field.default || null,
            error: null,
        };
    }
    for (const childName in model.children) {
        const child = model.children[childName];
        defaultRecord.children[child.name] = [];
    }
	if (model.parents) for (const parent of model.parents) defaultRecord.parents.push({...parent});
    return defaultRecord;
}
function setupModelOnState(modelName, state) {
    if (!(modelName in coreModels)) throw new Error('Model "'+modelName+'" not found in models');

    state.models[modelName] = {
		responseLength: null,
		lists: {},
        default: makeDefaultModelRecord(coreModels[modelName]),
    };
    return state;
}

export function cloneModel(model) {
    const clonedModel = {data: {}, children: {}, parents: [], childrenLoaded: false};

	//if (!model) return clonedModel;
    for (const fieldName in model.data) {
        clonedModel.data[fieldName] = { ...model.data[fieldName] };
    }

    for (const childName in model.children) {
        const childCollection = model.children[childName];
        clonedModel.children[childName] = [];
        for (const child of childCollection) {
            clonedModel.children[childName].push({model: child.model, id: child.id});
        }
    }

	for (const childName in model.children) {
        const childCollection = model.children[childName];
        clonedModel.children[childName] = [];
        for (const child of childCollection) {
            clonedModel.children[childName].push({model: child.model, id: child.id});
        }
    }

	if (model.childrenLoaded) clonedModel.childrenLoaded = model.childrenLoaded;

	if (model.parents) for (const parent of model.parents) clonedModel.parents.push({...parent});

    return clonedModel;
}

const appReducer = (state = INITIAL_STATE, action) => {
    const payload = action.payload;

    const root = {...state.root, userData: {...state.root.userData}, mobileUI: { ...state.root.mobileUI} };

    const models = {};

    if (action.type !== 'LOGIN_SUCCESS' && action.type !== 'LOGOUT') for (const modelName in state.models) {
        const modelCollection = state.models[modelName];
        models[modelName] = {responseLength: modelCollection.responseLength, lists: modelCollection.lists};
        for (const modelID in modelCollection) {
			if (modelID === 'responseLength' || modelID === 'lists') continue
            const model = modelCollection[modelID];
            models[modelName][modelID] = cloneModel(model);
        }
    }

    const newState = {root, models};

    switch (action.type) {
        case 'SERVER_CONNECT':
            root.connecting = false;
			root.loggingIn = false;
        case 'LOGIN_LOADING_STATUS':
            root.loading = payload;
            break;
        case 'REQUEST_LOGIN':
            root.loading = false;
            root.loggedIn = false;
            root.loginError = null;
            root.loggingIn = true;
            root.userData = payload;
            root.userData.valid = false; 
            break;
        case 'LOGIN_SUCCESS':
            root.loading = false;
            root.loggedIn = true;
            root.loginError = null;
            root.loggingIn = false;
            root.userData = payload;
            root.userData.valid = true; 

            //load models
            for (const modelName in coreModels) {
                setupModelOnState(modelName, newState);
            }
            break;
        case 'LOGIN_ERROR':
            root.loading = false;
            root.loggedIn = false;
            root.loginError = action.payload;
            root.loggingIn = false;
            root.userData.valid = false; 
            break;
        case 'ADD_MODEL':
        case 'UPDATE_MODEL':
            if (payload.remove) for (const modelName in payload.remove) {
                for (const removeID of payload.remove[modelName]) {
                    if (removeID in models[modelName]) {
						delete models[modelName][removeID];
						if (models[modelName].responseLength > 0) models[modelName].responseLength--;

						const model = models[modelName];
						for (const listID in model.lists) {
							const idList = model.lists[listID];
							const idx = idList.indexOf(removeID);
							if (idx != -1) {
								model.lists[listID].splice(idx, 1);
							}
						}

						for (const listModelName in models) {
							for (const modelID in models[listModelName]) {
								if (modelID === 'responseLength' || modelID === 'lists') continue;
								const model = models[listModelName][modelID];
								//remove from children
								for (const childName in model.children) {
									const childCollection = model.children[childName];
									for (let i = 0; i < childCollection.length; i++) {
										const child = childCollection[i];
										if (child.model == modelName && child.id == removeID) {
											childCollection.splice(i, 1);
											break;
										}
									}
								}
							}
						}
					}
                }
            }

			const updatedModels = {};
			const updatedLists = {};

            function updateModel(modelName, data, parents, listID) {
                if (!data || !(modelName in coreModels) || !(modelName in models)) return;

				const defaultListID = 'default'; //'{"data":{}}';

				let responseLength = models[modelName].responseLength || 0;

				const modelLists = models[modelName].lists; // { listID: [modelID, ...], ... }
				//console.log(modelLists);

                (Array.isArray(data)?data:[data]).forEach(data => {
                    if (!('data' in data)) return;

					let isNewModel = false;

                    const modelID = (typeof data.data.id === 'object') ? data.data.id.value : data.data.id;
                    if (!(modelID in models[modelName])) { // new record
						models[modelName][modelID] = makeDefaultModelRecord(coreModels[modelName]);
						responseLength++;
						isNewModel = true;
					}

					const model = models[modelName][modelID];

                    for (const fieldName in data.data) {
                        const dataField = data.data[fieldName];
						let dataValue = dataField;
                        if (dataField && typeof dataField === 'object') {
							model.data[fieldName] = { ...dataField };
							//console.log(dataField, data.data);
							dataValue = dataField.value;
						} else model.data[fieldName] = { value: dataField, error: null };
                    }

					//add child to parent
					if (parents) for (const parent of parents) {
						const parentName = parent.model;

						const parentChildInfo = coreModels[modelName].parents.find(info => info.model === parentName);
						
						if (parentChildInfo && parentName in models) for (const parentID of parent.ids) if (parentID in models[parentName]) {							
							const parentModel = models[parentName][parentID];

							const childName = parentChildInfo.childName || parentChildInfo.name;

							const parentChildList = parentModel.children[childName];
							if (!parentChildList) break;

							//check not already in list
							if (!parentChildList.find((child)=> child.id == modelID)) {
								parentModel.children[childName].push({ 
									name: childName, 
									model: modelName, 
									id: modelID 
								});
							}
						}
					}

					//update children models
					const modelChildren = model.children;
                    if (modelChildren && data.children) for (const childName in data.children) { // children: [{model, data, children}]
                        const childList = data.children[childName];
                        for (const child of childList) {
							//console.log(childModelName, child, data);
                            const childID = (typeof child.data.id === 'object') ? child.data.id.value : child.data.id;

                            if (!(childName in modelChildren)) modelChildren[childName] = [];
                            if (!modelChildren[childName].find((child)=> child.id == childID)) modelChildren[childName].push({ name: childName, model: child.model, id: childID });

                            updateModel(child.model, child, child.parents || [{model: modelName, ids: [modelID]}]);
                        }
						model.childrenLoaded = true;
						//console.log(coreModels[modelName])
						const childModelName = coreModels[modelName].children[childName].model;
						if (!models[childModelName].responseLength) models[childModelName].responseLength = 0;
                    }
/*
					const coreChildren = coreModels[modelName].children;
					for (const key in coreChildren) {
						const coreChild = coreChildren[key];
						const searchValue = model.data[coreChild.innerField].value;
						if (!searchValue) continue;
						const childModels = [];
						if (coreChild.outerField === 'id') {
							childModels.push(searchValue);
						} else {
							for (const searchID in models[coreChild.model]) {
								if (models[coreChild.model][searchID]?.data?.[coreChild.outerField]?.value == searchValue) {
									childModels.push(searchID);
								}
							}
						}
						childModels.forEach(childID => {
							if (!modelChildren[coreChild.name].find((child)=> child.id == childID)) {
								modelChildren[coreChild.name].push({ name: coreChild.name, model: coreChild.model, id: childID });
							}
						});
						console.log(modelChildren[coreChild.name]);
					}
*/
					if (!(modelName in updatedModels)) updatedModels[modelName] = [];
					if (!updatedModels[modelName].includes(modelID)) updatedModels[modelName].push(modelID);

					if (isNewModel) {
						if (!(defaultListID in modelLists)) modelLists[defaultListID] = [];
						if (!modelLists[defaultListID].includes(modelID)) modelLists[defaultListID].push(modelID);

						if (!(modelName in updatedLists)) updatedLists[modelName] = {[defaultListID]: []};
						updatedLists[modelName][defaultListID].push(modelID);
					}
                });

				payload.requestData

				models[modelName].responseLength = responseLength;
            }
            updateModel(payload.name, payload.data, (payload.parents && payload.parents.length) ? payload.parents : (payload.parent ? [{model: payload.parent.model, ids:[payload.parent.id]}] : false), payload.listID);

			console.log("MODELS UPDATED:", JSON.stringify(updatedModels), payload, models);
			console.log("LISTS UPDATED:", JSON.stringify(updatedLists));

			const listID = payload.listID;
			const modelName = payload.name;

			if (listID && payload.data && (modelName in coreModels) && (modelName in models)) {
				if (!(listID in models[modelName].lists)) models[modelName].lists[listID] = [];
				if (Array.isArray(payload.data)) payload.data.forEach(data => {
					const modelID = (typeof data.data.id === 'object') ? data.data.id.value : data.data.id;
					if (modelID && !models[modelName].lists[listID].includes(modelID)) models[modelName].lists[listID].push(modelID);
				});
				models[modelName].lists[listID].loaded = true;
				if (payload.requestData.loadChildren) models[modelName].lists[listID].childrenLoaded = true;
				console.log("MODEL LIST UPDATED:", modelName, listID, models[modelName].lists[listID]);
			}

            break;
        case 'OPEN_MOBILE_UI':
            root.mobileUI.open = true;
            root.mobileUI.purpose = action.payload.purpose;
            root.mobileUI.data = action.payload.data;
            root.mobileUI.data.callbackReturn = null;
            break;
        case 'CLOSE_MOBILE_UI':
            root.mobileUI.open = false;
            root.mobileUI.purpose = null;
            root.mobileUI.data = null;
            break;
        case 'CALLBACK_MOBILE_UI':
            if (root.mobileUI.open) root.mobileUI.data.callbackReturn = action.payload;
            break;
        case 'ENABLE_SCROLL':
            root.scrollEnabled = true;
            break;
        case 'DISABLE_SCROLL':
            root.scrollEnabled = false;
            break;
		case 'LOGOUT': break;
        default:
            console.log("unhandled action: ", action);
    }

	currentState = newState;

    return newState;
};

const rootReducer = (state, action) => {
    if (action.type == 'LOGOUT') {
        AsyncStorage.getItem('userData').then(strUserData => {
            if (strUserData == null) return;
            const userData = JSON.parse(strUserData);
    
            userData.valid = false;
            return AsyncStorage.setItem('userData', JSON.stringify(userData));
        }).catch(err => console.error(err));

        const newState = appReducer(undefined, action);
        newState.root.connecting = state.root.connecting;
        return newState;
    }
    return appReducer(state, action);
}

const rootStore = createStore(rootReducer);
export default rootStore;