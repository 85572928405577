import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faTimesCircle, faCheckCircle, faWifi } from '@fortawesome/free-solid-svg-icons'
import { faUsb, faBluetooth } from '@fortawesome/free-brands-svg-icons'

import { ModelComponent, connect } from '../ModelComponent';

import PageView from '../../components/views/page.view.component'
import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';
import IconButton from '../../components/iconButton.component';

import SiteSettingsModal from './modals/siteSettings.modal';
import LinkButton from '../../components/linkButton.component';

class SiteComponent extends ModelComponent {
	constructor(props) {
		super(props);
		this.state = {
			settingsOpen: false,
		}
	}
	renderContent() {
		const getValue = this.modelValueGetter('Site', this.props.id);
		const boxStyle = (this.props.showBox === false ? { flexDirection: 'row', gap: 10, padding: 10, flexWrap: 'wrap' } : [styles.shadowBoxNoPadding, { flexDirection: 'row', gap: 10, padding: 10, flexWrap: 'wrap' }]);
		return (<>
			{this.accountAccess === 'admin' && <SiteSettingsModal
				id={this.props.id}
				open={this.state.settingsOpen}
				closeModal={() => this.setState({ settingsOpen: false })}
			/>}
			<View style={boxStyle}>
				<View style={{ flex: 1, flexDirection: 'column', minWidth: 270 }}>
					<Text style={[styles.title, {marginBottom: 5}]}>{getValue('name')}</Text>
					{this.accountAccess === 'admin' && <LinkButton style={{marginBottom: 10}} color={'#8a745a'} stretchContainer={false} title="Open Site Settings" onPress={() => this.setState({ settingsOpen: true })} />}
					<Text>{getValue('description')}</Text>
				</View>
				{this.renderError()}
			</View>
		</>);
	}
}
export const Site = connect({
	component: SiteComponent,
	models: [{
		model: 'Site',
	}]
});


export const deviceStyles = StyleSheet.create({
	deviceBoxContainer: {
		backgroundColor: '#F6F6F6',
		marginBottom: 20,
	},
	deviceLineContainer: {
		padding: 20,
		backgroundColor: '#FFF',
		borderRadius: 10,
	},
	noBottomBorderRadius: {
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
	},
	commandContainer: {
		padding: 20,
	},
	slotsContainer: {
		marginBottom: 10,
	},
	slotContainer: {
		backgroundColor: '#FFF',
		marginBottom: 5,
		borderRadius: 10,
		padding: 10,
	},
	statusContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	statusIcon: {
		color: '#AAA',
		paddingTop: 2
	},
	status: {
		marginLeft: 5,
		marginRight: 10,
	},
	deviceName: {
		color: '#000',
		fontWeight: '500',
	},
	icon: {
		marginHorizontal: 5,
		transform: [
			{ translateY: 2 },
		],
	},
	iconConnected: {
		color: '#5AF'
	},
	iconDisconnected: {
		color: '#AAA'
	}
});