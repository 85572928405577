import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { RadioButton } from 'react-native-paper';
import { styles } from '../main.styles';

export default class RadioButtons extends React.Component {
  render() {
    const { value, optionStyle, labelStyle, onChange, options, style, flexDirection, gap } = this.props;

    return (
      <View style={[{flexDirection: flexDirection || 'row', gap: gap || 25, flexWrap: 'wrap'}, style]}>
        {options && options.map(option => (
          <View key={option.value} style={[{flexDirection: 'row'}, optionStyle]}>
            <Text style={[{paddingTop: 8}, styles.label, labelStyle]}>{option.label}</Text>
            <RadioButton
              value={option.value}
              status={ option.value == value ? 'checked' : 'unchecked' }
              onPress={() => onChange(option.value)}
            />
          </View>
        ))}
      </View>
    )
  }
}
