import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import PlaylistPlayer from '../playlistPlayer.component'

import { ModelModal, connect } from '../../ModelComponent';

class PlaylistPreviewModal extends ModelModal { // todo: extend from APIModal + make APIModal
	constructor(props) {
		super(props, "Playlist Preview");
	}
	renderModal() {
		const getValue = this.modelValueGetter('SignagePlaylist', this.props.id);
		const itemList = this.getModelList('SignagePlaylistItem', (modelData) => this.props.id == modelData.model.data.playlistID.value);
		itemList.sort((a, b) => a.playlistIndex.value - b.playlistIndex.value);

		const playlist = itemList.map(item => {
			return {
				name: item.name.value,
				index: item.playlistIndex.value,
				itemType: item.itemType.value,
				duration: item.duration.value,
				url: this.getFileUri(item.filePath.value),
			};
		});

		return (<>		
			<PlaylistPlayer playlist={playlist} muted={getValue('mutePlaylist')} width={500} height={300} onPress={() => console.log("pressed")} />
		</>);
	}
	renderModalBottom() {
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Close" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
			</View>
		</>);
	}
}
export default PlaylistPreviewModal = connect({
	component: PlaylistPreviewModal,
	models: [{
		model: 'SignagePlaylist',
		loadChildren: true,
	}, {
		model: 'SignagePlaylistItem',
		list: true,
		keyName: 'playlistID',
		key: (r, props) => props.id,
	}]
});