import { Platform } from 'react-native';
//import { sha256 as sha256Native } from 'react-native-sha256';
//import * as sha256Native from 'react-native-sha256'
import { sha256 as sha256Native } from 'js-sha256';

export default async function sha256(data) {
    if (!data) return Promise.reject(new Error('sha256: empty hash input'));
    if (Platform.OS === 'web') {
        if ('crypto' in window) {
            if ('subtle' in crypto) {
                const hashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(data));
                const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
                return hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
            }
        }
        console.error('crypto library not available for sha256 hashing');
        return Promise.reject(null);
    }
    return Promise.resolve(sha256Native(data));
}
