import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { ModelModal, connect } from '../../ModelComponent';

import { navigate } from '../../../root.navigation';

import ButtonStyleSettingsModal from '../../library/modals/buttonStyleSettings.modal';
import LibraryComponent from '../../library/library.component';

import ButtonPreview from '../../../components/buttonPreview.component';
import WebView from '../../../components/webView.component';

class ButtonSettingsModal extends ModelModal {
	constructor(props) {
		super(props, "Edit "+props.name);
		this.state = {
			...this.state,
			styleSettingsOpen: false,
			previewSize: { width: 250, height: 250 },
			style: {},
			openWebpage: null,
			customCommandNames: [],
		}
	}
	componentDidMount() {
		super.componentDidMount();
		console.log(this.props.kioskID);
		const getKioskValue = this.modelValueGetter('Kiosk', this.props.kioskID);
		const getRevealValue = this.modelValueGetter('RevealSettings', getKioskValue('revealSettingsID'));
		const syncID = getRevealValue('signageSyncID');

		console.log(syncID);

		this.apiCall('getWebsocketCommandNames', {syncID}).then(response => {
			if (!response.success) return;
			if (Array.isArray(response.data)) this.updateState({customCommandNames: response.data});
		})
	}
	componentDidUpdate(prevProps, prevState) {
		super.componentDidUpdate(prevProps, prevState);

		const getComponentValue = this.modelValueGetter('TemplateComponent', this.props.id);
		const componentID = getComponentValue('componentID');

		const getButtonValue = this.modelValueGetter('TemplateComponentButton', componentID);
		const styleID = getButtonValue('buttonStyleID');

		if (styleID) {
			const getStyleValue = this.modelValueGetter('ButtonStyle', styleID);
			const width = getStyleValue('width');
			const height = getStyleValue('height');

			if (this.state.style.styleID != styleID) {
				this.updateState({style: {width, height, styleID}});
			} else if (this.state.style.width !== width || this.state.style.height !== height) {
				this.updateState({style: {styleID, width, height}}, ()=> {
					const templatePositionDataID = getComponentValue('templatePositionDataID');

					this.setModelFields({width, height}, 'TemplatePositionData', templatePositionDataID).then(() => {
						this.saveModel(false, null, 'TemplatePositionData', templatePositionDataID).then();
					})
				});
			}
		}
	}
	renderModalLeft() {
		const componentID = this.getModelValue('componentID', 'TemplateComponent', this.props.id);
		const chosenAction = this.getModelValue('action', 'TemplateComponentButton', componentID);
		const chosenActionData = this.getModelValue('actionData', 'TemplateComponentButton', componentID);

		const actionOptions = [{label: 'Reveal', value: 'reveal'}, {label: 'Goto Page', value: 'gotoPage'}, {label: 'Play Default Signage Playlist', value: 'playDefaultSignagePlaylist'}, /*{label: 'Play Signage Playlist', value: 'playSignagePlaylist'},*/ {label: 'Open Webpage', value: 'openWebpage'}, {label: 'Signage Command', value: 'signageCommand'}, {label: 'Open Modal', value: 'openModal'}, {label: 'Show Customer Settings', value: 'showCustomerSettings'}]
		
		actionOptions.push(...this.state.customCommandNames.map(trigger => ({
			label: trigger.name+' (Signage)',
			value: trigger.command
		})));

		if (chosenAction && chosenAction.substring(0,7) == 'custom:') {
			let actionFound = false;
			actionOptions.forEach(action => {
				if (action.value == chosenAction) actionFound = true;
			});
			if (!actionFound) actionOptions.push({label: chosenAction.substring(7)+' (Signage)', value: chosenAction});
		}
		
		if (this.props.editingModal) actionOptions.push({label: 'Close Modal', value: 'closeModal'});

		const buttonStyleID = this.getModelValue('buttonStyleID', 'TemplateComponentButton', componentID);
		const buttonStyle = this.getModelData('ButtonStyle', buttonStyleID)?.data;

		return (<>
			{this.renderModelTextInput({ name: 'name', model: 'TemplateComponent', id: this.props.id })}

			{this.renderModelTextInput({ name: 'description', model: 'TemplateComponent', id: this.props.id }, true)}

			{this.renderModelDropdown({
				displayText: 'Action', 
				name: 'action',
				model: 'TemplateComponentButton',
				id: componentID,
				options: actionOptions,
				onChange: (val) => {
					if (val && val != chosenAction) {
						this.setModelField('actionData', null, 'TemplateComponentButton', componentID)
					}
					console.log(val) 
				}
			})}
			{chosenAction === 'playSignagePlaylist' && this.renderModelDropdown({
				displayText: 'Signage Playlist', 
				name: 'actionData',
				model: 'TemplateComponentButton',
				id: componentID,
				options: this.makeDropdownOptions('SignagePlaylist', 'name', 'id'),
				onChange: (val) => { console.log(val) }
			})}
			{chosenAction === 'gotoPage' && this.renderModelDropdown({
				displayText: 'Page', 
				name: 'actionData',
				model: 'TemplateComponentButton',
				id: componentID,
				options: this.makeDropdownOptions('TemplatePage', 'name', 'id', (model) => {
					return ( model?.data.id?.value !== this.props.currentPageID 
						&& model?.data.kioskTemplateID?.value === this.props.templateID
						&& model?.data.type?.value === 'page' );
				}),
				onChange: (val) => { console.log(val) }
			})}
			{chosenAction === 'openModal' && this.renderModelDropdown({
				displayText: 'Modal', 
				name: 'actionData',
				model: 'TemplateComponentButton',
				id: componentID,
				options: this.makeDropdownOptions('TemplatePage', 'name', 'id', (model) => {
					return ( model?.data.id?.value !== this.props.currentPageID 
						&& model?.data.templatePageID?.value === this.props.currentPageID
						&& model?.data.type?.value === 'modal' );
				}),
				onChange: (val) => { console.log(val) }
			})}
			{chosenAction === 'signageCommand' && this.renderModelTextInput({
				label: 'Command', 
				name: 'actionData',
				model: 'TemplateComponentButton',
				id: componentID,
				onChange: (val) => { console.log(val) }
			})}
			{chosenAction === 'showCustomerSettings' && this.renderModelTextInput({
				label: 'Access Pin Code',
				name: 'actionData',
				model: 'TemplateComponentButton',
				numeric: true,
				numberString: true,
				maxLength: 4,
				id: componentID,
				onChange: (val) => { console.log(val) }
			})}
			<View style={{ flexDirection: 'row', alignItems: 'center', gap: 5 }}>
				<View style={{ flex: 1 }}>
					{chosenAction === 'openWebpage' && this.renderModelTextInput({
						label: 'Webpage URL', 
						name: 'actionData',
						model: 'TemplateComponentButton',
						id: componentID,
						onChange: (val) => { console.log(val) }
					})}
				</View>
				{(chosenAction === 'openWebpage' && chosenActionData) && <>
					{!this.state.openWebpage && <Button style={{marginTop: 10}} title="Preview" onPress={() => this.updateState({openWebpage: chosenActionData})} />}
				</>}
			</View>
			{(chosenAction === 'openWebpage' && chosenActionData) && <>
				{this.state.openWebpage && <>
					<Text style={styles.label}>Preview:</Text>
					<View style={{ flex: 1, borderWidth: 1, borderColor: '#AAA', minHeight: 500, borderRadius: 5, position: 'relative', justifyContent: "center", alignItems: "center", overflow: 'hidden' }}>
						<View style={{flex: 1, flexDirection:'column', position: 'absolute', height: '100%', width: '100%', zIndex: 1001}}>
							<View style={{alignItems: 'center', padding: 10, backgroundColor: '#FFF', flexDirection: 'row'}}>
								<Button title="Close" onPress={() => this.updateState({openWebpage: null})}/>
								{/*<Text style={[{flex:1, textAlign: 'center'}, styles.subTitle]}>{this.state.openWebpage}</Text>*/}
							</View>
							<View style={{flex: 1}}>
								<WebView url={this.state.openWebpage} style={{height: '100%', width: '100%', background: '#EEE'}} />
							</View>
						</View>
					</View>
				</>}
			</>}
			{/* 
				"refresh": 'REFRESH',
				"startinteractive": 'OPEN_INTERACTIVE',
				"endinteractive": 'CLOSE_INTERACTIVE',
				"playprevious": 'PLAY_PREVIOUS',
				"playnext": 'PLAY_NEXT',
				"pause": 'PAUSE',
				"play": 'PLAY',
				"seek": 'SEEK',
				"restart": 'RESTART', // stop, reset then play
				"stop": 'STOP', //pause and reset player design
				"skip": 'SKIP', //needs a number of items to skip... default 1, like playnext
				"newPlayerData": 'newPlayerData',
				"canvasBack": 'CANVAS_BACK',
				"custom": 'CUSTOM'
			*/}

			{(!buttonStyle || buttonStyle.allowText) ? this.renderModelTextInput({ name: 'text', model: 'TemplateComponentButton', id: componentID }, true) : null}
		</>);
	}
	renderModalRight() {
		const getComponentValue = this.modelValueGetter('TemplateComponent', this.props.id);
		const componentID = getComponentValue('componentID');
		const getButtonValue = this.modelValueGetter('TemplateComponentButton', componentID);
		const buttonStyleID = getButtonValue('buttonStyleID');

		const buttonStyle = this.getModelData('ButtonStyle', buttonStyleID)?.data;
		const button = {};
		button.component = {
			text: this.getModelValue('text', 'TemplateComponentButton',componentID),
			buttonStyle,
		}
		if (button.component.buttonStyle?.textStyleID) button.component.buttonStyle.textStyle = this.getModelData('TextStyle', button.component.buttonStyle.textStyleID)?.data;
		const previewScale = Math.min(1, (this.state.previewSize.width-10) / button.component.buttonStyle?.width);

		return (
			<View>
				<Text style={styles.label}>Preview:</Text>
				<View style={{ flex: 1, borderWidth: 1, borderColor: '#AAA', borderRadius: 5, minHeight: 200, justifyContent: "center", alignItems: "center", overflow: 'hidden' }} onLayout={(layoutEvent) => {
					const { width, height } = layoutEvent.nativeEvent.layout;
					this.updateState({ previewSize: { width, height } });
				}}>
					<ButtonPreview
						button={button}
						scale={previewScale}
						noTranslate={true}
					/>
				</View>

				{buttonStyleID && <>
					<ButtonStyleSettingsModal 
						id={buttonStyleID} 
						open={this.state.styleSettingsOpen} 
						closeModal={(saved, styleID) => {
							if (styleID === buttonStyleID) {
								this.resetIndividualModel('ButtonStyle', buttonStyleID);
								this.resetIndividualModel('TextStyle', '*');
							}
							else if (saved && styleID) this.setModelFields({buttonStyleID: styleID}, 'TemplateComponentButton', componentID);
							this.updateState({styleSettingsOpen: false})
						}} 
					/>
					<LinkButton title="Edit button style" onPress={() => { 
						this.updateState({styleSettingsOpen: true});
					}} />
				</>}

				<LibraryComponent isSelector={true} filterBy={'button'} style={{marginBottom: 10, marginTop: 5, width: 530}} selected={this.getModelValue('buttonStyleID', 'TemplateComponentButton', componentID)} onSelected={(itemID) => {
					if (itemID) {
						const getStyleValue = this.modelValueGetter('ButtonStyle', itemID);
						const width = getStyleValue('width');
						const height = getStyleValue('height');
						const templatePositionDataID = getComponentValue('templatePositionDataID');
						this.setModelField('buttonStyleID', itemID, 'TemplateComponentButton', componentID)
							.then(() => this.setModelFields({width, height}, 'TemplatePositionData', templatePositionDataID));
					}
				}} />
				
			</View>
		);
	}
	renderModalBottom() {
		const getComponentValue = this.modelValueGetter('TemplateComponent', this.props.id);
		const templatePositionDataID = getComponentValue('templatePositionDataID');
		const componentID = getComponentValue('componentID');
		const getButtonValue = this.modelValueGetter('TemplateComponentButton', componentID);
		const buttonStyleID = getButtonValue('buttonStyleID');
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Close" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Save" style={{ width: 200 }} onPress={() => {
						const buttonData = this.getModelData('TemplateComponentButton', componentID);
						//if (!buttonData.children) buttonData.children = [];
						//buttonData.children.push(this.getModelData('ButtonStyle', buttonStyleID));
						//console.log(buttonData);
						const children = [
							buttonData, 
							this.getModelData('TemplatePositionData', templatePositionDataID)
						];
						this.saveModel(false, children, 'TemplateComponent', this.props.id).then(
						() => this.props.closeModal()).catch(console.error);
					}} />
				</View>
			</View>
		</>);
	}
}
export default ButtonSettingsModal = connect({
	loadingName: 'Button',
	loadingSize: 'xsmall',
	models: [{ model: 'Kiosk', key: (root, props) => props.kioskID, require: false, loadChildren: true }, { model: 'TemplateComponent', loadChildren: true }, { model: 'TemplatePage', list: true }, {model: 'TemplatePositionData', key: (root, props) => props.positionDataID}, { model: 'TemplateComponentButton', key: (root, props) => props.componentID }, {model:'ButtonStyle', list:true}, {model:'TextStyle', list:true}],
	component: ButtonSettingsModal
});

