import React, {useState} from 'react';
import { View, Text } from 'react-native';
import NativeSlider from '@react-native-community/slider';
//import NativeSlider from "react-native-slider";
import { styles } from '../main.styles';

export default class Slider extends React.Component {
    constructor(props) {
        super(props); // props = {label, initialValue, onValueChange, minimumValue, maximumValue, valuePreffix, valueSuffix}

        this.state = {
            value: props.initialValue,
        }
    }
    render() {
        const {label, initialValue, step, onValueChange, onValueUpdate, minimumValue, maximumValue, valuePreffix, valueSuffix, showValue, showValueLeft, sliderWidth, style} = this.props;
        
        //console.log(label, initialValue, step, onValueChange, minimumValue, maximumValue, valuePreffix, valueSuffix);

		const containerStyle = showValueLeft ? {flexDirection: 'row', gap: 10} : styles.formGroupStacked;
        return (
			<View style={[containerStyle, style]}>
				{(showValue !== false && showValueLeft) ? <Text style={[styles.greyText]}>{valuePreffix}{parseFloat(this.state.value || 0).toFixed(0)}{valueSuffix}</Text> : null}
                {label && <Text style={styles.label}>{label}:</Text>}
                <NativeSlider
                    style={{width: sliderWidth || '100%', height: showValueLeft?20:30, flex: 1}}
                    minimumValue={minimumValue || 0}
                    maximumValue={maximumValue || 100}
                    minimumTrackTintColor={this.props.color}
                    thumbTintColor={this.props.color}
					//thumbTouchSize={{width: showValueLeft?20:40, height: showValueLeft?20:40}}
                    value={initialValue || 0}
                    step={step || 1}
                    onValueChange={sliderValue => {
                        const newValue = parseInt(parseFloat(sliderValue).toFixed(0)) || 0;
                        this.setState({value: newValue})
                        if (onValueUpdate) onValueUpdate(newValue);
                    }}
                    onSlidingComplete={(sliderValue) => {
                        const newValue = parseInt(parseFloat(sliderValue).toFixed(0)) || 0;
                        this.setState({value: newValue});
                        if (onValueChange) onValueChange(newValue);
                    }}
                />
                {(showValue !== false && !showValueLeft) && <Text style={[styles.greyText, {textAlign: 'center', width: '100%'}]}>{valuePreffix}{parseFloat(this.state.value || 0).toFixed(0)}{valueSuffix}</Text>}
            </View>
        );
    }
}


