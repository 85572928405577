import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { ModelModal, connect } from '../../ModelComponent';

class TemplateSettingsModal extends ModelModal {
  constructor(props) {
    super(props, "Template Settings");
  }
  renderModal() {
	const form = this.getFormHelper('KioskTemplate', this.props.id);
    return (<>
      {form.dropdown({name: 'siteID', displayText: 'Site', options: this.makeDropdownOptions('Site')})}
      {form.textInput('name')}
      {form.textInput('description', true)}
    </>);
  }
  renderModalBottom() {
	const form = this.getFormHelper('KioskTemplate', this.props.id);
    return (<>
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 1, justifyContent: "center"}}>
          <LinkButton title="Close" color="#A00" style={{width: 200}} onPress={() => this.props.closeModal()} />
        </View>
        <View style={{flex: 1, flexDirection: 'row-reverse'}}>
          <Button title="Done" style={{width: 100}} onPress={() => form.save().then(() => this.props.closeModal()).catch(console.error)} />
        </View>
      </View>
    </>);
  }
}
export default TemplateSettingsModal = connect({
	component: TemplateSettingsModal,
	models: [{
  		model: 'KioskTemplate', 
	}]
});

