import React, { useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";

import FontPicker from './fontPicker.component';
import Slider from './slider.component';
import ColorPicker from './colorPicker.component';
import Button from './button.component';

export default function TextStylePicker({fontFamily, fontSize, fontColor, centerText, text, onChange, style, palette, onPaletteAdd}) {
    if (!fontFamily || !fontSize || !fontColor) return null;
    const results = {
        fontFamily: fontFamily,
        fontSize: fontSize,
        fontColor: fontColor,
        centerText: centerText || 0,
    }
    function wc_hex_is_light(color) {
        if (!color) return false;
        const hex = color.replace('#', '');
        const c_r = parseInt(hex.substr(0, 2), 16);
        const c_g = parseInt(hex.substr(2, 2), 16);
        const c_b = parseInt(hex.substr(4, 2), 16);
        const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
        return brightness > 155;
    }
    const colorIsLight = wc_hex_is_light(fontColor);

    return (
		<View style={{flex:1}}>
			<View style={[{borderWidth: StyleSheet.hairlineWidth, borderColor: '#AAA', backgroundColor: colorIsLight?'#111':'#FFF'}, style]}>
				<Text style={{fontFamily: fontFamily, fontSize: fontSize, color: fontColor, textAlign: centerText ? 'center' : 'left',  padding: 10, borderBottomWidth: StyleSheet.hairlineWidth, borderColor: '#AAA'}}>{text || 'Sample Text...'}</Text>
				<View style={{flexDirection: 'row', flexWrap: 'wrap', gap: 10}}>
					<TouchableOpacity style={{width: 50, height: 50, marginTop: 7, flexDirection: 'column', justifyContent: 'center', backgroundColor: results.centerText ? fontColor : '#FFF' }} onPress={() => {
						results.centerText = (!results.centerText ? 1 : 0);
						if (onChange) onChange(results);
					}}>
						<Text style={{textAlign: 'center', color: (results.centerText && !colorIsLight? '#FFF' : '#111')}}>Center</Text>
					</TouchableOpacity>

					<View style={{flexDirection: 'row'}}>
						<FontPicker
							style={{
								justifyContent: 'center',
							}}
							initialValue={fontFamily} 
							textColor={fontColor}
							onChange={value => {
								results.fontFamily = value;
								if (onChange) onChange(results);
							}} 
						/>
					</View>
					
					<View style={{flex: 1, paddingHorizontal: 5}}>
						<Slider 
							minimumValue={10}
							maximumValue={150}
							valueSuffix="px"
							initialValue={fontSize}
							color={fontColor}
							onValueChange={value => {
								results.fontSize = value;
								if (onChange) onChange(results);
							}} 
						/>
					</View>
				</View>
			</View>
			<View style={{maxWidth: 150, marginTop: 5}}>
				<ColorPicker 
					label="Colour"
					color={fontColor} 
					onChange={value => {
						results.fontColor = value;
						if (onChange) onChange(results);
					}} 
					palette={palette}
					onPaletteAdd={onPaletteAdd}
				/>
			</View>
		</View>
    )
}
