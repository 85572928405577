import React, {useState} from 'react';
import { View, Text, Platform, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import DropDown from "react-native-paper-dropdown";
import RNPickerSelect  from 'react-native-picker-select';

export default function Dropdown(props) { // <Dropdown selectedValue="value" onValueChange={() => {}} style={{...}} containerStyle={{...}} list={[{label:'name', value:123}]}/>
  const [open, setOpen] = useState(false);
  if (!props) return null;
  const list = props.list;
  let key = 1;
  for (let item of list) {
    if (item.value === null) item.value;
    else item.value = (typeof item.value.toString === 'function' ? item.value.toString() : item.value)
    item.key = key++;
  }
  const initialValue = (props.selectedValue && typeof props.selectedValue.toString === 'function' ? props.selectedValue.toString() : props.selectedValue);
  //const [value, setValue] = useState(props.selectedValue);

  if (Platform.OS == 'web') return (
    <View style={[{flex:1}, props.containerStyle]}>
      {props.displayText ? <Text style={styles.label}>{props.displayText}:</Text> : []}
      {list.length === 0 
        ? <Text style={styles.greyText}>Nothing to select</Text>
        : <RNPickerSelect 
            placeholder={{
              label: 'Select an item...',
              value: '',
            }}
            value={initialValue || ''}
            disabled={props.enabled === false}
            style={props.smallStyle ? webSelectStylesSmall : webSelectStyles}
            onValueChange={(_newValue) => {
              let newValue = (_newValue === 'Select an item...' || _newValue === '') ? null : _newValue;

              if (initialValue == newValue) return;
              if (props.allowNull === false && newValue === null) return;
              //setValue(newValue);
              if (props.onValueChange) props.onValueChange(newValue);
            }}
            items={list}
          />
      }
    </View>
  );

  
  return (
    <View style={[{flex:1, width:'100%'}, props.containerStyle]}>
      <DropDown
        label={props.displayText}
        mode={"outlined"}
        visible={open}
        showDropDown={() => setOpen(true)}
        onDismiss={() => setOpen(false)}
        value={value}
        setValue={setValue}
        list={list}
      />
    </View>
  );
}

const webSelectStylesSmall = StyleSheet.create({
	inputWeb: {
	  borderColor:'#AAA',
	  backgroundColor:'white',
	  shadowColor:'white',
	  overlayColor:'white',
	  tintColor:'white',
	  textShadowColor:'white',
	  elevation:0,
	  fontSize:14,
	  fontFamily:'arial',
	  borderWidth:1,
	  height:15,
	  borderRadius:5,
	  padding: 5,
	  paddingTop: 5,
	  marginTop: 0,
	  paddingHorizontal: 10,
	  color:'#222222',
	  flex:1,
	  flexDirection:'row',
	  alignItems:'center'
	},
  });

const webSelectStyles = StyleSheet.create({
  inputWeb: {
    borderColor:'#AAA',
    backgroundColor:'white',
    shadowColor:'white',
    overlayColor:'white',
    tintColor:'white',
    textShadowColor:'white',
    elevation:0,
    fontSize:14,
    fontFamily:'arial',
    borderWidth:1,
    height:25,
    borderRadius:5,
    padding: 7.5,
	paddingHorizontal: 10,
    color:'#222222',
    flex:1,
    flexDirection:'row',
    alignItems:'center'
  },
});


/*
const mapStateToProps = (state) => {
  return state.mobileUI.mobileUI;
};
export default connect(mapStateToProps)(Dropdown);

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pickerOpen: (Platform.OS === 'ios') ? false : true,
      label: ''
    }
    props.children.some((child, i) => {
      if (child && child.props && child.props.value == props.selectedValue) {
        if (this.state.label != child.props.label) this.state.label = child.props.label;
        return true;
      }
      return false;
    });
  }
  componentDidMount = () => {
    this.id = generateId();
  }
  componentDidUpdate = (props) => {
    if (isIOS) {
      props.children.some((child, i) => {
        if (child && child.props && child.props.value == props.selectedValue) {
          if (this.state.label != child.props.label) this.setState({label: child.props.label});
          return true;
        }
        return false;
      });

      if (this.props.mobileUI.data?.id == this.id) {
        const selectedItem = this.props.mobileUI.data?.callbackReturn;
        if (selectedItem) this.onSelection(selectedItem.itemValue, selectedItem.itemIndex);
        else if (this.state.pickerOpen) this.setState({pickerOpen: false});
      }
    }
  }
  onSelection = (itemValue, itemIndex) => {
    if (isIOS) {
      const newState = {pickerOpen: true, label: itemValue};
      newState.pickerOpen = false;
      this.setState(newState);
      rootStore.dispatch(closeMobileUI());
    }
    if (this.props.onValueChange != null) this.props.onValueChange(itemValue, itemIndex);

{isIOS && this.props.children.length == 0 && 
          <Text style={{color:'#AAA'}}>None to choose from</Text>
        }
        {isIOS && this.props.children.length > 0 && 
          <TouchableOpacity 
            onPress={() => { 
              const willOpen = (this.props.enabled === false) ? false : !this.state.pickerOpen;
              this.setState({pickerOpen: willOpen}); 
              if (willOpen) {
                rootStore.dispatch(openMobileUI({
                  purpose: 'select', 
                  data: {
                    id: this.id,
                    displayText: this.props.displayText,
                    items: this.props.children, 
                    selectedValue: this.props.selectedValue
                  }
                }));
              } else {
                rootStore.dispatch(closeMobileUI());
              }
            }} 
          >
            <Text style={styles.iosDropdownButton}>{this.state.label}</Text>
          </TouchableOpacity>
        }
        {!isIOS && 
          <Picker
            selectedValue={this.props.selectedValue}
            enabled={this.props.enabled !== false}
            style={[styles.dropdown, this.props.style]}
            onValueChange={(itemValue, itemIndex) => { this.onSelection(itemValue, itemIndex); }}
          >
            {this.props.children}
          </Picker> 
        }
*/
