import React, { useRef, useEffect, useState } from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';

const PlaylistPlayer = ({ playlist, width = '100%', height = '100%', onReady = ()=>{}, backgroundColor = '#000', muted = true, style = {}, onPress = () => { } }) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [calledOnReady, setCalledOnReady] = useState(0);
	const [initialInteraction, setInitialInteraction] = useState(0);
	const [timeoutID, setTimeoutID] = useState(0);
	const [itemPlayStartTime, setItemPlayStartTime] = useState(0);
	const [itemPauseStartTime, setItemPauseStartTime] = useState(0);
	const [itemTimeRemaining, setItemTimeRemaining] = useState(0);
	const [paused, setPaused] = useState(0);
	const videoRef = useRef(null);
	const imageRef = useRef(null);

	const getNextIndex = () => {
		if (currentIndex + 1 < playlist.length) {
			return currentIndex + 1;
		} else {
			return 0;
		}
	}
	const playNext = async () => {
		setCurrentIndex(getNextIndex());
	};

	const currentItem = playlist[currentIndex];
	const nextItem = playlist[getNextIndex()];

	if (!currentItem) return null;

	useEffect(() => {
		let returnFN = () => {};
		if (currentItem.itemType == 'video') {
			if (videoRef.current) {
				videoRef.current.style.display = 'block';
				imageRef.current.style.display = 'none';

				videoRef.current.addEventListener('ended', playNext);
				if (!paused) {
					videoRef.current.play().catch(console.error);
					setItemPlayStartTime(Date.now());
				}
				returnFN = () => {
					if (videoRef.current) videoRef.current.removeEventListener('ended', playNext);
				}
			}
		} else if (currentItem.itemType == 'image') {
			if (imageRef.current) {
				imageRef.current.style.display = 'block';
				videoRef.current.style.display = 'none';
				
				const duration = (currentItem.duration || 10) * 1000;
				setItemTimeRemaining(duration);
				if (paused) {
					setTimeoutID(0);
				} else {
					setItemPlayStartTime(Date.now());
					setTimeoutID(setTimeout(playNext, duration));
				}
				returnFN = () => {
					clearTimeout(timeoutID);
				}
			}
		}

		if (calledOnReady == 0) {
			onReady();
			setCalledOnReady(1);
		}

		return returnFN;
	}, [currentIndex]);

	useEffect(() => {
		if (!currentItem || !calledOnReady) return;

		if (currentItem.itemType == "video") {
			if (paused) {
				setItemPauseStartTime(Date.now());
				videoRef.current.pause();
			} else {
				setItemPlayStartTime(Date.now());
				videoRef.current.play().catch(console.error);
			}
		} else if (currentItem.itemType == 'image') {
			if (paused) {
				setItemPauseStartTime(Date.now())
				setItemTimeRemaining(itemTimeRemaining - (Date.now() - itemPlayStartTime));
				clearTimeout(timeoutID);
			} else {
				setTimeoutID(setTimeout(playNext, itemTimeRemaining));
				setItemPlayStartTime(Date.now());
			}
		}
	}, [paused]);

	return (
		<Pressable style={[{ width, height, backgroundColor }, style]} onPress={() => {
			if (currentItem.itemType == 'video' && videoRef.current && (!muted) && videoRef.current.paused && !initialInteraction) {
				videoRef.current.play().catch(console.error);
				setInitialInteraction(1);
			} else {
				onPress(paused, setPaused);
			}
		}}>
			<video ref={videoRef} src={currentItem.url} muted={muted} playsInline={true} autoPlay={true} preload={'auto'} crossOrigin="anonymous" style={{ display: 'none', width, height, aspectRatio: 16 / 9 }} />
			<video src={nextItem.url} autoPlay={false} muted={true} preload={'auto'} crossOrigin="anonymous" style={{ display: 'none', width, height, aspectRatio: 16 / 9 }} />
			<img ref={imageRef} src={currentItem.url} style={{ display: 'none', width, height, objectFit: 'contain' }} />
			<img src={nextItem.url} preload={'auto'} style={{ display: 'none', width, height, objectFit: 'contain' }} />
		</Pressable>
	);
};

export default PlaylistPlayer;
