import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { Button as PaperButton } from 'react-native-paper';

export default class Button extends React.Component {
  render() {
    return (
      <View style={this.props.style}>
        <PaperButton 
          mode="contained" 
          onPress={this.props.onPress} 
          style={[this.props.buttonStyle, {backgroundColor: this.props.color || (this.props.disabled ? '#BBB' : '#8a745a')}]}
          labelStyle={[buttonStyles.title, this.props.titleStyle, {color: this.props.textColor || 'white'}]}
		  contentStyle={{cursor: (this.props.disabled ? 'default' : 'pointer')}}
		  icon={this.props.icon}
		  disabled={this.props.disabled}
          {...this.props.buttonProps} 
        >
          {this.props.title}
        </PaperButton>
      </View>
    )
  }
}

const buttonStyles = StyleSheet.create({
  innerContainer: {
    width: '100%', 
    height: '100%',
    maxHeight: 35,
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: '#147EFB',
    borderRadius: 10,
    elevation: 2,
  },
  title: {
    color: 'white',
    textAlign: 'center',
  },
});
