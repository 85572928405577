import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity, TouchableWithoutFeedback, Platform } from 'react-native';
import { Button } from 'react-native-paper';
//import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { FontAwesomeIcon as FontAwesomeReact } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon as FontAwesomeNative } from '@fortawesome/react-native-fontawesome';
const FontAwesomeIcon = (Platform.OS === 'web' ? FontAwesomeReact : FontAwesomeNative);
import { faCog } from '@fortawesome/free-solid-svg-icons';

import EntypoIcon from 'react-native-vector-icons/Entypo';

import Tooltip from 'react-native-walkthrough-tooltip';

const isTouchDevice = (function is_touch_device() {
	if (Platform.OS === 'ios' || Platform.OS === 'android') return true;
    try {
        let prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

        let mq = function (query) {
            return window.matchMedia(query).matches;
        };

        if (('ontouchstart' in window) || (typeof window.DocumentTouch !== "undefined" && document instanceof window.DocumentTouch)) {
            return true;
        }

        return mq(['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(''));
    } catch (e) {
        console.error('(Touch detect failed)', e);
        return false;
    }
})();

export default class IconButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showTooltip: false,
		}
	}
	render() {
		const props = this.props;

		//const adjustment = (Platform.OS === 'ios') ? {transform: [{translateY : 3}]} : {};
		const faStyle = StyleSheet.flatten([
			{
				color: props.color || '#8a745a', //'#35F'
				width: props.width || props.size || (props.icon ? 15 : 50),
				height: props.height || props.size || (props.icon ? 15 : 50),
				transform: [{rotate: props.rotation || '0deg'}]
			},
			this.props.iconStyle
		]);
		/* {{
			padding: 5,
			backgroundColor: '#FCFCFC',
			borderRadius: 5,
			borderWidth: 1,
			borderColor: '#AAA',
			shadowColor: "#000",
			shadowOffset: { width: 0, height: 1, },
			shadowOpacity: 0.2,
			shadowRadius: 4,
			elevation: 3
		}} */
		const margin = typeof props.margin === 'number' ? props.margin : (props.icon ? 2.5 : 5);
		const outerStyle = {margin, justifyContent: 'center', alignItems: 'center'};
		if (this.props.stretchContainer === false) {
			outerStyle.width = faStyle.width;
			outerStyle.height = faStyle.height;
			outerStyle.justifyContent = 'flex-start';
		}

		if (isTouchDevice) return (
			<TouchableOpacity style={[outerStyle, this.props.style, {flexDirection: 'row'}]} 
				onPress={this.props.onPress} 
			>
				{props.entypo ? <EntypoIcon name={props.icon} size={props.size} style={faStyle} />
				: <FontAwesomeIcon
					icon={props.icon || faCog}
					style={faStyle}
				/>}
				{props.sideText && <Text style={{marginLeft: 5, alignContent: 'center'}}>{props.sideText}</Text>}
			</TouchableOpacity>
		);

		const touchStyle = {cursor: 'default', flexDirection: 'row'}
		if (this.props.onPress) {
			touchStyle.cursor = 'pointer';
		}
		return (<View style={[outerStyle, this.props.style]}>
			<Tooltip
				isVisible={this.props.title && this.state.showTooltip}
				content={<Text style={this.props.tooltipTextStyle}>{this.props.title}</Text>}
				contentStyle={{
					shadowColor: "#000",
					shadowOffset: { width: 0, height: 1, },
					shadowOpacity: 0.2,
					shadowRadius: 4,
				}}
				placement="top"
				disableShadow={true}
				backgroundColor="rgba(0,0,0,0)"
				onClose={() => this.setState({ showTooltip: false })}
			>
				<TouchableOpacity style={touchStyle} 
					onPress={this.props.onPress} 
					//onMouseOver={() => this.setState({ showTooltip: true })}
					onMouseLeave={() => this.setState({ showTooltip: false })} 
				>
					{props.entypo ? <EntypoIcon name={props.icon} size={props.size} style={faStyle} />
					: <FontAwesomeIcon
						icon={props.icon || faCog}
						style={faStyle}
					/>}
					{props.sideText && <Text style={{marginLeft: 5, alignContent: 'center'}}>{props.sideText}</Text>}
				</TouchableOpacity>
			</Tooltip>
		</View>);
	}
}
