import React from 'react';
import { SafeAreaView, View, Text, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import { connect } from 'react-redux';
import { styles } from '../../main.styles';
//import { Picker } from '@react-native-community/picker';

import { UICallback, closeMobileUI } from '../../actions/mobileUI.actions'
import Button from '../button.component';

class MobileUI extends React.Component {
  render() {
    if (!this.props.open) return null;
    return (<>
      <TouchableWithoutFeedback 
        onPress={() => {
          this.props.data.onCancel?.();
          this.props.dispatch(closeMobileUI());
          this.props.data.onClose?.();
        }}
      >
        <View style={{flex: 1, backgroundColor: 'rgba(0,0,0,0.5)', borderColor: '#AAA', width: '100%', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: 100}}>
        {this.props.purpose == 'modal' &&
          <View style={styles.fullCenteredView}>
            <TouchableWithoutFeedback>
            <View style={modalStyles.modal}>
              <View style={modalStyles.contentContainer}>
                {this.props.data.title && <Text style={modalStyles.title}>{this.props.data.title}</Text> }
                {this.props.data.text && <Text style={modalStyles.text} selectable>{this.props.data.text}</Text>}
                <>{this.props.data.children}</>
              </View>
              {this.props.data.showButtons !== false &&
                <View style={[styles.inlineView, {width: '100%', justifyContent: 'flex-end'}]}>
                  <Button
                    onPress={() => { this.props.data.onCancel?.(); this.props.data.onClose?.(); }}
                    title="Close"
                  />
                  {this.props.data.onConfirm && 
                    <Button
                      onPress={() => { this.props.data.onConfirm?.(); this.props.data.onClose?.(); }}
                      title="Confirm"
                      style={{marginLeft: 10}}
                    />
                  }
                </View>
              }
            </View>
            </TouchableWithoutFeedback>
          </View>
        }
        {/*this.props.mobileUI.purpose == 'select' &&
          <View style={{flex: 1, backgroundColor: '#F6F6F6', borderTopWidth: 1, borderColor: '#AAA', width: '100%', height: '30%', minHeight: 300, position: 'absolute', left: 0, bottom: 0}}>
            <SafeAreaView>
                <View style={{alignItems: 'center'}}>
                  <Text style={{marginTop: 20, fontWeight: '500'}}>{this.props.mobileUI.data.displayText}</Text>
                  <Picker
                    selectedValue={this.props.mobileUI.data.selectedValue}
                    style={{width: '100%', height: 180}}
                    onValueChange={(itemValue, itemIndex) => { this.props.dispatch(UICallback({itemValue: itemValue, itemIndex: itemIndex})); }}
                  >
                    {this.props.mobileUI.data.items}
                  </Picker>
                </View>
              
            </SafeAreaView>
          </View>
      */}
        </View>
      </TouchableWithoutFeedback>
    </>);
  }
}
const mapStateToProps = (state) => {
  return state.root.mobileUI;
};
export default connect(mapStateToProps)(MobileUI);

export const modalStyles = StyleSheet.create({
  container: {
    borderWidth:0, 
    position:'absolute', 
    zIndex: 100, 
    backgroundColor:'rgba(0,0,0,0.5)',
    width:'100%', 
    height:'100%',
    flexWrap: 'wrap',
  },
  modal: {
    margin: 20,
    backgroundColor: "#FFF",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
        width: 0,
        height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  contentContainer: {
    //marginBottom: 20,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: 10,
  },
  text: {

  }
});