import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { ModelModal, connect } from '../../ModelComponent';

class AddSiteModal extends ModelModal { // todo: extend from APIModal + make APIModal
  constructor(props) {
    super(props, "Add Site");
  }
  renderModal() {
	const form = this.getFormHelper('Site', 'default');
    return (<>
      {form.textInput('name')}
      {form.textInput('description', true)}
      {form.textInput('address', true)}
    </>);
  }
  renderModalBottom() {
	const form = this.getFormHelper('Site', 'default');
    return (<>
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 1, justifyContent: "center"}}>
          <LinkButton title="Cancel" color="#A00" style={{width: 200}} onPress={() => this.props.closeModal()} />
        </View>
        <View style={{flex: 1, flexDirection: 'row-reverse'}}>
          <Button title="Add" style={{width: 100}} onPress={() => form.create().then(() => this.props.closeModal()).catch(console.error)} />
        </View>
      </View>
    </>);
  }
}
export default AddSiteModal = connect({
	component: AddSiteModal,
	models: [{
  		model: 'Site', 
	}]
});