import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { ModelModal, APIModal, connect } from '../../ModelComponent';

import ButtonPreview from '../../../components/buttonPreview.component';

import TextStyleSettingsModal from './textStyleSettings.modal';

class SaveAsDialogue extends APIModal {
	constructor(props) {
		super(props, "Save As");
		this.state = {
			...this.state,
			saveAsName: this.props.name,
		}
	}
	renderModal() {
		return (<>
			{this.renderTextInput({ name: 'New Style Name', value: this.state.saveAsName, onUpdate: (newValue) => this.updateState({ saveAsName: newValue }) })}
		</>);
	}
	renderModalBottom() {
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Close" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal(false)} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Save" style={{ width: 100, marginRight: 20 }} onPress={() => {
						this.props.closeModal(true, this.state.saveAsName);
					}} />
				</View>
			</View>
		</>);
	}
}

class ButtonStyleSettingsModal extends ModelModal {
	constructor(props) {
		super(props, "Button Style Settings");
		this.state = {
			...this.state,
			textStyleEditorOpen: false,
			saveAsDialogueOpen: false,
		}
	}
	renderModalLeft() {
		const textStyleID = this.getModelValue('textStyleID', 'ButtonStyle', this.props.id);
		return (<>
			{this.state.saveAsDialogueOpen && <SaveAsDialogue
				open={this.state.saveAsDialogueOpen} 
				name={this.getModelValue('name', 'ButtonStyle', this.props.id)}
				closeModal={(saved, newName) => {
					if (!saved) return this.updateState({saveAsDialogueOpen: false});
					
					this.setModelField('name', newName, 'ButtonStyle', this.props.id)
					.then(() => this.saveModel(true, null, 'ButtonStyle', this.props.id, ['id'], false))
					.then((response) => {
						const id = response.data.data.data.id.value;
						if (!id) return this.props.closeModal(true, id);
						this.saveModel(false, null, 'ButtonStyle', this.props.id, [], true, id).then(() => this.updateState({saveAsDialogueOpen: false}, this.props.closeModal(true, id))); //do again with id and uploads
					}).catch(console.error);
				}}
			/>}
			<TextStyleSettingsModal 
				id={textStyleID} 
				open={this.state.textStyleEditorOpen} 
				closeModal={(saved, styleID) => {
					if (saved && styleID) this.setModelFields({textStyleID: styleID}, 'ButtonStyle', this.props.id);
					this.resetIndividualModel('TextStyle', '*');
					this.setState({textStyleEditorOpen: false});
				}}
			/>
			{this.renderModelTextInput({ name: 'name', model: 'ButtonStyle', id: this.props.id })}
			{this.renderModelTextInput({ name: 'description', model: 'ButtonStyle', id: this.props.id }, true)}
			{this.renderModelDropdown({
				displayText: 'Text Style',
				name: 'textStyleID',
				model: 'ButtonStyle',
				id: this.props.id,
				options: this.makeDropdownOptions('TextStyle'),
				onChange: (val) => { console.log(val) }
			})}
			{textStyleID && <LinkButton title="Edit text style" onPress={() => { 
				this.updateState({textStyleEditorOpen: true});
			}} />}
			<Text style={[styles.label, {marginTop: 10}]}>Background:</Text>
			{this.renderModelColorPicker({label: 'Color', name: 'backgroundColor', model: 'ButtonStyle', id: this.props.id, palette: this.makeSimpleList('PaleteColor', data => data.color.value), onPaletteAdd: (color) => {
				this.resetDefaultModel('PaleteColor')
				.then(() => this.setModelFields({
					name: color,
					color
				}, 'PaleteColor', 'default'))
				.then(() => {
					this.saveModel(true, null, 'PaleteColor', 'default').catch(console.error);
				});
			}})}
			{this.renderModelColorPicker({label: 'Color (Pressed)', name: 'backgroundColorPressed', model: 'ButtonStyle', id: this.props.id, palette: this.makeSimpleList('PaleteColor', data => data.color.value), onPaletteAdd: (color) => {
				this.resetDefaultModel('PaleteColor')
				.then(() => this.setModelFields({
					name: color,
					color
				}, 'PaleteColor', 'default'))
				.then(() => {
					this.saveModel(true, null, 'PaleteColor', 'default').catch(console.error);
				});
			}})}
			{this.renderModelFilePicker({label: 'Image', name: 'backgroundImage', model: 'ButtonStyle', id: this.props.id})}
			{this.renderModelFilePicker({label: 'Image (Pressed)', name: 'backgroundImagePressed', model: 'ButtonStyle', id: this.props.id})}
			{this.renderModelSlider({label: 'Opacity', name: 'opacity', model: 'ButtonStyle', id: this.props.id, valueSuffix: '%'})}
		</>);
	}
	renderModalRight() {
		const buttonStyle = this.getModelData('ButtonStyle', this.props.id)?.data;
		const button = {};
		button.component = { 
			text: (buttonStyle && buttonStyle.allowText) ? "Button Text" : "", 
			buttonStyle
		}
		if (button.component.buttonStyle?.textStyleID) button.component.buttonStyle.textStyle = this.getModelData('TextStyle', button.component.buttonStyle.textStyleID)?.data;

		return (
			<View>
				<Text style={styles.label}>Preview:</Text>
				<View style={{ flex: 1, borderWidth: 1, borderColor: '#AAA', borderRadius: 5, minHeight: 200, justifyContent: "center", alignItems: "center", overflow: 'hidden' }}>
					<ButtonPreview 
						button={button}
						scale={1}
						images={this.getModelUploadValues(['backgroundImage', 'backgroundImagePressed'], 'ButtonStyle', this.props.id)} 
					/>
				</View>
				<View style={{ flexDirection: 'row', gap: 10 }}>
					<View style={{ flex: 1 }}>
						{this.renderModelTextInput({ name: 'width', model: 'ButtonStyle', id: this.props.id, numeric: true })}
					</View>
					<View style={{ flex: 1 }}>
						{this.renderModelTextInput({ name: 'height', model: 'ButtonStyle', id: this.props.id, numeric: true })}
					</View>
				</View>
				<View style={{ flexDirection: 'row', gap: 20 }}>
					{this.renderModelCheckBox({name: 'resizable', model: 'ButtonStyle', id: this.props.id})}
					{this.renderModelCheckBox({name: 'allowText', model: 'ButtonStyle', id: this.props.id})}
				</View>
				<View style={{ flexDirection: 'column' }}>
					{this.renderModelSlider({label: 'Corner Radius', name: 'cornerRadius', model: 'ButtonStyle', id: this.props.id, valueSuffix: 'px'})}
					{this.renderModelSlider({label: 'Bevel Size', name: 'bevelSize', model: 'ButtonStyle', id: this.props.id, valueSuffix: 'px'})}
				</View>
			</View>
		);
	}
	renderModalBottom() {
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Close" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Save" style={{ width: 100, marginRight: 20 }} onPress={() => this.saveModel(false, null, 'ButtonStyle', this.props.id).then(() => this.props.closeModal(true)).catch(console.error)} />
					<OutlineButton title="Save As New" style={{ width: 200, marginRight: 20 }} onPress={() => {
						this.updateState({saveAsDialogueOpen: true});
					}} />
					<OutlineButton title="Delete" color='#A00' style={{ width: 100, marginRight: 20 }} onPress={() => {
						const buttonStyle = this.getModelData('ButtonStyle', this.props.id)?.data;
						this.showConfirmDialogue("Delete "+buttonStyle.name+"?", "Are you sure you want to delete this Button Style?")
						.then(() => this.deleteModel({
							model: 'ButtonStyle',
							id: this.props.id,
							children: []
						}))
						.then(() => this.props.closeModal(true))
						.catch(console.error)
					}} />
				</View>
			</View>
		</>);
	}
}
export default ButtonStyleSettingsModal = connect({
	models: [{ model: 'ButtonStyle' }, { model: 'TextStyle', list: true }, { model: 'PaleteColor', list: true }],
	component: ButtonStyleSettingsModal
});

