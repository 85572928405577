import { createNavigationContainerRef } from '@react-navigation/native';

let ready = false;
let callbackID = 0;
const navigationCallbacks = {
    onReady: {},
    onNavigate: {},
};
function addCallbacks({ onNavigate, onReady }) {
    callbackID++;
    if (onReady) {
		if (ready) setTimeout(() => onReady(), 0);
		navigationCallbacks.onReady[callbackID] = onReady;
	}
    if (onNavigate) navigationCallbacks.onNavigate[callbackID] = onNavigate;
    return callbackID;
}
function clearCallbacks(callbackID) {
    if (callbackID in navigationCallbacks.onReady) delete navigationCallbacks.onReady[callbackID];
    if (callbackID in navigationCallbacks.onNavigate) delete navigationCallbacks.onNavigate[callbackID];
}

let currentRoute = null;

export const navigationRef = createNavigationContainerRef();

const stateChangeListener = (event) => {
    //if (!event.data?.state?.routes) return;
    const newRoute = navigationRef.getCurrentRoute(); //event.data.state.routes[event.data.state.index];
    if (newRoute && (currentRoute == null || currentRoute.name != newRoute.name)) {
        currentRoute = newRoute;
        for (const callbackID in navigationCallbacks.onNavigate) navigationCallbacks.onNavigate[callbackID](currentRoute.name, currentRoute);
    }
}

export function navigationRefReady() {
    for (const callbackID in navigationCallbacks.onReady) navigationCallbacks.onReady[callbackID]();
	ready = true;
    navigationRef.addListener('state', stateChangeListener);
}

export function initNavigation({ onNavigate, onReady }) {
    return addCallbacks({ onNavigate, onReady });
}
export function clearNavigation(callbackID) {
    if (callbackID) {
        clearCallbacks(callbackID);
    } else {
        navigationCallbacks.onReady = {};
        navigationCallbacks.onNavigate = {};
    }
}

export function getCurrentRoute() {
	return currentRoute;
}

export function navigate(name, params) {
  if (!navigationRef.isReady()) return;
  navigationRef.navigate(name, params);
}
