if (!window.navigator.userAgent) window.navigator.userAgent = "react-native";
const { io } = require('socket.io-client');
import AsyncStorage from '@react-native-async-storage/async-storage';
import rootStore, { getState } from '../root.store.js';
import { SERVER_DOMAIN } from '../constants';
import { Platform } from 'react-native';
//connect
let firstConnection = true;

//const socket = io((isWeb ? '' : 'https://cxreveal.com'), {
const socket = io(SERVER_DOMAIN, {
    jsonp: false,
    transports: ['websocket'],
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax : 5000,
    reconnectionAttempts: Infinity,
    autoConnect: false,
});
socket.connectionID = null;

export function connectSocket() {
    socket.connect();
}

export function getConnectionID() {
	return socket.connectionID;
}

const apiKey = (() => {
	if (!Platform.OS === "web") return null;
  
	const requestParams = new URLSearchParams(window.location.search);
	const apiKey = requestParams.get('apiKey');
	if (apiKey) return apiKey;
  
	const pathParts = window.location.pathname.split('/'); 
	if (pathParts.length == 3 && pathParts[1] == 'webReveal') {
	  return pathParts[2];
	}
  
	return null;
})();

const loginKey = (() => {
	if (!Platform.OS === "web") return null;
  
	const requestParams = new URLSearchParams(window.location.search);
	const loginKey = requestParams.get('loginKey');
	if (loginKey) return loginKey;
  
	return null;
})();

const onLoggedIn = (response) => {
    AsyncStorage.getItem('userData').then(strUserData => {
        if (strUserData == null) return;
        const userData = JSON.parse(strUserData) || {};
        userData.valid = response.success;
        return AsyncStorage.setItem('userData', JSON.stringify(userData));
    }).catch(err => console.error(err));

    if (!response.success) return rootStore.dispatch(loginError(response.reason));
    rootStore.dispatch(loginSuccess(response.data));
};

export const api = {}; // {modelName: {endpointName: callback(), ...}, other: {endpointName: callback(), ...}, ...}
export const models = {}; // {modelName: {name: "", fields: ""}, ...}

const endpointRegister = {};
const endpointRegisterData = {totalCalls: 0, serverCalls: 0, mitigatedCalls: 0}
function registerEndpointAndCheckIfServerCallNeeded({path, modelName, requestData, resolve, reject}) {
	endpointRegisterData.totalCalls++;
	if (!(path in endpointRegister)) {
		endpointRegisterData.serverCalls++;
		return null;
	}
	
	const endpointCallID = modelName + JSON.stringify(requestData);
	if (!(endpointCallID in endpointRegister[path].calls)) {
		endpointRegister[path].calls[endpointCallID] = { ready: false, calling: false, promises: [], loadChildren: requestData.loadChildren || false, response: null };
	}

	const registeredCall = endpointRegister[path];
	const endpointCall = registeredCall.calls[endpointCallID];

	const listID = (() => {
		if (registeredCall.type !== 'list') return null;
		return requestData.listID || 'default';
	})();

	if (registeredCall.type === 'get') {
		const models = getState().models;
		const modelID = requestData.data?.id;
		const model = models?.[modelName]?.[modelID] || false;
	
		let modelHasChildren = false;
		if (model) for (const childName in model.children) { modelHasChildren = true; break; }

		//if ((model && (!requestData.loadChildren || (requestData.loadChildren && modelHasChildren))) || endpointCall.ready) { // if (endpointCall.ready) { 
		if ((!requestData.loadChildren || (requestData.loadChildren && model.childrenLoaded))) {
			if (model) {
				resolve(model.data);
				return false;
			}
			if (endpointCall.ready) {
				endpointRegisterData.mitigatedCalls++; 
				console.log('API call MITIGATED ['+endpointRegisterData.mitigatedCalls+'/'+endpointRegisterData.totalCalls+'] (model already loaded):', path, endpointCallID); 

				resolve(model? model.data : endpointCall.response);
				return false;
			}
		}
		if (endpointCall.calling) {
			endpointRegisterData.mitigatedCalls++;
			console.log('API call MITIGATED ['+endpointRegisterData.mitigatedCalls+'/'+endpointRegisterData.totalCalls+'] (already loading):', path, endpointCallID, '...');

			endpointCall.promises.push({resolve, reject});
			return false;
		}
	} else if (registeredCall.type === 'list') { //check lists
		const models = getState().models;
		const list = models?.[modelName]?.lists[listID] || false;

		if (list && list.loaded && endpointCall.ready) {
			endpointRegisterData.mitigatedCalls++; 
			console.log('API call MITIGATED ['+endpointRegisterData.mitigatedCalls+'/'+endpointRegisterData.totalCalls+'] (list) already loaded):', path, endpointCallID); 

			resolve(endpointCall.response);
			return false;
		} else if (endpointCall.calling) {
			endpointRegisterData.mitigatedCalls++;
			console.log('API call MITIGATED ['+endpointRegisterData.mitigatedCalls+'/'+endpointRegisterData.totalCalls+'] (already loading):', path, endpointCallID, '...');

			endpointCall.promises.push({resolve, reject});
			return false;
		}
	}
	//console.log(registeredCall, endpointCall)

	endpointRegisterData.serverCalls++;
	console.log('API SERVER CALL ['+endpointRegisterData.serverCalls+'/'+endpointRegisterData.totalCalls+']:', path, endpointCallID); 

	endpointCall.calling = true;
	endpointCall.promises = [];
	endpointCall.response = null;
	return registeredCall.type === 'list' ? listID : null;
}
function rejectEndpoint({path, modelName, requestData, response, reject}) {
	if (!(path in endpointRegister)) return reject(response.reason);
	const endpointCallID = modelName + JSON.stringify(requestData);
	if (!(endpointCallID in endpointRegister[path].calls)) return reject(response.reason);
	
	const endpointCall = endpointRegister[path].calls[endpointCallID];

	endpointCall.promises.forEach(promise => promise.reject(response.reason));
	endpointCall.calling = false;
	endpointCall.promises = [];
	endpointCall.response = response;
		
	return reject(response.reason); // toast reason?
}
function resolveEndpoint({path, modelName, requestData, response, resolve}) {
	if (!(path in endpointRegister)) return resolve(response);
	const endpointCallID = modelName + JSON.stringify(requestData);
	if (!(endpointCallID in endpointRegister[path].calls)) return resolve(response);
	
	const endpointCall = endpointRegister[path].calls[endpointCallID];

	endpointCall.response = response;
	endpointCall.promises.forEach(promise => promise.resolve(response));
	endpointCall.calling = false;
	endpointCall.promises = [];
	endpointCall.ready = true;

	resolve(response);
}

export const apiListener = ({path, callback}) => {
	socket.on(path, callback);

	const unsubscribe = ()=> {
		socket.off(path, callback);
	}

	return unsubscribe;
}

export const apiCall = ({path, modelName, requestData}) => { // move api to component base?
    return new Promise((resolve, reject) => {
		const listID = registerEndpointAndCheckIfServerCallNeeded({path, modelName, requestData, resolve, reject});
		if (listID === false) return;
		//console.log(listID);

        if (!socket.connected) return reject(new Error('Not connected to server'));

		const model = (modelName ? models[modelName] : null);

		const registeredCall = endpointRegister[path];

        socket.emit(path, {data: requestData}, (response) => { // add, get, update => response = { removed: {modelName: [id, ...], ...}, data: { data: {fieldName: {value, error}, ...}, children: [{model, data, children}, ...] } }
            // list will have data as an array, delete will have the model name/id in "removed"
			if (!response.success) return rejectEndpoint({path, modelName, requestData, response, reject});
            if (model && response.data.data) rootStore.dispatch({ 
                type: 'UPDATE_MODEL', 
                payload: {
                    name: modelName,
                    data: response.data.data,
                    remove: response.data.removed,
					parent: requestData.parent,
					parents: response.data.data?.parents || [],
					requestType: registeredCall?.type || 'unknown',
					requestData: requestData,
					listID
                }
            });

			resolveEndpoint({path, modelName, requestData, response, resolve});
        });
    })
}
const createEndpoint = (model, endpoint) => {
	const isAdd = endpoint.path.substring(0,3) === 'add';
	const isList = endpoint.path.substring(0,4) === 'list';
	const isListCount = endpoint.path.substring(0,9) === 'listCount';
	const isGet = endpoint.path.substring(0,3) === 'get';
	const isDelete = endpoint.path.substring(0,6) === 'delete';
	const isUpdate = endpoint.path.substring(0,6) === 'update';
	if (isList || isGet) {
		endpointRegister[endpoint.path] = {
			type: isAdd?'add':
				isList?'list':
				isListCount?'listCount':
				isGet?'get':
				isDelete?'delete':
				isUpdate?'update':
				'unknown',	
			calls: []
		};
	}
    return (requestData) => apiCall({
        path: endpoint.path, 
        modelName: model ? model.name : null, 
        requestData
    });
}

export const loginSuccess = (data) => {
    //save user data
    AsyncStorage.getItem('userData').then(strUserData => {
        if (strUserData == null) return;
        const userData = JSON.parse(strUserData) || {};        

        userData.valid = true;
        return AsyncStorage.setItem('userData', JSON.stringify(userData));
    }).catch(err => console.error(err));

    const dataModels = data.models.models;
    console.log(dataModels);
    for (const modelName in dataModels) {
        const model = dataModels[modelName];
        models[model.name] = {name: model.name, fields: model.fields, parents: model.parents, children: model.children};

        const modelAPI = {};
        if (model.api) for (const endpointName in model.api) {
            const endpoint = model.api[endpointName];
            modelAPI[endpointName] = createEndpoint(model, endpoint);
        }
        api[model.name] = modelAPI;
    }
	console.log(models);
    const otherEndpoints = data.models.otherEndpoints;
    for (const endpointName in otherEndpoints) {
        const endpoint = otherEndpoints[endpointName];
        if (endpoint) api[endpointName] = createEndpoint(null, endpoint);
    }

    return {
        type: 'LOGIN_SUCCESS',
        payload: data,
    }
}
export const loginError = error => {
    AsyncStorage.getItem('userData').then(strUserData => {
        if (strUserData == null) return;
        const userData = JSON.parse(strUserData) || {};

        userData.valid = false;
        return AsyncStorage.setItem('userData', JSON.stringify(userData));
    }).catch(err => console.error(err)); 
    return {
        type: 'LOGIN_ERROR',
        payload: error,
    }
}

export function login({loginID, loginPassword}) {
    AsyncStorage.setItem('userData', JSON.stringify({
        email: loginID,
        password: loginPassword
    })).then(() => {
        socket.emit('login', {email: loginID, passwordHash: loginPassword}, onLoggedIn);
    }).catch(err => console.error(err));
    return {
        type: 'REQUEST_LOGIN',
        payload: {email: loginID, passwordHash: loginPassword},
    }
}

export function logout() {
	socket.emit('logout', (response) => {
		console.log(response);
	});
	return {
        type: 'LOGOUT',
        payload: null,
    }
}

export function tokenLogin(loginKey) {
    socket.emit('tokenLogin', {token: loginKey}, onLoggedIn);
    return {
        type: 'REQUEST_LOGIN',
        payload: {loginKey},
    }
}

function onConnected(response) {
    socket.connectionID = response.data.connectionID;
	firstConnection = false;

	//console.log(response, socket.connectionID);
	
	AsyncStorage.setItem('connectionID', JSON.stringify(socket.connectionID)).catch(console.error);
	rootStore.dispatch({
        type: 'SERVER_CONNECT',
        payload: null,
    });
	if (loginKey) tokenLogin(loginKey);
    else if (response.data.loggedIn) onLoggedIn(response);
	else rootStore.dispatch({
        type: 'LOGOUT',
        payload: null,
    });
}

socket.on('connect', () => {
    console.log("SOCKET CONNECTED");
	if (apiKey) {
		socket.emit("ready", onConnected);
	} else {
		function connectSession(connectionID) {
			console.log(connectionID);
			if (connectionID) socket.emit("reconnectSession", {connectionID}, onConnected);
			else socket.emit("ready", onConnected);
		}
		if (loginKey) return connectSession();
		AsyncStorage.getItem('connectionID').then(connectionIDStr => {
			const connectionID = JSON.parse(connectionIDStr);
			connectSession(connectionID || socket.connectionID);
		}).catch((err) => {
			console.error(err);
			connectSession(socket.connectionID);
		});
	}
});

socket.on('disconnect', () => {
    console.log("SOCKET DISCONNECTED");
    rootStore.dispatch({
        type: 'SERVER_DISCONNECT',
        payload: null,
    });
	if (apiKey && Platform.OS === "web") {
		window.onbeforeunload = () => {}
		location.reload();
	} else setTimeout(() => {
		if ((!socket.connected) && Platform.OS === "web") {
			window.onbeforeunload = () => {}
			location.reload();
		}
	}, 2000);
});

socket.io.on('reconnect', (attempt) => {
    console.log("SOCKET RECONNECTED");
    if (!socket.connectionID) rootStore.dispatch({
        type: 'LOGOUT',
        payload: null,
    });
});

socket.io.on('reconnect_error', error => {
    console.error(error);
    rootStore.dispatch({
        type: 'LOGOUT',
        payload: null,
    });
});

socket.on('modelUpdate', ({model, data, parent, parents}) => {
    rootStore.dispatch({ 
        type: 'UPDATE_MODEL', 
        payload: {
            name: model,
            data: {data},
            remove: data.removed,
			parent,
			parents
        }
    });
});

export default socket;