import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { ModelModal, connect } from '../../ModelComponent';

import { navigate } from '../../../root.navigation';

import TemplatePreview from '../templatePreview.component';

class ImportPageModal extends ModelModal {
	constructor(props) {
		super(props, "Import Page");
		this.state = {
			...this.state,
			selectedSiteID: null,
			selectedKioskTemplateID: null,
			selectedPageID: null,
			newPageName: null,
		}
	}
	getPageName() {
		return this.state.newPageName || (this.getModelValue('name', 'TemplatePage', this.state.selectedPageID)+' Copy');
	}
	renderModal() {
		return (<>
			{this.renderDropdown({
				label: 'Select Site', 
				defaultValue: this.state.selectedSiteID,
				options: this.makeDropdownOptions('Site'),
				onChange: (val)=> {
					this.setState({selectedSiteID: val});
				}
			})}
			{this.state.selectedSiteID && this.renderDropdown({
				label: 'Select Template', 
				defaultValue: this.state.selectedKioskTemplateID,
				options: this.makeDropdownOptions('KioskTemplate', 'name', 'id', (model)=> model.data.siteID.value == this.state.selectedSiteID),
				onChange: (val)=> {
					this.setState({selectedKioskTemplateID: val});
				}
			})}
			{this.state.selectedKioskTemplateID && this.renderDropdown({
				label: 'Select Page', 
				defaultValue: this.state.selectedPageID,
				options: this.makeDropdownOptions('TemplatePage', 'name', 'id', (model)=> model.data.kioskTemplateID.value == this.state.selectedKioskTemplateID),
				onChange: (val)=> {
					this.setState({selectedPageID: val});
				}
			})}

			{this.state.selectedKioskTemplateID && <>
				<Text style={styles.label}>Preview:</Text>
				<View style={{borderRadius: 10, overflow: 'hidden'}}>
					<TemplatePreview id={this.state.selectedKioskTemplateID} pageID={this.state.selectedPageID} />
				</View>
			</>}

			{this.state.selectedPageID && this.renderTextInput({name: 'New Page Name', value: this.getPageName(), onUpdate: (val)=> {
				this.setState({newPageName: val});
			}})}
		</>);
	}
	renderModalBottom() {
		//const componentID = this.getModelValue('componentID', 'TemplateComponent', this.props.id);
		//const positionDataID = this.getModelValue('templatePositionDataID', 'TemplateComponent', this.props.id);
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Cancel" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					{this.state.selectedPageID && <Button title="Import" style={{ width: 200 }} onPress={() => {
						console.log(this.state.selectedPageID);

						this.apiCall('KioskTemplate', 'importTemplatePage', {
							kioskTemplateID: this.props.kioskTemplateID,
							importPageID: this.state.selectedPageID,
							newPageName: this.getPageName()
						})
						.then(response => {
							console.log(response);
							this.props.closeModal();
						}).catch(console.error);
					}} />}
				</View>
			</View>
		</>);
	}
}
export default ImportPageModal = connect({
	loadingName: 'Page Importer',
	models: [{ model: 'Site', list: true }, { model: 'KioskTemplate', list: true }, { model: 'TemplatePage', list: true }],
	component: ImportPageModal
});

