import React from 'react';
import { View, Text, StyleSheet, Platform } from 'react-native';
import { styles } from '../../main.styles';

//import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { FontAwesomeIcon as FontAwesomeReact } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon as FontAwesomeNative } from '@fortawesome/react-native-fontawesome';
const FontAwesomeIcon = (Platform.OS === 'web' ? FontAwesomeReact : FontAwesomeNative);

import { faTimesCircle, faCheckCircle, faWifi, faBatteryFull, faBatteryThreeQuarters, faBatteryHalf, faBatteryQuarter, faBatteryEmpty } from '@fortawesome/free-solid-svg-icons'
import { faUsb, faBluetooth } from '@fortawesome/free-brands-svg-icons'

import { ModelComponent, connect } from '../ModelComponent';

import PageView from '../../components/views/page.view.component'
import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';
import IconButton from '../../components/iconButton.component';

import DeviceSettingsModal from './modals/deviceSettings.modal';

class DeviceComponent extends ModelComponent {
	constructor(props) {
		super(props);
		this.state = {
			settingsOpen: false,
		}
	}
	renderContent() {
		const getValue = this.modelValueGetter('Device', this.props.id);
		const id = getValue('id');
		const hasConnection = getValue('connected'); //(this.props.wifiConnected || this.props.usbConnected || this.props.bleConnected);
		const wifiConnected = hasConnection;
		const usbConnected = false;
		const bleConnected = false;
		const status = getValue('status');
		const statusText = (!hasConnection) ? 'Offline' : (!status) ? 'loading...' : status.tx ? 'Transmitting' : status.rx ? 'Scanning' : 'Online';
		const statusColor = (statusText === 'Offline') ? '#F24' : (statusText === 'Ready' || statusText === 'Online') ? '#0B0' : '#24F';
		
		const deviceSiteID = getValue('siteID');
		const siteName = deviceSiteID ? this.getModelValue('name', 'Site', deviceSiteID) : 'Unassigned';

		console.log(status);
		let batteryIcon = faBatteryEmpty;
		let batteryIconColor = "#AAA";
		if (status?.soc && status.soc < 150) {
			const soc = status.soc;
			if (soc >= 80) {
				batteryIcon = faBatteryFull;
				if (hasConnection) batteryIconColor = "#0B0";
			} else if (soc >= 60) {
				batteryIcon = faBatteryThreeQuarters;
				if (hasConnection) batteryIconColor = "#0B0";
			} else if (soc >= 40) {
				batteryIcon = faBatteryHalf;
				if (hasConnection) batteryIconColor = "#FF4";
			} else if (soc >= 20) {
				batteryIcon = faBatteryQuarter;
				if (hasConnection) batteryIconColor = "#F24";
			} else {
				if (hasConnection) batteryIconColor = "#F24";
			}
		}

		return (<>
			<DeviceSettingsModal
				id={id}
				open={this.state.settingsOpen}
				closeModal={() => this.setState({ settingsOpen: false })}
			/>
			<View style={[styles.shadowBoxNoPadding, { padding: 10, flexDirection: 'row', gap: 10, flexWrap: 'wrap' }]}>
				<IconButton size={30} stretchContainer={false} title="Settings" onPress={() => this.setState({ settingsOpen: true })} />
				<View style={{ flex: 1 }}>
					<View style={{ flexDirection: 'column' }}>
						<View style={{ flexDirection: 'row', flex: 1 }}>
							
								<View style={deviceStyles.statusIcon}>
									{hasConnection
										? <FontAwesomeIcon style={StyleSheet.flatten([{ color: '#0B0' }])} icon={faCheckCircle} />
										: <FontAwesomeIcon style={StyleSheet.flatten([{ color: '#F24' }])} icon={faTimesCircle} />
									}
								</View>

							<Text style={deviceStyles.deviceName}>{getValue('deviceName')}{status?.name && " - "+status.name}</Text>
							<Text style={deviceStyles.status}>(<Text style={{ color: statusColor }}>{statusText}</Text>)</Text>
							{this.props.showIcons !== false && <>
								<View style={deviceStyles.icon}><FontAwesomeIcon style={StyleSheet.flatten([wifiConnected ? deviceStyles.iconConnected : deviceStyles.iconDisconnected])} icon={faWifi} /></View>
								<View style={deviceStyles.icon}><FontAwesomeIcon style={{color: batteryIconColor}} icon={batteryIcon} />{(status?.soc !== undefined && status.soc < 150) ? <Text style={{marginHorizontal: 5, marginTop: -1, fontWeight: 500, fontSize: "75%"}}>{status.soc}%</Text> : null}</View>
								{/*
								<View style={deviceStyles.icon}><FontAwesomeIcon style={StyleSheet.flatten([usbConnected ? deviceStyles.iconConnected : deviceStyles.iconDisconnected])} icon={faUsb} /></View>
								<View style={deviceStyles.icon}><FontAwesomeIcon style={StyleSheet.flatten([bleConnected ? deviceStyles.iconConnected : deviceStyles.iconDisconnected])} icon={faBluetooth} /></View>
								*/}
							</>}
						</View>
						<Text style={{marginTop: 10}}>Site: {siteName}</Text>
						{status?.v && <Text style={{marginTop: 5}}>Firmware Version: {status.v}</Text>}
						{status?.wifi && <Text style={{marginTop: 5}}>WiFi Connection: {status.wifi}</Text>}
						<Text style={{marginTop: 10}}>{getValue('description')}</Text>
					</View>
					<View>
						{this.renderError()}
					</View>
				</View>
			</View>
		</>);
	}
}
export const Device = connect({
	component: DeviceComponent,
	models: [
		{
			model: 'Device',
		},
		{
			model: 'Site',
			list: true,
		}
	]
});


export const deviceStyles = StyleSheet.create({
	deviceBoxContainer: {
		backgroundColor: '#F6F6F6',
		marginBottom: 20,
	},
	deviceLineContainer: {
		padding: 20,
		backgroundColor: '#FFF',
		borderRadius: 10,
	},
	noBottomBorderRadius: {
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
	},
	commandContainer: {
		padding: 20,
	},
	slotsContainer: {
		marginBottom: 10,
	},
	slotContainer: {
		backgroundColor: '#FFF',
		marginBottom: 5,
		borderRadius: 10,
		padding: 10,
	},
	statusContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	statusIcon: {
		color: '#AAA',
		paddingTop: 2,
		marginRight: 5
	},
	status: {
		marginLeft: 5,
		marginRight: 10,
	},
	deviceName: {
		color: '#000',
		fontWeight: '500',
	},
	icon: {
		flexDirection: 'row',
		marginHorizontal: 5,
		transform: [
			{ translateY: 2 },
		],
	},
	iconConnected: {
		color: '#5AF'
	},
	iconDisconnected: {
		color: '#AAA'
	}
});