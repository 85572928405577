import React from 'react';
import { connect } from 'react-redux';
import { View, StyleSheet, Text, TextInput, Platform } from 'react-native';
import { styles } from '../../main.styles';

import sha256 from '../../core/sha256.api';
import rootStore from '../../root.store.js';
import { navigate, initNavigation, clearNavigation } from '../../root.navigation.js';
import {logout} from '../../core/socket.api';
import {login} from '../../core/socket.api';

import PageView from '../../components/views/page.view.component';
import Button from '../../components/button.component';
import LinkButton from '../../components/linkButton.component';

const resetKey = (() => {
  if (!Platform.OS === "web") return null;
  return new URLSearchParams(window.location.search).get('resetKey');
})();

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      email: '',
      password: '',
      isNewPassword: false,
      originalEmail: '',
      originalPassword: '',
    };
    this.navigationCallbackID = false;
  }
  componentDidMount() {
    this.navigationCallbackID = initNavigation({
      onReady: () => {
		console.log(resetKey);
        if (resetKey) navigate('ResetPassword', {resetKey, email: null});
      }
    });
  }
  componentWillUnmount() {
    if (this.navigationCallbackID) clearNavigation(this.navigationCallbackID);
  }
  componentDidUpdate = () => {
    const newState = {
      ...this.state
    }
    let stateChanged = false;

    if (this.state.originalEmail != this.props.userData?.email) {
      newState.originalEmail = this.props.userData?.email;
      stateChanged = true;
    }
    if (this.state.originalPassword != this.props.userData?.password) {
      newState.originalPassword = this.props.userData?.password;
      stateChanged = true;
    }

    if (this.props.userData?.email && this.state.email == '') {
      newState.email = this.props.userData?.email;
      stateChanged = true;
    }
    if (this.props.userData?.password && this.state.password == '') {
      newState.password = this.props.userData?.password;
      stateChanged = true;
    }

    if (stateChanged) this.setState(newState);
  }
  render() {
    return (
      <>
      {/*<ImageBackground source={require('../assets/images/pattern_bkg.jpg')} resizeMode="cover" style={{flex: 1, height: Dimensions.get('window').height, width: Dimensions.get('window').width}}>*/}
        <PageView center="true">
          <View style={styles.centerView}>
            <View style={[loginStyles.container, styles.shadowBox, {borderWidth: 1, borderColor: '#ccc'}]}>
              <Text style={loginStyles.title}>Login</Text>
              <View style={{marginBottom: 5}}>
                <TextInput
                  style={[styles.input, {maxWidth: '100%', caretColor: 'rgba(0,0,0,0.5)'}]}
                  placeholder="Email"
                  onChangeText={email => this.setState({email: email})}
                  onChange={event => this.setState({email: event.nativeEvent.text})}
                  defaultValue={this.props.userData.email}
                  underlineColorAndroid="transparent"
                  textContentType="username"
                  placeholderTextColor="grey"
                  autoCapitalize="none"
                />
              </View>
              <View style={{marginBottom: 5}}>
                <TextInput
                  style={[styles.input, {maxWidth: '100%', caretColor: 'rgba(0,0,0,0.5)'}]}
                  placeholder="Password"
                  onChangeText={password => this.setState({isNewPassword: true, password: password})}
                  onChange={event => this.setState({isNewPassword: true, password: event.nativeEvent.text})}
                  underlineColorAndroid="transparent"
                  textContentType="password"
                  placeholderTextColor="grey"
                  autoCapitalize="none"
                  secureTextEntry={true}
                />
              </View>
              <View style={{alignItems: "flex-end"}}>
                <Button
                  title="Login"
                  onPress={() => {
                    if (!this.state.isNewPassword) login({loginID: this.state.email, loginPassword: this.state.password});
                    else sha256(this.state.password).then(hashedPassword => {
                      rootStore.dispatch(login({loginID: this.state.email, loginPassword: hashedPassword}));
                    }).catch(console.error);
                  }}
                />
              </View>
              {this.props.loginError && <Text style={styles.error}>{this.props.loginError}</Text>}
              <View style={{position: 'absolute', bottom: 20}}>
                <LinkButton title="Reset Password" onPress={() => navigate('ResetPassword', {resetKey: null, email: this.state.email})} />
              </View>
            </View>
          </View>
        </PageView>
      {/*</ImageBackground>*/}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return state.root;
};
export default connect(mapStateToProps)(Login);

export class Logout extends React.Component {
  componentDidMount = () => {
    rootStore.dispatch(logout());
  }
  render() {
    return null;
  }
}

const loginStyles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 50, 
    width: '100%',
    height: '100%', 
    maxWidth: 400,
    minHeight: 400,
    backgroundColor: '#FFF', //'rgba(0,0,0,0.5)',
  },
  title: {
    marginBottom: 50,
    color: '#222',
    fontSize: 40
  },
});
