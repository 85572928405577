import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Platform } from 'react-native';
import { styles } from '../../main.styles';

import { ModelComponent, connect } from '../ModelComponent';

import PageView from '../../components/views/page.view.component'
import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';
import LinkButton from '../../components/linkButton.component';
import IconButton from '../../components/iconButton.component';

import ButtonSettingsModal from './modals/buttonSettings.modal';
import ImageSettingsModal from './modals/imageSettings.modal';
import PlaylistSettingsModal from './modals/playlistSettings.modal';
import TextSettingsModal from './modals/textSettings.modal';
import CountdownSettingsModal from './modals/countdownSettings.modal';
import ModalSettingsModal from './modals/modalSettings.modal';

//import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import {FontAwesomeIcon as FontAwesomeReact} from '@fortawesome/react-fontawesome';
import {FontAwesomeIcon as FontAwesomeNative} from '@fortawesome/react-native-fontawesome';
const FontAwesomeIcon = (Platform.OS === 'web' ? FontAwesomeReact : FontAwesomeNative);
import { faImage, faFilm, faParagraph, faStopwatch, faPager, faCaretSquareRight, faEye, faEyeSlash, faCog, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { makeArrow } from './templateEditorScreen';

const ucFirst = (name) => typeof name === 'string' ? name.charAt(0).toUpperCase() + name.substring(1) : name;
function ComponentItem(props) {
	const { data } = props;
	const { templateID, currentPageID, onChange, onVisibilityChange, hiddenComponents, editingModal, kioskID } = props.parentProps;

	const [hidden, setHidden] = React.useState(hiddenComponents.includes(data.id.value));
	//const [loaded, setLoaded] = React.useState(true); //false);
	const [editorOpen, setEditorOpen] = React.useState(false);
	//const onLoaded = React.useCallback(() => setLoaded(true), []);

	const EditorModal = props.editorModal;

	props.openEditorCall.fn = ()=> {
		setEditorOpen(true);
	}

	return (
		<View style={{flexDirection: 'column', borderBottomWidth: 1, borderColor: '#CCC', padding: 5}}>
			{(EditorModal && editorOpen) && <EditorModal 
				open={editorOpen} 
				closeModal={() => {
					if (onChange) onChange();
					setEditorOpen(false)
				}} 
				id={data.id.value} 
				name={data.name.value} 
				templateID={templateID}
				editingModal={editingModal}
				currentPageID={currentPageID}
				kioskID={kioskID}
				componentID={data.componentID?.value} 
				positionDataID={data.templatePositionDataID?.value}
			/>}
			<View style={{flexDirection: 'row'}}>	
				<Text style={{flex:1, color: '#444'}}>{data.name.value}</Text>
				<IconButton icon={hidden?faEyeSlash:faEye} onPress={() => {
					setHidden(!hidden); 
					onVisibilityChange?.(data.id.value, !hidden);
				}} />
				<IconButton icon={faTrashAlt} style={{marginLeft: 10}} onPress={() => {
					const deleteComponent = () => {
						if (data.type.value == 'modal') {
							console.log(data.children);
							const children = [];

							//data.children[childName][child.id] = {model: child.model, data: childData};
							const addChildren = (data, list) => {
								console.log(data, list);
								if (data.children) for (const childName in data.children) {
									for (const childID in data.children[childName]) {
										const child = data.children[childName][childID];
										console.log(child);
										if ((!child) || (!child.model) || (!child.data?.id?.value) || child.model == 'ButtonStyle' || child.model == 'TextStyle') continue; //model not ButtonStyle or TextStyle
										
										const childItem = {
											model: child.model,
											id: child.data.id.value,
											children: [],
										};
										addChildren(child.data, childItem.children);

										console.log(childItem);
										list.push(childItem)
									}
								}
							}
							addChildren(data, children);

							console.log(children);

							props.context.deleteModel({
								model: 'TemplatePage',
								id: data.id.value,
								children
							});
						} else props.context.deleteModel({
							model: 'TemplateComponent', 
							id: data.id.value, 
							children: [
								{
									model: 'TemplatePositionData',
									id: data.templatePositionDataID?.value,
									children: []
								},
								{
									model: 'TemplateComponent'+ucFirst(data.type.value),
									id: data.componentID?.value,
									children: []
								},
							]
						});
					}
					props.context.showConfirmDialogue("Delete "+data.name.value+"?", "Are you sure you want to delete this "+ucFirst(data.type.value)+" component?")
					.then(() => deleteComponent())
					.catch(err => console.log(err));
				}} />
				{(true||(props.onEdit || props.EditorModal)) && <IconButton style={{marginLeft: 10}} icon={faCog} onPress={() => setEditorOpen(true) && props.onEdit?.() } />}
			</View>
		</View>
	)
}
function ComponentList(props) {
	const [open, setOpen] = React.useState(true); //props.components.length > 0);
	return (
		<View style={[{backgroundColor: '#FFF'}, props.style]}>
			<TouchableOpacity style={{ flexDirection: 'row', borderBottomWidth: 1, borderColor: '#CCC' }} onPress={() => setOpen(!open)}>
				{props.icon && <FontAwesomeIcon style={{color:'#AAA', width: 20, height: 20, margin: 5}} icon={props.icon} />}
				<Text style={[styles.labelTitle, {flex: 1, padding: 5, margin: 0}]}>{props.title}</Text>
				<View style={{flexDirection: 'column', justifyContent: 'center', marginRight: 5}}>{makeArrow(5, open?'#AAA':'#444', !open)}</View>
			</TouchableOpacity>
			{open && props.components.length > 0 && props.components.map(data => {
				const openEditorCall = {fn: ()=>{}, call: (...args)=> openEditorCall.fn(...args)};
				props.openEditorCalls[data.type.value+'-'+data.id.value] = openEditorCall;
				return (
					<ComponentItem key={data.type.value+'-'+data.id.value} data={data} openEditorCall={openEditorCall} parentProps={props.parentProps} context={props.context} editorModal={props.editorModal} />
				)
			})}
			{open && props.components.length === 0 && <Text style={{flex: 1, color: '#AAA', textAlign: 'center', paddingVertical: 5, borderBottomWidth: 1, borderColor: '#CCC'}}>empty</Text>}
		</View>
	);
}

class ComponentSettings extends ModelComponent {
	constructor(props) {
		super(props);
		this.openEditorCalls = {};
		if (props.openEditorCall) props.openEditorCall.fn = (componentType, componentID)=> {
			this.openEditorCalls[componentType+'-'+componentID].call();
		}
	}
	renderContent() {
		const {id, ...parentProps} = this.props;
		parentProps.currentPageID = id;

		const componentList = [
			...this.getModelList('TemplateComponent', (model) => model.model?.data.templatePageID?.value == id), 
			...this.getModelList('TemplatePage', (model) => model.model?.data.type?.value === 'modal' && model.model?.data.templatePageID?.value == id, true)
		];
		const components = {
			'button': [],
			'modal': [],
			'text': [],
			'image': [],
			'playlist': [],
			'widgets': [],
		}
		//console.log(componentList, this.props, this.state);
		for (const component of componentList) {
			if (component.type.value in components) components[component.type.value].push(component);
			else components['widgets'].push(component);
		}

		this.openEditorCalls = {};

		return (<>
			<View style={{ flexDirection: 'column', borderRadius: 10 }}>
				<ComponentList title="Modals" 
					icon={faPager} 
					components={components.modal} 
					editorModal={ModalSettingsModal}
					parentProps={parentProps}
					openEditorCalls={this.openEditorCalls}
					context={this}
					style={{borderTopEndRadius: 10, borderTopStartRadius: 10}} 
				/>
				<ComponentList title="Buttons" 
					icon={faCaretSquareRight} 
					components={components.button} 
					editorModal={ButtonSettingsModal}
					parentProps={parentProps}
					openEditorCalls={this.openEditorCalls}
					context={this}
				/>
				<ComponentList title="Images" 
					icon={faImage} 
					components={components.image} 
					editorModal={ImageSettingsModal}
					parentProps={parentProps}
					openEditorCalls={this.openEditorCalls}
					context={this}
				/>
				<ComponentList title="Playlists" 
					icon={faFilm} 
					components={components.playlist} 
					editorModal={PlaylistSettingsModal}
					parentProps={parentProps}
					openEditorCalls={this.openEditorCalls}
					context={this}
				/>
				<ComponentList title="Text" 
					icon={faParagraph} 
					components={components.text} 
					editorModal={TextSettingsModal}
					parentProps={parentProps}
					openEditorCalls={this.openEditorCalls}
					context={this}
				/>
				<ComponentList title="Widgets" 
					icon={faStopwatch} 
					components={components.widgets} 
					style={{borderBottomEndRadius: 10, borderBottomStartRadius: 10, paddingBottom: 10}} 
					editorModal={CountdownSettingsModal}
					parentProps={parentProps}
					openEditorCalls={this.openEditorCalls}
					context={this}
				/>
				{this.renderError()}
			</View>
		</>);
	}
}

// this should be in components right side box
export default ComponentSettings = connect({
	component: ComponentSettings,
	loadingName: 'Components',
	models: [
		{
			model: 'TemplateComponent',
			keyField: 'templatePageID',
			list: true,
			useKey: true,
			key: (root, props) => props.id,
		},
		{
			model: 'TemplatePage',
			list: true,
			loadChildren: true
		},
	]
});
