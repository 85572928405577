import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faTimesCircle, faCheckCircle, faWifi } from '@fortawesome/free-solid-svg-icons'
import { faUsb, faBluetooth } from '@fortawesome/free-brands-svg-icons'

import { ModelComponent, connect } from '../ModelComponent';

import PageView from '../../components/views/page.view.component'
import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';

class TemplateTabsView extends ModelComponent {
	renderContent() {
		const { currentPageID, onChange } = this.props;
		//const currentPageName = this.getModelValue('name', 'TemplatePage', currentPageID) || 'Loading';
		//console.log(currentPageName, currentPageID, this.state, this.props);
		
		return (<>
			<View style={{ backgroundColor: '#FFF', flexDirection: 'row', gap: 10, flexWrap: 'wrap', borderBottomWidth: 1, borderColor: '#CCC', padding: 4, minHeight: 40 }}>
				{this.props.editingModal && <OutlineButton title={this.props.currentPageName} />}
				{this.makeSimpleList('TemplatePage', (data) => (
					<View key={data.id.value}>
						{currentPageID == data.id.value
							? <OutlineButton title={data.name.value} />
							: <Button title={data.name.value} onPress={() => onChange(data.id.value)} />
						}
					</View>
				), (model) => model.model?.data.type?.value === 'page' && model.model?.data.kioskTemplateID?.value == this.props.id)}
				{this.renderError()}
			</View>
		</>);
	}
}
export default TemplateTabsView = connect({
	loadingName: 'Pages',
	component: TemplateTabsView,
	models: [
		{
			model: 'TemplatePage',
			keyField: 'kioskTemplateID',
			list: true,
			useKey: true,
			key: (root, props) => props.id,
		},
	]
});

