import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Platform } from 'react-native';
import { styles } from '../../main.styles';

//import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import {FontAwesomeIcon as FontAwesomeReact} from '@fortawesome/react-fontawesome';
import {FontAwesomeIcon as FontAwesomeNative} from '@fortawesome/react-native-fontawesome';
const FontAwesomeIcon = (Platform.OS === 'web' ? FontAwesomeReact : FontAwesomeNative);
import { faFile, faFilm, faCopy, faImage, faParagraph, faStopwatch, faPager, faCaretSquareRight, faDoorOpen } from '@fortawesome/free-solid-svg-icons'

import { ModelEditorComponent, connect } from '../ModelComponent';

import ImportPageModal from './modals/importPage.modal';

function ComponentButton(props) {
	return (
		<TouchableOpacity style={[{margin: 5, flexDirection: 'column'}, props.style]} onPress={() => props.onPress()}>
			<View style={{flexDirection: 'row', justifyContent: 'center'}}>
				{props.icon
				  ? <FontAwesomeIcon title={props.name} style={{color:'#8a745a', width: 30, height: 30, margin: 5}} icon={props.icon} />				
				  : <View style={{backgroundColor: '#CCC', width: 50, height: 50, borderRadius: 25}}>
						<View style={{backgroundColor: '#8a745a', width: 6, borderRadius: 3, height: 40, position: 'absolute', top: 5, left: 22}}></View>
						<View style={{backgroundColor: '#8a745a', width: 40, borderRadius: 3, height: 6, position: 'absolute', top: 22, left: 5}}></View>
					</View>
				}
			</View>
			{props.icon ? null : <Text style={{fontWeight: 400, textAlign: 'center'}}>{props.name}</Text>}
		</TouchableOpacity>
	);
}

class ComponentToolBox extends ModelEditorComponent {
	renderContent() {
		const editingPage = !this.props.editingModal;
		return (<>
			{this.state.importModelOpen && <ImportPageModal 
				kioskTemplateID={this.props.templateID} 
				open={this.state.importModelOpen} 
				closeModal={() => this.setState({importModelOpen: false})} 
			/>}
			<View style={{ backgroundColor: '#FFF', flex: 1, flexDirection: 'column', gap: 5, paddingTop: 5}}>
				{editingPage && <ComponentButton name="Add Page" onPress={() => this.addComponent('page')} icon={faFile} />}
				{editingPage && <ComponentButton name="Import Page" onPress={() => this.setState({importModelOpen: true})} icon={faCopy} />}
				{editingPage && <View style={{borderBottomWidth: 1, borderColor: '#AAA', width: '100%'}}></View>}
				<ComponentButton name="Add Button" onPress={() => this.addComponent('button')} icon={faCaretSquareRight} />
				<ComponentButton name="Add Modal" onPress={() => this.addComponent('modal')} icon={faPager} />
				<ComponentButton name="Add Image" onPress={() => this.addComponent('image')} icon={faImage} />
				<ComponentButton name="Add Playlist" onPress={() => this.addComponent('playlist')} icon={faFilm} />
				<ComponentButton name="Add Text" onPress={() => this.addComponent('text')} icon={faParagraph} />
				<View style={{borderBottomWidth: 1, borderColor: '#AAA', width: '100%'}}></View>
				<ComponentButton name="Add Countdown" onPress={() => this.addComponent('countdown')} icon={faStopwatch} />
				{this.props.parentPage && <>
					<View style={{borderBottomWidth: 1, borderColor: '#AAA', width: '100%'}}></View>
					<ComponentButton name={"Back To "+this.props.parentPage} onPress={() => this.props.exitEditor()} icon={faDoorOpen} />
				</>}
			</View>
		</>);
	}
	addComponent(type) {
		const createTemplateComponent = (name, childName, childData = {}, positionData = {}) => {
			this.setModelFields({
				templatePageID: this.props.currentPageID,
				name,
				type,
			}, 'TemplateComponent').then(() => {
				const children = [
					{name: childName, data: {...childData}},
					{
						name: 'TemplatePositionData', 
						data: {
							width: ('width' in positionData) ? positionData.width : 100, 
							height: ('height' in positionData) ? positionData.height : 100, 
							x: ('x' in positionData) ? positionData.x : 10, 
							y: ('y' in positionData) ? positionData.y : 10
						}
					},
				]
				return this.saveModel({create: true, children, model: 'TemplateComponent', id: 'default', parent: {model: 'TemplatePage', id: this.props.currentPageID}})
			}).then(() => {
				this.resetDefaultModel('TemplateComponent')
			}).then(() => {
				if (this.props.onAdd) this.props.onAdd(type);
			});
		}
		switch(type) {
			case 'page': {
				this.setModelFields({
					kioskTemplateID: this.props.templateID,
					name: 'New Page',
				}, 'TemplatePage').then(() => {
					return this.saveModel({create: true, model: 'TemplatePage', id: 'default', parent: {model: 'KioskTemplate', id: this.props.templateID}})
				}).then(() => {
					this.resetDefaultModel('TemplatePage')
				}).then(() => {
					if (this.props.onAdd) this.props.onAdd(type);
				});
				break;
			}
			case 'button': {
				const buttonStyle = this.getFirstModel('ButtonStyle');
				createTemplateComponent('New Button', 'TemplateComponentButton', {buttonStyleID: buttonStyle?.id.value || null}, {width: buttonStyle?.width.value || 300, height: buttonStyle?.height.value || 150});
				break;
			}
			case 'modal': {
				this.setModelFields({
					kioskTemplateID: this.props.templateID,
					templatePageID: this.props.currentPageID,
					type: 'modal',
					name: 'New Modal',
				}, 'TemplatePage').then(() => {
					const children = [
						{name: 'TemplatePositionData', data: {width: 500, height: 300, x: 10, y: 10}}
					];
					return this.saveModel({create: true, children, model: 'TemplatePage', id: 'default', parent: {model: 'KioskTemplate', id: this.props.templateID}});
				}).then(() => {
					this.resetDefaultModel('TemplatePage')
				}).then(() => {
					if (this.props.onAdd) this.props.onAdd(type);
				});
				break;
			}
			case 'image': {
				createTemplateComponent('New Image', 'TemplateComponentImage', {}, {width: 150, height: 150});
				break;
			}
			case 'playlist': {
				const playlist = this.getFirstModel('SignagePlaylist');
				createTemplateComponent('New Playlist', 'TemplateComponentPlaylist', {signagePlaylistID: playlist.id.value || null}, {width: 400, height: 300});
				break;
			}
			case 'text': {
				const textStyle = this.getFirstModel('TextStyle');
				createTemplateComponent('New Text Box', 'TemplateComponentText', {textStyleID: textStyle?.id.value || null}, {width: 300, height: 150});
				break;
			}
			case 'countdown': {
				createTemplateComponent('New Countdown', 'TemplateComponentCountdown');
				break;
			}
		}
	}
}
export default ComponentToolBox = connect({
	loadingName: 'Toolbox',
	models: [{model: 'TemplatePage'}, {model: 'TemplateComponent'}, {model: 'ButtonStyle', list: true}, {model: 'SignagePlaylist', list: true}, {model: 'TextStyle', list: true}],
	component: ComponentToolBox
});
