import React, { useState, useEffect } from 'react';
import { View, ScrollView, Text, StyleSheet, Platform } from 'react-native';
import { styles } from '../../main.styles';

import PageView from '../../components/views/page.view.component'
import Button from '../../components/button.component';
import IconButton from '../../components/iconButton.component';
import OutlineButton from '../../components/outlineButton.component';
import LinkButton from '../../components/linkButton.component';

import airshroudBridge from '../../core/ble.api';

import { FontAwesomeIcon as FontAwesomeReact } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon as FontAwesomeNative } from '@fortawesome/react-native-fontawesome';
const FontAwesomeIcon = (Platform.OS === 'web' ? FontAwesomeReact : FontAwesomeNative);

import { faCheckCircle, faWifi } from '@fortawesome/free-solid-svg-icons'

import { APIComponent } from '../ModelComponent';

function Bold(props) {
	return (<Text style={{ fontWeight: '500' }}>{props.children}</Text>);
}


const ConsoleOutput = () => {
	const [messages, setMessages] = useState([]);
  
	useEffect(() => {
	  const originalConsoleLog = console.log;
	  const originalConsoleError = console.error;
  
	  // Function to handle new messages (logs or errors)
	  const handleNewMessage = (message, type) => {
		setMessages((currentMessages) => [...currentMessages, { message, type }]);
	  };
  
	  console.log = (...args) => {
		args.forEach(arg => {
		  const message = typeof arg === 'object' ? JSON.stringify(arg) : arg;
		  handleNewMessage(message, 'log');
		});
		originalConsoleLog(...args);
	  };
  
	  console.error = (...args) => {
		args.forEach(arg => {
		  const message = typeof arg === 'object' ? 'toString' in arg ? arg.toString() : JSON.stringify(arg) : arg;
		  handleNewMessage(message, 'error');
		});
		originalConsoleError(...args);
	  };
  
	  // Cleanup function to reset console.log and console.error to their original states
	  return () => {
		console.log = originalConsoleLog;
		console.error = originalConsoleError;
	  };
	}, []);
  
	return (
		<ScrollView style={{width: '100%'}}>
		  <Text>Console Output:</Text>
		  {messages.map((msg, index) => (
			<Text style={{padding: 5, color: msg.type === 'error' ? 'red' : 'black' }} key={index}>{msg.message}</Text>
		  ))}
		</ScrollView>
	);
};


const SETUP_START = 0;
const SETUP_BLE_CONNECTING = 1;
const SETUP_WIFI_SCANNING = 2;
const SETUP_USER_INPUT = 3;
const SETUP_UPDATING_DETAILS = 4;
const SETUP_TESTING = 5;
const SETUP_COMPLETE = 6;
const SETUP_NORMAL = 7;
const SETUP_RECONNECTING = 8;

export default class DeviceWifiSetupPage extends APIComponent {
  constructor(props) {
    super(props);
	this.state = {
		...this.state,
		SSIDList: null, //[], //[['abc', 90, 'WPA'], ['def', 86, 'WPA2'], ['ghi', 97, 'WEP']],
		selectedSSID: '',
		wifiPassword: '',
		settings: [], // [MOTOR_REVEAL_SPEED, MOTOR_REVEAL_SLOW_SPEED, MOTOR_COVER_SPEED, FOB_REVEAL_DURATION, FOB_COVER_DURATION, SWITCH_REVEAL_DURATION]
		setupStep: SETUP_START,
	}
	airshroudBridge.onStatusChange((status) => {
		const step = this.state.setupStep;
		if (step == SETUP_WIFI_SCANNING && status.ssids && (status.ssids.length || !this.state.SSIDList)) {
			console.log(status);
			this.updateState({SSIDList: status.ssids, setupStep: SETUP_USER_INPUT});
		}
		else if (step == SETUP_UPDATING_DETAILS && status.wifi && status.wifi != '(disconnected)') {
			this.updateState({setupStep: SETUP_TESTING});
		}
		else if (step == SETUP_TESTING && this.state.selectedSSID && this.state.selectedSSID == status.wifi) {
			this.updateState({setupStep: SETUP_COMPLETE});
		}
		if (status.did == "AS1" && status.settings && status.settings.length) {
			this.updateState({settings: status.settings});
		}
	});
	if (Platform.OS === "web") window.onbeforeunload = null;
  }
  setupErrorCatcher(err) {
	console.error(err);
	airshroudBridge.disconnect();
	this.updateState({setupStep: SETUP_START});
  }
  renderContent() {
	const { setupStep, SSIDList, selectedSSID, wifiPassword, settings } = this.state;
	const [MOTOR_REVEAL_SPEED, MOTOR_REVEAL_SLOW_SPEED, MOTOR_COVER_SPEED, FOB_REVEAL_DURATION, FOB_COVER_DURATION, SWITCH_REVEAL_DURATION] = settings;
	const ssidStyle = {
		background: '#E5E5E5',
		borderBottomWidth: 1,
		borderColor: '#CCC',
	};
	const ssidSelectedStyle = {
		background: '#789',
		borderBottomWidth: 1,
		borderColor: '#CCC',
	};
	const ssidSelectedTextStyle = {
		color: '#FFF',
	}
	const readyToSubmit = (selectedSSID && wifiPassword);
	const registrationStatus = airshroudBridge.status().reg;
    return (
      <PageView center={true} style={{height: '100%'}} padding={0}>
		<View style={[styles.centerView, {height: '100%', overflow: 'auto', caretColor: 'rgba(0,0,0,0)', flexDirection: 'column', flex: 1}]}>
		
		<View style={{flex:1}}>
		{setupStep == SETUP_COMPLETE
			? <View style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
				<Text style={[styles.title]}><FontAwesomeIcon style={StyleSheet.flatten([{ color: '#0B0' }])} icon={faCheckCircle} /> Wi-Fi Setup Complete</Text>
				<Text style={{color:'#0A0'}}>Airshroud successfully connected to {selectedSSID}</Text>
				{registrationStatus == 'unclaimed' && <LinkButton style={{marginTop: 20}} title="Add this device to your CX Reveal Account" onPress={() => { window.localStorage.setItem('addDevice', airshroudBridge.status().id); window.location = '/';}} />}
				<LinkButton style={{marginTop: 50}} title="Back to Settings" onPress={() => this.updateState({setupStep: SETUP_NORMAL})}/>
			  </View>
			: <View style={{flexDirection: 'row', padding: 20, flex: 1, width: '100%', maxWidth: 900}}>
				<View style={{flexDirection: 'column', flex: 1}}>
					<View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
						<Text style={[styles.title, {marginBottom: 0, flex: 1}]}>Airshroud Wi-Fi Setup</Text>
						<View style={{justifyContent: 'center'}}>
							<Button title={setupStep > SETUP_BLE_CONNECTING ? "Disconnect" : "Connect"} icon="bluetooth-connect" disabled={setupStep == SETUP_BLE_CONNECTING} style={{width: 160}} onPress={() => {
								if (setupStep == SETUP_START) {
									console.log("connect ble");
									this.updateState({setupStep: SETUP_BLE_CONNECTING});
									airshroudBridge.connect().then(() => {
										// Check the status
										console.log(airshroudBridge.status());

										// Add a device disconnect event listener
										airshroudBridge.onDisconnect((connected) => {
											console.log("connected: "+connected);
											this.updateState({setupStep: SETUP_START});
										});

										airshroudBridge.onReconnecting((connected) => {
											console.log("connected: "+connected);
											this.updateState({setupStep: SETUP_RECONNECTING});
										});

										airshroudBridge.onReconnect((connected) => {
											console.log("connected: "+connected);
											this.updateState({setupStep: SETUP_NORMAL});
										});

										const wifiStatus = airshroudBridge.status().wifi;
										if (wifiStatus && wifiStatus != '(disconnected)' && wifiStatus != '(connecting)' && wifiStatus != '(failed)') {
											this.updateState({setupStep: SETUP_NORMAL});
										} else {
											this.updateState({setupStep: SETUP_WIFI_SCANNING});
											airshroudBridge.getSSIDs().catch(err => this.setupErrorCatcher(err));
										}
									}).catch(err => {
										if (err.toString() != 'NotFoundError: User cancelled the requestDevice() chooser.') {
											console.error(err);
										}
										this.updateState({setupStep: SETUP_START});
									});
								} else {
									console.log("disconnect ble");
									airshroudBridge.disconnect();
									this.updateState({setupStep: SETUP_START, hasSSIDList: false, SSIDList: [], selectedSSID: '', wifiPassword: '', updatingWifiDetails: false, setupComplete: false});
								}
							}} />
							{setupStep > SETUP_BLE_CONNECTING && (setupStep == SETUP_RECONNECTING ? <Text style={{color: '#AA0', textAlign: 'center', marginTop: 5}}>Airshroud Reconnecting</Text> : <Text style={{color: '#0A0', textAlign: 'center', marginTop: 5}}>Airshroud Connected</Text>)}
						</View>
					</View>
					{setupStep == SETUP_START && <Text style={{marginTop: 30}}>Tap <Bold>CONNECT</Bold> to pair your Airshroud with this device. You will be presented with a popup window asking you to select the Bluetooth device you wish to pair with; Then <Bold>Select your Airshroud</Bold> to continue.</Text>}
					{setupStep == SETUP_START && <Text style={[styles.greyItalicsText, {marginTop: 10}]}>Note: Make sure you are using a Bluetooth enabled browser and that the Airshroud is turned on.</Text>}
					{setupStep == SETUP_BLE_CONNECTING && this.renderLoader({text: 'Connecting to Airshroud...'})}
					{setupStep > SETUP_BLE_CONNECTING && <>
						<View style={{flexDirection: 'row', flexWrap: 'wrap', marginTop: 10, gap: 20}}>
							<View style={{flex: 2, minWidth: 400}}>
								<Bold>Device Info</Bold>
								<View style={{flexDirection: 'column', marginTop: 10}}>
									<Text>Type: {airshroudBridge.status().did == "AS1" ? 'Airshroud' : 'Airshroud Communication Bridge'}</Text>
									<Text>ID: {airshroudBridge.status().hasID ? airshroudBridge.status().id : '(Not assigned)'}</Text>
									{registrationStatus != 'unconnected' && <Text>Registration: {registrationStatus}{registrationStatus == 'unclaimed' ? <> - <LinkButton title="Add device to CX Reveal Account" onPress={() => { window.localStorage.setItem('addDevice', airshroudBridge.status().id); window.location = '/'; }} /></>:null}</Text>}
									<Text>Speed: {airshroudBridge.status().mode == 'slow' ? (MOTOR_REVEAL_SLOW_SPEED+'% (Slow mode)') : (MOTOR_REVEAL_SPEED+'%') }</Text>
									<Text style={{marginTop: 10}}>Wi-Fi SSID: {airshroudBridge.status().wifi}</Text>
									{setupStep == SETUP_NORMAL ? <LinkButton title="Network Setup" onPress={() => {this.updateState({setupStep: SETUP_WIFI_SCANNING}); airshroudBridge.getSSIDs().catch(err => this.setupErrorCatcher(err));}} /> : null}
								</View>

								{setupStep == SETUP_WIFI_SCANNING 
									? this.renderLoader({text: 'Scanning Wifi Networks...'})
									: setupStep == SETUP_RECONNECTING ? this.renderLoader({text: 'BLE connection lost. Attempting to reconnect...'})
									: setupStep == SETUP_UPDATING_DETAILS ? this.renderLoader({text: 'Updating Device...'})
									: setupStep == SETUP_TESTING ? this.renderLoader({text: 'Testing Wi-Fi connection...'})
									: setupStep == SETUP_USER_INPUT && <View style={{flexDirection: 'column', alignItems: 'center', marginTop: 20}}>
										<View style={{flex: 1, width: '100%'}}>
											<Text style={{marginBottom: 10}}><Bold>Network Setup</Bold></Text>
											<View style={{marginBottom: 10, width: '100%'}}>
												<Text style={{marginBottom: 5}}>Select the name of your Wi-Fi network:</Text>
												{SSIDList.map((ssid, idx) => (<View key={'SSID_'+idx} style={(selectedSSID && selectedSSID === ssid[0]) ? ssidSelectedStyle : ssidStyle}>
													<Text style={[{padding: 10}, (selectedSSID && selectedSSID === ssid[0]) ? ssidSelectedTextStyle : {}]} onPress={() => this.updateState({selectedSSID: ssid[0]})}>
														{(selectedSSID && selectedSSID === ssid[0]) && <FontAwesomeIcon style={StyleSheet.flatten([{ color: '#AFA', marginRight: 5 }])} icon={faCheckCircle} />} {ssid[0]}
														<Text style={[styles.greyText, {float: 'right'}]}><Text style={[{marginRight: 10}, (selectedSSID && selectedSSID === ssid[0]) ? ssidSelectedTextStyle : {}]}>{ssid[2]} </Text>
														<Text style={[(selectedSSID && selectedSSID === ssid[0]) ? {color:'#fff'} : {color: 'rgb('+(200-200*(ssid[1]/100))+','+(200*(ssid[1]/100))+',0)'}]}>{ssid[1]}%</Text></Text>
													</Text>
												</View>))}
											</View>
											{selectedSSID ? <View style={{marginBottom: 20, width: '100%'}}>
												{this.renderTextInput({ name: 'Enter your network\'s password', placeholder: 'Wi-Fi Password', value: wifiPassword, isPassword: true, onUpdate: (value) => this.updateState({wifiPassword: value}) })}
											</View>:null}
											<View style={{flexDirection: 'row', justifyContent: 'center'}}><Button title="Update" disabled={!readyToSubmit} style={{width: 150}} onPress={() => {
												this.updateState({setupStep: SETUP_UPDATING_DETAILS});
												console.log("send wifi settings");
												airshroudBridge.setWifi(selectedSSID, wifiPassword).catch(err => this.setupErrorCatcher(err));
											}} /></View>
										</View>
									</View>
								}
							</View>

							{setupStep != SETUP_RECONNECTING && settings.length? <View style={{flex: 1, minWidth: 200}}>
								<Bold>Reveal Settings:</Bold>
								<View style={{flexDirection: 'column', marginTop: 20}}>
									{this.renderSlider({label: 'Reveal Speed', min: 10, max: 100, value: MOTOR_REVEAL_SPEED, valueSuffix: '%', onChange: (value) => { settings.splice(0, 1, value); this.updateState({settings: settings}); airshroudBridge.updateSettings(settings).catch(err => this.setupErrorCatcher(err)) }})}	
									{this.renderSlider({label: 'Slow Reveal Speed', min: 10, max: 100, value: MOTOR_REVEAL_SLOW_SPEED, valueSuffix: '%', onChange: (value) => { settings.splice(1, 1, value); this.updateState({settings: settings}); airshroudBridge.updateSettings(settings).catch(err => this.setupErrorCatcher(err)) }})}
									{this.renderSlider({label: 'Cover Speed', min: 10, max: 100, value: MOTOR_COVER_SPEED, valueSuffix: '%', onChange: (value) => { settings.splice(2, 1, value); this.updateState({settings: settings}); airshroudBridge.updateSettings(settings).catch(err => this.setupErrorCatcher(err)) }})}
									{this.renderSlider({label: 'Key Fob Reveal Duration', min: 500, step: 500, max: 45000, value: FOB_REVEAL_DURATION, valueSuffix: ' milliseconds', onChange: (value) => { settings.splice(3, 1, value); this.updateState({settings: settings}); airshroudBridge.updateSettings(settings).catch(err => this.setupErrorCatcher(err)) }})}
									{this.renderSlider({label: 'Key Fob Cover Duration', min: 500, step: 500, max: 45000, value: FOB_COVER_DURATION, valueSuffix: ' milliseconds', onChange: (value) => { settings.splice(4, 1, value); this.updateState({settings: settings}); airshroudBridge.updateSettings(settings).catch(err => this.setupErrorCatcher(err)) }})}
									{this.renderSlider({label: 'DMX Switch Reveal Duration', min: 10, max: 45, value: SWITCH_REVEAL_DURATION/1000, valueSuffix: ' seconds', onChange: (value) => { settings.splice(5, 1, value*1000); this.updateState({settings: settings}); airshroudBridge.updateSettings(settings).catch(err => this.setupErrorCatcher(err)) }})}
								</View>
							</View>: null}
						</View>
						
					</>}
				</View>
			</View>
		}
		</View>
		<View style={{flex:1}}>
			<ConsoleOutput />
		</View>
		</View>
      </PageView>
    );
  }
}