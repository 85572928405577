import 'react-native-gesture-handler';
import React from 'react';

import Root from './src/root.component';

export default function App() {
  return (
    <Root />
  );
}
