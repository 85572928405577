import React, { useState, useRef, useEffect } from 'react';
import { Image as RNImage, View, Platform } from 'react-native';
import Loader from './loader.component';

//const defaultImage = require('../assets/images/default_background.png')

export default function Image({ source, onLoadEnd, style }) {
	const [loaded, setLoaded] = useState(false);
	return (<>
			<RNImage source={source} onLoadEnd={(...args) => {
				setLoaded(true);
				onLoadEnd(...args)
			}} style={loaded ? style : {width: 0, height: 0}} />
			{!loaded ? <View style={[style, {justifyContent: 'center', alignItems: 'center'}]}>
				<Loader />
				{/*<RNImage style={{width: '100%', height: '100%', opacity: 0.8}} source={defaultImage} />*/}
			</View> : null}
		</>
	);
}