import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { APIModal } from '../../ModelComponent';

export default class AddDeviceModal extends APIModal { // todo: extend from APIModal + make APIModal
  constructor(props) {
    super(props, "Add Device");
    this.state = {
      deviceID: props.deviceID || '',
      description: '',
    }
  }
  renderModal() {
    return (<>
      <Text>{this.props.deviceID ? 'Click "Add" to add the new device to your account' : 'Enter your device ID to add it to your devices.'}</Text>
      {this.renderTextInput({name:'Device ID', value:this.state.deviceID, onUpdate:(deviceID) => this.setState({deviceID})})}
      {this.renderTextInput({name:'Description', value:this.state.description, multiline: true, onUpdate:(description) => this.setState({description})})}
    </>);
  }
  renderModalBottom() {
    return (<>
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 1, justifyContent: "center"}}>
          <LinkButton title="Cancel" color="#A00" style={{width: 200}} onPress={() => this.props.closeModal()} />
        </View>
        <View style={{flex: 1, flexDirection: 'row-reverse'}}>
          <Button title="Add" onPress={() => {
            if (this.state.deviceID.length !== 8) return;
            this.apiCall('Device', 'claimDevice', {id: this.state.deviceID, description: this.state.description}).then(response => {
              return this.loadModelList('Device').then(() => this.props.closeModal());
            }).catch(err => console.error(err));
          }} />
        </View>
      </View>
    </>);
  }
}
