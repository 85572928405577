import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { ModelModal, connect } from '../../ModelComponent';

class RevealSettingsModal extends ModelModal {
	constructor(props) {
		super(props, "Reveal Settings");
	}
	renderModal() {
		const id = this.props.id;

		const deviceID = this.getModelValue('deviceID', 'RevealSettings', id);
		const deviceStatus = deviceID ? this.getModelData('Device', deviceID)?.data?.status : null;
		const slotOptions = [];
		if (deviceStatus) {
			const slots = deviceStatus.slots; //JSON.parse(deviceStatus)?.slots;
			if (slots) slots.forEach((slotData, idx) => {
				if (slotData == 0) return;
				const slot = idx+1;
				slotOptions.push({ label: 'Slot '+slot, value: slot });
			});
		}
		const deviceType = (deviceStatus ? ((deviceStatus.did && deviceStatus.did == "AS1") ? 'AS1' : 'ACB') : 'AS1'); //UNKNOWN');

		return (<>
			{this.renderModelRadioButtons({
				name: 'revealSolution',
				model: 'RevealSettings',
				id: id,
				options: [
					{ label: 'Wi-Fi Reveal', value: 'acb' },
					{ label: 'Signage Reveal', value: 'signage' },
				],
				onChange: (value) => console.log(value)
			})}
			{this.getModelValue('revealSolution', 'RevealSettings', id) !== 'signage'
				? <>
					{/*this.renderModelRadioButtons({
						name: 'connectionMethod',
						model: 'RevealSettings',
						id: id,
						label: 'Connection Method',
						options: [
							{ label: 'Wi-Fi', value: 'wifi' },
							{ label: 'BLE', value: 'ble' },
						],
					})*/}

					{this.renderModelDropdown({
						name: 'deviceID',
						model: 'RevealSettings',
						id: id,
						label: "Device",
						options: this.makeDropdownOptions('Device', 'deviceName'),
					})}

					{deviceType != 'UNKNOWN' && (deviceType == 'ACB'
						? this.renderModelDropdown({
							name: 'deviceSlot',
							model: 'RevealSettings',
							id: id,
							label: "Device Slot",
							numericInt: true,
							options: slotOptions,
						})
						: this.renderModelSlider({
							name: 'revealSpeed',
							model: 'RevealSettings',
							id: id,
							label: "Reveal Speed",
							min: 10,
							max: 100,
							valueSuffix: '%'
						})
					)}

					{deviceType != 'UNKNOWN' && this.renderModelSlider({
						name: 'commandDuration',
						model: 'RevealSettings',
						id: id,
						label: 'Duration',
						valueSuffix: 's',
						min: 10,
						max: 32
					})}

					{this.renderModelCheckBox({
						name: 'sendWebsocketOnRevealButtonPress',
						model: 'RevealSettings',
						id: id,
						label: 'Send Websocket on Reveal Button Press'
					})}
				</>
				: <>
					{this.renderModelTextInput({
						name: 'signageSyncID',
						model: 'RevealSettings',
						id: id,
						label: 'Sync ID'
					})}
					{this.renderModelTextInput({
						name: 'signageCommand',
						model: 'RevealSettings',
						id: id,
						label: 'Command'
					})}
					{this.renderModelTextInput({
						name: 'signageCanvasID',
						model: 'RevealSettings',
						id: id,
						label: 'Canvas ID'
					})}
					{this.renderModelTextInput({
						name: 'signageZoneID',
						model: 'RevealSettings',
						id: id,
						label: 'Zone ID'
					})}
				</>
			}
		</>);
	}
	renderModalBottom() {
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Close" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Save" style={{ width: 100 }} onPress={() => {
						const deviceID = this.getModelValue('deviceID', 'RevealSettings', this.props.id);
						const deviceStatus = deviceID ? this.getModelData('Device', deviceID)?.data?.status : null;
						const deviceType = (deviceStatus ? ((deviceStatus.did && deviceStatus.did == "AS1") ? 'AS1' : 'ACB') : 'UNKNOWN');

						this.setModelFields({
							revealDevice: deviceType, 
						}, 'RevealSettings', this.props.id).then(()=> this.saveModel(false, null, 'RevealSettings', this.props.id).then(() => this.props.closeModal())).catch(console.error);
					}} />
				</View>
			</View>
		</>);
	}
}
export default RevealSettingsModal = connect({
	models: [{ model: 'RevealSettings' }, { model: 'Device', list: true }],
	component: RevealSettingsModal,
});

