import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import { navigate, getCurrentRoute } from '../../root.navigation';

import PageView from '../../components/views/page.view.component'
import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';
import LinkButton from '../../components/linkButton.component';
import IconButton from '../../components/iconButton.component';

import { faSearch, faEdit } from '@fortawesome/free-solid-svg-icons';

import { ModelComponent, ModelEditorComponent, connect } from '../ModelComponent';

import KioskSettingsModal from './modals/kioskSettings.modal';
import TemplatePreview from '../templateEditor/templatePreview.component';

export class KioskComponent extends ModelComponent {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			settingsOpen: false,
		}
	}
	renderContent() {
		const kioskID = this.props.id;
		const getModelValue = this.modelValueGetter('Kiosk', kioskID);

		const description = getModelValue('description');

		return (<>
			{this.accountAccess === 'admin' && this.state.settingsOpen && <KioskSettingsModal
				id={kioskID}
				open={this.state.settingsOpen}
				closeModal={() => this.setState({ settingsOpen: false })}
			/>}

			<View style={[]}>
				<View style={[{ flexDirection: 'row', gap: 10, padding: 10, flexWrap: 'wrap' }]}>
					{/*<View style={{ flexDirection: 'column', height: 140, width: 140, borderWidth: 1, borderRadius: 5, borderColor: '#AAA' }}>
						<TemplatePreview id={getModelValue('templateID')} />
					</View>*/}

					{this.accountAccess === 'admin' && <IconButton size={30} stretchContainer={false} title="Settings" onPress={() => this.setState({ settingsOpen: true })} />}

					<View style={{ flex: 1, flexDirection: 'column', minWidth: 270 }}>
						<Text style={[styles.title, {marginBottom: 0}]}>{getModelValue('name')}</Text>
						{description && <Text style={{marginTop: 10}}>{description}</Text>}
					</View>
					
					{this.renderError()}
				</View>
				<View style={{borderTopWidth: 1, borderColor: '#8a745a'}}>
					<KioskLiveSettings 
						id={kioskID} 
						showPreview={true}
						templateID={getModelValue('templateID')}
						revealSettingsID={getModelValue('revealSettingsID')} 
						dataGroupID={getModelValue('dataGroupID')} 
						dataSetID={getModelValue('dataSetID')} 
					/>
				</View>
			</View>
		</>);
	}
}
export const Kiosk = connect({
	models: [{model: 'Kiosk'}],
	component: KioskComponent
});


class KioskLiveSettingsBase extends ModelEditorComponent {
	extraState = {
		customerDataUpdated: false,
		dataSetID: null,
		customerName: null,
		customerMessage: null,
	}
	componentDidMount() {
		super.componentDidMount();
		this.updateState({dataSetID: this.props.dataSetID});
		this.refreshPreview = this.createChildCall();
		if (this.props.resetCall) this.props.resetCall.fn = (onDone) => this.resetModel(onDone);
	}
	componentDidUpdate(prevProps, prevState) {
		super.componentDidUpdate(prevProps, prevState);
		if (prevProps.dataSetID != this.props.dataSetID) {
			this.updateState({dataSetID: this.props.dataSetID});
		}
		if (this.props.onSetKioskDataSet && this.state.customerDataUpdated && (this.state.customerName || this.state.customerMessage) && ((this.state.customerName != prevState.customerName) || (this.state.customerMessage != prevState.customerMessage))) {
			const data = {
				customerName: this.getModelValue('customerNameOverride', 'Kiosk', this.props.id), 
				customerMessage: this.getModelValue('customerMessageOverride', 'Kiosk', this.props.id)
			}
			this.props.onSetKioskDataSet(data);
		}
	}
	renderContent() {
		const kioskID = this.props.id;
		const getModelValue = this.modelValueGetter('Kiosk', kioskID);
		const getModelPropValue = this.modelPropValueGetter('Kiosk', kioskID);
		const getRevealValue = this.modelPropValueGetter('RevealSettings', this.props.revealSettingsID);

		const dataGroupID = this.props.dataGroupID;
		//console.log({dataGroupID})

		//console.log(this.state.models)
		const isSignageReveal = (getRevealValue('revealSolution') === 'signage');
		const isAirshroudReveal = (getRevealValue('revealDevice') === 'AS1');
		//console.log(isSignageReveal);

		return (
		<View style={[{flexDirection: 'row', marginTop: 2, flexWrap: 'wrap', gap: this.props.showPreview ? 20 : 0}, this.props.style]}>
			{this.props.showPreview && <View style={{flex: 1, flexDirection: 'column', minWidth: 250, maxWidth: 400}}>
				<View style={{overflow: 'hidden', position: 'relative'}}>
					<TemplatePreview id={this.props.templateID} refreshCall={this.refreshPreview} previewDataSetOverride={{
						customerName: getModelValue('customerNameOverride'), 
						customerMessage: getModelValue('customerMessageOverride')
					}} />
					<View style={{position: 'absolute', flexDirection: 'row', justifyContent: "center", alignItems: "center", gap: 10, right: 0, bottom: 0, backgroundColor: '#FFF', paddingVertical: 5, paddingHorizontal: 10, borderWidth: 1, borderColor: "#8a745a", borderTopLeftRadius: 10, borderRadius: 0}}>
						{this.accountAccess === 'admin' && this.props.templateID && <>
							<IconButton size={20} style={{ margin: 0 }} tooltipTextStyle={{width: 50, textAlign: 'center'}} color="#8a745a" title="Edit" icon={faEdit} onPress={() => { 
								navigate('TemplateEditor', {id: this.props.templateID, kioskID: kioskID, parentPage: getCurrentRoute()});
							}} />
							<View style={{borderLeftWidth: 2, borderColor: "#8a745a", height: 20, flex: 1}}></View>
						</>}
						<IconButton size={20} style={{ margin: 0 }}  icon={faSearch} color={"8a745a"} title="Preview" onPress={() => {
							//console.log("preview button click", this.props.model);
							window.open('/?apiKey='+getModelValue('apiKey')+'&preview=true', '_blank');
						}} />
					</View>
				</View>
			</View>}

			<View style={[{flexDirection: 'column', marginTop: 15}, this.props.showPreview ? null : {flex: 1}]}>
				<Text style={this.props.showPreview ? styles.subTitle : styles.label}>Device Status:</Text>
				<View style={{flexDirection: 'column', marginLeft: 20}}>
					<View style={{flexDirection: 'row', gap: 5}}>
						<Text style={styles.heavy}>iPad:</Text>
						<KioskStatus id={kioskID} />
					</View>
					<View style={{flexDirection: 'row', gap: 5}}>
						<Text style={styles.heavy}>{isSignageReveal? 'Signage' : isAirshroudReveal ? 'Airshroud' : 'ACB'}:</Text>
						{isSignageReveal
							? <SignagePlayerStatus id={this.props.revealSettingsID} />
							: <DeviceStatus id={getRevealValue('deviceID')} />
						}
					</View>
				</View>
				<View style={{marginTop: 20}}>
					<View style={{flexDirection: this.props.showPreview ? 'row-reverse' : 'column', gap: this.props.showPreview ? 20 : 0}}>
						<View style={{maxWidth: 300}}>
							{dataGroupID && <DataSetPicker 
								dataGroupID={dataGroupID}
								dataSetID={this.state.dataSetID}
								onChange={({ dataSetID, customerName, customerMessage }) => {
									//console.log(newValue, {customerName, customerMessage}, this.state.models, this.props);
									this.setModelFields({dataSetID, customerNameOverride: customerName, customerMessageOverride: customerMessage}, 'Kiosk', kioskID)
									.then(()=> this.updateState({customerDataUpdated: true, customerName, customerMessage, dataSetID}));	
								}}
							/>}
						</View>
						<View style={{flex: 1, maxWidth: 300}}>
							{this.renderModelTextInput({ label: 'Customer Name', name: 'customerNameOverride', model: 'Kiosk', id: kioskID, onChange: (value)=> this.updateState({customerDataUpdated: true, customerName: value}) })}
						</View>
					</View>
					<View style={{maxWidth: 300}}>
						{this.renderModelTextInput({ label: 'Customer Message', name: 'customerMessageOverride', model: 'Kiosk', id: kioskID, onChange: (value)=> this.updateState({customerDataUpdated: true, customerMessage: value}) }, true)}
					</View>

					{this.state.customerDataUpdated && <Button title="Publish" style={{maxWidth: 100}} onPress={()=> {
						this.setModelFields({previewDataSetID: this.state.dataSetID}, 'KioskTemplate', this.props.templateID)
							.then(() => { if (this.props.templateID) return this.saveModel({model: 'KioskTemplate', id: this.props.templateID, fields: ['previewDataSetID'], reloadModels: false})})
							.then(() => this.saveModel(false, null, 'Kiosk', kioskID))
							.then(() => this.apiCall('Kiosk', 'publishCustomerData', { kioskID }))
							.then(()=> this.updateState({customerDataUpdated: false}, ()=> this.refreshPreview.call()))
							.catch(error => this.updateState({error}));
					}}/>}

					{this.renderError()}
				</View>
			</View>
		</View>);
	}
}
export const KioskLiveSettings = connect({
	loadingName: 'Kiosk Live Settings',
	models: [
		{model: 'Kiosk', loadChildren: true}, 
		/*
		{model: 'KioskTemplate', key: (root, props)=> props.templateID},  
		{model: 'RevealSettings', key: (root, props)=> props.revealSettingsID}, 
		
		{model: 'DataSet', list: true, keyField: 'dataGroupID', useKey: true, key: (r, props) => props.dataGroupID, require: false}
		*/
	],
	component: KioskLiveSettingsBase
});



class DataSetPickerBase extends ModelComponent {
	renderContent() {
		const dataGroupID = this.props.dataGroupID;
		return this.renderDropdown({
			label: 'Auto Fill',
			defaultValue: this.props.dataSetID,
			numericInt: true,
			options: this.makeDropdownOptions('DataSet', 'customerName', 'id', (model)=> model.data.dataGroupID.value == dataGroupID),
			onChange: (newValue) => {
				if (!newValue) return;
				const {customerName, customerMessage} = this.getModelValues(['customerName', 'customerMessage'], 'DataSet', newValue);
				console.log({customerName, customerMessage});
				if (this.props.onChange) this.props.onChange({dataSetID: newValue, customerName, customerMessage});								
			}
		})
	}
}
const DataSetPicker = connect({
	loadingName: 'Kiosk Live Settings',
	loadingSize: 'small',
	models: [
		{model: 'DataGroup', key: (root, props)=> props.dataGroupID, useKey: true, loadChildren: true},
	],
	component: DataSetPickerBase
});

class KioskStatusBase extends ModelComponent {
	renderContent() {
		const kioskID = this.props.id;
		
		const hasConnection = this.getModelValue('connected', 'Kiosk', kioskID);
		const statusText = hasConnection ? 'Online' : 'Offline';
		const statusColor = hasConnection ? '#0B0' : '#F24';

		return (<>
			<Text>(<Text style={{color: statusColor}}>{statusText}</Text>) {this.getModelValue('name', 'Kiosk', kioskID)}</Text>
		</>);
	}
}
const KioskStatus = connect({
	models: [{model: 'Kiosk'}],
	component: KioskStatusBase
});


class DeviceStatusBase extends ModelComponent {
	renderContent() {
		const deviceID = this.props.id;
		if (!deviceID) return (<Text>(<Text style={{color: '#F24'}}>Offline</Text>) Not Selected</Text>);
		const getModelValue = this.modelPropValueGetter('Device', deviceID);

		const hasConnection = getModelValue('connected'); //(this.props.wifiConnected || this.props.usbConnected || this.props.bleConnected);
		const wifiConnected = hasConnection;
		const usbConnected = false;
		const bleConnected = false;
		const status = getModelValue('status');
		const statusText = (!hasConnection) ? 'Offline' : (!status) ? 'loading...' : status.tx ? 'Transmitting' : status.rx ? 'Scanning' : 'Online';
		const statusColor = (statusText === 'Offline') ? '#F24' : (statusText === 'Ready' || statusText === 'Online') ? '#0B0' : '#24F';

		return (<>
			<Text>(<Text style={{color: statusColor}}>{statusText}</Text>) {getModelValue('deviceName')}</Text>
		</>);
	}
}
export const DeviceStatus = connect({
	models: [{model: 'Device'}],
	component: DeviceStatusBase
});



class SignagePlayerStatusBase extends ModelComponent {
	componentDidMount() {
		this.checkPlayerStatus({})
	}
	componentDidUpdate(prevProps, prevState) {
		this.checkPlayerStatus(prevState);
	}
	checkPlayerStatus(prevState) {
		const revealSettingsID = this.props.id;
		if (!revealSettingsID) return;
		const getRevealValue = this.modelPropValueGetter('RevealSettings', revealSettingsID);
		const signageSyncID = getRevealValue('signageSyncID');
		if (prevState.signageSyncID != signageSyncID) {
			this.updateState({signageSyncID, sigangePlayerOnline: null});
			this.apiCall('Kiosk', 'checkPlayerStatus', {revealSettingsID}).then(response => {
				if (!response.success) return this.updateState({sigangePlayerOnline: false});
				this.updateState({sigangePlayerOnline: response.data.playerOnline});
			});
		}
	}
	renderContent() {
		const revealSettingsID = this.props.id;
		if (!revealSettingsID) return (<Text>(<Text style={{color: '#F24'}}>Offline</Text>) Not Selected</Text>);

		const getRevealValue = this.modelPropValueGetter('RevealSettings', revealSettingsID);

		const signageSyncID = getRevealValue('signageSyncID');
		const signageCanvasID = getRevealValue('signageCanvasID');
		const playerOnline = this.state.sigangePlayerOnline;

		const statusText = (signageSyncID ? (playerOnline === false ? 'Offline' : (playerOnline? 'Online' : 'loading...')) : 'SyncID Not Set');
		const statusColor = (statusText === 'Offline' || statusText === 'SyncID Not Set') ? '#F24' : (statusText === 'Online') ? '#0B0' : '#24F';

		return (<>
			<Text>(<Text style={{color: statusColor}}>{statusText}</Text>) {signageSyncID ? signageSyncID+' - ' : ''}Canvas {signageCanvasID ? signageCanvasID : 'Not Set'}</Text>
		</>);
	}
}
export const SignagePlayerStatus = connect({
	models: [{model: 'RevealSettings'}],
	component: SignagePlayerStatusBase
});

