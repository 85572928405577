import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';
import IconButton from '../../../components/iconButton.component';

import { ModelModal, connect } from '../../ModelComponent';

import AddKioskModal from '../../kiosks/modals/addKiosk.modal';
import KioskSettingsModal from '../../kiosks/modals/kioskSettings.modal';

class SiteSettingsModal extends ModelModal {
	constructor(props) {
		super(props, "Site Settings");
	}
	renderModal() {
		const form = this.getFormHelper('Site', this.props.id);
		return (<>
			<AddKioskModal
				open={this.state.addKioskModalOpen}
				closeModal={() => this.setState({ addKioskModalOpen: false })}
			/>
			{form.textInput('name')}
			{form.textInput('description', true)}
			<Text style={styles.label}>Kiosks</Text>
			{this.makeList('Kiosk', (data) => (<View key={data.id.value}>
				{this.state.kioskSettingsOpen?.[data.id.value] && <KioskSettingsModal
					id={data.id.value}
					open={this.state.kioskSettingsOpen?.[data.id.value]}
					closeModal={() => this.updateState({ kioskSettingsOpen: { ...this.state.kioskSettingsOpen, [data.id.value]: false } })}
				/>}
				<View style={{flexDirection: 'row', gap: 5, alignItems: 'center'}}>
					<IconButton size={20} onPress={() => this.updateState({ kioskSettingsOpen: { ...this.state.kioskSettingsOpen, [data.id.value]: true } })} />
					<Text style={{fontWeight: 500}}>{data.name.value}</Text>
				</View>
			</View>), null, 10)}
			<View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 10 }}>
				<LinkButton title="Add Kiosk" onPress={() => this.setState({ addKioskModalOpen: true })} />
			</View>
		</>);
	}
	renderModalBottom() {
		const form = this.getFormHelper('Site', this.props.id);
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Close" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Done" style={{ width: 100 }} onPress={() => form.save().then(() => this.props.closeModal()).catch(console.error)} />
				</View>
			</View>
		</>);
	}
}
export default SiteSettingsModal = connect({
	component: SiteSettingsModal,
	models: [{
		model: 'Site',
		loadChildren: true,
	}]
});

