import socket from '../core/socket.api';
import rootStore from '../root.store.js';

socket.on('clientBrowserStatus', clientBrowser => rootStore.dispatch(addClientBrowser(clientBrowser)));
socket.on('clientBrowserRevealStarted', commandInfo => console.log('clientBrowserRevealStarted', commandInfo));
socket.on('clientBrowserRevealComplete', commandInfo => console.log('clientBrowserRevealComplete', commandInfo));


export const fetch = () => {
    socket.emit('listClientBrowsers', (response) => {
        if (!response.success) return rootStore.dispatch(clientBrowsersError(response.reason));
        rootStore.dispatch(addClientBrowsers(response.data));
    });
    return { 
        type: 'FETCH_CLIENT_BROWSERS',
        payload: null,
    }
};

export const clientBrowsersError = (error) => ({ 
    type: 'CLIENT_BROWSERS_ERROR',
    payload: error,
});

export const addClientBrowser = clientBrowser => ({
    type: 'ADD_CLIENT_BROWSER',
    payload: clientBrowser,
});

export const addClientBrowsers = clientBrowsers => ({
    type: 'ADD_CLIENT_BROWSERS',
    payload: clientBrowsers,
});

export const reloadClientBrowser = (id) => {
    socket.emit('reloadClientBrowser', {id}, response => {
        if (!response.success) return rootStore.dispatch(clientBrowsersError(response.reason));
    });
}

export const setClientBrowserDevice = (clientBrowserID, deviceCommandInfo) => {
    socket.emit('setDeviceToClientBrowser', {clientBrowserID, ...deviceCommandInfo}, response => {
        if (!response.success) return rootStore.dispatch(clientBrowsersError(response.reason));
        rootStore.dispatch(clientBrowserDeviceSet(clientBrowserID, deviceCommandInfo));
    });
    return {
        type: 'SET_DEVICE_ON_CLIENT_BROWSER',
        payload: {
            clientBrowserID,
            deviceCommandInfo
        },
    };
}
export const clientBrowserDeviceSet = (clientBrowserID, deviceCommandInfo) => ({
    type: 'DEVICE_SET_ON_CLIENT_BROWSER',
    payload: {
        clientBrowserID,
        deviceCommandInfo
    },
});

export const setClientBrowserRevealSolution = (id, revealSolution) => {
    socket.emit('setRevealSolutionToClientBrowser', {id, revealSolution}, response => {
        if (!response.success) return rootStore.dispatch(clientBrowsersError(response.reason));
        rootStore.dispatch(clientBrowserRevealSolutionSet(id, revealSolution));
    });
    return {
        type: 'SET_REVEAL_SOLUTION_ON_CLIENT_BROWSER',
        payload: {
            id,
            revealSolution
        },
    };
}
export const clientBrowserRevealSolutionSet = (id, revealSolution) => ({
    type: 'REVEAL_SOLUTION_SET_ON_CLIENT_BROWSER',
    payload: {
        id,
        revealSolution
    },
});

export const setClientBrowserWebpage = (clientBrowserID, webRevealID) => {
    socket.emit('setWebPageToClientBrowser', {clientBrowserID, webRevealID}, response => {
        if (!response.success) return rootStore.dispatch(clientBrowsersError(response.reason));
        rootStore.dispatch(clientBrowserWebpageSet(clientBrowserID, webRevealID));
    });
    return {
        type: 'SET_WEBPAGE_ON_CLIENT_BROWSER',
        payload: {
            clientBrowserID,
            webRevealID
        },
    };
}
export const clientBrowserWebpageSet = (clientBrowserID, webRevealID) => ({
    type: 'WEBPAGE_SET_ON_CLIENT_BROWSER',
    payload: {
        clientBrowserID,
        webRevealID
    },
});

export const setClientBrowserName = (clientBrowserID, name) => {
    socket.emit('setNameToClientBrowser', {clientBrowserID, name}, response => {
        if (!response.success) return rootStore.dispatch(clientBrowsersError(response.reason));
        rootStore.dispatch(clientBrowserNameSet(clientBrowserID, name));
    });
    return {
        type: 'SET_NAME_ON_CLIENT_BROWSER',
        payload: {
            clientBrowserID,
            name
        },
    };
}
export const clientBrowserNameSet = (clientBrowserID, name) => ({
    type: 'NAME_SET_ON_CLIENT_BROWSER',
    payload: {
        clientBrowserID,
        name
    },
});

export const setClientBrowserDataGroup = (clientBrowserID, dataGroupID) => {
    socket.emit('setDataGroupToClientBrowser', {clientBrowserID, dataGroupID}, response => {
        if (!response.success) return rootStore.dispatch(clientBrowsersError(response.reason));
        rootStore.dispatch(clientBrowserDataGroupSet(clientBrowserID, dataGroupID));
    });
    return {
        type: 'SET_DATA_GROUP_ON_CLIENT_BROWSER',
        payload: {
            clientBrowserID,
            dataGroupID
        },
    };
}
export const clientBrowserDataGroupSet = (clientBrowserID, dataGroupID) => ({
    type: 'DATA_GROUP_SET_ON_CLIENT_BROWSER',
    payload: {
        clientBrowserID,
        dataGroupID
    },
});

export const setClientBrowserDataSet = (clientBrowserID, dataSetID) => {
    socket.emit('updateWebRevealDataSet', {customerBrowserID: clientBrowserID, dataSetID}, response => {
        if (!response.success) return rootStore.dispatch(clientBrowsersError(response.reason));
        rootStore.dispatch(clientBrowserDataSetSet(clientBrowserID, dataSetID));
    });
    return {
        type: 'SET_DATA_GROUP_ON_CLIENT_BROWSER',
        payload: {
            clientBrowserID,
            dataSetID
        },
    };
}
export const clientBrowserDataSetSet = (clientBrowserID, dataSetID) => ({
    type: 'DATA_SET_SET_ON_CLIENT_BROWSER',
    payload: {
        clientBrowserID,
        dataSetID
    },
});
