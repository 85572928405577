import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import PageView from '../../components/views/page.view.component'
import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';
import LinkButton from '../../components/linkButton.component';

import { ModelComponent, connect } from '../ModelComponent';

import { Template } from './template.component';
import AddTemplateModal from './modals/addTemplate.modal'

class TemplatesView extends ModelComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      addModalOpen: false
    }
  }
  render() {
    return (
      <PageView>
        <AddTemplateModal
          open={this.state.addModalOpen} 
          closeModal={() => this.setState({addModalOpen: false})} 
        />
        {this.makeList('KioskTemplate', (data) => (
          <Template id={data.id.value} key={data.id.value} />
        ))}
        <View style={{flexDirection: 'row', justifyContent: 'center', marginTop: 10}}>
          <LinkButton title="Add Template" onPress={() => this.setState({addModalOpen: true})} />
        </View>
      </PageView>
    );
  }
}

export default TemplatesView = connect({
	component: TemplatesView,
	models: [{
  		model: 'KioskTemplate', 
  		list: true,
	}]
});
