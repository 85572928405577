import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { ModelModal, connect } from '../../ModelComponent';

class AddCustomerGroupModal extends ModelModal {
  constructor(props) {
    super(props, "Add Customer Group");
    this.state = {
      name: '',
      description: '',
    }
  }
  renderModal() {
	const form = this.getFormHelper('DataGroup', 'default');
    return (<>
      {form.dropdown({name: 'siteID', displayText: 'Site', options: this.makeDropdownOptions('Site')})}
      {form.textInput('name')}
      {form.textInput('description', true)}
    </>);
	//{this.renderTextInput({name:'Name', value:this.state.name, onUpdate:(name) => this.setState({name})})}
	//{this.renderTextInput({name:'Description', value:this.state.description, multiline: true, onUpdate:(description) => this.setState({description})})}
  }

  renderModalBottom() {
	const form = this.getFormHelper('DataGroup', 'default');
    return (<>
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 1, justifyContent: "center"}}>
          <LinkButton title="Cancel" color="#A00" style={{width: 200}} onPress={() => this.props.closeModal()} />
        </View>
        <View style={{flex: 1, flexDirection: 'row-reverse'}}>
          <Button title="Add" style={{width: 100}} onPress={() => {
			this.saveModel({create: true, model: 'DataGroup', id: 'default', parent: {model: 'Site', id: form.getModelValue('siteID')}}).then(() => this.props.closeModal()).catch(console.error)
		  }} />
        </View>
      </View>
    </>);
  }
}
export default AddCustomerGroupModal = connect({
	component: AddCustomerGroupModal,
	models: [{
		model: 'DataGroup',
	}]
});

/*
import { ModelModal, connect } from '../../ModelComponent';

class AddCustomerGroupModal extends ModelModal {
	constructor(props) {
		super(props, "Add Customer Group");
	}
	renderModal() {
		return (<>
			{this.renderModelTextInput('name')}
			{this.renderModelTextInput('description', true)}

			{this.renderModelDropdown({
				name: 'siteID', 
				displayText: 'Site',
				options: this.makeDropdownOptions('Site')
			})}
		</>);
	}
	renderModalBottom() {
		console.log(this.props);
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Cancel" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Add" style={{ width: 100 }} onPress={() => {
						this.setModelFields({
							organisationID: this.props.modelOptions.root.userData.userID, 
						}, 'DataGroup', 'default').then(()=> this.saveModel(true, null, 'DataGroup', 'default').then(() => this.props.closeModal()).catch(console.error));
					}} />
				</View>
			</View>
		</>);
	}
}
export default AddCustomerGroupModal = connect({
	component: AddCustomerGroupModal,
	models: [{
		model: 'DataGroup',
	}]
});

*/