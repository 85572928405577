import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';
import IconButton from '../../../components/iconButton.component';

import { ModelModal, connect } from '../../ModelComponent';
import Loader from '../../../components/loader.component';

class PlaylistItemSettingsModal extends ModelModal {
	constructor(props) {
		super(props, "Playlist Item Settings");
		this.state = {
			...this.state,
			isImage: false
		}
	}
	componentDidMount() {
		const form = this.getFormHelper('SignagePlaylistItem', this.props.id);
		if (form.value('itemType') == 'image') {
			this.setState({isImage: true});
		}
	}
	renderModal() {
		const form = this.getFormHelper('SignagePlaylistItem', this.props.id);
		return (<>
			{form.textInput('name')}
			{form.textInput('description', true)}
			{form.filePicker('filePath', {onChange: (result) => {
				if (result?.uri?.startsWith('data:image/')) {
					this.setState({isImage: true});
					if (!form.value('duration')) {
						form.setModelField('duration', 10);
					}
				}
			}})}
			{this.state.isImage && form.textInput('duration', {label: 'Duration (seconds)', placeholder: 'Duration', numeric: true})}
		</>);
	}
	renderModalBottom() {
		const form = this.getFormHelper('SignagePlaylistItem', this.props.id);
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Close" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					{this.state.awaitingRequest ?
						<Loader text="Uploading..." size="largeInline" />
					: <Button title="Done" style={{ width: 100 }} onPress={() => {
						form.setModelFields({
							itemType: this.state.isImage ? 'image' : 'video',
						}).then(() => {
							form.save().then(() => this.props.closeModal()).catch(console.error)
						});
					}} />}
				</View>
			</View>
		</>);
	}
}
export default PlaylistItemSettingsModal = connect({
	component: PlaylistItemSettingsModal,
	models: [{
		model: 'SignagePlaylistItem',
		loadChildren: true,
	}]
});

