import React from 'react';
import { StyleSheet, Text, View, PanResponder, Animated, Platform, Pressable, TouchableOpacity, TouchableWithoutFeedback, Dimensions } from 'react-native';
import { SERVER_DOMAIN } from '../constants';
import rootStore from '../root.store.js';
import * as Font from 'expo-font';

import Image from './image.component';

import { availableFonts } from './fontPicker.component';

import { enableScroll, disableScroll } from '../actions/pageView.actions';

import ButtonPreview from './buttonPreview.component';
import WebView from './webView.component';
import Loader from './loader.component';
import StyledText from './styledText.component';

import { Video } from 'expo-av'
import VideoPlayer from 'expo-video-player'
import PlaylistPlayer from '../screens/signage/playlistPlayer.component.js';

import Slider from './slider.component';

const editorDotRadius = 7.5;
const componentOutterStyleRadius = 20;

const getFileUri = (fileName) => {
	if (!fileName) return null;
	return SERVER_DOMAIN + '/customerWebPages/' + fileName;
}

export default class TemplatePagePreview extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			scale: 1,
			inverseScale: 1,
			selectedElement: null,
			viewportWidth: 0,
			backgroundLoading: true,
		}

		this.panResponder = PanResponder.create({
			onStartShouldSetPanResponder: () => true,
			onPanResponderGrant: (e) => {
				if (this.state.selectedElement) this.setState({ selectedElement: null });
				if (this.props.onSelected) this.props.onSelected(null);
			}
		});

		this._mounted = false;
	}
	componentDidMount() {
		this._mounted = true;
	}
	componentWillUnmount() {
		this._mounted = false;
	}
	componentDidUpdate = (prevProps) => {
		if (this.props.editorWidth != prevProps.editorWidth && this.state.viewportWidth) {
			const width = this.state.viewportWidth;

			this.setState({
				scale: width / this.props.editorWidth,
				inverseScale: this.props.editorWidth / width,
			});
		}
	}
	render() {
		const userType = this.props.userType;

		const backgroundImageURI = this.props.backgroundImageURI;
		const backgroundColor = this.props.backgroundColor;

		const components = this.props.components;
		const grid = this.props.grid;

		return (
			<View
				style={[previewStyles.wrapper, this.props.style, { backgroundColor: backgroundColor }, this.state.viewportWidth === 0 && { alignItems: 'center', justifyContent: 'center' }]}
				onLayout={(event) => {
					const { x, y, width, height } = event.nativeEvent.layout;
					if (this._mounted) this.setState({
						viewportWidth: width,
						scale: width / this.props.editorWidth,
						inverseScale: this.props.editorWidth / width,
					});
				}}
			>

				{this.state.viewportWidth === 0 ? <View><Loader /></View>
					: <View style={[previewStyles.container, { height: Math.ceil(this.props.editorHeight * this.state.scale) }, this.props.containerStyle]} {...this.panResponder.panHandlers}>
						{backgroundImageURI ? <>
							<Image source={{ uri: backgroundImageURI }} onLoadEnd={() => {
								if (this.state.backgroundLoading) {
									this.setState({ backgroundLoading: false });
								}
							}} style={{
								width: '100%',
								height: '100%',
								position: 'absolute',
								left: 0,
								top: 0,
							}} />
							{this.state.backgroundLoading && <Loader />}
						</>: null}

						{components.images && Object.values(components.images).filter(component => !!component.component).map(component => {
							if (!component.positionData) return null;
							return (<DisplayImage key={'image_' + component.id}
								selectID={'image_' + component.id}
								dataField={component}
								editorWidth={this.props.editorWidth}
								editorHeight={this.props.editorHeight}
								editable={this.props.editable}
								onUpdate={(updatedComponent, updateType) => {
									if (this.props.onUpdate) this.props.onUpdate(updatedComponent, updateType);
								}}
								scale={this.state.scale}
								inverseScale={this.state.inverseScale}
								maintainAspectRatio={true}
								selectedElement={this.state.selectedElement}
								grid={grid}
								onDoubleTap={() => {
									if (this.props.onOpenEditor) this.props.onOpenEditor('image', component.id);
								}}
								onSelected={() => {
									this.setState({ selectedElement: 'image_' + component.id })
									if (this.props.onSelected) this.props.onSelected(component);
								}}
							/>)
						})}

						{components.playlists && Object.values(components.playlists).filter(component => !!component.component).map(component => {
							if (!component.positionData) return null;
							return (<DisplayPlaylist key={'playlist_' + component.id}
								selectID={'playlist_' + component.id}
								dataField={component}
								editorWidth={this.props.editorWidth}
								editorHeight={this.props.editorHeight}
								editable={this.props.editable}
								onUpdate={(updatedComponent, updateType) => {
									if (this.props.onUpdate) this.props.onUpdate(updatedComponent, updateType);
								}}
								scale={this.state.scale}
								inverseScale={this.state.inverseScale}
								maintainAspectRatio={false}
								selectedElement={this.state.selectedElement}
								grid={grid}
								onDoubleTap={() => {
									if (this.props.onOpenEditor) this.props.onOpenEditor('playlist', component.id);
								}}
								onSelected={() => {
									this.setState({ selectedElement: 'playlist_' + component.id })
									if (this.props.onSelected) this.props.onSelected(component);
								}}
							/>)
						})}

						{components.texts && Object.values(components.texts).filter(component => !!component.component).map(component => {
							if (!component.positionData) return null;
							return (
								<DisplayText key={'text_' + component.id}
									selectID={'text_' + component.id}
									dataField={component}
									editorWidth={this.props.editorWidth}
									editorHeight={this.props.editorHeight}
									editable={this.props.editable}
									onUpdate={(updatedComponent, updateType) => {
										if (this.props.onUpdate) this.props.onUpdate(updatedComponent, updateType);
									}}
									scale={this.state.scale}
									inverseScale={this.state.inverseScale}
									maintainAspectRatio={false}
									selectedElement={this.state.selectedElement}
									grid={grid}
									onDoubleTap={() => {
										if (this.props.onOpenEditor) this.props.onOpenEditor('text', component.id);
									}}
									onSelected={() => {
										this.setState({ selectedElement: 'text_' + component.id })
										if (this.props.onSelected) this.props.onSelected(component);
									}}
								/>
							);
						})}

						{components.buttons && Object.values(components.buttons).filter(component => !!component.component).map(component => {
							if (!component.positionData) return null;
							return (
								<DisplayButton key={'button_' + component.id}
									selectID={'button_' + component.id}
									dataField={component}
									editorWidth={this.props.editorWidth}
									editorHeight={this.props.editorHeight}
									editable={this.props.editable}
									resizable={component.component.buttonStyle?.resizable}
									onUpdate={(updatedComponent, updateType) => {
										if (this.props.onUpdate) this.props.onUpdate(updatedComponent, updateType);
									}}
									scale={this.state.scale}
									inverseScale={this.state.inverseScale}
									maintainAspectRatio={true}
									selectedElement={this.state.selectedElement}
									grid={grid}
									onDoubleTap={() => {
										if (this.props.onOpenEditor) this.props.onOpenEditor('button', component.id);
									}}
									onSelected={() => {
										this.setState({ selectedElement: 'button_' + component.id })
										if (this.props.onSelected) this.props.onSelected(component);
									}}
								/>
							)
						}
						)}

						{components.countdowns && Object.values(components.countdowns).filter(component => !!component.component).map(component => {
							if (!component.positionData) return null;
							return (
								<DisplayCountdown key={'countdown_' + component.id}
									selectID={'countdown_' + component.id}
									dataField={component}
									editorWidth={this.props.editorWidth}
									editorHeight={this.props.editorHeight}
									editable={this.props.editable}
									onUpdate={(updatedComponent, updateType) => {
										if (this.props.onUpdate) this.props.onUpdate(updatedComponent, updateType);
									}}
									scale={this.state.scale}
									inverseScale={this.state.inverseScale}
									maintainAspectRatio={true}
									selectedElement={this.state.selectedElement}
									grid={grid}
									onDoubleTap={() => {
										if (this.props.onOpenEditor) this.props.onOpenEditor('countdown', component.id);
									}}
									onSelected={() => {
										this.setState({ selectedElement: 'countdown_' + component.id })
										if (this.props.onSelected) this.props.onSelected(component);
									}}
								/>
							)
						})}

						{components.modals && Object.values(components.modals).map(component => {
							if (!component.positionData) return null;
							return (
								<DisplayModal key={'modal_' + component.id}
									selectID={'modal_' + component.id}
									dataField={component}
									editorWidth={this.props.editorWidth}
									editorHeight={this.props.editorHeight}
									editable={this.props.editable}
									onUpdate={(updatedComponent, updateType) => {
										if (this.props.onUpdate) this.props.onUpdate(updatedComponent, updateType);
									}}
									scale={this.state.scale}
									inverseScale={this.state.inverseScale}
									maintainAspectRatio={false}
									selectedElement={this.state.selectedElement}
									grid={grid}
									onDoubleTap={() => {
										if (this.props.onEditModalContents) this.props.onEditModalContents(component.id);
										else if (this.props.onOpenEditor) this.props.onOpenEditor('modal', component.id);
									}}
									onSelected={() => {
										this.setState({ selectedElement: 'modal_' + component.id })
										if (this.props.onSelected) this.props.onSelected(component);
									}}
									menuOptions={(() => {
										if (this.props.onEditModalContents)	return [
											{ 
												label: 'Edit Contents',
												onPress: () => {
													this.props.onEditModalContents(component.id);
												}
											}
										];
										return [];
									})()}
								/>
							)
						})}

						{grid.showGrid && <OverlayGrid size={grid.gridSize} scale={this.state.scale} editorWidth={this.props.editorWidth} editorHeight={this.props.editorHeight} />}
					</View>
				}
			</View>
		);
	}
}

class OverlayGrid extends React.Component {
	render() {
		const size = this.props.size * this.props.scale;

		const height = Math.ceil(this.props.editorHeight * this.props.scale);
		const width = Math.ceil(this.props.editorWidth * this.props.scale);

		const gridColor = '#DDD';

		const result = [];
		for (let y = size; y < height; y += size) {
			result.push(<View key={'ovh' + y} style={{ top: y, left: 0, width: width, borderBottomWidth: 1, borderStyle: 'dotted', borderColor: gridColor, position: 'absolute' }}></View>)
		}
		for (let x = size; x < width; x += size) {
			result.push(<View key={'ovw' + x} style={{ top: 0, left: x, height: height, borderLeftWidth: 1, borderStyle: 'dotted', borderColor: gridColor, position: 'absolute' }}></View>)
		}
		return (<View pointerEvents="none" style={{ width: width, height: height, opacity: 0.5, zIndex: 120, overflow: 'hidden', position: 'absolute', top: 0, left: 0 }}>
			<View style={{ flex: 1, position: 'relative' }}>
				{result}
			</View>
		</View>)
	}
}

class PreviewElement extends React.Component {
	extraState = {}
	constructor(props) {
		super(props);

		this.state = {
			dataField: {
				...this.props.dataField,
				positionData: {
					...this.props.dataField.positionData
				}
			},
			selected: false,
			temporaryScaleWidth: 0,
			temporaryScaleHeight: 0,
			temporaryScaleX: 0,
			temporaryScaleY: 0,
			pan: new Animated.ValueXY(),
			...this.extraState
		}

		this.panResponder = PanResponder.create({
			onMoveShouldSetPanResponder: () => true,
			onMoveShouldSetPanResponderCapture: () => true,
			onStartShouldSetPanResponder: () => true,
			onStartShouldSetPanResponderCapture: () => true,
			onPanResponderTerminationRequest: () => true,
			onPanResponderTerminate: (e) => {
				e.stopPropagation();
				this.state.pan.setOffset({ x: 0, y: 0 });
				this.state.pan.setValue({ x: 0, y: 0 });
				this.setState({ selected: false });
				if (Platform.OS !== 'web') rootStore.dispatch(enableScroll());
			},
			onPanResponderGrant: (e) => {
				e.stopPropagation();
				this.state.pan.setOffset({
					x: this.state.pan.x._value,
					y: this.state.pan.y._value
				});

				const prevTapStartTime = this.state.tapStartTime;
				const tapStartTime = Date.now();

				this.state.pan.setValue({ x: 0, y: 0 });
				this.setState({ selected: true, tapStartTime, prevTapStartTime });
				this.props.onSelected();
				if (Platform.OS !== 'web') rootStore.dispatch(disableScroll());
			},
			onPanResponderMove: Animated.event([
				null,
				{ dx: this.state.pan.x, dy: this.state.pan.y }
			], { useNativeDriver: false }),
			onPanResponderRelease: (e) => {
				e.stopPropagation();

				const prevTapStartTime = this.state.prevTapStartTime;
				const prevTapDuration = this.state.tapDuration;

				const tapStartTime = this.state.tapStartTime;
				const tapDuration = Date.now() - this.state.tapStartTime;

				if (this.props.onDoubleTap && prevTapStartTime && prevTapDuration) {
					const timeBetweenTaps = tapStartTime - (prevTapStartTime + prevTapDuration);
					if (prevTapDuration < 500 && tapDuration < 500 && timeBetweenTaps < 150) {
						this.props.onDoubleTap();
					}
				}

				if (this.state.pan.x._value != 0 || this.state.pan.y._value != 0) {
					const newDataField = {
						...this.props.dataField,
						positionData: {
							...this.props.dataField.positionData
						}
					};

					let newX = ((newDataField.positionData.x * this.props.scale) + this.state.pan.x._value) * this.props.inverseScale;
					let newY = ((newDataField.positionData.y * this.props.scale) + this.state.pan.y._value) * this.props.inverseScale;

					const grid = this.props.grid;
					if (grid.snapToGrid) {
						const gridSize = grid.gridSize;
						const gridX = Math.floor(newX / gridSize);
						const gridY = Math.floor(newY / gridSize);

						const cellX = newX % gridSize;
						const cellY = newY % gridSize;

						const gridSnapX = (cellX > gridSize / 2) ? gridX + 1 : gridX;
						const gridSnapY = (cellY > gridSize / 2) ? gridY + 1 : gridY;

						const snapX = gridSnapX * gridSize;
						const snapY = gridSnapY * gridSize;

						newX = snapX;
						newY = snapY;
					}

					newDataField.positionData.x = newX;
					newDataField.positionData.y = newY;

					newDataField.positionData.width = this.state.dataField.positionData.width;
					newDataField.positionData.height = this.state.dataField.positionData.height;

					if (JSON.stringify(newDataField.positionData) != JSON.stringify(this.state.dataField.positionData)) {
						this.props.onUpdate(newDataField, {name: 'move', addToHistory: true});
					}

					this.setState({
						dataField: newDataField,
						selected: false,
						tapDuration,
					});
				} else {
					this.setState({
						selected: false,
						tapDuration,
					});
				}
				this.state.pan.setOffset({ x: 0, y: 0 });
				this.state.pan.setValue({ x: 0, y: 0 });
				if (Platform.OS !== 'web') rootStore.dispatch(enableScroll());
			}
		});
	}
	componentDidUpdate = (prevProps, prevState) => {
		let prevDataField = prevState.dataField;
		if (this.props.selectedElement != this.props.selectID && prevProps.selectedElement == this.props.selectID) {
			this.setState({
				showEditorDots: false
			})
		}
		if ((!prevDataField) || JSON.stringify(this.props.dataField) !== JSON.stringify(prevDataField)) {
			this.setState({
				dataField: {
					...this.props.dataField,
					positionData: {
						...this.props.dataField.positionData
					}
				},
			});
		}
	}
	createPositionDataStyle = (zIndex = 10) => {
		const dataField = this.state.dataField;
		const scale = this.props.scale;
		const translateX = (Platform.OS === 'web') ? this.state.pan.x : this.state.pan.x._value;
		const translateY = (Platform.OS === 'web') ? this.state.pan.y : this.state.pan.y._value;
		//if (this.props.selectedElement && this.props.selectedElement == this.props.selectID) zIndex += 100;
		if (this.state.dataField.type == 'modal') zIndex += 100;
		return {
			width: this.state.temporaryScaleWidth * scale || dataField.positionData.width * scale,
			height: this.state.temporaryScaleHeight * scale || dataField.positionData.height * scale,
			zIndex: zIndex+dataField.positionData.z,
			position: 'absolute',
			opacity: dataField.positionData.opacity/100,
			left: this.state.temporaryScaleX * scale || dataField.positionData.x * scale,
			top: this.state.temporaryScaleY * scale || dataField.positionData.y * scale,
			transform: [{ translateX }, { translateY }, { rotate: '0deg' }],
		};
	}
	createDotPosition = (name) => {
		const { dataField, temporaryScaleWidth, temporaryScaleHeight, temporaryScaleX, temporaryScaleY } = this.state;

		let selectedDot = (name == this.state.selectedDot);

		const scale = this.props.scale;
		const dataFieldWidth = ((temporaryScaleWidth) || dataField.positionData.width) * scale;
		const dataFieldHeight = ((temporaryScaleHeight) || dataField.positionData.height) * scale;
		const dataFieldLeft = ((!selectedDot && temporaryScaleX) || dataField.positionData.x) * scale;
		const dataFieldTop = ((!selectedDot && temporaryScaleY) || dataField.positionData.y) * scale;
		const translateX = this.state.pan.x;
		const translateY = this.state.pan.y;

		let radiusOption = 'borderBottomRightRadius';
		if (name === 'tr') radiusOption = 'borderBottomLeftRadius';
		if (name === 'bl') radiusOption = 'borderTopRightRadius';
		if (name === 'br') radiusOption = 'borderTopLeftRadius';

		return {
			top: (name[0] == 't') ? componentOutterStyleRadius - editorDotRadius * 2 : componentOutterStyleRadius + dataFieldHeight, // - editorDotRadius,
			left: (name[1] == 'l') ? componentOutterStyleRadius - editorDotRadius * 2 : componentOutterStyleRadius + dataFieldWidth,// - editorDotRadius,
			transform: [{ translateX }, { translateY }, { rotate: '0deg' }],
			[radiusOption]: 0,
		};
	}
	updateScaleMove = (movements) => {
		const positionData = this.state.dataField.positionData;

		const newState = {
			temporaryScaleWidth: 0,
			temporaryScaleHeight: 0,
			temporaryScaleX: 0,
			temporaryScaleY: 0,
		};

		if (this.props.maintainAspectRatio) {
			let adjustment = movements.moveAmountX * (movements.name[1] == 'r' ? 1 : -1);
			if (Math.abs(movements.moveAmountY) > Math.abs(movements.moveAmountX)) adjustment = movements.moveAmountY * (movements.name[0] == 'b' ? 1 : -1);

			const newWidth = positionData.width + adjustment * 2;
			const newHeight = newWidth * (positionData.height / positionData.width);
			const newX = positionData.x - adjustment;
			const newY = positionData.y - adjustment / 2;

			if (newWidth > 0 && newHeight > 0) {
				newState.temporaryScaleWidth = newWidth;
				newState.temporaryScaleHeight = newHeight;
				newState.temporaryScaleX = newX;
				newState.temporaryScaleY = newY;
			}
		} else {
			if (movements.name == 'tl') {
				newState.temporaryScaleWidth = Math.max(1, positionData.width - movements.moveAmountX);
				newState.temporaryScaleHeight = Math.max(1, positionData.height - movements.moveAmountY);
				newState.temporaryScaleX = positionData.x + movements.moveAmountX;
				newState.temporaryScaleY = positionData.y + movements.moveAmountY;
			} else if (movements.name == 'bl') {
				newState.temporaryScaleWidth = Math.max(1, positionData.width - movements.moveAmountX);
				newState.temporaryScaleHeight = Math.max(1, positionData.height + movements.moveAmountY);
				newState.temporaryScaleX = positionData.x + movements.moveAmountX;
			} else if (movements.name == 'tr') {
				newState.temporaryScaleWidth = Math.max(1, positionData.width + movements.moveAmountX);
				newState.temporaryScaleHeight = Math.max(1, positionData.height - movements.moveAmountY);
				newState.temporaryScaleY = positionData.y + movements.moveAmountY;
			} else if (movements.name == 'br') {
				newState.temporaryScaleWidth = Math.max(1, positionData.width + movements.moveAmountX);
				newState.temporaryScaleHeight = Math.max(1, positionData.height + movements.moveAmountY);
			}
		}

		this.setState(newState);
	}
	updateScale = (movements) => {
		const newState = {
			dataField: {
				...this.props.dataField,
				positionData: {
					...this.props.dataField.positionData
				}
			},
			temporaryScaleWidth: 0,
			temporaryScaleHeight: 0,
			temporaryScaleX: 0,
			temporaryScaleY: 0,
		};
		const positionData = newState.dataField.positionData;

		if (this.props.maintainAspectRatio) {
			let adjustment = movements.moveAmountX * (movements.name[1] == 'r' ? 1 : -1);
			if (Math.abs(movements.moveAmountY) > Math.abs(movements.moveAmountX)) adjustment = movements.moveAmountY * (movements.name[0] == 'b' ? 1 : -1);

			const newWidth = positionData.width + adjustment * 2;
			const newHeight = newWidth * (positionData.height / positionData.width);
			const newX = positionData.x - adjustment;
			const newY = positionData.y - adjustment / 2;

			if (newWidth > 0 && newHeight > 0) {
				positionData.width = newWidth;
				positionData.height = newHeight;
				positionData.x = newX;
				positionData.y = newY;
			}
		} else {
			if (movements.name == 'tl') {
				positionData.width = Math.max(1, positionData.width - movements.moveAmountX);
				positionData.height = Math.max(1, positionData.height - movements.moveAmountY);
				positionData.x = positionData.x + movements.moveAmountX;
				positionData.y = positionData.y + movements.moveAmountY;
			} else if (movements.name == 'bl') {
				positionData.width = Math.max(1, positionData.width - movements.moveAmountX);
				positionData.height = Math.max(1, positionData.height + movements.moveAmountY);
				positionData.x = positionData.x + movements.moveAmountX;
			} else if (movements.name == 'tr') {
				positionData.width = Math.max(1, positionData.width + movements.moveAmountX);
				positionData.height = Math.max(1, positionData.height - movements.moveAmountY);
				positionData.y = positionData.y + movements.moveAmountY;
			} else if (movements.name == 'br') {
				positionData.width = Math.max(1, positionData.width + movements.moveAmountX);
				positionData.height = Math.max(1, positionData.height + movements.moveAmountY);
			}
		}

		this.setState(newState);

		this.props.onUpdate(newState.dataField, {name: 'scale', addToHistory: true});
	}
	startUpdateScale = (name) => {
		this.setState({ selectedDot: name });
		//this.props.onSelected();
	}
	onScaleUpdateEnd = (name) => {
		this.setState({ selectedDot: null });
	}
	cancelUpdateScale = (name) => {
		const newState = {
			selectedDot: false,
			temporaryScaleWidth: 0,
			temporaryScaleHeight: 0,
			temporaryScaleX: 0,
			temporaryScaleY: 0,
		};
		this.setState(newState);
	}
	updatePositionData = (data, updateName = 'move') => {
		const newState = {
			dataField: {
				...this.props.dataField,
				positionData: {
					...this.props.dataField.positionData
				}
			},
			temporaryScaleWidth: 0,
			temporaryScaleHeight: 0,
			temporaryScaleX: 0,
			temporaryScaleY: 0,
		};
		const positionData = newState.dataField.positionData;
		for (let key in data) {
			positionData[key] = data[key];
		}
		this.setState(newState);
		this.props.onUpdate(newState.dataField, {name: updateName, addToHistory: true});
	}
	centerHorizontally = () => {
		const screenWidth = this.props.editorWidth;

		this.updatePositionData({
			centerHorizontally: true,
			x: Math.round(screenWidth / 2 - this.state.dataField.positionData.width / 2),
		}, 'Center Horizontally');
	}
	centerVertically = () => {
		const screenHeight = this.props.editorHeight;

		this.updatePositionData({
			centerVertically: true,
			y: Math.round(screenHeight / 2 - this.state.dataField.positionData.height / 2),
		}, 'Center Vertically');
	}
	editorRender = (content) => {
		if (!this.props.editable) return (
			<View style={this.createPositionDataStyle()}>
				{content}
			</View>
		);

		let webStyle = {};
		if (Platform.OS === 'web') {
			webStyle.cursor = 'pointer';
		}

		const isSelected = (this.props.selectedElement && this.props.selectedElement == this.props.selectID);

		let menuPosition = {width: 130};
		if (isSelected) {
			const menuWidth = menuPosition.width;
			const scaledScreenWidth = this.props.editorWidth * this.props.scale;
			const scaledWidth = this.state.dataField.positionData.width * this.props.scale;
			const scaledX = this.state.dataField.positionData.x * this.props.scale;
			const scaledY = this.state.dataField.positionData.y * this.props.scale;

			if (scaledX + scaledWidth + menuWidth+10 > scaledScreenWidth) menuPosition = { left: scaledX-(menuWidth+10), top: scaledY };
			else menuPosition = { left: scaledX+(scaledWidth+10), top: scaledY };
		}

		const moving = this.state.selected;

		const positionDataStyle = this.createPositionDataStyle();
		const outterStyle = { ...positionDataStyle };
		outterStyle.width += componentOutterStyleRadius*2;
		outterStyle.height += componentOutterStyleRadius*2;
		outterStyle.left -= componentOutterStyleRadius;
		outterStyle.top -= componentOutterStyleRadius;

		//outterStyle.backgroundColor = 'rgba(200,200,200,0.5)';

		return (<>
			{(this.props.resizable === undefined || this.props.resizable) ? <View style={outterStyle} pointerEvents={false}>
				<div
					style={{width: '100%', height: '100%'}} 
					onMouseEnter={() => {
						if (!this.props.selectedElement) this.setState({showEditorDots: true})
					}} 
					onMouseLeave={() => {
						if (!isSelected) this.setState({showEditorDots: false})
					}}
				>
					{isSelected || this.state.showEditorDots ? <>
						<EditorDot positionStyle={this.createDotPosition('tl')} active={isSelected} name='tl' scale={this.props.scale} inverseScale={this.props.inverseScale} onStartUpdate={this.startUpdateScale} onUpdate={this.updateScale} onUpdateMove={this.updateScaleMove} onUpdateEnd={this.onScaleUpdateEnd} onCancelUpdate={this.cancelUpdateScale} />
						<EditorDot positionStyle={this.createDotPosition('bl')} active={isSelected} name='bl' scale={this.props.scale} inverseScale={this.props.inverseScale} onStartUpdate={this.startUpdateScale} onUpdate={this.updateScale} onUpdateMove={this.updateScaleMove} onUpdateEnd={this.onScaleUpdateEnd} onCancelUpdate={this.cancelUpdateScale} />
						<EditorDot positionStyle={this.createDotPosition('tr')} active={isSelected} name='tr' scale={this.props.scale} inverseScale={this.props.inverseScale} onStartUpdate={this.startUpdateScale} onUpdate={this.updateScale} onUpdateMove={this.updateScaleMove} onUpdateEnd={this.onScaleUpdateEnd} onCancelUpdate={this.cancelUpdateScale} />
						<EditorDot positionStyle={this.createDotPosition('br')} active={isSelected} name='br' scale={this.props.scale} inverseScale={this.props.inverseScale} onStartUpdate={this.startUpdateScale} onUpdate={this.updateScale} onUpdateMove={this.updateScaleMove} onUpdateEnd={this.onScaleUpdateEnd} onCancelUpdate={this.cancelUpdateScale} />
					</> : null}
				</div>
			</View> : null}
			<Animated.View
				style={[positionDataStyle, webStyle, (isSelected ? previewStyles.selectedBackground : this.state.showEditorDots ? previewStyles.visibleBackground : null)]}
				{...this.panResponder.panHandlers}
				onMouseEnter={() => {
					if (!this.props.selectedElement) this.setState({showEditorDots: true})
				}} 
				onMouseLeave={() => {
					if (!isSelected) this.setState({showEditorDots: false})
				}}
			>
				{content}
			</Animated.View>
			{/*
			{(!moving && isSelected && !this.state.selectedDot) ? <View style={[menuPosition, { position: 'absolute', zIndex: 140, backgroundColor: '#FFF', borderWidth: StyleSheet.hairlineWidth, borderColor: '#CCC', padding: 5, borderRadius: 5 }]}>
				<TouchableOpacity onPress={() => this.centerHorizontally()} >
					<Text style={{ padding: 5 }}>Center Horizontal</Text>
				</TouchableOpacity>
				<TouchableOpacity onPress={() => this.centerVertically()} >
					<Text style={{ borderTopWidth: StyleSheet.hairlineWidth, borderColor: '#CCC', padding: 5 }}>Center Vertical</Text>
				</TouchableOpacity>
				{this.state.dataField.type != 'modal' && <>
					{this.state.dataField.positionData.z + 1 <= 100 && <TouchableOpacity onPress={() => { const newZ = this.state.dataField.positionData.z + 1; if (newZ <= 100) this.updatePositionData({z: newZ}, 'Move Forward'); }} >
						<Text style={{ borderTopWidth: StyleSheet.hairlineWidth, borderColor: '#CCC', padding: 5 }}>Move Forward</Text>
					</TouchableOpacity>}
					{this.state.dataField.positionData.z - 1 >= 0 && <TouchableOpacity onPress={() => { const newZ = this.state.dataField.positionData.z - 1; if (newZ >= 0) this.updatePositionData({z: newZ}, 'Move Back'); }} >
						<Text style={{ borderTopWidth: StyleSheet.hairlineWidth, borderColor: '#CCC', padding: 5 }}>Move Back</Text>
					</TouchableOpacity>}
				</>}
				<Slider
					style={{ borderTopWidth: StyleSheet.hairlineWidth, borderColor: '#CCC', padding: 5 }}
					minimumValue={0}
					maximumValue={100}
					valueSuffix={'%'}
					valuePreffix={"Opacity: "}
					initialValue={this.state.dataField.positionData.opacity}
					onValueChange={(val) => this.updatePositionData({opacity: val}, 'Opacity')}
				/>
				{(this.props.menuOptions || []).map((option, idx) => (
					<TouchableOpacity key={this.props.selectID+'_'+idx} onPress={() => option.onPress()} >
						<Text style={{ borderTopWidth: StyleSheet.hairlineWidth, borderColor: '#CCC', padding: 5 }}>{option.label}</Text>
					</TouchableOpacity>
				))}
			</View> : null}
			*/}
			
		</>);
	}
}


class DisplayButton extends PreviewElement {
	extraState = {
		imageLoading: true,
	}
	componentDidMount() {
		this._mounted = true;
	}
	componentWillUnmount() {
		this._mounted = false;
	}
	render() {
		const button = this.props.dataField;
		const uploadedImages = this.props.uploadedImages || {};
		return this.editorRender(<>
			{((button.imagePath || uploadedImages.pressedImage) && this.state.imageLoading) ? <Loader /> : null}
			<ButtonPreview button={button} uploadedImages={this.state.uploadedImages} scale={this.props.scale} pressable={false} onLoadEnd={() => {
				if (this._mounted && this.state.imageLoading) this.setState({ imageLoading: false });
			}} />
			{/* <Image source={uploadedImages[button.id || button.tmpID]?.uri || getFileUri(button.imagePath)} style={previewStyles.displayImage} onLoadEnd={} /> */}
		</>);
	}
}

class DisplayPlaylist extends PreviewElement {
	extraState = {
		playlistLoading: true,
	}
	componentDidMount() {
		this._mounted = true;
		this.player = null;
	}
	componentWillUnmount() {
		this._mounted = false;
	}
	render() {
		const playlist = this.props.dataField.component;
		const positionData = this.createPositionDataStyle()

		const playlistForPlayer = playlist.playlistItems.map(item => {
			return {
				name: item.name.value,
				index: item.playlistIndex.value,
				itemType: item.itemType.value,
				duration: item.duration.value,
				url: getFileUri(item.filePath.value),
			};
		});

		console.log(playlist);

		return this.editorRender(<>
			{this.state.playlistLoading
				? <Loader />
				: <PlaylistPlayer 
					playlist={playlistForPlayer} 
					muted={playlist.mutePlaylist} 
					width={positionData.width} 
					height={positionData.height} 
					onReady={() => {
						if (this._mounted && this.state.playlistLoading) this.setState({ playlistLoading: false });
					}}
				/>
			}
		</>);
	}
}

class DisplayImage extends PreviewElement {
	extraState = {
		imageLoading: true,
	}
	componentDidMount() {
		this._mounted = true;
		this.player = null;
	}
	componentWillUnmount() {
		this._mounted = false;
	}
	render() {
		const image = this.props.dataField.component;
		const uri = getFileUri(image.imagePath);
		const isVideo = uri && (uri.endsWith('.mp4') || uri.endsWith('.webp') || uri.endsWith('.avi'));
		const positionData = this.createPositionDataStyle()
		return this.editorRender(<>
			{image.imagePath
				? this.state.imageLoading
					? <Loader />
					: isVideo 
						? <VideoPlayer videoProps={{
							shouldPlay: true,
							resizeMode: "contain",
							source: { uri },
							isLooping: true,
							isMuted: true,
							onReadyForDisplay: () => {
								if (this._mounted && this.state.imageLoading) this.setState({ imageLoading: false });
							}
						  }}
						  defaultControlsVisible={false}
						  timeVisible={false}
						  slider={{visible: false}}
						  /*
						  playbackCallback={(info) => {
							console.log(info);
						  }
						  */
						  errorCallback={(err) => console.error(err)} 
						  style={{width: positionData.width, height: positionData.height}} />
						: <Image source={uri} style={previewStyles.displayImage} onLoadEnd={() => {
							if (this._mounted && this.state.imageLoading) this.setState({ imageLoading: false });
						}} />
				: <Text style={{ textAlign: 'center' }} focusable={false} selectable={false}>{'(' + this.props.dataField.name + " Image not set...)"}</Text>
			}
		</>);
	}
}

class DisplayText extends PreviewElement {
	render() {
		const text = this.props.dataField.component;
		const textStyle = text.textStyle || {};
		let displayText = "";
		if (text.isLinked) {
			if (text.previewDataSetOverride) displayText = text.previewDataSetOverride[text.linkedDataSetFieldName || 'customerName'];
			else if (text.previewDataSet) displayText = text.previewDataSet[text.linkedDataSetFieldName || 'customerName'];
			else displayText = '(' + text.linkedDataSetFieldName + ')';
		} else {
			displayText = text.text;
		}
		return this.editorRender(<>
			<StyledText style={{ textAlign: 'center' }}
				text={displayText}
				scale={this.props.scale}
				fontFamily={textStyle.fontFamily}
				fontSize={textStyle.fontSize}
				fontColor={textStyle.fontColor}
				centerText={textStyle.centerText}
			/>
		</>);
	}
}

class DisplayCountdown extends PreviewElement {
	render() {
		const unscaledWidth = (this.state.temporaryScaleWidth || this.state.dataField.positionData.width);
		const width = unscaledWidth; //*this.props.scale;
		const height = (this.state.temporaryScaleHeight || this.state.dataField.positionData.height); //*this.props.scale;

		if (!width || !height) return null;

		const circumference = 2 * Math.PI * ((width / 2) - 4);

		const translateAmount = ((1 - this.props.scale) / 2) * 100;

		const countdownComponent = this.state.dataField.component;

		const countdownHTML = `<style>
		  body {
			  padding: 0;
			  margin: 0;
			  overflow: hidden;
		  }
		  .item {
			  position: relative;
			  float: left;
			  width:`+ width + `px;
			  height:`+ height + `px;
			  transform: translateX(-`+ translateAmount + `%) translateY(-` + translateAmount + `%) scale(` + (this.props.scale || 1) + `);
		  }
  
		  .item h2 {
			  text-align:center;
			  position: absolute;
			  font-family: Arial, sans-serif;
			  font-size: `+ width * 0.7 + `%;
			  top: 50%;
			  left: 50%;
			  margin: 0;
			  transform-origin: center center; 
			  transform: translate(-50%, -50%);
			  color:#FFF;
		  }
  
		  svg {
			  -webkit-transform: rotate(-90deg);
			  transform: rotate(-90deg);
		  }
  
		  #circle_animation {
			  stroke-dasharray: `+ circumference + `;
			  stroke-dashoffset: 0;
			  transition: all 1s linear;
		  }
	  </style>
  
	  <div class="item html">
		  <svg width="`+ width + `" height="` + height + `" xmlns="http://www.w3.org/2000/svg">
		  <g>
			<title>Layer 1</title>
			<circle id="circle_animation" r="`+ Math.max(0, (width / 2) - 4) + `" cy="` + height / 2 + `" cx="` + width / 2 + `" stroke-width="8" stroke="#EEE" stroke-linecap="round" fill="rgba(0,0,0,0.5)"/>
		  </g>
		  </svg>
		  <h2 id="text">`+ countdownComponent.duration + `<br/>Seconds</h2>
	  </div>
  
	  <script>
		  var time = `+ countdownComponent.duration + `;
		  var maxOffset = `+ circumference + `;
  
		  /* Need initial run as interval hasn't yet occured... */
		  document.getElementById('circle_animation').style.strokeDashoffset = 0;
  
		  function run() {
			  var i = time;
			  var interval = setInterval(function() {
				  document.getElementById('text').innerHTML = i+'<br/>Seconds';
				  if (i <= 0) {  	
					  clearInterval(interval);
					  return;
				  }
				  i--;
				  const newOffset = -((time-i)*(maxOffset/time));
				  document.getElementById('circle_animation').style.strokeDashoffset = newOffset;
			  }, 1000);
		  }
		  //run();
	  </script>`;
		return this.editorRender(
			<View style={previewStyles.displayImage}>
				<WebView disableTouch={true} style={{ width: '100%', height: '100%' }} html={countdownHTML} />
			</View>
		);
	}
}


class DisplayModal extends PreviewElement {
	render() {
		const props = this.props;
		const modal = props.dataField;
		//console.log(modal.components);
		return this.editorRender(
			<TemplatePagePreview
				style={{ width: "100%", height: "100%", overflow: 'hidden', borderRadius: modal.borderRadius*props.scale }}
				editable={false}
				editorWidth={modal.positionData.width}
				editorHeight={modal.positionData.height}
				backgroundImageURI={getFileUri(modal.backgroundImage)}
				backgroundColor={modal.backgroundColor}
				components={modal.components}
				containerStyle={{overflow: 'hidden'}}
				onOpenEditor={(componentType, componentID) => {
					if (this.props.onOpenEditor) this.props.onOpenEditor(componentType, componentID);
				}}
				grid={{showGrid: false}} 
				/*
				onUpdate={(updatedComponent) => {
					console.log("UPDATING", updatedComponent.positionData.id, this.state.tempPositionDataID)
					if (this.state.tempPositionDataID) return;
					this.updateState({tempPositionDataID: updatedComponent.positionData.id, tempPositionData: updatedComponent.positionData});
					this.props.onUpdate(updatedComponent)
						.then(() => this.updateState({tempPositionDataID: null, tempPositionData: null}))
						.catch((error) => this.updateState({error, tempPositionDataID: null, tempPositionData: null}));
				}}
				*/
			/>
		);
	}
}


class EditorDot extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			pan: new Animated.ValueXY(),
			scale: new Animated.Value(1),
			selected: false,
			moveX: 0,
			moveY: 0,
		}

		this.panResponder = PanResponder.create({
			onMoveShouldSetPanResponder: () => true,
			onMoveShouldSetPanResponderCapture: () => true,
			onStartShouldSetPanResponder: () => true,
			onStartShouldSetPanResponderCapture: () => true,
			onPanResponderTerminationRequest: () => true,
			onPanResponderTerminate: () => {
				this.state.pan.setOffset({ x: 0, y: 0 });
				this.state.pan.setValue({ x: 0, y: 0 });
				this.setState({ selected: false });
				this.props.onCancelUpdate(this.props.name);
				if (Platform.OS !== 'web') rootStore.dispatch(enableScroll());
			},
			onPanResponderGrant: () => {
				this.state.pan.setOffset({
					x: this.state.pan.x._value,
					y: this.state.pan.y._value
				});
				this.state.pan.setValue({ x: 0, y: 0 });
				this.state.scale.setValue(1);
				Animated.spring(
					this.state.scale,
					{ toValue: 1.5, friction: 5, useNativeDriver: false }
				).start();
				this.setState({ selected: true });
				this.props.onStartUpdate(this.props.name);
				if (Platform.OS !== 'web') rootStore.dispatch(disableScroll());
			},
			onPanResponderMove: Animated.event([
				null,
				{ dx: this.state.pan.x, dy: this.state.pan.y }
			], {
				useNativeDriver: false,
				listener: () => {
					const moveAmountX = this.state.pan.x._value * this.props.inverseScale;
					const moveAmountY = this.state.pan.y._value * this.props.inverseScale;
					const name = this.props.name;
					this.setState({moveX: moveAmountX, moveY: moveAmountY});
					this.props.onUpdateMove({ moveAmountX, moveAmountY, name });
				}
			}
			),
			onPanResponderRelease: () => {
				const moveAmountX = this.state.pan.x._value * this.props.inverseScale;
				const moveAmountY = this.state.pan.y._value * this.props.inverseScale;
				const name = this.props.name;
				this.props.onUpdate({ moveAmountX, moveAmountY, name });
				this.state.pan.setOffset({ x: 0, y: 0 });
				this.state.pan.setValue({ x: 0, y: 0 });
				this.state.scale.setValue(1.5);
				this.props.onUpdateEnd(this.props.name);
				this.setState({moveX: 0, moveY: 0});
				Animated.spring(
					this.state.scale,
					{ toValue: 1, friction: 5, useNativeDriver: false }
				).start();
				this.setState({ selected: false });
				if (Platform.OS !== 'web') rootStore.dispatch(enableScroll());
			}
		});
	}
	getPositionStyle = (zIndex = 0) => {
		const positionStyle = {
			...this.props.positionStyle,
			zIndex: zIndex,
		}
		if (this.state.selected) {
			const translateX = this.state.moveX; //pan.x;
			const translateY = this.state.moveY; //pan.y;
			const animationScale = this.state.scale;
			positionStyle.transform = [{ translateX }, { translateY }, { rotate: '0deg' }, { scale: animationScale }];
		}
		//console.log(positionStyle);
		return positionStyle;
	}
	render() {
		let webStyle = {};
		if (Platform.OS === 'web') {
			webStyle.cursor = 'pointer';
		}
		const positionStyle = { ...this.props.positionStyle };
		delete positionStyle.left;
		delete positionStyle.top;
		return (
			<View style={[{position: 'absolute'}, this.getPositionStyle(125)]}>
				<Animated.View
					style={[previewStyles.editorDot, positionStyle, webStyle]}
					{...this.panResponder.panHandlers}>
				</Animated.View>
			</View>
		);
	}
}

const previewStyles = StyleSheet.create({
	wrapper: {
		width: '100%',
	},
	container: {
		width: '100%',
	},
	displayImage: {
		width: '100%',
		height: '100%',
	},
	displayText: {
		width: '100%',
		height: '100%',
	},
	visibleBackground: {
		backgroundColor: 'rgba(0,0,0,0.25)',
	},
	selectedBackground: {
		backgroundColor: 'rgba(0,0,0,0.25)',
	},
	editorDot: {
		width: editorDotRadius * 2,
		height: editorDotRadius * 2,
		borderRadius: editorDotRadius / 1.5,
		backgroundColor: 'rgba(50, 225, 50, 0.85)',
		borderColor: 'rgba(225, 225, 225, 0.5)',
		borderWidth: StyleSheet.hairlineWidth,
		position: 'absolute',
	},
});
