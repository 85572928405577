import React from "react";
import { ActivityIndicator, StyleSheet, View, Text } from "react-native";
import { styles, siteBlue } from '../main.styles';

const Loader = ({size = 'small', color, text}) => {
	const style = size === 'small' ? smallLoaderStyles
		: size === 'xsmall' ? xSmallLoaderStyles
		: size === 'largeInline' ? largeInlineLoaderStyles
		: largeLoaderStyles;

	return (
		<View style={style.container}>
			<ActivityIndicator style={style.loader} size={size == "largeInline" ? "large" : size} color={color || siteBlue} />
			<Text style={[styles.greyText, style.text]}>{text}</Text>
		</View>
	);
}
export default Loader;

const largeInlineLoaderStyles = StyleSheet.create({
    container: {
        flex: 1,
		flexDirection: 'row',
        justifyContent: "end",
		alignItems: "center",
        padding: 10,
    },
	loader: {

	},
	text: {
		textAlign: 'center', 
		paddingLeft: 10,
	}
});

const largeLoaderStyles = StyleSheet.create({
    container: {
        flex: 1,
		flexDirection: 'column',
        justifyContent: "center",
		alignItems: "center",
        padding: 10,
    },
	loader: {

	},
	text: {
		textAlign: 'center', 
		paddingTop: 20,
	}
});

const smallLoaderStyles = StyleSheet.create({
    container: {
        flex: 1,
		flexDirection: 'row',
        justifyContent: "flex-start",
		alignItems: "center",
		gap: 10,
		width: '100%',
        padding: 5,
    },
	loader: {

	},
	text: {
		textAlign: 'center', 
		paddingTop: 20,
	}
});

const xSmallLoaderStyles = StyleSheet.create({
    container: {
        flex: 1,
		flexDirection: 'row',
        justifyContent: "flex-start",
		alignItems: "center",
		gap: 10,
		width: '100%',
        padding: 2.5,
    },
	loader: {
		width: 15,
		height: 15,
	},
	text: {
		fontSize: 10
	}
});
