import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Checkbox } from 'react-native-paper';
import { styles } from '../main.styles';

export default class CheckBox extends React.Component {
  render() {
    const { value, label, labelStyle, onChange, onValue = true, offValue = false, style } = this.props;
	return (
		<View style={[{flexDirection: 'row'}, style]}>
			{label && <Text style={[{paddingTop: 8}, styles.label, labelStyle]}>{label}:</Text>}
			<Checkbox status={value == onValue ? 'checked' : 'unchecked'} onPress={() => {
				onChange((value == onValue) ? offValue : onValue);
			}} />
		</View>
	);
  }
}
