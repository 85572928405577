import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { ModelModal, connect } from '../../ModelComponent';

class AddTemplateModal extends ModelModal { // todo: extend from APIModal + make APIModal
	constructor(props) {
		super(props, "Add Template");
	}
	renderModal() {
		const form = this.getFormHelper('KioskTemplate', 'default');
		return (<>
			{form.dropdown({ name: 'siteID', displayText: 'Site', options: this.makeDropdownOptions('Site') })}
			{form.textInput('name')}
			{form.textInput('description', true)}
		</>);
	}
	renderModalBottom() {
		const form = this.getFormHelper('KioskTemplate', 'default');
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Cancel" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Add" style={{ width: 100 }} onPress={() => form.create({
						children: [{
							name: 'TemplatePages',
							data: { name: 'Main Page' }
						}]
					}).then(() => this.props.closeModal()).catch(console.error)} />
				</View>
			</View>
		</>);
	}
}
export default AddTemplateModal = connect({
	component: AddTemplateModal,
	models: [{
		model: 'KioskTemplate',
	}]
});
