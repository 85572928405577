import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { styles } from '../../main.styles';

import { navigate } from '../../root.navigation';

import PageView from '../../components/views/page.view.component'
import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';
import IconButton from '../../components/iconButton.component';

import { ModelComponent, connect } from '../ModelComponent';

import ButtonStyleSettingsModal from './modals/buttonStyleSettings.modal';

import ButtonPreview from '../../components/buttonPreview.component';

class ButtonStyleComponent extends ModelComponent {
	constructor(props) {
		super(props);
		this.state = {
			settingsOpen: false,
		}
	}
	componentDidUpdate(prevProps, prevState) {
		super.componentDidUpdate(prevProps, prevState);
		if (this.props.openSettings !== prevProps.openSettings) this.setState({ settingsOpen: this.props.openSettings });
	}
	renderContent() {
		const buttonStyleID = this.props.id;
		const isSelector = this.props.isSelector || false;

		const button = {};
		button.component = {
			text: "Button Text",
			buttonStyle: this.getModelData('ButtonStyle', buttonStyleID)?.data,
		}
		if (button.component.buttonStyle?.textStyleID) button.component.buttonStyle.textStyle = this.getModelData('TextStyle', button.component.buttonStyle.textStyleID)?.data;
		const previewScale = Math.min(1, 130 / button.component.buttonStyle?.width);

		const getValue = this.modelValueGetter('ButtonStyle', this.props.id);

		const isCompact = this.props.compact || false;

		return (<>
			<ButtonStyleSettingsModal
				id={buttonStyleID}
				open={this.state.settingsOpen}
				closeModal={() => this.updateState({ settingsOpen: false }, () => {
					if (this.props.onSettingsClosed) this.props.onSettingsClosed();
				})}
			/>

			{isCompact ?
				<TouchableOpacity style={[this.props.isSelected ? {backgroundColor: '#AAF'} : {}, { flexDirection: 'column', height: '100%', width: '100%', borderRadius: 5 }]} onPress={() => this.props.isEditor ? this.setState({ settingsOpen: true }) : this.props.onSelected(this.props.id)}>
					<View style={{ flex: 1, justifyContent: "center", alignItems: "center", overflow: 'hidden' }}>
						<ButtonPreview
							button={button}
							scale={previewScale}
							noTranslate={true}
						/>
					</View>
					<View style={{ flexDirection: 'row', justifyContent: "center", alignItems: "center" }}>
						{/* this.props.isEditor && <IconButton size={15} title="Settings" onPress={() => this.setState({ settingsOpen: true })} /> */}
						<Text style={[{textAlign: "center", fontWeight: 400, padding: 5}, isSelector? {} : { paddingLeft: 35, paddingRight: 35}]}>{getValue('name')}</Text>
					</View>
				</TouchableOpacity>
			: <View style={[styles.shadowBoxNoPadding, { backgroundColor: '#FFF', flexDirection: 'row', gap: 10, flexWrap: 'wrap' }]}>
				<IconButton size={30} stretchContainer={false} style={{paddingTop: 10, paddingLeft: 10}} title="Settings" onPress={() => this.setState({ settingsOpen: true })} />

				<View style={{ flex: 1, flexDirection: 'column', minWidth: 270, paddingTop: 10 }}>
					<Text style={styles.title}>{getValue('name')}</Text>
					<Text>{getValue('description')}</Text>
				</View>

				<View style={{ flexDirection: 'column', height: 140, width: 140, borderWidth: 1, borderRadius: 5, borderColor: '#AAA' }}>
					<View style={{ flex: 1, justifyContent: "center", alignItems: "center", overflow: 'hidden' }}>
						<ButtonPreview
							button={button}
							scale={previewScale}
							noTranslate={true}
						/>
					</View>
				</View>
				{this.renderError()}
			</View>}
		</>);
	}
}
export default ButtonStyleComponent = connect({
	component: ButtonStyleComponent,
	models: [{
		model: 'ButtonStyle',
		loadChildren: true,
	}]
});

