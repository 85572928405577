import React from 'react';
import { connect } from 'react-redux';
import { TouchableWithoutFeedback, Keyboard, SafeAreaView, StyleSheet, ScrollView, View, Platform } from 'react-native';

import FadeInView from './fadein.view.component'

class PageView extends React.Component {
	render() {
		let touchableAction = null;
		if (Platform.OS != 'web') touchableAction = Keyboard.dismiss;
		return (
			<ScrollView scrollEnabled={this.props.scrollEnabled} style={pageViewstyles.scrollContainer} contentContainerStyle={pageViewstyles.scrollContentContainer} scrollIndicatorInsets={{ right: 1 }}>
				<FadeInView style={{ flex: 1, height: '100%' }}>
					<TouchableWithoutFeedback onPress={touchableAction}>
						<View style={this.props.center ? pageViewstyles.centerContainer : { padding: ('padding' in this.props ? this.props.padding : 20), flex: 1 }}>
							<View style={pageViewstyles.pageContainer}>
								<SafeAreaView style={{ flex: 1 }}>
									<View style={{ flex: 1 }}>
										{this.props.children}
										{(this.props.padding || this.props.padding === undefined) && <View style={{height: this.props.padding || 50}} />}
									</View>
								</SafeAreaView>
							</View>
						</View>
					</TouchableWithoutFeedback>
				</FadeInView>
			</ScrollView>
		);
	}
}
const mapStateToProps = (state) => {
	return state.root;
};
export default connect(mapStateToProps)(PageView);

const pageViewstyles = StyleSheet.create({
	scrollContainer: {
		flex: 1,
		height: '100%',
		padding: 0,
		backgroundColor: '#FCFCFC', //'F2F2F6',
	},
	scrollContentContainer: {
		flex: 1
	},
	centerContainer: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
	},
	pageContainer: {
		flex: 1,
		//maxWidth: 960,
		width: '100%',
		height: '100%',
		flexDirection: 'column',
	},
});
