import React from 'react';
import { View, Text, StyleSheet, Platform } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { faFolderOpen, faFolderPlus, faImage, faEllipsisH, faCaretSquareRight, faParagraph } from '@fortawesome/free-solid-svg-icons';

import { Menu, Divider, PaperProvider } from 'react-native-paper';

import { FontAwesomeIcon as FontAwesomeReact } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon as FontAwesomeNative } from '@fortawesome/react-native-fontawesome';
const FontAwesomeIcon = (Platform.OS === 'web' ? FontAwesomeReact : FontAwesomeNative);

import LinkButton from '../../components/linkButton.component';
import IconButton from '../../components/iconButton.component';
import RadioButtons from '../../components/radioButtons.component';

import { siteBlue, siteRed } from '../../main.styles';

import { ModelComponent, ModelEditorComponent, connect } from '../ModelComponent';

import AddLibraryFolderModal from './modals/addLibraryFolder.modal';
import FolderSettingsModal from './modals/folderSettings.modal';

import LibraryItem from './libraryItem.component';
import AddLibraryItemModal from './modals/addLibraryItem.modal';

import ButtonStyle from './buttonStyle.component';
import AddButtonStyleModal from './modals/addButtonStyle.modal';

import TextStyle from './textStyle.component';
import AddTextStyleModal from './modals/addTextStyle.modal';

import FinderModal from './modals/finder.modal';
import TextInput from '../../components/textInput.component';

class LibraryEditorComponent extends ModelEditorComponent {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			parentID: 0,
			parentTree: [0],
			sortBy: 'recent',
			searchTerm: '',
			addLibraryItemModalOpen: false,
			folderSettingsOpen: false,
			addLibraryFolderModalOpen: false,
			addButtonStyleModalOpen: false,
			addTextStyleModalOpen: false,
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevState.parentID != this.state.parentID) {
			if (this.props.onPathChange) {
				const path = this.state.parentTree.map((id) => id == 0 ? 'LIBRARY' : this.getModelPropValue('name', 'LibraryFolder', id)).join(' / ');
				this.props.onPathChange(this.state.parentID, path);
			}
		}
	}
	renderContent() {
		const filterBy = this.props.filterBy || false;
		const isSelector = this.props.isSelector || false;
		const onSelected = this.props.onSelected || (()=>{});
		const selectedID = this.props.selected || false;

		const parentID = this.state.parentID;
		const searchTerm = this.state.searchTerm;
		const searchTermLC = searchTerm.toLocaleLowerCase();

		const filter = (data) => {
			const model = data.model.data;
			if (searchTerm) {
				if (
					(model.name?.value && model.name.value.toLowerCase().indexOf(searchTermLC) >= 0) ||
					(model.description?.value && model.description.value.toLowerCase().indexOf(searchTermLC) >= 0)
				) return true;
				return false;
			}
			const itemParentID = model.parentID?.value || 0;
			return (itemParentID == parentID);
		};

		const modelsByName = {};
		if (filterBy == 'image' || !filterBy) {
			modelsByName.LibraryItem = this.getModelList('LibraryItem', filter);
		}
		if (filterBy == 'button' || !filterBy) {
			modelsByName.ButtonStyle = this.getModelList('ButtonStyle', filter);
		}
		if (filterBy == 'text' || !filterBy) {
			modelsByName.TextStyle = this.getModelList('TextStyle', filter);
		}

		const folders = this.getModelList('LibraryFolder', (data) => filter(data) && this.props.removeFolderFromSearch != data.model.data.id.value);
		folders.forEach((folder) => { folder.modelName = 'LibraryFolder' });

		let models = [];
		for (const modelName in modelsByName) {
			const modelList = modelsByName[modelName];
			models.push(...modelList.map((data) => {
				data.modelName = modelName;
				return data;
			}));
		}

		const sortBy = this.state.sortBy;
		if (sortBy == 'name') {
			const nameSort = (a, b) => {
				if ( a.name.value < b.name.value ) return -1;
				if ( a.name.value > b.name.value ) return 1;
				return 0;
			};
			folders.sort(nameSort)
			models.sort(nameSort);
		} else if (sortBy == 'recent') {
			const recentSort = (a, b) => {
				const aDate = a.dateUpdated.value || a.dateCreated.value;
				const bDate = b.dateUpdated.value || b.dateCreated.value;
				const aTime = (new Date(aDate)).getTime();
				const bTime = (new Date(bDate)).getTime();
				if ( aTime > bTime ) return -1;
				if ( aTime < bTime ) return 1;
				return 0;
			}
			folders.sort(recentSort)
			models.sort(recentSort);
		}

		const folderPathView = this.state.parentTree.map((id) => {
			const isLast = (id == this.state.parentTree[this.state.parentTree.length-1]);
			const isRoot = (id == 0);
			const fontSize = isSelector ? 15 : 20;
			const name = isRoot ? searchTerm ? 'Search ('+searchTerm+')' : 'LIBRARY' : this.getModelPropValue('name', 'LibraryFolder', id);

			return <View style={{flexDirection: 'row', alignItems: 'center'}} key={id}>
				{isLast ? <Text style={{fontWeight: 600, fontSize}}>{name}{isRoot && " /"}</Text>
				: <Text style={{fontWeight: 600, fontSize, textDecorationLine: 'underline'}} onPress={() => {
					const newParentTree = [];
					for (let i = 0; i < this.state.parentTree.length; i++) {
						const treeID = this.state.parentTree[i];
						newParentTree.push(treeID);
						if (treeID == id) break;
					}
					this.updateState({parentID: id, parentTree: newParentTree, searchTerm: ''});
				}}>{name}</Text>}
				{!isLast && <Text style={{fontWeight: 600, fontSize}}> / </Text>}
			</View>;
		})
		
		const itemWidth = isSelector ? 100 : 200;
		const itemHeight = isSelector ? 130 : 230;
		console.log(this.state);

		const context = this;

		return (<View style={[{flexDirection: 'column', flex: 1}, isSelector ? {maxHeight: 400, minHeight: 220} : {}]}>
			<AddLibraryFolderModal
				parentID={parentID}
				open={this.state.addLibraryFolderModalOpen}
				closeModal={() => this.updateState({ addLibraryFolderModalOpen: false })}
			/>
			<FolderSettingsModal
				id={parentID}
				open={this.state.folderSettingsOpen}
				closeModal={(deleted) => {
					if (deleted) {
						this.state.parentTree.pop();
						const newID = this.state.parentTree[this.state.parentTree.length-1]
						this.updateState({ folderSettingsOpen: false, parentID: newID, parentTree: this.state.parentTree});
					} else {
						this.updateState({ folderSettingsOpen: false })
					}
				}}
			/>
			{!isSelector && <>
				<AddLibraryItemModal
					parentID={parentID}
					open={this.state.addLibraryItemModalOpen}
					closeModal={() => this.updateState({ addLibraryItemModalOpen: false })}
				/>
				<AddButtonStyleModal
					parentID={parentID}
					open={this.state.addButtonStyleModalOpen}
					closeModal={() => this.updateState({ addButtonStyleModalOpen: false })}
				/>
				<AddTextStyleModal
					parentID={parentID}
					open={this.state.addTextStyleModalOpen}
					closeModal={() => this.updateState({ addTextStyleModalOpen: false })}
				/>
				<FinderModal filterBy={'folder'} 
					open={this.state.moveItemModalOpen}
					moveItemName={this.state.moveItemName}
					removeFolderFromSearch={ this.state.moveItemType == 'LibraryFolder' ? this.state.moveItemID : 0}
					onFound={(folderID) => {
						console.log(folderID, this.state.moveItemType, this.state.moveItemID);
						this.resetIndividualModel(this.state.moveItemType, this.state.moveItemID, () => {
							this.setModelField('parentID', folderID, this.state.moveItemType, this.state.moveItemID)
							.then(() => this.saveModel(false, null, this.state.moveItemType, this.state.moveItemID))
							.then(() => this.updateState({ moveItemModalOpen: false }))
							.catch(console.error)
						});
					}}
					closeModal={() => this.updateState({ moveItemModalOpen: false })}
				/>
			</>
			}

			{this.props.isLibraryScreen && 
				<View style={{flexDirection: 'row', height: 52, borderBottom: '2px solid #8a745a'}}>
					<Text style={{color: '#333333', fontSize: 20, fontWeight: 400, paddingLeft: 17, paddingTop: 10, paddingBottom: 15}}>{folderPathView}</Text>
					{!isSelector && <View style={{flex: 1, flexDirection: 'row-reverse', alignItems: 'center'}}>
						{parentID != 0 && <LinkButton title="Folder Settings" style={{marginHorizontal: 20}} textStyle={{fontSize: 15}} onPress={() => this.updateState({folderSettingsOpen: true})} />}
					</View>}
				</View>
			}
			<View style={[isSelector ? {maxHeight: 400} : {flex: 1, minWidth: 875}, this.props.style, {minHeight: this.props.style?.minHeight || 200, flexDirection: 'column'}]}>
				<View style={{flexDirection: 'column', width: '100%'}}>
					<View style={[isSelector ? {height: 30} : {height: 40}, {flexDirection: 'row', width: '100%', borderRadius: isSelector ? 5 : 0, backgroundColor: '#DDD', alignItems: 'center'}]}>
						<View style={{flex: 1, flexDirection: 'row'}}>
							{((!isSelector) || this.props.showAddFolderOption) && <IconButton icon={faFolderPlus} title="Add Folder" sideText="Add Folder" color={siteBlue} size={20} margin={0} style={{ paddingHorizontal: 10, justifyContent: 'center', alignItems: 'center' }} onPress={() => this.updateState({addLibraryFolderModalOpen: true})} />}
							{!isSelector && <>
								<IconButton icon={faImage} title="Add Image" sideText="Add Image" color={siteBlue} size={20} margin={0} style={{ paddingHorizontal: 10, justifyContent: 'center', alignItems: 'center' }} onPress={() => this.updateState({addLibraryItemModalOpen: true})} />
								<IconButton icon={faCaretSquareRight} title="Add Button Style" sideText="Add Button Style" color={siteBlue} size={20} margin={0} style={{ paddingHorizontal: 10, justifyContent: 'center', alignItems: 'center' }} onPress={() => this.updateState({addButtonStyleModalOpen: true})} />
								<IconButton icon={faParagraph} title="Add Text Style" sideText="Add Text Style" color={siteBlue} size={20} margin={0} style={{ paddingHorizontal: 10, justifyContent: 'center', alignItems: 'center' }} onPress={() => this.updateState({addTextStyleModalOpen: true})} />
							</>}
						</View>
						<View style={{flex: 1, flexDirection: 'row-reverse', alignItems: 'center'}}>
							<TextInput value={searchTerm} placeholder="Search" showLabel={false} inputStyle={{height: 30, marginTop: -1, paddingTop: 8}} style={{width: 200, height: 28, fontSize: 15, borderRadius: 5, backgroundColor: '#FFF', marginRight: isSelector ? 0 : 5}} onChangeText={(val) => this.updateState({searchTerm: val})} />
							
							<View style={{alignItems: 'end', marginTop: 8, marginRight: 5}} >
								{this.renderDropdown({ label: '', smallStyle: true, containerStyle: {margin: 0, marginTop: isSelector? -1 : 0, marginBottom: 0}, defaultValue: sortBy, onChange: (val) => this.updateState({sortBy: val}), options: [
									{label: 'Sort By Name', value: 'name'},
									{label: 'Sort By Recent', value: 'recent'}
								]})}
							</View>
						</View>
					</View>
					{isSelector && <View style={{flexDirection: 'row', marginTop: 10, marginLeft: 10}}>{folderPathView}</View>}
				</View>

				<View style={{flex: 1}}>
					<ScrollView style={{height: '100%', overflowY: 'scroll'}}>
						<View style={{ flexDirection: 'row', gap: 20, flexWrap: 'wrap', padding: 20 }}>								
							{[
								...folders, 
								this.props.injectorItem ? {modelName: 'injector', view: this.props.injectorItem} : null,
								...models
							].map((data, idx) => {
								if (!data) return null;
								const isInjector = (data.modelName == 'injector');
								if (isInjector) data.id = {value: 'injector_'+idx};
								const isFolder = (data.modelName == 'LibraryFolder');
								const key = data.modelName + data.id.value;
								const settingsKey = 'showSettingsMenu'+key
								const settingsOpen = this.state[settingsKey];

								const fileTypeIcon = isFolder? null : (modelName) => {
									const extraStyle = {marginLeft: 9, marginTop: 6};
									if (modelName == 'LibraryItem') extraStyle.marginTop = 1;
									if (modelName == 'TextStyle') extraStyle.marginLeft = 5;

									return <FontAwesomeIcon
										icon={(() => {
											if (modelName == 'LibraryItem') return faImage;
											if (modelName == 'ButtonStyle') return faCaretSquareRight;
											if (modelName == 'TextStyle') return faParagraph;
											return faImage;
										})()}
										style={{
											width: '20%',
											height: '20%',
											color: 'rgba(100,100,100,0.5)',
											position: 'absolute',
											zIndex: 10,
											left: 0,
											top: 0,
											pointerEvents: 'none',
											...extraStyle
										}}
									/>
								};

								const numFiles = isFolder? (() => {
									const filter = (_data) => {
										const itemParentID = _data.model.data.parentID?.value || 0;
										if (itemParentID && itemParentID === data.id.value) return true;
									}
									let len = 0;
									if (!filterBy || filterBy == 'image' || filterBy == 'folder') len += this.getModelList('LibraryItem', filter).length;
									if (!filterBy || filterBy == 'button' || filterBy == 'folder') len += this.getModelList('ButtonStyle', filter).length;
									if (!filterBy || filterBy == 'text' || filterBy == 'folder') len += this.getModelList('TextStyle', filter).length;
									len += this.getModelList('LibraryFolder', filter).length;
									
									return len;
								})() : 0;


								return <View key={key} style={{flexDirection: 'column', width: itemWidth, height: itemHeight, borderRadius: 5, backgroundColor: isFolder? 'rgba(0,0,0,0)' : '#DDD', justifyContent: 'center', alignItems: 'center'}}>
									{!isFolder && !isInjector && !isSelector && fileTypeIcon(data.modelName)}

									{isInjector && data.view}
									{isFolder && <>
										<IconButton icon={faFolderOpen} color={siteBlue} height={isSelector ? 100 : 200} width='100%' margin={0} style={{ paddingHorizontal: '10%', justifyContent: 'center', alignItems: 'center' }} onPress={() => {
											const parentTree = [data.id.value];
											const checkFolderForParent = (parentID) => {
												if (!parentID) {
													parentTree.unshift(0);
												} else {
													parentTree.unshift(parentID);
													const parent = this.getModelList('LibraryFolder', (data) => {
														return data.model.data.id.value == parentID
													});
													const newParentID = parent[0].parentID?.value || 0;
													checkFolderForParent(newParentID);
												}
											}
											checkFolderForParent(data.parentID.value);
											console.log(parentTree);
										
											this.updateState({parentID: data.id.value, parentTree, searchTerm: ''});
										}} />
										<Text style={[{textAlign: "center", fontWeight: 400, padding: 5}, isSelector? {} : {paddingLeft: 35, paddingRight: 35}]}>{data.name.value} <Text style={{fontWeight: 800 }}>({numFiles})</Text></Text>

									</>}
									{data.modelName == 'LibraryItem' && <LibraryItem id={data.id.value} isEditor={!isSelector} 
										onSettingsClosed={()=>this.updateState({[settingsKey+'openSettings']: false, [settingsKey]: false})} 
										openSettings={this.state[settingsKey+'openSettings']} 
										isSelected={(selectedID == data.id.value)} 
										onSelected={onSelected} 
									/>}
									{data.modelName == 'ButtonStyle' && <ButtonStyle id={data.id.value} compact={true} isSelector={isSelector} 
										onSettingsClosed={()=>this.updateState({[settingsKey+'openSettings']: false, [settingsKey]: false})} 
										openSettings={this.state[settingsKey+'openSettings']} 
										isEditor={!isSelector} 
										isSelected={(selectedID == data.id.value)} 
										onSelected={onSelected} 
									/>}									
									{data.modelName == 'TextStyle' && <TextStyle id={data.id.value} compact={true} isSelector={isSelector} 
										onSettingsClosed={()=>this.updateState({[settingsKey+'openSettings']: false, [settingsKey]: false})} 
										openSettings={this.state[settingsKey+'openSettings']} 
										isEditor={!isSelector} 
										isSelected={(selectedID == data.id.value)} 
										onSelected={onSelected} 
									/>}

									{!isSelector && !isInjector && <View style={{position: 'absolute', right: 5, bottom: 0, zIndex: 10}}>
										{isFolder && <FolderSettingsModal
											id={data.id.value}
											open={this.state[settingsKey+'openSettings']}
											closeModal={(deleted) => {
												this.updateState({ [settingsKey+'openSettings']: false, [settingsKey]: false })
											}}
										/>}
										<Menu visible={settingsOpen} onDismiss={()=>this.updateState({[settingsKey]: false})} anchor={
											<IconButton icon={faEllipsisH} title="Options" color={'rgba(100,100,100,1)'} size={20} margin={0} style={{padding: 5}} onPress={() => this.updateState({[settingsKey]: true})} />
										}>
											<Menu.Item title={isFolder? "Rename" : "Edit"} onPress={()=>this.updateState({[settingsKey+'openSettings']: true, [settingsKey]: false})} />
											<Menu.Item title="Move" onPress={() => {
												this.updateState({ [settingsKey]: false, moveItemModalOpen: true, moveItemName: data.name.value, moveItemType: data.modelName, moveItemID: data.id.value });
											}} />
											<Divider />
											<Menu.Item title="Delete" onPress={() => {
												this.updateState({ [settingsKey]: false });
												if (isFolder) {
													this.showConfirmDialogue("Delete "+data.name.value+"?", "Are you sure you want to delete this Library Folder?\n\nThis action will also delete all Images, Button Styles and Text Styles within it.\nThis action cannot be undone.")
													.then(() => {
														const modelDeletions = [{
															model: 'LibraryFolder',
															id: data.id.value,
															children: []
														}];
							
														const context = this;
							
														function searchFolderForDeletions(folderID) {
															const filter = (data) => {
																const itemParentID = data.model.data.parentID?.value || 0;
																if (itemParentID && itemParentID === folderID) return true;
															}
															
															['LibraryFolder', 'LibraryItem', 'TextStyle', 'ButtonStyle'].forEach(itemName => {
																context.getModelList(itemName, filter).forEach(item => {
																	modelDeletions.push({
																		model: itemName,
																		id: item.id.value,
																		children: []
																	});
																	if (itemName == 'LibraryFolder') {
																		searchFolderForDeletions(item.id.value);
																	}
																});
															});
														}
														searchFolderForDeletions(data.id.value);
							
														const promises = [];
														modelDeletions.forEach(deletion => { promises.push(context.deleteModel(deletion)) });
														return Promise.all(promises);
													}).catch(console.error);
													return;
												}

												this.showConfirmDialogue("Delete "+data.name.value+"?", "Are you sure you want to delete this "+this.camelCaseToLabel(data.modelName)+"?")
												.then(() => this.deleteModel({
													model: data.modelName,
													id: data.id.value,
													children: []
												}))
												.catch(console.error)
											}} />
										</Menu>
									</View>}
								</View>
							})}							
						</View>
					</ScrollView>
				</View>
			</View>
		</View>);
	}
}
export default LibraryEditorComponent = connect({
	component: LibraryEditorComponent,
	models: [
		{
			model: 'LibraryItem',
			/*
			keyField: 'siteID', 
			useKey: true, 
			key: (r, props) => props.siteID,
			*/
			list: true,
		},
		{
			model: 'LibraryFolder',
			list: true,
			loadChildren: true
		},
		{ model: 'ButtonStyle', list: true, loadChildren: true },
		{ model: 'TextStyle', list: true },
	]
});

