
const BLE_SERVICE_UUID = 'ee99bbab-f964-40ce-815c-96a32197f980';
const BLE_CHARACTERISTIC_WRITE_UUID = '3aba5642-f096-4326-9486-169779f46020';
const BLE_CHARACTERISTIC_COMMAND_RETURN_UUID = '977deab1-44db-4246-af37-0532f06c4e70';
const BLE_CHARACTERISTIC_READ_UUID = '0eb55f1c-a18b-41b5-a00f-2a8a8235a6e2';

let device = null;
let server = null;
let service = null;
let writeCharacteristic = null;
let commandReturnCharacteristic = null;
let readCharacteristic = null;

let connectedDeviceID = null;

let sending = false;

let onReceivedCB = function () { };

let status = { connected: false };
let commandReturn = [];
let lastStatusText = "";
let onStatusChanges = [];
let onDisconnects = [];
let onReconnectings = [];
let onReconnects = [];
let reconnecting = false;
let onReconnection = function () { };
let onReconnectionCancel = function () { };
function updateStatus(value, isCommandReturn) {
	if (value) {
		const statusText = new TextDecoder().decode(value.buffer);
		if (isCommandReturn) {
			status.ssids = JSON.parse(statusText);
			commandReturn = status.ssids;
			return true;
		}
		else if (statusText != lastStatusText) {
			lastStatusText = statusText;
			status = JSON.parse(statusText);
			status.connected = true;
			if (!'ssids' in status) status.ssids = commandReturn;
			return true;
		}
	}
	return false;
}

function connect(deviceShortID) {
	if (!status.connected) {
		device = null;
		server = null;
		service = null;
		writeCharacteristic = null;
		commandReturnCharacteristic = null;
		readCharacteristic = null;

		sending = false;

		onReceivedCB = function () { };

		status = { connected: false };
		commandReturn = [];
		lastStatusText = "";

		function finishConnect() {
			console.log("has device");
			return new Promise(function (resolve, reject) {
				// connect to device
				device.gatt.connect()
					.then(_server => {
						server = _server;
						console.log("device connected");
						return server.getPrimaryService(BLE_SERVICE_UUID);
					}).then(_service => {
						service = _service;

						service.getCharacteristic(BLE_CHARACTERISTIC_WRITE_UUID).then(_writeCharacteristic => {
							writeCharacteristic = _writeCharacteristic;
							console.log("got write characteristic");
							
							writeCharacteristic.readValue().then(value => {
								connectedDeviceID = deviceShortID;
								updateStatus(value);
								console.log("got status value");
								console.log(status);
								resolve();
							}).catch(err => {
								console.log("could not get status value");
								console.error(err);
							});

							return writeCharacteristic.startNotifications().then(_ => {
								writeCharacteristic.oncharacteristicvaluechanged = function (event) {
									if (updateStatus(event.target.value)) {
										onStatusChanges.forEach(function (cb) { cb(status); });
									}
								};
								console.log("started write characteristic notifications");
							});
						}).catch(err => {
							console.log("could not setup write characteristic");
							console.error(err);
						});

						service.getCharacteristic(BLE_CHARACTERISTIC_COMMAND_RETURN_UUID).then(_commandReturnCharacteristic => {
							commandReturnCharacteristic = _commandReturnCharacteristic;
							console.log("got command return characteristic");
							
							/*commandReturnCharacteristic.readValue().then(value => {
								updateStatus(value, true);
								console.log("got command return value");
								console.log(commandReturn);
							}).catch(err => {
								console.log("could not get command return value");
								console.error(err);
							});*/

							return commandReturnCharacteristic.startNotifications().then(_ => {
								commandReturnCharacteristic.oncharacteristicvaluechanged = function (event) {
									if (updateStatus(event.target.value, true)) {
										onStatusChanges.forEach(function (cb) { cb(status); });
									}
								}
								console.log("started command return characteristic notifications");
							});
						}).catch(err => {
							console.log("could not setup command return characteristic");
							console.error(err);
						});
						
						service.getCharacteristic(BLE_CHARACTERISTIC_READ_UUID).then(_readCharacteristic => {
							readCharacteristic = _readCharacteristic;
							console.log("got read characteristic");

							return readCharacteristic.startNotifications().then(_ => {
								readCharacteristic.oncharacteristicvaluechanged = function (event) {
									let value = new TextDecoder().decode(event.target.value.buffer);
									if (value == "RECEIVED") {
										onReceivedCB();
										onReceivedCB = function () { };
									}
								}
								console.log("started read characteristic notifications");
							})
						}).catch(err => {
							console.log("could not setup read characteristic");
							console.error(err);
						});
					})
					.catch(reject);
			});
		}

		const filter = {
			services: [BLE_SERVICE_UUID]//,
			//name: 'Airshroud',
		}
		//if (deviceShortID) filter.name += ': '+deviceShortID;

		return navigator.bluetooth.requestDevice({
			filters: [filter]
		})
			.then(_device => {
				device = _device;
				device.ongattserverdisconnected = function onDisconnectedEventCB(event) {
					function finishDisconnect(error) {
						if (error) console.error(error);
						reconnecting = false;
						onReconnectionCancel(error);
						onReconnection = function () { };
						onReconnectionCancel = function () { };
						status.connected = false;
						onDisconnects.forEach(function (cb) { cb(status.connected); });
						status = { connected: false };
						connectedDeviceID = null;
						console.log('finishDisconnect', reconnecting);
					}
					if (!status.connected) return finishDisconnect();
					onReconnectings.forEach(function (cb) { cb(status.connected); });
					// try to reconnect
					reconnecting = true;
					console.log('disconnect', reconnecting);
					finishConnect().then(() => {
						reconnecting = false;
						onReconnection();
						onReconnection = function () { };
						onReconnectionCancel = function () { };
						console.log('finishConnect', reconnecting);
						if (!status.connected) return finishDisconnect();
						else onReconnects.forEach(function (cb) { cb(status.connected); });
					}).catch(finishDisconnect);
				}
				return finishConnect();
			});
	}
	return Promise.resolve();
}

function sendMessage(message) {
	if (sending) {
		return Promise.reject(new Error('Send still in progress'));
	}
	sending = true;
	console.log('send', reconnecting)
	if (reconnecting) {
		return new Promise((resolve, reject) => {
			onReconnection = () => {
				reconnecting = false;
				onReconnection = function () { };
				onReconnectionCancel = function () { };
				sending = false;
				sendMessage(message).then(resolve).catch(reject);
			}
			onReconnectionCancel = (error) => {
				reconnecting = false;
				sending = false;
				onReconnection = function () { };
				onReconnectionCancel = function () { };
				reject(error);
			}
		});
	}
	if (!status.connected) {
		sending = false;
		return Promise.reject(new Error('BLE not connected'));
	}

	return new Promise(function (resolve, reject) {
		let buffer = new ArrayBuffer(message.length);
		let bufView = new Uint8Array(buffer);
		for (let i = 0, strLen = message.length; i < strLen; i++) {
			bufView[i] = message.charCodeAt(i);
		}

		const timeoutError = setTimeout(function () {
			sending = false;
			//startSendingKeepAliveMessages();
			reject(new Error("Command acknowledgement from BLE device timeout"));
		}, 20000);

		onReceivedCB = function () {
			clearTimeout(timeoutError);
			sending = false;
			//startSendingKeepAliveMessages();
			resolve();
		}

		readCharacteristic.writeValue(buffer).catch(function (err) {
			clearTimeout(timeoutError);
			sending = false;
			//startSendingKeepAliveMessages();
			console.error(err);
			reject(err);
		});
	});
}

let bleAvailable = false;

if ('bluetooth' in navigator) {
	navigator.bluetooth.getAvailability()
		.then(isBluetoothAvailable => {
			bleAvailable = isBluetoothAvailable;
		});

	if ('onavailabilitychanged' in navigator.bluetooth) {
		navigator.bluetooth.addEventListener('availabilitychanged', (event) => {
			bleAvailable = event.value;
		});
	}
}

class AirshroudBridgeAPI {
	constructor() { }

	connect = connect;
	disconnect = () => {
		if (!status.connected) return;
		status.connected = false;
		device.gatt.disconnect();
	};
	connected = () => {
		if (!device) return false;
		return device.gatt.connected;
	}
	status = () => status;
	onStatusChange = (onChange) => onStatusChanges.push(onChange);
	clearEvents = () => {
		onStatusChanges = [];
		onDisconnects = [];
		onReconnects = [];
		onReconnectings = [];
	}
	_sendMessage = sendMessage;
	reveal = (slotNumber, duration) => sendMessage("reveal:" + slotNumber + "-" + duration);
	cover = (slotNumber, duration) => sendMessage("cover:" + slotNumber + "-" + duration);
	clearSlot = (slotNumber) => sendMessage("removeSlot:" + slotNumber);
	clearAllSlots = () => sendMessage("CLEAR_SLOTS");
	setWifi = (ssid, password) => sendMessage("SET_WIFI:" + ssid + ":" + password);
	updateSettings = (settings) => sendMessage("SET_SETTINGS:" + settings.join(':'));
	clearWifi = () => sendMessage("CLEAR_WIFI");
	fullReset = () => sendMessage("FULL_RESET");
	getSSIDs = () => sendMessage("GET_SSIDS");
	scan = (slotNumber) => sendMessage("scan:" + slotNumber);
	onDisconnect = (onDisconnect) => onDisconnects.push(onDisconnect);
	onReconnect = (onReconnect) => onReconnects.push(onReconnect);
	onReconnecting = (onReconnecting) => onReconnectings.push(onReconnecting);
	isAvailable = () => bleAvailable;
	deviceID = () => connectedDeviceID;
}

export default new AirshroudBridgeAPI();


/* ---- How To Use ----

Include this script in your web page and use the code below to interact with the AirShroud BLE device.


// Create the API instance to use in your code:
import airshroudBridge from 'ble.api';

// Check if the device is connected
console.log(airshroudBridge.connected());

// Connect to the bluetooth device
airshroudBridge.connect()
.then(function () {
  // Check the status
  console.log(airshroudBridge.status());

  // Add a device disconnect event listener
  airshroudBridge.onDisconnect(function (connected) {
	console.log("connected: "+connected);
  });

  // Add a status change event listener
  const scanResultsErrors = ["None", "Added successfully", "Timeout reached", "Slot alread exists", "All slots are full", "RF Code error"];
  airshroudBridge.onStatusChange(function (status) {
	console.log(status);
	if (status.scan != "0:0:0") { // scan code results
	  const scanResults = status.scan.split(":");
	  const scanError = scanResults[0];
	  const slotNumber = scanResults[1];
	  const rfCode = scanResults[2];
	  
	  console.log(scanResultsErrors[scanError]+" | slot: "+slotNumber+" | code: "+rfCode);
	}
  });

  // Activate the Airshroud Reveal for a specified slot and duration
  airshroudBridge.reveal(1, 20)
  .then(function () {
	console.log("sent reveal command to slot 1 for 20 seconds");
	setTimeout(coverFN, 21000); // wait for it to finish
  })
  .catch(console.error);

  function coverFN() {
	// Activate the Airshroud Cover for a specific slot
	airshroudBridge.cover(1, 20)
	.then(function () {
	  console.log("sent cover command to slot 1 for 20 seconds");
	  setTimeout(disconnectFN, 21000); // wait for it to finish
	})
	.catch(console.error);
  }

  function disconnectFN() {
	// Disconnect from the device
	airshroudBridge.disconnect();
  }

})
.catch(console.error);

*/
