import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Platform } from 'react-native';
import { Button } from 'react-native-paper';

export default class LinkButton extends React.Component {
  render() {
    const adjustment = (Platform.OS === 'ios') ? {transform: [{translateY : 3}]} : {};
    return (
      <View style={[adjustment, {flexDirection: 'row'}, this.props.style]}>
        <Text onPress={this.props.onPress} style={[{color: this.props.color || '#8a745a', textDecorationLine: 'underline'}, this.props.textStyle]}>{this.props.title}</Text>
        {/* <Button mode="text" onPress={this.props.onPress} {...this.props.buttonProps}>{this.props.title}</Button> */}
		<View style={{flex: 1}} />
      </View>
    );
  }
}
