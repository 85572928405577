import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { styles } from '../../main.styles';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faTimesCircle, faCheckCircle, faWifi, faUndo, faRedo } from '@fortawesome/free-solid-svg-icons'
import { faUsb, faBluetooth } from '@fortawesome/free-brands-svg-icons'

import { ModelEditorComponent, ModelComponent, connect } from '../ModelComponent';

import PageView from '../../components/views/page.view.component'
import Button from '../../components/button.component';
import Slider from '../../components/slider.component';
import IconButton from '../../components/iconButton.component';
import OutlineButton from '../../components/outlineButton.component';

import TemplatePagePreview from '../../components/templatePagePreview.component';

class EditorWindowInner extends ModelComponent {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			previewSize: { width: 0, height: 0 },
			tempPositionDataID: null,
			tempPositionData: null,
			selectedComponent: null,
		};
		if (this.props.refreshCall) this.props.refreshCall.fn = () => {
			//this.resetModel(() => this.loadModels(true));
			//this.loadModels(true)//.then(()=> this.resetModel());
			this.setState({ tempPositionDataID: null, tempPositionData: null });
		}
	}
	componentDidUpdate(prevProps, prevState) {
		super.componentDidUpdate(prevProps, prevState);
		if (this.props.currentPageID && prevProps.currentPageID != this.props.currentPageID) {
			this.loadModels(true)//.then(()=> this.resetModel());
			this.setState({ tempPositionDataID: null, tempPositionData: null, selectedComponent: null });
		}
	}
	updateTemplateComponent(updatedComponent, updateType) {
		console.log("UPDATING", updatedComponent.positionData.id, this.state.tempPositionDataID)
		if (this.state.tempPositionDataID) return;
		this.updateState({ tempPositionDataID: updatedComponent.positionData.id, tempPositionData: updatedComponent.positionData });
		this.props.onUpdate(updatedComponent, updateType)
			.then(() => this.updateState({ tempPositionDataID: null, tempPositionData: null }))
			.catch((error) => this.updateState({ error, tempPositionDataID: null, tempPositionData: null }));
	}
	renderContent() {
		const props = this.props;
		const { id, kioskID, currentPageID, onChange } = props;
		const getKioskValue = this.modelValueGetter('Kiosk', kioskID);
		//console.log(getKioskValue('name'));
		const getTemplateValue = this.modelValueGetter('KioskTemplate', id);

		const getPageValue = this.modelValueGetter('TemplatePage', currentPageID);
		const isModal = this.props.editingModal;
		const getModalPositionValue = isModal ? this.modelValueGetter('TemplatePositionData', getPageValue('templatePositionDataID')) : null;

		const screenResolutionX = isModal ? getModalPositionValue('width') : getTemplateValue('screenResolutionX');
		const screenResolutionY = isModal ? getModalPositionValue('height') : getTemplateValue('screenResolutionY');
		const previewDataGroupID = getTemplateValue('previewDataGroupID');
		const previewDataSetID = getTemplateValue('previewDataSetID');

		const createComponent = (component) => {
			if (this.props.hiddenComponents.includes(component.id.value)) return { type: 'hidden' };

			const newComponent = {};

			for (const fieldName in component) newComponent[fieldName] = component[fieldName].value;

			if (this.state.tempPositionDataID && this.state.tempPositionDataID == newComponent.templatePositionDataID) {
				newComponent.positionData = this.state.tempPositionData;
			} else newComponent.positionData = this.getModelData('TemplatePositionData', newComponent.templatePositionDataID)?.data;

			if (newComponent.type == 'modal') {
				const modalComponents = this.getModelList('TemplateComponent', data => data.model.data.templatePageID.value == newComponent.id).map(component => {
					return createComponent(component);
				});
				newComponent.components = {
					buttons: modalComponents.filter(component => component.type === 'button'),
					images: modalComponents.filter(component => component.type === 'image'),
					playlists: modalComponents.filter(component => component.type === 'playlist'),
					texts: modalComponents.filter(component => component.type === 'text'),
					countdowns: modalComponents.filter(component => component.type === 'countdown'),
				}
			} else {
				const typedComponent = this.getModelData('TemplateComponent' + newComponent.type.substring(0, 1).toUpperCase() + newComponent.type.substring(1), newComponent.componentID);
				newComponent.component = typedComponent?.data;
				if (newComponent.component) switch (newComponent.type) {
					case 'playlist':
						const playlist = this.getModelData('SignagePlaylist', typedComponent?.data?.signagePlaylistID)?.data;
						newComponent.component.signagePlaylist = playlist;

						const playlistItems = this.getModelList('SignagePlaylistItem', (modelData) => typedComponent?.data?.signagePlaylistID == modelData.model.data.playlistID.value);
						playlistItems.sort((a, b) => a.playlistIndex.value - b.playlistIndex.value);
						newComponent.component.playlistItems = playlistItems;
						break;
					case 'button':
						const buttonStyle = this.getModelData('ButtonStyle', typedComponent?.data?.buttonStyleID)?.data;
						newComponent.component.buttonStyle = buttonStyle
						if (buttonStyle) {
							if (newComponent.positionData && !buttonStyle.resizable) {
								newComponent.positionData.width = buttonStyle.width;
								newComponent.positionData.height = buttonStyle.height;
							}
							if (buttonStyle?.textStyleID) buttonStyle.textStyle = this.getModelData('TextStyle', buttonStyle.textStyleID)?.data;
						}
						break;
					case 'text':
						newComponent.component.textStyle = this.getModelData('TextStyle', typedComponent?.data?.textStyleID)?.data;
						//console.log(id, previewDataGroupID, previewDataSetID)
						if (newComponent.component.isLinked) {
							if (kioskID) {
								if (this.props.kioskDataSet) newComponent.component.previewDataSet = this.props.kioskDataSet;
								else {
									const customerName = getKioskValue('customerNameOverride');
									const customerMessage = getKioskValue('customerMessageOverride');
									newComponent.component.previewDataSet = { customerName, customerMessage };
								}
							}
							else if (previewDataGroupID && previewDataSetID) {
								const previewData = this.getModelValues(['customerName', 'customerMessage'], 'DataSet', previewDataSetID);
								newComponent.component.previewDataSet = previewData;
							}
							//newComponent.component.previewDataSetOverride = previewDataSetOverride;
						}
						break;
				}
			}
			return newComponent;
		}

		const modelComponents = this.getModelList('TemplateComponent', data => data.model.data.templatePageID.value == currentPageID).map(component => {
			return createComponent(component);
		});

		const modals = this.getModelList('TemplatePage', data => data.model.data.type.value == 'modal' && data.model.data.templatePageID.value == currentPageID).map(component => {
			return createComponent(component);
		});

		const components = {
			buttons: modelComponents.filter(component => component.type === 'button'),
			images: modelComponents.filter(component => component.type === 'image'),
			texts: modelComponents.filter(component => component.type === 'text'),
			playlists: modelComponents.filter(component => component.type === 'playlist'),
			countdowns: modelComponents.filter(component => component.type === 'countdown'),
			modals,
		}

		//console.log(modals);

		const previewWidth = this.state.previewSize.width;

		//console.log(components, this.props);

		const { showGrid, snapToGrid, gridSize } = getTemplateValue(['showGrid', 'snapToGrid', 'gridSize']);

		const pageActions = props.actions[currentPageID];
		//console.log(pageActions);
		const hasHistory = pageActions?.actionHistory.length > 0;
		const hasFuture = pageActions?.actionFuture.length > 0;
		const historyAction = hasHistory ? pageActions.actionHistory[pageActions.actionHistory.length-1] : null;
		const futureAction = hasFuture ? pageActions.actionFuture[pageActions.actionFuture.length-1] : null;

		const selectedComponent = this.state.selectedComponent;

		return (
			<View style={{ flex: 1, backgroundColor: '#AAA' }}>
				<View style={{position: 'absolute', left: 0, top: 0, right: 0, width: '100%', zIndex: 1000, padding: 5, backgroundColor: '#555', flexDirection: 'row', gap: 10}}>
					{hasHistory ? <IconButton icon={faUndo} color="#222" title={"Undo "+this.ucFirst(historyAction.updateType.name)+" "} onPress={() => props.onUndo()} /> : <IconButton icon={faUndo} color="#999" />}
					{hasFuture ? <IconButton icon={faRedo} color="#222" title={"Redo "+this.ucFirst(futureAction.updateType.name)+" "} onPress={() => props.onRedo()} /> : <IconButton icon={faRedo} color="#999" />}
					{selectedComponent && <>
						<Text style={{color:'#AAA'}}> </Text>
						<Text style={{color:'#CCC'}}>{selectedComponent.name} ({this.ucFirst(selectedComponent.type)}):</Text>
						{selectedComponent.type != 'modal' && <>
							{selectedComponent.positionData.z + 1 <= 100 && <>
								<TouchableOpacity onPress={() => { const newZ = selectedComponent.positionData.z + 1; if (newZ <= 100) selectedComponent.positionData.z = newZ; this.updateTemplateComponent(selectedComponent, {name: 'Move Forward', addToHistory: true}); }} >
									<Text style={{color:'#EEE'}}>Move Forward</Text>
								</TouchableOpacity>
								<Text style={{color:'#AAA'}}>|</Text>
							</>}
							{selectedComponent.positionData.z - 1 >= 0 && <>
								<TouchableOpacity onPress={() => { const newZ = selectedComponent.positionData.z - 1; if (newZ >= 0) selectedComponent.positionData.z = newZ; this.updateTemplateComponent(selectedComponent, {name: 'Move Back', addToHistory: true}); }} >
									<Text style={{color:'#EEE'}}>Move Back</Text>
								</TouchableOpacity>
								<Text style={{color:'#AAA'}}>|</Text>
							</>}
						</>}
						<IconButton icon="align-horizontal-middle" title="Center Horizontal" entypo={true} size={20} margin={0} color="#EEE" onPress={() => {
							selectedComponent.positionData.centerHorizontally = true;
							selectedComponent.positionData.x = Math.round(screenResolutionX / 2 - selectedComponent.positionData.width / 2);
							this.updateTemplateComponent(selectedComponent, {name: 'Center Horizontally', addToHistory: true});
						}} />
						<Text style={{color:'#AAA'}}>|</Text>
						<IconButton icon="align-horizontal-middle" rotation="90deg" title="Center Vertical" entypo={true} size={20} margin={0} color="#EEE" onPress={() => {
							selectedComponent.positionData.centerVertically = true;
							selectedComponent.positionData.y = Math.round(screenResolutionY / 2 - selectedComponent.positionData.height / 2);
							this.updateTemplateComponent(selectedComponent, {name: 'Center Vertically', addToHistory: true});
						}} />
						<Text style={{color:'#AAA'}}>|</Text>
						<View style={{width: 250}}>
							<Slider
								showValueLeft={true}
								sliderWidth={150}
								minimumValue={0}
								maximumValue={100}
								valueSuffix={'%'}
								valuePreffix={"Opacity: "}
								initialValue={selectedComponent.positionData.opacity}
								onValueChange={(val) => { selectedComponent.positionData.opacity = val; this.updateTemplateComponent(selectedComponent, {name: 'Opacity', addToHistory: true}); }}
							/>
						</View>
						{selectedComponent.type === 'modal' && <>
							<Text style={{color:'#AAA'}}>|</Text>
							<TouchableOpacity onPress={() => { this.props.onEditModalContents(selectedComponent.id); }} >
								<Text style={{color:'#CCC'}}>Edit Modal</Text>
							</TouchableOpacity>
						</>}
					</>}
				</View>
				<View style={{ flex: 1, alignItems: 'center', overflow: 'hidden' }} onLayout={(layoutEvent) => {
					const { width, height } = layoutEvent.nativeEvent.layout;
					this.updateState({ previewSize: { width, height } });
				}}>
					{previewWidth > 0 && <TemplatePagePreview
						style={{ width: Math.min(screenResolutionX, previewWidth - (previewWidth * 0.1)), marginVertical: 40, elevation: 10 }}
						editable={true}
						editorWidth={screenResolutionX}
						editorHeight={screenResolutionY}
						grid={{ showGrid, snapToGrid, gridSize }}
						/* style={{ borderRadius: page.fullScreen ? 0 : page.borderRadius }} */
						backgroundImageURI={this.getImageUri(this.getModelValue('backgroundImage', 'TemplatePage', currentPageID))}
						backgroundColor={this.getModelValue('backgroundColor', 'TemplatePage', currentPageID)}
						components={components}
						onOpenEditor={(componentType, componentID) => {
							if (this.props.onOpenEditor) this.props.onOpenEditor(componentType, componentID);
						}}
						onSelected={(component) => {
							this.updateState({selectedComponent: component});
						}}
						onEditModalContents={this.props.onEditModalContents}
						onUpdate={(updatedComponent, updateType) => this.updateTemplateComponent(updatedComponent, updateType)}
					/>}
				</View>
				{this.renderError()}
			</View>
		);
	}
}
const _EditorWindowInner = connect({
	component: EditorWindowInner,
	loadingName: 'Editor Window',
	models: [
		{ model: 'Kiosk', keyField: 'id', key: (root, props) => props.kioskID, require: false },
		{ model: 'KioskTemplate', keyField: 'id', key: (root, props) => props.id, loadChildren: true },
		{ model: 'DataSet', key: (root, props) => props.previewDataSetID },
		{ model: 'SignagePlaylist', list: true },
		{ model: 'SignagePlaylistItem', list: true },
		{ model: 'ButtonStyle', list: true },
		{ model: 'TextStyle', list: true },
		//{ model: 'TemplatePage', keyField: 'id', key: (root, props) => props.currentPageID, loadChildren: true },
		/*
		{
			model: 'TemplatePage',
			list: true,
			loadChildren: true
		},
		*/
		//{ model: 'TemplateComponent', keyField: 'templatePageID', list: true, useKey: true, loadChildren: true, key: (root, props) => props.currentPageID },
	],
});

class EditorWindow extends ModelEditorComponent {
	constructor(props) {
		super(props);
		this.state = { 
			...this.state, 
			actions: {}, // { pageID: {actionHistory: [], actionFuture: []}, ... }
		}
	}
	componentDidUpdate(prevProps, prevState) {
		super.componentDidUpdate(prevProps, prevState);
		if (this.props.id && prevProps.id != this.props.id) {
			this.resetModel(()=> this.updateState({ actions: {} }));
		} else if (this.props.currentPageID && prevProps.currentPageID != this.props.currentPageID) {
			this.resetModel();
		}
		this.refreshCall = this.createChildCall();
		if (this.props.refreshCall) this.props.refreshCall.fn = () => {
			const stateReserve = this.state.actions; //{ actionHistory: this.state.actionHistory, actionFuture: this.state.actionFuture };
			this.resetModel(() => {
				this.updateState(stateReserve, () => this.refreshCall.call());
			});
		}
	}
	historyUndo() {
		if (!(this.props.currentPageID in this.state.actions)) return;
		const actionHistory = this.state.actions[this.props.currentPageID].actionHistory;
		if (!actionHistory) return;
		if (actionHistory.length === 0) return;
		const action = actionHistory.pop();

		let promise = null;
		if (action.componentType == 'modal') {
			const children = [
				{ name: 'TemplatePositionData', data: action.prevPositionData }
			];
			promise = this.saveModel(false, children, 'TemplatePage', action.componentID);
		} else {
			const children = [
				{ name: 'TemplatePositionData', data: action.prevPositionData }
			];
			promise = this.saveModel(false, children, 'TemplateComponent', action.componentID);
		}

		promise.catch(err => console.log(err)).then(() => this.updateState({
			actions: {
				...this.state.actions, 
				[this.props.currentPageID]: {
					actionHistory: [
						...(this.state.actions[this.props.currentPageID]?.actionHistory || []),
					],
					actionFuture: [
						...(this.state.actions[this.props.currentPageID]?.actionFuture || []), 
						action
					]
				}
			}
		}));
	}
	historyRedo() {
		if (!(this.props.currentPageID in this.state.actions)) return;
		const actionFuture = this.state.actions[this.props.currentPageID].actionFuture;
		if (!actionFuture) return;
		if (actionFuture.length === 0) return;
		const action = actionFuture.pop();

		let promise = null;
		if (action.componentType == 'modal') {
			const children = [
				{ name: 'TemplatePositionData', data: action.positionData }
			];
			promise = this.saveModel(false, children, 'TemplatePage', action.componentID);
		} else {
			const children = [
				{ name: 'TemplatePositionData', data: action.positionData }
			];
			promise = this.saveModel(false, children, 'TemplateComponent', action.componentID);
		}

		promise.catch(err => console.log(err)).then(() => this.updateState({
			actions: {
				...this.state.actions, 
				[this.props.currentPageID]: {
					actionHistory: [
						...(this.state.actions[this.props.currentPageID]?.actionHistory || []), 
						action
					],
					actionFuture: [
						...(this.state.actions[this.props.currentPageID]?.actionFuture || []), 
					],
				}
			}
		}));
	}
	renderContent() {
		const props = this.props;
		const getTemplateValue = this.modelValueGetter('KioskTemplate', props.id);
		const previewDataGroupID = getTemplateValue('previewDataGroupID');
		const previewDataSetID = getTemplateValue('previewDataSetID');

		return (
			<_EditorWindowInner
				id={props.id}
				kioskID={props.kioskID}
				kioskDataSet={props.kioskDataSet}
				previewDataSetID={previewDataSetID}
				previewDataGroupID={previewDataGroupID}
				currentPageID={props.currentPageID}
				refreshCall={this.refreshCall}
				hiddenComponents={props.hiddenComponents}
				onOpenEditor={props.onOpenEditor}
				editingModal={props.editingModal}
				onEditModalContents={props.onEditModalContents}
				actions={this.state.actions}
				onUndo={() => this.historyUndo()}
				onRedo={() => this.historyRedo()}
				onUpdate={(updatedComponent, updateType) => {
					//add to history
					if (updateType && updateType.addToHistory) {
						const prevPosition = this.getModelData('TemplatePositionData', updatedComponent.positionData.id)?.data;
						if (prevPosition) this.updateState({
							actions: {
								...this.state.actions, 
								[props.currentPageID]: {
									actionHistory: [
										...(this.state.actions[props.currentPageID]?.actionHistory || []), 
										{
											componentName: updatedComponent.name,
											componentType: updatedComponent.type,
											componentID: updatedComponent.id,
											prevPositionData: prevPosition,
											positionData: updatedComponent.positionData,
											updateType,
										}
									],
									actionFuture: [],
								}
							}
						});
					}

					//save model position data
					if (updatedComponent.type == 'modal') {
						const children = [
							{ name: 'TemplatePositionData', data: updatedComponent.positionData }
						];
						return this.saveModel(false, children, 'TemplatePage', updatedComponent.id);
					} else {
						const children = [
							{ name: 'TemplateComponent' + updatedComponent.type.substring(0, 1).toUpperCase() + updatedComponent.type.substring(1), data: updatedComponent.component },
							{ name: 'TemplatePositionData', data: updatedComponent.positionData }
						];
						return this.saveModel(false, children, 'TemplateComponent', updatedComponent.id);
					}
				}}
			/>
		)
	}
}
export default EditorWindow = connect({
	component: EditorWindow,
	loadingName: 'Editor Window',
	models: [
		{ model: 'KioskTemplate', keyField: 'id', key: (root, props) => props.id, loadChildren: true },
	]
});
