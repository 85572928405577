import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';
import LinkButton from '../../components/linkButton.component';

import { ModelEditorComponent, connect } from '../ModelComponent';

const resolutions = {
	'iPad': [1112, 834],
	'10.2" iPad': [1080, 810],
	'1080p': [1920, 1080],
	'2k': [2560, 1440],
	'4k': [3840, 2160]
}

class EditorSettings extends ModelEditorComponent {
	constructor(props) {
		super(props);
		this.state = { 
			...this.state,
			orientation: 'landscape',
			resolution: 'iPad',
			nameChanged: false,
		}
	}
	componentDidMount() {
		super.componentDidMount();
		this.checkResolutionUpdate();
	}
	componentDidUpdate(prevProps, prevState) {
		super.componentDidUpdate(prevProps, prevState);
		const orientation = this.state.orientation;
		const resolution = this.state.resolution;
		if (prevState.orientation != orientation || prevState.resolution != resolution) {
			const isLandscape = (orientation === 'landscape');
			this.setModelFields({
				screenResolutionX: isLandscape ? resolutions[resolution][0] : resolutions[resolution][1], 
				screenResolutionY: isLandscape ? resolutions[resolution][1] : resolutions[resolution][0]
			}, 'KioskTemplate', this.props.id);
		}
		this.checkResolutionUpdate(prevState);
	}
	checkResolutionUpdate(prevState) {
		const screenResolutionX = this.getModelValue('screenResolutionX', 'KioskTemplate', this.props.id);
		const screenResolutionY = this.getModelValue('screenResolutionY', 'KioskTemplate', this.props.id);
		const prevScreenResolutionX = this.getModelValueFromState(prevState, 'screenResolutionX', 'KioskTemplate', this.props.id);
		const prevScreenResolutionY = this.getModelValueFromState(prevState, 'screenResolutionY', 'KioskTemplate', this.props.id);

		if (screenResolutionX != prevScreenResolutionX || screenResolutionY != prevScreenResolutionY) {
			for (const resolutionName in resolutions) {
				const resolutionData = resolutions[resolutionName];
				if (screenResolutionX == resolutionData[0] && screenResolutionY == resolutionData[1]) {
					this.updateState({ orientation: 'landscape', resolution: resolutionName });
				} else if (screenResolutionX == resolutionData[1] && screenResolutionY == resolutionData[0]) {
					this.updateState({ orientation: 'portrait', resolution: resolutionName });
				}
			}
		}
	}
	renderContent() {
		const editingPage = !this.props.editingModal;
		const save = ()=> this.saveModel({model: 'KioskTemplate', id: this.props.id}).then(() => { 
			if (this.props.onChange) this.props.onChange(); 
			this.updateState({nameChanged: false});
		}).catch(console.error);
		return (<>
			{editingPage && this.renderModelTextInput({name: 'name', model: 'KioskTemplate', id: this.props.id, onChange: ()=> this.updateState({nameChanged: true})})}
			{editingPage && this.state.nameChanged && <LinkButton title="Save Name" style={{flexDirection: 'row', justifyContent: 'center', marginBottom: 10}} onPress={()=> save()} />}

			{editingPage && this.renderDropdown({
				name: 'Resolution', 
				defaultValue: this.state.resolution, 
				onChange: (resolution) => {
					const orientation = this.state.orientation;
					if (this.state.resolution != resolution) {
						const isLandscape = (orientation === 'landscape');
						this.setModelFields({
							screenResolutionX: isLandscape ? resolutions[resolution][0] : resolutions[resolution][1], 
							screenResolutionY: isLandscape ? resolutions[resolution][1] : resolutions[resolution][0]
						}, 'KioskTemplate', this.props.id).then(()=> this.updateState({resolution}, ()=> save()));
					}
				}, 
				options: Object.keys(resolutions).map(resolutionName => ({label: resolutionName, value: resolutionName}))
			})}

			{editingPage && this.renderRadioButtons({
				value: this.state.orientation,
				options: [
					{label: 'Landscape', value: 'landscape'},
					{label: 'Portrait', value: 'portrait'}
				],
				onChange: (orientation)=> {
					const resolution = this.state.resolution;
					if (this.state.orientation != orientation) {
						const isLandscape = (orientation === 'landscape');
						this.setModelFields({
							screenResolutionX: isLandscape ? resolutions[resolution][0] : resolutions[resolution][1], 
							screenResolutionY: isLandscape ? resolutions[resolution][1] : resolutions[resolution][0]
						}, 'KioskTemplate', this.props.id).then(()=> this.updateState({orientation}, ()=> save()))
					}
				}
			})}

			<View style={{flexDirection: 'row', gap: 25}}>
				{this.renderModelCheckBox({name: 'snapToGrid', model: 'KioskTemplate', id: this.props.id, onChange: ()=> save()})}

				{this.renderModelCheckBox({name: 'showGrid', model: 'KioskTemplate', id: this.props.id, onChange: ()=> save()})}
			</View>

			{this.renderModelSlider({name: 'gridSize', label: 'Grid Size', valueSuffix: 'px', style:{marginTop: 7}, model: 'KioskTemplate', id: this.props.id, onChange: ()=> save()})}
		</>);
	}
}

export default EditorSettings = connect({
	component: EditorSettings,
	models: [{
		model: 'KioskTemplate',
	}]
});
