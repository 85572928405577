import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { ModelModal, connect } from '../../ModelComponent';

class AddTextStyleModal extends ModelModal { // todo: extend from APIModal + make APIModal
	constructor(props) {
		super(props, "Add Text Style");
	}
	renderModal() {
		const form = this.getFormHelper('TextStyle', 'default');
		return (<>
			{form.textInput('name')}
			{form.textInput('description', true)}
		</>);
	}
	renderModalBottom() {
		const form = this.getFormHelper('TextStyle', 'default');
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Cancel" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Add" style={{ width: 100 }} onPress={() => {
						form.setModelFields({ 'organisationID': this.userData.organisationID, 'siteID': this.userData.siteID, 'parentID': this.props.parentID })
						.then(() => form.create().then(() => this.props.closeModal()).catch(console.error))
					}} />
				</View>
			</View>
		</>);
	}
}
export default AddTextStyleModal = connect({
	component: AddTextStyleModal,
	models: [{
		model: 'TextStyle',
	}]
});